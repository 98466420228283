import React from 'react';
import PropTypes from 'prop-types';
import { FLASH_MIRROR_FIELD } from '../../../../constants/orderFields';
import { Field } from 'redux-form';
import SelectInput from '../../../default_redux/SelectInput';
import * as Validators from '../../../../utils/Validators';

export default class RxOptionFlashMirror extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange (e) {
    const flashMirrorID = e.target.value || "";
    const { loadProductRxLens } = this.props;

    loadProductRxLens({
      fetchName: 'flashMirrorChange',
      flashMirror: flashMirrorID,
      loadingTint: true,
    });
  }

  render () {
    const { show, label, values, required } = this.props;
    if(!show) return null;

    return (
      <Field component={SelectInput}
        name={FLASH_MIRROR_FIELD}
        label={label}
        options={
          values.map((flashMirror) => {
            return({
              name: flashMirror.name,
              value: flashMirror.id
            });
          })
        }
        selectFirst={false}
        labelClass='col-xs-3'
        selectWrapperClass='col-sm-6'
        required={required}
        handleChange={this.handleChange.bind(this)}
        validate={[Validators.required.bind(null, required)]}
        placeholder="(No Flash Mirror)"
      />
    );
  }
}
RxOptionFlashMirror.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  values: PropTypes.array,
  loadProductRxLens: PropTypes.func,
};

RxOptionFlashMirror.defaultProps = {
  label: "Flash Mirror",
  show: false,
  required: false,
  loadProductRxLens: () => {},
  values: []
};
