import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Panel, Form } from "react-bootstrap";
import isBoolean from "lodash/isBoolean";
import isUndefined from "lodash/isUndefined";
import isPresent from "utils/isPresent";
import isDiffSPH from "utils/isDiffSPH";
import { connect } from "react-redux";
import { reduxForm, formValueSelector, Field } from "redux-form";
import getIn from "redux-form/lib/structure/plain/getIn";
// import setIn from 'redux-form/lib/structure/plain/setIn';

import SelectUser from "components/shared/SelectUser";
import RxSelectProduct from "components/ordering/redux_rx_lens/RxSelectProduct";
import RxCustomerForm from "components/ordering/redux_rx_lens/RxCustomerForm";
import RxPrivateNote from "components/ordering/redux_rx_lens/RxPrivateNote";
import RxNoteOnLabel from 'components/ordering/redux_rx_lens/RxNoteOnLabel';

import GlazingType from "components/ordering/glazing/GlazingType";
import FrameType from "components/ordering/glazing/FrameType";
import FrameMaterial from "components/ordering/glazing/FrameMaterial";
import PreOptThickness from "components/ordering/redux_rx_lens/glazing/PreOptThickness";
import FrameShape from "components/ordering/redux_rx_lens/glazing/FrameShape";
import FrameToCome from "components/ordering/redux_rx_lens/glazing/FrameToCome";
import FrameId from "components/ordering/redux_rx_lens/glazing/FrameId";
import FrameA from "components/ordering/redux_rx_lens/glazing/FrameA";
import FrameB from "components/ordering/redux_rx_lens/glazing/FrameB";
import FrameDBL from "components/ordering/redux_rx_lens/glazing/FrameDBL";

import ShapeType from "components/ordering/redux_rx_lens/glazing/ShapeType";
import RoundShape from "components/ordering/redux_rx_lens/glazing/RoundShape";
import OvalShape from "components/ordering/redux_rx_lens/glazing/OvalShape";

import Corridor from "components/ordering/redux_rx_lens/options/Corridor";
import RxOptionTint from "components/ordering/redux_rx_lens/options/Tint";
import RxOptionUv from "components/ordering/redux_rx_lens/options/Uv";
import RxOptionFlashMirror from "components/ordering/redux_rx_lens/options/FlashMirror";
import RxOptionDegression from "components/ordering/redux_rx_lens/options/Degression";
import RxOptionExpress from "components/ordering/redux_rx_lens/options/Express";

import RxPrescriptionForm from "components/ordering/redux_rx_lens/prescription_form/RxPrescriptionForm";
import RxOrderConfirmModal from "components/ordering/redux_rx_lens/RxOrderConfirmModal";

import Loading from "components/shared/Loading";
import StepHeader from "components/shared/StepHeader";

import { required as requiredValidate } from "utils/Validators";

import {
  ROUND_OPTION,
  OVAL_OPTION,
  OPTICAL_INFO_ATTR,
  OPT_THICKNESS,
  LENS_ID,
  AGENT_ID,
  LEFT_SIDE,
  RIGHT_SIDE,
  QUANTITY,
  FRAME_TYPE,
  FRAME_MATERIAL_ID,
  CYL,
  ADD,
  SPH,
  AXIS,
  FRAME_TO_COME,
  FRAME_LIBRARY_ID,
  DECENTER,
  PRISM,
  FAR_PD,
  FLASH_MIRROR,
  CUTTING,
  PATIENT,
  TEST_ID
} from "constants/mapOrderColumns";
import {
  AGENT_ID_FIELD,
  PATIENT_FIELD,
  OPT_THICKNESS_FIELD,
  FLASH_MIRROR_FIELD,
  CUTTING_FILED,
  TINT_ID_FIELD,
  TINT_VALUE_FIELD,
  SHAPE_TYPE_FIELD,
  ROUND_SHAPE_FIELD,
  FRAME_SHAPE_FIELD,
  ORIGINAL_SHAPE_FILE_FIELD,
  INSET_TYPE_FIELD,
  LEFT_LENS_ID_FIELD,
  RIGHT_LENS_ID_FIELD,
  LEFT_QUANTITY_FIELD,
  RIGHT_QUANTITY_FIELD,
  LEFT_SPH_FIELD,
  RIGHT_SPH_FIELD,
  LEFT_CYL_FIELD,
  RIGHT_CYL_FIELD,
  LEFT_ADD_FIELD,
  RIGHT_ADD_FIELD,
  LEFT_AXIS_FIELD,
  RIGHT_AXIS_FIELD,
  FRAME_TYPE_FIELD,
  FRAME_MATERIAL_ID_FIELD,
  FRAME_ID_FIELD,
  FRAME_TO_COME_FIELD,
  FRAME_SHAPE_VALID_FIELD
} from "constants/orderFields";
import { RX_ORDER as RX_ORDER_FORM_NAME } from "constants/reduxFormNames";

class RxLensOrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agentId: this.props.currentUserId,
      selectProduct: null,
      confirm: false,
      nextNumber: null,
      shapeType: null,
      loading: {
        product: false,
        tint: false,
        glazing: true,
        glazingFields: true,
        prescription: true,
        frameShapeFields: true
      },
      order: {}
    };

    this.lensInputs = this.lensInputs.bind(this);
  }

  onConfirm(values) {
    this.setState({ confirm: true, order: values.order });
  }

  handleAfterModalShow(_e) {
    this.refs.confirmBtn.innerHTML = this.props.locales.buttons.confirm;
  }

  handleAfterModalHidden(_e) {
    this.refs.confirmBtn.disabled = false;
    this.setState({ confirm: false });
  }

  loadProductEnabledFields(options = {}) {
    const { loadProductRxLens, searchingProducts } = this.props.actions;
    searchingProducts(true);

    const loading = this.props.loading || {};
    loading.product = true;
    loading.tint = options.loadingTint || false;
    loading.glazing = options.loadingGlazing || false;
    loading.glazingFields = options.loadingGlazingFields || false;
    loading.frameShapeFields = options.loadingframeShapeField || false;
    loading.frameIdFields = options.loadingframeIdField || false;
    loading.prescription = options.loadingPrescription || false;

    const order = {};
    const sku = options.sku || this.props.product.sku;
    const cutting = options.cutting || this.props.cutting;
    const pre_optimized = isBoolean(options.optThickness)
      ? options.optThickness
      : this.props.optThickness;
    const frame_type = options.frameType || this.props.frameType;
    const frame_to_come = options.frameToCome || this.props.frameToCome;
    const frame_library_id = options.frameLibraryId || this.props.frameLibraryId;
    const user_id = this.props.order.user_id;
    order[OPTICAL_INFO_ATTR] = {};
    order[OPTICAL_INFO_ATTR][CUTTING] = cutting;
    order[OPTICAL_INFO_ATTR][OPT_THICKNESS] = pre_optimized;
    order[OPTICAL_INFO_ATTR][FRAME_TYPE] = frame_type;
    order[OPTICAL_INFO_ATTR][FRAME_TO_COME] = frame_to_come;
    order[OPTICAL_INFO_ATTR][FRAME_LIBRARY_ID] = frame_library_id;
    order[AGENT_ID] = user_id;

    // need to explicitly check whether flashMirror has been set, because
    // blank (unset) values are valid
    let flashMirror;
    if (isUndefined(options.flashMirror)) {
      flashMirror = this.props.flashMirror;
    } else {
      flashMirror = options.flashMirror;
    }
    order[OPTICAL_INFO_ATTR][FLASH_MIRROR] = flashMirror;

    const { leftPower, rightPower } = this.props;
    if (leftPower && leftPower[QUANTITY] > 0) order[LEFT_SIDE] = leftPower;
    if (rightPower && rightPower[QUANTITY] > 0) order[RIGHT_SIDE] = rightPower;

    let fetchName = options.fetchName || "loadProductEnabledFields";
    const loadCallback = _product => {
      searchingProducts(false);
    };
    const beforeRetryCallback = () => {};

    loadProductRxLens(
      sku,
      order,
      loading,
      fetchName,
      loadCallback,
      beforeRetryCallback
    );
  }

  resetOrder() {
    const { leftPower, rightPower } = this.props;
    this.props.resetOrder(leftPower, rightPower);
    this.props.reset();
  }

  renderWarnings() {
    const { valid } = this.props;
    const { showWarnings } = this.state;
    if (!showWarnings || valid) return null;
    const {
      locales: {
        hints: { formInvalid: formInvalidText }
      }
    } = this.props;
    return (
      <Row>
        <Col xs={12}>
          <p
            className="text-danger text-center"
            dangerouslySetInnerHTML={{ __html: formInvalidText }}
          ></p>
        </Col>
      </Row>
    );
  }

  showFormWarnings() {
    const { invalid } = this.props;
    const showWarnings = invalid;
    this.setState({ showWarnings });
  }

  render() {
    const { order, confirm } = this.state;
    const {
      product,
      handleSubmit,
      change,
      locales,
      formConfirmUrl,
      s3SignUrl,
      formUrl,
      shapeFile,
      formAction,
      productChangeFetchError,
      leftPower,
      rightPower,
      productsResponse,
      actions: { storeProductRxLens, searchProductsByPower }
    } = this.props;
    return (
      <Form
        className="form-horizontal"
        onSubmit={handleSubmit(this.onConfirm.bind(this))}
      >
        <Row>
          <Col xs={12}>
            <RxSelectProduct
              productsResponse={productsResponse}
              loadProductRxLens={this.loadProductEnabledFields.bind(this)}
              resetOrder={this.resetOrder.bind(this)}
              changeOrder={change}
              clearProduct={storeProductRxLens.bind(null)}
              searchProductsByPower={searchProductsByPower.bind(null)}
              fetchError={productChangeFetchError}
              selectedProduct={product}
              locales={locales}
            />
          </Col>
          {!productChangeFetchError && (
            <div>
              <Col xs={12}>{this.lensInputs()}</Col>
              {product != null && (
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>
                      <Panel className="panel__bg--gray">
                        <RxNoteOnLabel
                          label={locales.order.noteOnLabel}
                          placeholder={locales.placeholders.noteOnLabel}
                          helpText={locales.hints.noteOnLabelHelpText}
                        />
                      </Panel>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Panel className="panel__bg--gray">
                        <Panel.Body>
                          <RxPrivateNote
                            label={locales.order.privateNote}
                            placeholder={locales.placeholders.privateNote}
                            helpText={locales.hints.privateNoteHelpText}
                          />
                        </Panel.Body>
                      </Panel>
                    </Col>
                  </Row>
                  <Row>
                    <Col xsOffset={3} xs={6}>
                      <Button
                        type="submit"
                        ref="confirmBtn"
                        bsStyle="primary"
                        block
                        onClick={this.showFormWarnings.bind(this)}
                      >
                        {locales.buttons.confirm}
                      </Button>
                      {this.renderWarnings.bind(this)()}
                    </Col>
                  </Row>
                </Col>
              )}
              {confirm && (
                <RxOrderConfirmModal
                  formId={"rx-lens-ordering"}
                  formAction={formAction}
                  formUrl={formUrl}
                  confirmUrl={formConfirmUrl}
                  order={order}
                  s3SignUrl={s3SignUrl}
                  afterShow={this.handleAfterModalShow.bind(this)}
                  afterHidden={this.handleAfterModalHidden.bind(this)}
                  locales={locales}
                  shapeFile={shapeFile}
                  diffSPH={isDiffSPH(
                    getIn(leftPower, SPH),
                    getIn(rightPower, SPH)
                  )}
                />
              )}
            </div>
          )}
        </Row>
      </Form>
    );
  }

  lensInputs() {
    const { product } = this.props;
    if (!product) return null;
    // if( this.props.loading.product) return <Loading />;
    const {
      sources: { searchAgentsUrl },
      locales,
      locales: {
        order: { patient: patientLabel, userId: userIdLabel }
      }
    } = this.props;
    return (
      <Panel>
        <Panel.Body>
          <Row>
            <Col xsOffset={2} xs={8}>
              <StepHeader
                tag="h2"
                number="3"
                desc={locales.page.steps.fillOrdering}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Panel className="panel__bg--gray">
                <Panel.Body>
                  <Row>
                    <Col xsOffset={2} xs={8}>
                      <SelectUser
                        label={userIdLabel}
                        name={AGENT_ID_FIELD}
                        loadUrl={searchAgentsUrl}
                        labelClass="col-xs-3"
                        inputClass="col-xs-6"
                        liveSearch={true}
                        selectFirst={true}
                        required={true}
                      />
                      <RxCustomerForm label={patientLabel} name={`order[${PATIENT}]`} />
                    </Col>
                  </Row>
                </Panel.Body>
              </Panel>
            </Col>
          </Row>

          {this.renderParticularities.bind(this)()}

          <Row>
            <Col xs={12}>{this.renderGlazingBox.bind(this)()}</Col>
          </Row>
          <Row>
            <Col xs={12}>{this.renderPrescriptionBox.bind(this)()}</Col>
          </Row>
        </Panel.Body>
      </Panel>
    );
  }

  renderParticularities() {
    const {
      product: {
        properties: {
          enabled_corridor,
          enabled_tint,
          enabled_uv,
          enabled_degression,
          enabled_flash_mirror
        }
      },
      extraFields: { enabled_express },
      change,
      loading
    } = this.props;
    if (
      !(
        enabled_corridor.show ||
        enabled_tint.show ||
        enabled_uv.show ||
        enabled_flash_mirror.show ||
        enabled_degression.show ||
        enabled_express.show
      )
    )
      return null;
    const {
      tintId,
      tintValue,
      locales,
      product: {
        properties: {
          corridor_values,
          tint_values,
          flash_mirror_values,
          degression_values
        }
      }
    } = this.props;

    return (
      <Row>
        <Col xs={12}>
          <Panel className="panel__bg--gray">
            <Panel.Body>
              <Row>
                <Col xsOffset={2} xs={8}>
                  {enabled_express.show && (
                    <RxOptionExpress label="Express" {...enabled_express} />
                  )}
                  {enabled_degression.show && (
                    <RxOptionDegression
                      label="Degression"
                      {...enabled_degression}
                      {...degression_values}
                    />
                  )}
                  {enabled_corridor.show && (
                    <Corridor
                      label="Corridor"
                      {...enabled_corridor}
                      values={corridor_values}
                    />
                  )}
                  {enabled_flash_mirror.show && (
                    <RxOptionFlashMirror
                      label="Flash Mirror"
                      {...enabled_flash_mirror}
                      values={flash_mirror_values}
                      loadProductRxLens={this.loadProductEnabledFields.bind(
                        this
                      )}
                    />
                  )}
                  {enabled_tint.show && (
                    <RxOptionTint
                      label="Tint"
                      labelPecentage="Tint %"
                      tintId={tintId}
                      tintValue={tintValue}
                      locales={locales}
                      {...enabled_tint}
                      tintValues={tint_values}
                      changeOrder={change}
                      loading={loading.tint}
                    />
                  )}
                  {enabled_uv.show && (
                    <RxOptionUv label="UV Protection" {...enabled_uv} />
                  )}
                </Col>
              </Row>
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    );
  }

  changeGlazingType(cutting) {
    this.loadProductEnabledFields({
      fetchName: "glazingChange",
      cutting,
      optThickness: false,
      loadingGlazingFields: true
    });
  }

  changeFrameType(type) {
    this.loadProductEnabledFields({
      fetchName: "frameTypeChange",
      frameType: type,
      loadingframeShapeField: true,
      loadingframeIdField: true
    });
  }

  convertFieldName(field) {
    return field;
  }

  renderGlazingBox() {
    const { product } = this.props;
    if (!product) return <Loading size="lg" />;
    const { loading, locales } = this.props;
    if (loading.glazing) return <Loading size="lg" />;
    const {
      optThickness,
      glazingChangeFetchError,
      product: {
        properties: { enabled_glazing_type, glazing_type_values, enabled_test_id }
      },
      locales: {
        order: { testId: testIdLabel }
      }
    } = this.props;
    return (
      <Panel className="panel__bg--gray">
        <Panel.Body>
          <Row>
            <Col xsOffset={2} xs={8}>
              {enabled_test_id.show && (<RxCustomerForm label={testIdLabel} name={`order[${OPTICAL_INFO_ATTR}][${TEST_ID}]`} />)}
              {enabled_glazing_type.show && (
                <GlazingType
                  label="Glazing"
                  name={`order[${OPTICAL_INFO_ATTR}][${CUTTING}]`}
                  {...enabled_glazing_type}
                  options={glazing_type_values}
                  onChangeGlazingType={this.changeGlazingType.bind(this)}
                  optThickness={optThickness}
                  fetchError={glazingChangeFetchError}
                  locales={locales}
                />
              )}
              {!isPresent(glazingChangeFetchError) &&
                this.renderGlazingFields.bind(this)()}
            </Col>
          </Row>
        </Panel.Body>
      </Panel>
    );
  }

  renderGlazingFields() {
    if (this.props.loading.glazingFields)
      return (
        <Row>
          <Col xsOffset={3} xs={6}>
            <Loading size="md" />
          </Col>
        </Row>
      );
    const {
      change,
      optThicknessChangeFetchError,
      locales,
      product: {
        properties: {
          enabled_pre_optimized,
          enabled_frame_type,
          frame_type_values,
          enabled_frame_material,
          frame_material_values
        }
      }
    } = this.props;
    return (
      <div>
        {enabled_frame_type.show && (
          <FrameType
            name={`order[${OPTICAL_INFO_ATTR}][${FRAME_TYPE}]`}
            label="Frame Type"
            {...enabled_frame_type}
            options={frame_type_values}
            onChangeFrameType={this.changeFrameType.bind(this)}
          />
        )}
        {enabled_frame_material.show && (
          <FrameMaterial
            name={`order[${OPTICAL_INFO_ATTR}][${FRAME_MATERIAL_ID}]`}
            label="Frame Material"
            {...enabled_frame_material}
            options={frame_material_values}
          />
        )}
        {enabled_pre_optimized.show && (
          <PreOptThickness
            label="Pre-optimized Thickness"
            {...enabled_pre_optimized}
            changeOrder={change}
            loadProductRxLens={this.loadProductEnabledFields.bind(this)}
            fetchError={optThicknessChangeFetchError}
            locales={locales}
          />
        )}
        {!isPresent(optThicknessChangeFetchError) &&
          this.renderFrameShapeFields.bind(this)()}
        {!isPresent(optThicknessChangeFetchError) &&
          this.renderCutMountFrameShapeFields.bind(this)()}
      </div>
    );
  }

  renderFrameShapeFields() {
    const {
      optThickness,
      frameShape,
      shapeFile,
      shapeType,
      change,
      s3SignUrl,
      loading,
      product: {
        properties: {
          enabled_frame_shape,
          enabled_select_frame_shape,
          select_frame_shape_values,
          enabled_frame_library,
          frame_library_values,
          enabled_upload_frame_shape,
          upload_frame_shape_values,
          enabled_frame_id,
          frame_id_values,
          enabled_frame_a,
          frame_a_values,
          enabled_frame_b,
          frame_b_values,
          enabled_frame_dbl,
          frame_dbl_values,
          enabled_shape_type,
          shape_type_values,
          enabled_round_shape,
          round_shape_values,
          enabled_oval_shape,
          oval_shape_values
        }
      }
    } = this.props;
    if (loading.frameShapeFields && enabled_frame_shape.show)
      return (
        <Row>
          <Col xsOffset={3} xs={6}>
            <Loading size="md" position="left" />
          </Col>
        </Row>
      );
    return (
      <div>
        {enabled_frame_shape.show && (
          <Field
            component={FrameShape}
            name={FRAME_SHAPE_VALID_FIELD}
            label="Frame Shape"
            {...enabled_frame_shape}
            show={enabled_frame_shape.show && optThickness}
            selectFrameShape={{
              enabled_frame_library,
              frame_library_values,
              enabled_select_frame_shape,
              select_frame_shape_values,
              frameShape
            }}
            uploadFrameShape={{
              enabled_upload_frame_shape,
              upload_frame_shape_values,
              shapeFile
            }}
            selectFrameId={{
              enabled_frame_id,
              frame_id_values
            }}
            convertName={this.convertFieldName.bind(this)}
            change={change}
            s3SignUrl={s3SignUrl}
            loading={loading.frameShapeField}
            validate={[
              requiredValidate.bind(null, enabled_frame_shape.required)
            ]}
            loadProductRxLens={this.loadProductEnabledFields.bind(
              this
            )}
          />
        )}
        {enabled_frame_a.show && optThickness && (
          <FrameA
            label="A"
            {...enabled_frame_a}
            show={enabled_frame_a.show && optThickness}
            {...frame_a_values}
          />
        )}
        {enabled_frame_b.show && optThickness && (
          <FrameB
            label="B"
            {...enabled_frame_b}
            show={enabled_frame_b.show && optThickness}
            {...frame_b_values}
          />
        )}
        {enabled_frame_dbl.show && optThickness && (
          <FrameDBL
            label="DBL"
            {...enabled_frame_dbl}
            show={enabled_frame_dbl.show && optThickness}
            {...frame_dbl_values}
          />
        )}
        {enabled_shape_type.show && !optThickness && (
          <ShapeType
            label="Shape"
            {...enabled_shape_type}
            show={enabled_shape_type.show && !optThickness}
            options={shape_type_values}
          />
        )}
        {enabled_round_shape.show &&
          !optThickness &&
          shapeType == ROUND_OPTION && (
            <RoundShape
              label="Round Shape Type"
              {...enabled_round_shape}
              show={
                enabled_round_shape.show &&
                !optThickness &&
                shapeType == ROUND_OPTION
              }
              {...round_shape_values}
              changeOrder={this.props.change}
              roundType={this.props.roundType}
            />
          )}
        {enabled_oval_shape.show &&
          !optThickness &&
          shapeType == OVAL_OPTION && (
            <OvalShape
              label="Oval Shape Type"
              {...enabled_oval_shape}
              show={
                enabled_oval_shape.show &&
                !optThickness &&
                shapeType == OVAL_OPTION
              }
              options={oval_shape_values}
              roundType={this.props.roundType}
            />
          )}
      </div>
    );
  }

  renderCutMountFrameShapeFields() {
    const {
      loading,
      product: {
        properties: { enabled_frame_id, frame_id_values, enabled_frame_to_come }
      }
    } = this.props;
    return (
      <div>
        {enabled_frame_to_come.show && (
          <FrameToCome
            label="Frame to come"
            loadProductRxLens={this.loadProductEnabledFields.bind(this)}
            {...enabled_frame_to_come}
          />
        )}
        <FrameId
          name={FRAME_ID_FIELD}
          label="Frame Id"
          values={frame_id_values}
          show={enabled_frame_id.show}
          loading={loading.frameIdFields}
          {...enabled_frame_id}
          validate={[requiredValidate.bind(null, enabled_frame_id.required)]}
        />
      </div>
    );
  }

  renderPrescriptionBox() {
    const { product } = this.props;
    if (!product) return <Loading size="lg" />;
    const { loading } = this.props;
    if (loading.prescription) return <Loading size="lg" />;
    const {
      change,
      insetType,
      rightQuantity,
      leftQuantity,
      leftCyl,
      rightCyl,
      leftDecenter,
      rightDecenter,
      leftPrism,
      rightPrism,
      leftFarPD,
      rightFarPD,
      locales
    } = this.props;
    return (
      <RxPrescriptionForm
        changeOrder={change}
        product={product}
        insetType={insetType}
        rightQuantity={rightQuantity}
        leftQuantity={leftQuantity}
        leftCyl={leftCyl}
        rightCyl={rightCyl}
        leftDecenter={leftDecenter}
        rightDecenter={rightDecenter}
        leftPrism={leftPrism}
        rightPrism={rightPrism}
        leftFarPD={leftFarPD}
        rightFarPD={rightFarPD}
        locales={locales}
      />
    );
  }
}

RxLensOrderForm.propTypes = {
  products: PropTypes.array,
  currentUserId: PropTypes.number,
  formConfirmUrl: PropTypes.string,
  formUrl: PropTypes.string,
  formAction: PropTypes.string,
  s3SignUrl: PropTypes.string,
  customers: PropTypes.array,
  nextOrderCodeUrl: PropTypes.string,
  sources: PropTypes.object,
  indexes: PropTypes.array,
  designs: PropTypes.array,
  frameType: PropTypes.string,
  frameMaterialId: PropTypes.number,
  frameToCome: PropTypes.bool,
  extraFields: PropTypes.shape({
    enabled_express: PropTypes.shape({
      show: PropTypes.bool
    })
  }).isRequired,
  resetOrder: PropTypes.func.isRequired,

  // Redux store
  order: PropTypes.object,
  product: PropTypes.object,
  actions: PropTypes.shape({
    updateRxLens: PropTypes.func.isRequired,
    loadProductRxLens: PropTypes.func.isRequired,
    storeProductRxLens: PropTypes.func.isRequired,
    searchingProducts: PropTypes.func.isRequired
  }),
  loading: PropTypes.shape({
    product: PropTypes.bool,
    tint: PropTypes.bool,
    glazing: PropTypes.bool,
    glazingFields: PropTypes.bool,
    frameShapeFields: PropTypes.bool,
    prescription: PropTypes.bool
  }),

  // Redux Form
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  reset: PropTypes.func,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,

  agentId: PropTypes.any,
  patient: PropTypes.string,
  flashMirror: PropTypes.any,
  cutting: PropTypes.string,
  tintId: PropTypes.any,
  tintValue: PropTypes.any,
  optThickness: PropTypes.any,
  shapeType: PropTypes.any,
  roundType: PropTypes.string,
  frameShape: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shapeFile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  insetType: PropTypes.any,

  leftPower: PropTypes.shape({
    [QUANTITY]: PropTypes.number,
    [CYL]: PropTypes.any,
    [ADD]: PropTypes.string,
    [SPH]: PropTypes.string,
    [AXIS]: PropTypes.string
  }),
  leftQuantity: PropTypes.any,
  leftDecenter: PropTypes.any,
  leftPrism: PropTypes.any,
  leftFarPD: PropTypes.any,
  leftCyl: PropTypes.any,
  leftSph: PropTypes.any,
  leftAdd: PropTypes.any,
  leftAxis: PropTypes.any,

  rightPower: PropTypes.shape({
    [QUANTITY]: PropTypes.number,
    [CYL]: PropTypes.string,
    [ADD]: PropTypes.string,
    [SPH]: PropTypes.string,
    [AXIS]: PropTypes.string
  }),
  rightQuantity: PropTypes.any,
  rightDecenter: PropTypes.any,
  rightPrism: PropTypes.any,
  rightFarPD: PropTypes.any,
  rightCyl: PropTypes.any,
  rightSph: PropTypes.any,
  rightAdd: PropTypes.any,
  rightAxis: PropTypes.any,

  productChangeFetchError: PropTypes.object,
  glazingChangeFetchError: PropTypes.object,
  optThicknessChangeFetchError: PropTypes.object,

  productsResponse: PropTypes.shape({
    url: PropTypes.string,
    response: PropTypes.shape({
      data: PropTypes.array,
      meta: PropTypes.object
    })
  }),

  // locales
  locales: PropTypes.shape({
    order: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      patient: PropTypes.string.isRequired,
      testId: PropTypes.string.isRequired,
    }).isRequired,
    buttons: PropTypes.shape({
      confirm: PropTypes.string.isRequired,
      close: PropTypes.string
    }).isRequired,
    page: PropTypes.shape({
      steps: PropTypes.shape({
        selectLens: PropTypes.string.isRequired
      })
    }),
    modals: PropTypes.shape({
      titles: PropTypes.shape({
        productList: PropTypes.string
      })
    }),
    placeholders: PropTypes.shape({
      searchProducts: PropTypes.string
    }),
    hints: PropTypes.shape({
      notFoundProducts: PropTypes.string
    })
  }).isRequired
};

RxLensOrderForm.defaultProps = {
  indexes: [],
  designs: []
};

// Decorate the form component
let form = reduxForm({
  form: RX_ORDER_FORM_NAME
})(RxLensOrderForm);

const selector = formValueSelector(RX_ORDER_FORM_NAME); // <-- same as form name
form = connect((state, props) => {
  const leftPower = {
    [LENS_ID]: selector(state, LEFT_LENS_ID_FIELD),
    [QUANTITY]: selector(state, LEFT_QUANTITY_FIELD),
    [SPH]: selector(state, LEFT_SPH_FIELD),
    [CYL]: selector(state, LEFT_CYL_FIELD),
    [ADD]: selector(state, LEFT_ADD_FIELD),
    [AXIS]: selector(state, LEFT_AXIS_FIELD)
  };
  const rightPower = {
    [LENS_ID]: selector(state, RIGHT_LENS_ID_FIELD),
    [QUANTITY]: selector(state, RIGHT_QUANTITY_FIELD),
    [SPH]: selector(state, RIGHT_SPH_FIELD),
    [CYL]: selector(state, RIGHT_CYL_FIELD),
    [ADD]: selector(state, RIGHT_ADD_FIELD),
    [AXIS]: selector(state, RIGHT_AXIS_FIELD)
  };

  const initialOrder = props.order || {};

  const patient = selector(state, PATIENT_FIELD);
  const agentId = selector(state, AGENT_ID_FIELD);

  const optThickness = selector(state, OPT_THICKNESS_FIELD);
  const flashMirror = selector(state, FLASH_MIRROR_FIELD);
  const cutting = selector(state, CUTTING_FILED);
  const tintId = selector(state, TINT_ID_FIELD);
  const tintValue = selector(state, TINT_VALUE_FIELD);
  const shapeType = selector(state, SHAPE_TYPE_FIELD);
  const roundType = selector(state, ROUND_SHAPE_FIELD);
  const frameShape = selector(state, FRAME_SHAPE_FIELD);
  const shapeFile = selector(state, ORIGINAL_SHAPE_FILE_FIELD);
  const insetType = selector(state, INSET_TYPE_FIELD);
  const frameType = selector(state, FRAME_TYPE_FIELD);
  const frameMaterialId = selector(state, FRAME_MATERIAL_ID_FIELD);
  const frameToCome = selector(state, FRAME_TO_COME_FIELD);

  const leftQuantity = selector(state, `order.${LEFT_SIDE}.${QUANTITY}`);
  const leftCyl = selector(state, LEFT_CYL_FIELD);
  const leftDecenter = selector(state, `order.${LEFT_SIDE}.${DECENTER}`);
  const leftPrism = selector(state, `order.${LEFT_SIDE}.${PRISM}`);
  const leftFarPD = selector(state, `order.${LEFT_SIDE}.${FAR_PD}`);

  const rightQuantity = selector(state, `order.${RIGHT_SIDE}.${QUANTITY}`);
  const rightCyl = selector(state, RIGHT_CYL_FIELD);
  const rightDecenter = selector(state, `order.${RIGHT_SIDE}.${DECENTER}`);
  const rightPrism = selector(state, `order.${RIGHT_SIDE}.${PRISM}`);
  const rightFarPD = selector(state, `order.${RIGHT_SIDE}.${FAR_PD}`);

  return {
    initialValues: { order: initialOrder },
    patient,
    agentId,
    flashMirror,
    cutting,
    tintId,
    tintValue,
    optThickness,
    frameShape,
    shapeFile,
    shapeType,
    roundType,
    insetType,
    frameType,
    frameMaterialId,
    frameToCome,
    leftPower,
    rightPower,
    leftQuantity,
    rightQuantity,
    leftCyl,
    rightCyl,
    leftDecenter,
    rightDecenter,
    leftPrism,
    rightPrism,
    leftFarPD,
    rightFarPD
  };
})(form);
export default form;
