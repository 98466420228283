import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SelectInput from '../../../default_redux/SelectInput';
import { required as requiredValidate } from '../../../../utils/Validators';
import Loading from '../../../../components/shared/Loading';

export default class FrameId extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    const { show, loading, name, label, values, required } = this.props;
    if(loading) return <Loading size="md" />;
    if(!show) return null;

    return (
      <div>
        <Field component={SelectInput}
          name={name}
          label={label}
          options={
            values.map((value) => {
              return({
                name: value.number,
                value: value.id
              });
            })
          }
          liveSearch={true}
          labelClass='col-xs-3'
          selectWrapperClass='col-sm-6'
          required={required}
          validate={[requiredValidate.bind(null, required)]}
        />
      </div>
    );
  }
}
FrameId.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.array,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  show: PropTypes.bool,
};

FrameId.defaultProps = {
  label: 'Frame Id',
  required: false,
  show: false,
  loading: false
};
