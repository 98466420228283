import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { ET as ETColumn } from '../../../../constants/mapOrderColumns';
import TextInput from '../../../default_redux/TextInput';
import {
  required as requiredValidate,
  max as maxValidate,
  min as minValidate,
  period as periodValidate
} from '../../../../utils/Validators';

export default class ET extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    if(!this.props.show) return null;
    // console.log(`Render ${this.props.label}`);
    const {
      label, attrNestedName, required,
      min, max, period
    } = this.props;
    return (
      <Field component={TextInput}
        label={label}
        type="number"
        step="any"
        name={`order${attrNestedName}[${ETColumn}]`}
        suffixAddon={`${min}-${max} mm.`}
        inputClass="col-xs-8"
        labelClass="col-xs-4"
        required={required}
        validate={[
          requiredValidate.bind(null, required),
          minValidate.bind(null, min),
          maxValidate.bind(null, max),
          periodValidate.bind(null, period)
        ]}
      />
    );
  }
}

ET.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  period: PropTypes.number,
  default: PropTypes.number,
  attrNestedName: PropTypes.string,
};

ET.defaultProps = {
  label: "ET",
  show: false,
  required: false,
  min: 0,
  max: 0,
  period: 0,
  default: null,
  attrNestedName: '',
};
