import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Panel } from "react-bootstrap";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import TextInput from "components/default_redux/TextInput";
import SelectProductsInput from "components/default_redux/SelectProductsInput";
import LenPropertiesForm from "components/ordering/stock_lens/LenPropertiesForm";
import FetchRetry from "utils/FetchRetry";
import GenUrl from "utils/GenUrl";
import isPresent from "utils/isPresent";
import isString from "lodash/isString";
import GlazingType from "components/ordering/glazing/GlazingType";
import FrameType from "components/ordering/glazing/FrameType";
import FrameShape from "components/ordering/glazing/FrameShape";
import FrameShapeId from "components/ordering/glazing/FrameShapeId";
import FrameShapeToCome from "components/ordering/glazing/FrameShapeToCome";
import PrescriptionBox from "components/ordering/prescription/PrescriptionBox";
import Csrf from "utils/Csrf";
import Loading from "components/shared/Loading";
import {
  PATIENT,
  OPTICAL_INFO_ATTR,
  CUTTING,
  AGENT_ID,
  LEFT_SIDE,
  RIGHT_SIDE,
  FRAME_TYPE,
  FRAME_TO_COME,
  ORIGINAL_SHAPE_FILE
} from "constants/mapOrderColumns";
import {
  CUTTING_FILED,
  FRAME_TYPE_FIELD,
  FRAME_TO_COME_FIELD,
  FRAME_ID_FIELD,
  FRAME_SHAPE_VALID_FIELD
} from "constants/orderFields";
import { required } from "utils/Validators";

class LenOrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.currentOrder || { [AGENT_ID]: props.userId },
      productsResponse: {
        url: props.productsUrl,
        response: {}
      }
    };
    this.loadProducts = this.loadProducts.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.loadSelectedProduct = this.loadSelectedProduct.bind(this);
    this.loadFilterFields = this.loadFilterFields.bind(this);
    this.convertFieldName = this.convertFieldName.bind(this);
  }

  componentDidMount() {
    const { currentOrder } = this.props;
    this.loadProducts();

    if (isPresent(currentOrder)) {
      this.loadSelectedProduct(currentOrder.sku);
      this.loadFilterFields(currentOrder.sku);
    }
  }

  convertFieldName(field) {
    return field.replace("order", this.props.orderName);
  }

  loadProducts(
    url,
    queryParams,
    beforeLoading,
    loadingCallback,
    beforeRetryCallback
  ) {
    const fetchName = "loadStockLens";
    const { productsUrl } = this.props;
    url = GenUrl(productsUrl, queryParams);
    beforeLoading && beforeLoading();
    FetchRetry(url, {
      fetchName,
      updateErrorType: (fetchName, _errorType = null, _options = {}) => {
        // dispatch(updateFetchErrors(fetchName, errorType, options));
      },
      beforeRetryCallback: () => {
        beforeLoading && beforeLoading();
        beforeRetryCallback && beforeRetryCallback();
      },
      credentials: "same-origin",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const productsResponse = {
          url: url,
          response: {
            data: json.data,
            meta: json.meta
          }
        };
        this.setState({ productsResponse });
        return loadingCallback && loadingCallback();
      })
      .catch(_ex => {});
  }

  handleProductChange(e) {
    const sku = e.target.value;
    this.loadSelectedProduct(sku);
    this.loadFilterFields(sku);
  }

  loadSelectedProduct(sku) {
    if (!isPresent(sku)) return;
    const fetchName = "loadSelectedLens";
    const url = `/products/${sku}`;
    // beforeLoading && beforeLoading();
    FetchRetry(url, {
      fetchName,
      updateErrorType: (fetchName, _errorType = null, _options = {}) => {
        // dispatch(updateFetchErrors(fetchName, errorType, options));
      },
      beforeRetryCallback: () => {
        // beforeLoading && beforeLoading();
        // beforeRetryCallback && beforeRetryCallback();
      },
      credentials: "same-origin",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const product = json;
        this.setState({ product });
      })
      .catch(_ex => {});
  }

  loadFilterFields(sku) {
    if (!isPresent(sku)) return;
    const fetchName = "loadFilterFields";
    const url = `/products/${sku}/enabled_fields`;

    const { order } = this.state;
    if (!isPresent(order[OPTICAL_INFO_ATTR])) {
      order[OPTICAL_INFO_ATTR] = { [CUTTING]: "UnCut" };
    }

    // beforeLoading && beforeLoading();
    FetchRetry(url, {
      fetchName,
      updateErrorType: (fetchName, _errorType = null, _options = {}) => {
        // dispatch(updateFetchErrors(fetchName, errorType, options));
      },
      beforeRetryCallback: () => {
        // beforeLoading && beforeLoading();
        // beforeRetryCallback && beforeRetryCallback();
      },
      credentials: "same-origin",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        order,
        stock_lens: true,
        authenticity_token: Csrf.token()
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const properties = json;
        this.setState(properties);
        this.setState({ loading: null });
      })
      .catch(_ex => {});
    this.setState({ order });
  }

  changeGlazingType(type) {
    const { order } = this.state;

    order[OPTICAL_INFO_ATTR][CUTTING] = type;
    this.setState({
      order: order,
      loading: { frameDetails: true }
    });
    this.loadFilterFields(this.props.sku);
  }

  changeFrameType(type) {
    if (!isString(type)) return null;
    const { order } = this.state;

    order[OPTICAL_INFO_ATTR][FRAME_TYPE] = type;
    this.setState({
      order: order,
      loading: {
        frameShape: true,
        frameId: true
      }
    });
    this.loadFilterFields(this.props.sku);
  }

  changeFrameToCome(frameToCome) {
    const { order } = this.state;

    order[OPTICAL_INFO_ATTR][FRAME_TO_COME] = frameToCome;
    this.setState({
      order: order,
      loading: { frameId: true }
    });
    this.loadFilterFields(this.props.sku);
  }

  renderPower() {
    const { properties } = this.state;
    if (!isPresent(properties)) return null;
    return (
      <LenPropertiesForm
        orderName={this.props.orderName}
        properties={properties}
        locales={this.props.locales}
        change={this.props.change}
        unregisterField={this.props.unregisterField}
        currentOrder={this.props.currentOrder}
        {...this.props}
      />
    );
  }

  renderGlazingBox() {
    if (!isPresent(this.state.properties)) return null;
    const {
      properties: { glazing_type_values }
    } = this.state;
    const { locales } = this.props;
    return (
      <Row>
        <hr className="line-glazing" />
        <Col xs={12}>
          <Panel className="panel__bg--gray">
            <Panel.Body>
              <Row>
                <Col xsOffset={2} xs={8}>
                  <GlazingType
                    label="Glazing"
                    name={this.convertFieldName(CUTTING_FILED)}
                    options={glazing_type_values}
                    show={true}
                    onChangeGlazingType={this.changeGlazingType.bind(this)}
                    locales={locales}
                  />
                  {this.renderFrameShape.bind(this)()}
                </Col>
              </Row>
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    );
  }

  renderFrameShape() {
    if (!isPresent(this.state.properties)) return null;
    if (this.state.loading && this.state.loading.frameDetails)
      return <Loading size="md" iconStyle="loading" />;
    const { change, s3SignUrl, shapeFile } = this.props;
    const {
      loading,
      properties: {
        enabled_frame_type,
        frame_type_values,
        enabled_frame_library,
        frame_library_values,
        enabled_upload_frame_shape,
        upload_frame_shape_values,
        enabled_frame_id,
        frame_id_values,
        enabled_frame_to_come,
        enabled_frame_shape
      }
    } = this.state;
    return (
      <div>
        {enabled_frame_type.show && (
          <FrameType
            label="Frame Type"
            name={this.convertFieldName(FRAME_TYPE_FIELD)}
            {...enabled_frame_type}
            onChangeFrameType={this.changeFrameType.bind(this)}
            options={frame_type_values}
          />
        )}
        {enabled_frame_shape.show && (
          <Field
            component={FrameShape}
            name={this.convertFieldName(FRAME_SHAPE_VALID_FIELD)}
            label="Frame Shape"
            {...enabled_frame_shape}
            selectFrameLibrary={{
              enabled_frame_library,
              frame_library_values
            }}
            uploadFrameShape={{
              enabled_upload_frame_shape,
              upload_frame_shape_values,
              shapeFile
            }}
            convertFieldName={this.convertFieldName.bind(this)}
            change={change}
            s3SignUrl={s3SignUrl}
            validate={[required.bind(null, enabled_frame_shape.required)]}
            loading={loading}
          />
        )}
        {enabled_frame_to_come.show && (
          <FrameShapeToCome
            label="Frame to come"
            name={this.convertFieldName(FRAME_TO_COME_FIELD)}
            change={change}
            handleChange={this.changeFrameToCome.bind(this)}
            {...enabled_frame_to_come}
          />
        )}
        {enabled_frame_id.show && (
          <FrameShapeId
            label="Frame Id"
            name={this.convertFieldName(FRAME_ID_FIELD)}
            {...enabled_frame_id}
            options={frame_id_values}
            loading={loading}
          />
        )}
      </div>
    );
  }

  renderPrescription() {
    const { properties } = this.state;
    const { locales, getOrder, orderName, change } = this.props;
    const rightPower = getOrder()[RIGHT_SIDE];
    const leftPower = getOrder()[LEFT_SIDE];

    if (!isPresent(properties)) return null;
    if (!isPresent(rightPower) && !isPresent(leftPower))
      return null;

    return (
      <Row>
        <Col xs={6}>
          <PrescriptionBox
            side="right"
            name={`${orderName}[${RIGHT_SIDE}]`}
            properties={properties}
            lenPower={rightPower}
            change={change}
            locales={locales}
          />
        </Col>
        <Col xs={6}>
          <PrescriptionBox
            side="left"
            name={`${orderName}[${LEFT_SIDE}]`}
            properties={properties}
            lenPower={leftPower}
            change={change}
            locales={locales}
          />
        </Col>
      </Row>
    );
  }

  render() {
    const { orderName, locales } = this.props;
    const { product } = this.state;
    return (
      <Panel>
        <Panel.Body>
          <Row>
            <Col xsOffset={1} xs={8}>
              <Field
                component={TextInput}
                label={locales.order.patient}
                type="text"
                name={`${orderName}[${PATIENT}]`}
                labelClass="col-xs-4"
                inputClass="col-xs-8"
                required={true}
                validate={[required.bind(null, true)]}
              />
              <Field
                component={SelectProductsInput}
                name={`${orderName}[sku]`}
                label={locales.order.lens}
                searchProducts={this.loadProducts}
                productsResponse={this.state.productsResponse}
                handleProductChange={this.handleProductChange}
                selectedProduct={product}
                labelClass="col-xs-4"
                inputClass="col-xs-8"
                required={true}
                validate={[required.bind(null, true)]}
                locales={locales}
              />
            </Col>
          </Row>

          {this.renderPower.bind(this)()}
          {this.renderGlazingBox.bind(this)()}
          {this.renderPrescription.bind(this)()}

          {/* <Button
            className="pull-right"
            onClick={this.props.removeNewOrder}
          >
            {this.props.locales.buttons.delete}
                  </Button> */}
        </Panel.Body>
      </Panel>
    );
  }
}

LenOrderForm.propTypes = {
  locales: PropTypes.object.isRequired,

  orderName: PropTypes.string,
  removeNewOrder: PropTypes.func,
  sku: PropTypes.string,
  currentOrder: PropTypes.object,
  productsUrl: PropTypes.string,
  userId: PropTypes.number,
  s3SignUrl: PropTypes.string,

  // Redux Store
  currentFilters: PropTypes.object,
  actions: PropTypes.object.isRequired,

  // Reduc Form
  change: PropTypes.func,
  unregisterField: PropTypes.func,
  getOrder: PropTypes.func,
  shapeFile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ])
};

LenOrderForm.defaultProps = {
  removeNewOrder: _index => {}
};

let form = reduxForm({
  form: "stockOrder"
})(LenOrderForm);

const selector = formValueSelector("stockOrder"); // <-- same as form name
form = connect((state, props) => {
  const shapeFile = selector(
    state,
    `${props.orderName}[${OPTICAL_INFO_ATTR}][${ORIGINAL_SHAPE_FILE}]`
  );
  return {
    shapeFile
  };
})(form);

export default form;
