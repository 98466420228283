import React from 'react';
import PropTypes from 'prop-types';
import InputForm from 'components/default_redux/InputForm';
import Switch from 'react-bootstrap-switch';

class SwitchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillUnmount() {
    const { input: { onChange } } = this.props;
    onChange(null);
  }

  onChange (e, state) {
    if (this.props.input.onChange) {
      this.props.input.onChange(state); // <-- To be aligned with how redux-form publishes its CHANGE action payload. The event received is an object with 2 keys: "value" and "label"
    }
    this.props.handleChange(state);
  }

  render () {
    const { name, value, onBlur } = this.props.input;
    return (
      <InputForm
        label={this.props.label}
        labelFor={name || this.props.inputName}
        formClass={this.props.formClass}
        inputClass={this.props.inputWaraperClass}
        labelClass={this.props.labelClass}
        showLabel={this.props.showLabel}
        required={this.props.required}
      >
        <Switch
          name={name}
          disabled={!this.props.enabled}
          readonly={this.props.readonly}
          offColor={this.props.offColor}
          offText={this.props.offText}
          // onChange={this.onChange.bind(this)}
          onColor={this.props.onColor}
          onText={this.props.onText}
          bsSize={this.props.size}
          // value={this.props.getValue() || false}
          {...this.props.input}
          value={value || false}
          onBlur={() => onBlur(value)}
          onChange={this.onChange.bind(this)}
        />
      </InputForm>
    );
  }
}
SwitchInput.propTypes = {
  value: PropTypes.bool,
  realValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  handleChange: PropTypes.func,
  formClass: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  inputName: PropTypes.string,
  inputWaraperClass: PropTypes.string,
  showLabel: PropTypes.bool,

  enabled: PropTypes.bool,
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  offColor: PropTypes.string,
  offText: PropTypes.string,
  onClicked: PropTypes.func,
  onColor: PropTypes.string,
  onText: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string,

  // Redux Form
  input: PropTypes.object,
};

SwitchInput.defaultProps = {
  value: false,
  handleChange: (_e) => {},
  inputName: '',
  formClass: '',
  label: '',
  labelClass: '',
  inputWaraperClass: '',
  showLabel: true,

  enabled: true,
  readonly: false,
  offColor: 'default',
  offText: 'No',
  onColor: 'success',
  onText: 'Yes',
  size: 'small',
};

export default SwitchInput;
