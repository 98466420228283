import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { DECENTER, DECENTER_X, DECENTER_Y } from '../../../../constants/mapOrderColumns';
import { genRange } from '../../../../utils/GenOptions';

import { Row, Col } from 'react-bootstrap';
import SwitchInput from '../../../default_redux/SwitchInput';
import SelectInput from '../../../default_redux/SelectInput';

export default class Decenter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.enabledOptionFields = this.enabledOptionFields.bind(this);
  }

  enabledOptionFields () {
    if(!this.props.decenter) return null;
    const {
      x: {
        min: minX,
        max: maxX,
        period: periodX
      },
      y: {
        min: minY,
        max: maxY,
        period: periodY
      }
    } = this.props;
    return(
      <Row>
        <Col xs={7}>
          <Field component={SelectInput}
            name={`order${this.props.attrNestedName}[${DECENTER_X}]`}
            options={genRange(minX, maxX, periodX, 0, false, 'asc')}
            liveSearch={true}
            selectFirst={true}
            label="X"
            labelClass="col-xs-7"
            selectWrapperClass="col-xs-5"
            required={true}
          />
        </Col>
        <Col xs={4}>
          <Field component={SelectInput}
            name={`order${this.props.attrNestedName}[${DECENTER_Y}]`}
            options={genRange(minY, maxY, periodY, 0, false, 'asc')}
            liveSearch={true}
            selectFirst={true}
            label="Y"
            labelClass="col-xs-3"
            selectWrapperClass="col-xs-9"
            required={true}
          />
        </Col>
      </Row>
    );
  }

  render () {
    if(!this.props.show) return null;
    // console.log(`Render ${this.props.label}`);
    return (
      <div>
        <Field component={SwitchInput}
          name={`order${this.props.attrNestedName}[${DECENTER}]`}
          label={this.props.label}
          labelClass="col-xs-4"
          inputWaraperClass="col-xs-8"
          onText="Yes"
          offText="No"
        />
        { this.enabledOptionFields() }
      </div>
    );
  }
}

Decenter.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  x: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    period: PropTypes.number,
  }),
  y: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    period: PropTypes.number,
  }),
  attrNestedName: PropTypes.string,
  decenter: PropTypes.bool,
};

Decenter.defaultProps = {
  label: "Decenter",
  show: false,
  x: {},
  y: {},
};
