import React from 'react';
import PropTypes from 'prop-types';
import { Button, Glyphicon } from 'react-bootstrap';

export default class OrdersPrinter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.print = this.print.bind(this);
    this.renderIframe = this.renderIframe.bind(this);
    this.renderButtonText = this.renderButtonText.bind(this);
    this.openPrintWindow = this.openPrintWindow.bind(this);
    this.setLoaded = this.setLoaded.bind(this);
  }

  print () {
    this.setState({printing: true});
    setTimeout(()=>{
      const { loaded } = this.state;
      if(loaded) {
        this.openPrintWindow();
        this.setState({printing: false});
      } else {
        this.print();
      }
    }, 1000);
  }

  openPrintWindow () {
    const { iframe } = this.refs;
    const pri = iframe.contentWindow;
    pri.focus();
    pri.print();
  }

  setLoaded () {
    this.setState({ loaded: true });
  }

  renderIframe () {
    const { url: orderUrl } = this.props;
    return(
      <iframe
        ref="iframe"
        onLoad={this.setLoaded}
        src={orderUrl}
        style={{
          height: "0px",
          width: '0px',
          position: "absolute",
          display: "none"
        }}
      ></iframe>
    );
  }

  renderButtonText () {
    const { label, showIcon } = this.props;
    const { printing } = this.state;
    if(printing) return (
      <div>
        <Glyphicon glyph="refresh" className="glyphicon-refresh-animate" />
        &nbsp;{label}
      </div>

    );
    return (
      <div>
        {showIcon && <span><Glyphicon glyph="print" />&nbsp;</span>}
        {label}
      </div>
    );
  }

  render () {
    const { printing, loaded } = this.state;
    const { bsStyle, bsSize, block } = this.props;
    let { className } = this.props;
    if(block) className = `btn-block ${className}`;
    return(
      <span className="hidden-print">
        <Button
          className={className}
          bsStyle={bsStyle}
          bsSize={bsSize}
          disabled={printing}
          onClick={this.print}
        >
          { this.renderButtonText() }
        </Button>
        { (printing || loaded) && this.renderIframe() }
      </span>
    );
  }
}

OrdersPrinter.propTypes = {
  url: PropTypes.string.isRequired,
  bsSize: PropTypes.oneOf(["lg", "large", "sm", "small", "xs", "xsmall"]),
  bsStyle: PropTypes.oneOf(["success", "warning", "danger", "info", "default", "primary", "link"]),
  label: PropTypes.string,
  block: PropTypes.bool,
  showIcon: PropTypes.bool,
  className: PropTypes.string,
};

OrdersPrinter.defaultProps = {
  label: 'Print',
  bsStyle: 'default',
  block: false,
  showIcon: true,
  className: ''
};
