import { actionNames } from "constants.js";
import update from "immutability-helper";
import isPresent from "utils/isPresent";

const fetchErrors = (state = {}, action) => {
  switch (action.type) {
    case actionNames.UPDATE_FETCH_ERRORS: {
      const fetchErrorName = `${action.fetchName}FetchError`;
      let fetchError = {
        type: action.errorType
      };
      fetchError = update(fetchError, { $merge: action.options });
      if (!isPresent(action.errorType)) fetchError = null;

      return update(state, { $merge: { [fetchErrorName]: fetchError } });
    }
    default: {
      return state;
    }
  }
};

export default fetchErrors;
