import React from "react";
import PropTypes from "prop-types";
import { SKU, SKU_FIELD } from "constants/mapOrderColumns";
import { Row, Col, Panel } from "react-bootstrap";
import { Field } from "redux-form";
import SelectProductsInput from "components/default_redux/SelectProductsInput";
import { required } from "utils/Validators";
import StepHeader from "components/shared/StepHeader";
import FetchError from "components/shared/FetchError";

export default class RxSelectProduct extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.clearProduct = this.clearProduct.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
  }

  componentDidMount() {}

  clearProduct() {
    const { clearProduct, changeOrder } = this.props;
    clearProduct();
    changeOrder(SKU_FIELD, null);
  }

  handleProductChange(e) {
    const sku = e.target.value;
    this.clearProduct();
    const { resetOrder, changeOrder, loadProductRxLens } = this.props;
    resetOrder();
    changeOrder(SKU_FIELD, sku);
    loadProductRxLens({
      fetchName: "productChange",
      sku,
      loadingGlazing: true,
      loadingPrescription: true
    });
  }

  render() {
    const {
      locales,
      fetchError,
      productsResponse,
      searchProductsByPower,
      selectedProduct
    } = this.props;
    return (
      <Panel>
        <Panel.Body>
          <Row>
            <Col xsOffset={2} xs={8}>
              <StepHeader
                tag="h2"
                number="2"
                desc={locales.page.steps.selectLens}
              />
            </Col>
          </Row>
          <Row>
            <Col xsOffset={2} xs={8}>
              <Field
                component={SelectProductsInput}
                name={`order[${SKU}]`}
                label="Item"
                searchProducts={searchProductsByPower}
                productsResponse={productsResponse}
                handleProductChange={this.handleProductChange}
                selectedProduct={selectedProduct}
                labelClass="col-xs-3"
                inputClass="col-xs-9"
                required={true}
                validate={[required.bind(null, true)]}
                locales={locales}
              />
              {fetchError && (
                <Row>
                  <Col xsOffset={3} xs={9}>
                    <FetchError error={fetchError} {...locales.requestErrors} />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Panel.Body>
      </Panel>
    );
  }
}

RxSelectProduct.propTypes = {
  products: PropTypes.array,
  loadProductRxLens: PropTypes.func.isRequired,
  resetOrder: PropTypes.func.isRequired,
  changeOrder: PropTypes.func.isRequired,
  clearProduct: PropTypes.func.isRequired,
  searchProductsByPower: PropTypes.func.isRequired,
  locales: PropTypes.shape({
    page: PropTypes.shape({
      steps: PropTypes.shape({
        selectLens: PropTypes.string.isRequired
      })
    }),
    modals: PropTypes.shape({
      titles: PropTypes.shape({
        productList: PropTypes.string
      })
    }),
    buttons: PropTypes.shape({
      close: PropTypes.string
    }),
    placeholders: PropTypes.shape({
      searchProducts: PropTypes.string
    }),
    hints: PropTypes.shape({
      notFoundProducts: PropTypes.string
    })
  }),
  fetchError: PropTypes.object,
  selectedProduct: PropTypes.object,
  productsResponse: PropTypes.shape({
    url: PropTypes.string,
    response: PropTypes.shape({
      data: PropTypes.array,
      meta: PropTypes.object
    })
  })
};

RxSelectProduct.defaultProps = {
  onChange: _product => {},
  selectedProduct: {},
  locales: {
    modals: {
      titles: {
        productList: "Select lens"
      }
    },
    buttons: {
      close: "Close"
    },
    productList: {
      buttons: {
        search: {
          placeholder: "Search name"
        }
      },
      errors: {
        notFound: "You don't have any products in this list."
      }
    }
  }
};
