export const ROLE = "role";
export const PARENT_ID = "parent_id";
export const STATUS = "status";
export const USERNAME = "username";
export const COMPANY = "company";
export const PASSWORD = "password";
export const PASSWORD_CONFIRMATION = "password_confirmation";
export const NAME = "name";
export const EMAIL = "email";
export const POSITION = "position";
export const PHONE = "phone";
