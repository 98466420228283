import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { ADD } from 'constants/mapOrderColumns';
import TextInput from 'components/default_redux/TextInput';
import { required } from 'utils/Validators';


export default class ADDInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    const { label, show, name } = this.props;
    if(!show) return null;

    const requiredInput = this.props.required;
    return (
      <Field component={TextInput}
        label={label}
        name={`${name}[${ADD}]`}
        labelClass="col-xs-4"
        inputClass="col-xs-8"
        required={requiredInput}
        validate={[
          required.bind(null, requiredInput),
        ]}
        readOnly={true}
      />
    );
  }
}

ADDInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

ADDInput.defaultProps = {
  label: "ADD",
  show: false,
  required: false,
  min: 0,
  max: 0,
};
