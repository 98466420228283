import { actionNames } from "constants.js";
import update from "immutability-helper";

const stockLens = (state = {}, action) => {
  // console.log('Reducer stockLens : ', state, action);
  switch (action.type) {
    case actionNames.UPDATE_FILTER: {
      return update(state, {
        $merge: {
          currentFilters: action.filters
        }
      });
    }
    //     case actionNames.UPDATE_STOCK_LENS: {
    //       return Object.assign({}, state, {
    //         orders: action.orders
    //       });
    //     }
    //     case actionNames.ADD_STOCK_LENS: {
    //       const newOrder = {
    //         user_id: action.agentId
    //       };
    //       return {
    //         orders: update(state.orders, {$push: [newOrder]})
    //       };
    //     }
    //     case actionNames.REMOVE_STOCK_LENS: {
    //
    //       return Object.assign({}, state, {
    //         orders: update(state.orders, {$splice: [[action.index, 1]]})
    //       });
    //     }
    //     case actionNames.UPDATE_AGEN_STOCK_LENS: {
    //       return Object.assign({}, state, {
    //         agentId: action.agenId
    //       });
    //     }
    default: {
      return state;
    }
  }
};

export default stockLens;
