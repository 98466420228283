import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import { SPH, CYL, ADD, AXIS } from 'constants/mapOrderColumns';
import { Field } from 'redux-form';
import SelectInput from 'components/default_redux/SelectInput';
import CheckboxInput from 'components/default_redux/CheckboxInput';
import { required } from 'utils/Validators';
import getIn from 'redux-form/lib/structure/plain/getIn';
import isPresent from 'utils/isPresent';
import { genRange } from 'utils/GenOptions';

export default class RxFilterPowerSide extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleEnabledChange = this.handleEnabledChange.bind(this);
    this.requiredAxis = this.requiredAxis.bind(this);

    this.state = {
      requiredAxis: this.requiredAxis(getIn(props), `filter.${CYL}`)
    };
  }

  componentWillReceiveProps(nextProps) {
    const oldCyl = getIn(this.props, `filter.${CYL}`);
    const newCyl = getIn(nextProps, `filter.${CYL}`);
    if ( newCyl != oldCyl) {
      if(parseFloat(newCyl) == 0) {
        nextProps.changeFilter({ [AXIS]: null });
      }
      this.setState({
        requiredAxis: this.requiredAxis(newCyl)
      });
    }
  }

  requiredAxis (cyl) {
    if(!isPresent(cyl)) return false;
    cyl = parseFloat(cyl);
    return cyl != 0;
  }

  handleEnabledChange (checked) {
    checked = checked == 'true' ? true : false;
    this.props.clearFilter(checked);
  }

  render () {
    const {
      label, side, enabled,
      canEdit, diffSPH,
      powerRanges,
    } = this.props;
    const { requiredAxis } = this.state;
    return (
      <Row>
        <Col xs={3}>
          <Col xs={12} className="text-right">
            <Field component={CheckboxInput}
              label={label}
              name="enabled"
              handleChange={this.handleEnabledChange}
              disabled={!canEdit}
            />
          </Col>
        </Col>
        {
          enabled ?
          <Col xs={9}>
            <Form componentClass="fieldset" disabled={!canEdit}>
              <Row>
                <Col xs={3}>
                  <Col xs={12}>
                    <Field component={SelectInput}
                      label={`${side} SPH`}
                      name={SPH}
                      options={genRange(powerRanges.sph.min, powerRanges.sph.max, 0.25, 2, true, 'desc')}
                      placeholder={`${side}-SPH`}
                      selectClass={!canEdit ? 'readonly' : null}
                      showLabel={false}
                      required={true}
                      validate={[required.bind(null, true)]}
                      liveSearch={true}
                      disabled={!enabled}
                      {...this.props}
                      formWrapperClass={diffSPH ? 'has-warning': null}
                    />
                  </Col>
                </Col>

                <Col xs={3}>
                  <Col xs={12}>
                    <Field component={SelectInput}
                      label={`${side} CYL`}
                      name={CYL}
                      options={genRange(powerRanges.cyl.min, powerRanges.cyl.max, 0.25, 2, true, 'desc')}
                      placeholder={`${side}-CYL`}
                      selectClass={!canEdit ? 'readonly' : null}
                      showLabel={false}
                      required={true}
                      validate={[required.bind(null, true)]}
                      liveSearch={true}
                      disabled={!enabled}
                    />
                  </Col>
                </Col>

                <Col xs={3}>
                  { requiredAxis ?
                    <Col xs={12}>
                      <Field component={SelectInput}
                        label={`${side} AXIS`}
                        name={AXIS}
                        options={genRange(powerRanges.axis.min, powerRanges.axis.max, 1, 0)}
                        placeholder={`${side}-AXIS`}
                        selectClass={!canEdit ? 'readonly' : null}
                        showLabel={false}
                        liveSearch={true}
                        disabled={!enabled}
                        required={true}
                        validate={[required.bind(null, true)]}
                      />
                    </Col> :
                    <div className="Select Select--single readonly">
                      <div className="Select-control">
                        <span className="Select-multi-value-wrapper">
                          <div className="Select-placeholder">{`${side}-AXIS`}</div>
                        </span>
                      </div>
                    </div>
                  }
                </Col>

                <Col xs={3}>
                  <Col xs={12}>
                    <Field component={SelectInput}
                      label={`${side} ADD`}
                      name={ADD}
                      options={genRange(powerRanges.add.min, powerRanges.add.max, 0.25, 2, true, 'desc')}
                      placeholder={`${side}-ADD`}
                      selectClass={!canEdit ? 'readonly' : null}
                      showLabel={false}
                      liveSearch={true}
                      disabled={!enabled}
                    />
                  </Col>
                </Col>
              </Row>
            </Form>
          </Col> : <Col xs={9}><div style={{height: '51px'}}></div></Col>
        }
      </Row>
    );
  }
}

RxFilterPowerSide.propTypes = {
  side: PropTypes.string.isRequired,
  label: PropTypes.string,
  enabled: PropTypes.bool,
  canEdit: PropTypes.bool,
  clearFilter: PropTypes.func,
  diffSPH: PropTypes.bool,
  filter: PropTypes.object,
  changeFilter: PropTypes.func,
  powerRanges: PropTypes.shape({
    sph: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    cyl: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    axis: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    add: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    dia: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }),
  // Redux store
  // filter: PropTypes.object,
  // updateRxLensFilter: PropTypes.func.isRequired
};

RxFilterPowerSide.defaultProps = {
  // onChange: (_agentId) => {},
};
