import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import Csrf from "utils/Csrf";
import isFile from "utils/isFile";
import isPresent from "utils/isPresent";
import isArray from "lodash/isArray";
import merge from "lodash/merge";
import { Row, Col, Modal, Button, Panel, ListGroup } from "react-bootstrap";
import OrderDetails from "components/orders/OrderDetails";
import S3Uploaded from "utils/S3Uploaded";
import Loading from "components/shared/Loading";
import FetchError from "components/shared/FetchError";
import {
  OPTICAL_INFO_ATTR,
  OPT_THICKNESS,
  REMOTE_SHAPE_FILE,
  ORIGINAL_SHAPE_FILE,
  SHAPE_FILE,
  FRAME_SHAPE
} from "constants/mapOrderColumns";
import getIn from "redux-form/lib/structure/plain/getIn";
import setIn from "redux-form/lib/structure/plain/setIn";
import FetchRetry from "utils/FetchRetry";

export default class RxOrderConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      loading: true,
      showModal: true
    };

    this.sendOrder = this.sendOrder.bind(this);
    this.orderParams = this.orderParams.bind(this);
  }

  componentDidMount() {
    this.loadConfirmOrder.bind(this)();
  }

  orderParams(orderData) {
    const isPreOptimized = getIn(
      orderData,
      `${OPTICAL_INFO_ATTR}.${OPT_THICKNESS}`
    );
    const frameShape = getIn(orderData, `${OPTICAL_INFO_ATTR}.${FRAME_SHAPE}`);

    if (isPreOptimized && isPresent(frameShape)) {
      orderData = setIn(
        orderData,
        `${OPTICAL_INFO_ATTR}.${ORIGINAL_SHAPE_FILE}`,
        null
      );
      orderData = setIn(orderData, `${OPTICAL_INFO_ATTR}.${SHAPE_FILE}`, null);
    }

    if (!isPreOptimized) {
      orderData = setIn(orderData, `${OPTICAL_INFO_ATTR}.${FRAME_SHAPE}`, null);
      orderData = setIn(
        orderData,
        `${OPTICAL_INFO_ATTR}.${ORIGINAL_SHAPE_FILE}`,
        null
      );
      orderData = setIn(orderData, `${OPTICAL_INFO_ATTR}.${SHAPE_FILE}`, null);
    }
    return orderData;
  }

  loadConfirmOrder() {
    const { confirmUrl } = this.props;
    const orderData = this.orderParams(this.props.order);
    FetchRetry(confirmUrl, {
      fetchName: "confirmOrder",
      beforeRetryCallback: () => {
        this.setState({ order: null, confirmFetchError: null });
      },
      updateErrorType: (_fetchName, errorType, options = {}) => {
        if (isPresent(errorType)) {
          const confirmFetchError = options;
          confirmFetchError.type = errorType;
          this.setState({ confirmFetchError });
        }
      },
      credentials: "same-origin",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        order: orderData,
        authenticity_token: Csrf.token()
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const order = json;
        let { shapeFile } = this.props;
        if (isArray(shapeFile)) shapeFile = shapeFile[0];
        shapeFile = shapeFile || {};
        if (isFile(shapeFile))
          order.optical_informations.shape_file = shapeFile;
        // console.log('Confirm Order : ', order);
        this.setState({ order, confirmFetchError: null });
      })
      .catch(_ex => {
        this.props.afterShow();
        this.props.afterHidden();
      });
  }

  uploadFrameShape(files = []) {
    return new Promise((resolve, reject) => {
      const { s3SignUrl } = this.props;
      const shapeFiles = files;
      if (!isArray(shapeFiles)) return resolve(null);
      const shapeFile = shapeFiles[0];
      if (!isFile(shapeFile)) return resolve(null);
      S3Uploaded(shapeFile, s3SignUrl)
        .then(({ _s3Data, _file, fileUrl }) => {
          resolve(fileUrl);
        })
        .catch(ex => {
          Sentry.captureException(ex);
          reject(ex);
        });
    });
  }

  async sendOrder() {
    const { formUrl, formAction, locales } = this.props;
    let orderData = this.orderParams(this.props.order);

    try {
      const shapeFiles =
        getIn(orderData, `${OPTICAL_INFO_ATTR}.${ORIGINAL_SHAPE_FILE}`) || [];
      const fileUrl = await this.uploadFrameShape(shapeFiles);
      orderData = setIn(
        orderData,
        `${OPTICAL_INFO_ATTR}.${ORIGINAL_SHAPE_FILE}`,
        fileUrl
      );
      orderData = setIn(
        orderData,
        `${OPTICAL_INFO_ATTR}.${REMOTE_SHAPE_FILE}`,
        fileUrl
      );
    } catch (error) {
      Sentry.captureException(error);
      let sendFetchError = { errorProp: "uploadShapeFileFailureText" };
      sendFetchError = merge(locales.requestErrors, sendFetchError);
      return this.setState({ sendFetchError, isSending: false });
    }

    FetchRetry(formUrl, {
      fetchName: "sendOrder",
      beforeRetryCallback: () => {
        this.setState({ sendFetchError: null });
      },
      updateErrorType: (_fetchName, errorType, options = {}) => {
        if (isPresent(errorType)) {
          let sendFetchError = options;
          sendFetchError.type = errorType;
          sendFetchError = merge(locales.requestErrors, sendFetchError);
          this.setState({ sendFetchError });
        }
      },
      credentials: "same-origin",
      method: formAction,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        order: orderData,
        authenticity_token: Csrf.token()
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        // console.info('Sending order finished ', json);
        const errors = json.errors;
        if (isPresent(errors)) {
          let sendFetchError = locales.requestErrors;
          const productRxIdError = errors["optical_informations.product_rx_id"];
          if (isPresent(productRxIdError)) {
            sendFetchError = merge(sendFetchError, {
              errorProp: "cannotLoadProductFromCatalogOnceOrderCreating"
            });
          } else {
            sendFetchError = merge(sendFetchError, {
              errorText: errors.join(", ")
            });
          }
          this.setState({ sendFetchError, isSending: false });
          return;
        }
        this.setState({ sendFetchError: null });
        const redirectUrl = json.location;
        window.location.href = redirectUrl;
      })
      .catch(error => {
        Sentry.captureException(error);
        this.props.afterShow();
        this.props.afterHidden();
        let sendFetchError = { type: "error", errorText: error.message };
        sendFetchError = merge(locales.requestErrors, sendFetchError);
        this.setState({ sendFetchError, isSending: false });
      });
  }

  open() {
    this.setState({ showModal: true });
  }

  close() {
    this.setState({ showModal: false });
  }

  _submitText() {
    if (!this.state.isSending) return this.props.locales.buttons.send;
    return (
      <div>
        {this.props.locales.buttons.send}
        <span
          className="glyphicon glyphicon-refresh glyphicon-refresh-animate"
          aria-hidden="true"
        ></span>
      </div>
    );
  }

  _handleSend() {
    this.setState({ isSending: true });
    this.sendOrder.bind(this)();
  }

  renderOrderDetails() {
    const { locales } = this.props;
    const { order, confirmFetchError } = this.state;
    if (isPresent(confirmFetchError))
      return (
        <FetchError error={confirmFetchError} {...locales.requestErrors} />
      );
    if (!isPresent(order)) return <Loading size="lg" position="center" />;
    return <OrderDetails order={order} locales={locales} />;
  }

  render() {
    const { diffSPH, locales } = this.props;
    const { order, isSending, sendFetchError } = this.state;
    return (
      <Modal
        show={this.state.showModal}
        bsSize="large"
        backdrop="static"
        keyboard={false}
        onEntered={this.props.afterShow}
        onExited={this.props.afterHidden}
      >
        <Modal.Header>
          <Modal.Title>{this.props.locales.page.modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Panel>
            <Panel.Body>
              <ListGroup>{this.renderOrderDetails.bind(this)()}</ListGroup>
            </Panel.Body>
          </Panel>
        </Modal.Body>
        {isPresent(order) && (
          <Modal.Footer>
            <Row>
              <Col xs={7} className="text-right">
                {diffSPH && (
                  <p className="text-warning">{locales.hints.diffSPH}</p>
                )}
              </Col>
              <Col xs={2}>
                <Button
                  block
                  onClick={this.close.bind(this)}
                  disabled={isSending}
                >
                  {this.props.locales.buttons.edit}
                </Button>
              </Col>
              <Col xs={3}>
                <Button
                  bsStyle="primary"
                  block
                  onClick={this._handleSend.bind(this)}
                  disabled={isSending}
                >
                  {this._submitText.bind(this)()}
                </Button>
              </Col>
            </Row>
            {sendFetchError && (
              <Row>
                <Col xs={12} className="text-right">
                  <br />
                  <FetchError error={sendFetchError} {...sendFetchError} />
                </Col>
              </Row>
            )}
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

RxOrderConfirmModal.propTypes = {
  formId: PropTypes.string.isRequired,
  formAction: PropTypes.oneOf(["POST", "PUT", "PATCH"]).isRequired,
  confirmUrl: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired,
  s3SignUrl: PropTypes.string.isRequired,
  shapeFile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),

  afterShow: PropTypes.func,
  afterHidden: PropTypes.func,
  locales: PropTypes.object,
  order: PropTypes.object,
  diffSPH: PropTypes.bool
};

RxOrderConfirmModal.defaultProps = {
  afterHidden: _e => {},
  afterShow: _e => {}
};
