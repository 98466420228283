import isPresent from "utils/isPresent";
import sortBy from 'lodash/sortBy';
import map from "lodash/map";

const sortCustomers = users => {
  return sortBy(users, (user)=>{
    return [user.roles[0], user.company];
  });
};

const userName = user => {
  const name = `${user.company || user.username}`;
  return `${user.roles[0]} :: ${name}`;
};

const reformatCustomers = users => {
  if (isPresent(users)) {
    const sortedUsers = sortCustomers(users);
    return map(sortedUsers, user => {
      return {
        name: userName(user),
        id: user.id
      };
    });
  }
};

export default reformatCustomers;
