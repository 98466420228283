import React from 'react';
import PropTypes from 'prop-types';
import { Button, Glyphicon } from 'react-bootstrap';
import TimeCountdown from 'components/shared/TimeCountdown';
import ReactTooltip from 'react-tooltip';

export default class OrderEditButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: props.disabled || false,
      loading: false,
    };
    this.renderButtonText = this.renderButtonText.bind(this);
    this.afterEndCountdown = this.afterEndCountdown.bind(this);
    this.afterStartCountdown = this.afterStartCountdown.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.renderCoundownMessage = this.renderCoundownMessage.bind(this);
    this.renderCoundown = this.renderCoundown.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  setLoading () {
    this.setState({loading: true});
  }

  afterStartCountdown () {
    this.setState({disabled: false});
  }

  afterEndCountdown () {
    this.setState({disabled: true});
  }

  renderButtonText () {
    const { label, showIcon } = this.props;
    const { loading } = this.state;
    if(loading) return (
      <span>
        <Glyphicon glyph="refresh" className="glyphicon-refresh-animate" />
        &nbsp;{label}
      </span>

    );
    return (
      <span>
        {showIcon && <span><Glyphicon glyph="pencil" />&nbsp;</span>}
        {label}
      </span>
    );
  }

  renderCoundownMessage () {
    const {
      expiredTime, replaceText,
      countMessage, endMessage, editable
    } = this.props;
    if (editable) return;
    return(
      <TimeCountdown
        expiredTime={expiredTime}
        replaceText={replaceText}
        countMessage={countMessage}
        endMessage={endMessage}
        afterStartCountdown={this.afterStartCountdown}
        afterEndCountdown={this.afterEndCountdown}
      />
    );
  }

  renderCoundown () {
    const {
      expiredTime,
      countMessage, endMessage, editable
    } = this.props;
    if (editable) return;
    return(
      <TimeCountdown
        expiredTime={expiredTime}
        replaceText={countMessage}
        countMessage={countMessage}
        endMessage={endMessage}
        afterStartCountdown={this.afterStartCountdown}
        afterEndCountdown={this.afterEndCountdown}
      />
    );
  }

  renderTooltip () {
    const { disabled } = this.state;
    const {
      url: tooltipID,
      replaceText,
      countMessage, endMessage,
      editableMessage, editable,
      tooltipPosition
    } = this.props;

    let tooltipMessages = null;
    if(editable) {
      tooltipMessages = (
        <div>
          {editableMessage}
        </div>
      );
    } else if(disabled) {
      tooltipMessages = <span dangerouslySetInnerHTML={{__html: endMessage}}></span>;
    } else {
      tooltipMessages = countMessage.split(replaceText);
      tooltipMessages = (
        <div>
          {tooltipMessages[0]}
          {this.renderCoundown()}
          {tooltipMessages[1]}
        </div>
      );
    }
    return(
      <span className="text-center">
        <ReactTooltip
          id={tooltipID}
          place={tooltipPosition}
          type="dark"
          effect="solid"
          resizeHide={false}
          getContent={[() => { return tooltipMessages; }, 1000]}
        />
      </span>
    );
  }

  renderButton () {
    const { disabled, loading } = this.state;
    const {
      messagePosition,
      url, bsStyle, bsSize, block,
    } = this.props;

    return(
      <Button
        href={url}
        block={block}
        bsStyle={bsStyle}
        bsSize={bsSize}
        disabled={disabled || loading}
        onClick={this.setLoading}
      >
        { this.renderButtonText() }&nbsp;
        {(messagePosition == 'tooltip' && !disabled) && this.renderCoundown() }
      </Button>
    );
  }

  render () {
    const { className, messagePosition, url } = this.props;
    const tooltipProps = {};
    if(messagePosition == 'tooltip') {
      tooltipProps["data-tip"] = true;
      tooltipProps["data-for"] = url;
    }
    return(
      <span className="hidden-print">
        { (messagePosition == 'show-top') &&
          <div className="text-center">
            <small>{ this.renderCoundownMessage() }</small>
          </div> }
        <div {...tooltipProps} className={className}>
          { this.renderButton() }
        </div>
        { (messagePosition == 'tooltip') && this.renderTooltip() }
      </span>
    );
  }
}

OrderEditButton.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  bsSize: PropTypes.oneOf(["lg", "large", "sm", "small", "xs", "xsmall"]),
  bsStyle: PropTypes.oneOf(["success", "warning", "danger", "info", "default", "primary", "link"]),
  label: PropTypes.string,
  showIcon: PropTypes.bool,
  messagePosition: PropTypes.oneOf(["show-top", "tooltip"]),
  tooltipPosition: PropTypes.oneOf(["bottom", "top", "left", "right"]),
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,

  countMessage: PropTypes.string,
  endMessage: PropTypes.string,
  expiredTime: PropTypes.string,
  replaceText: PropTypes.string,
  editableMessage: PropTypes.string,
};

OrderEditButton.defaultProps = {
  label: 'Edit',
  bsStyle: 'default',
  block: true,
  showIcon: true,
  disabled: true,
  editable: false,
  messagePosition: "popover",
  tooltipPosition: "bottom",
};
