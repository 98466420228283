import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Row, Col, Form, Button, Glyphicon } from 'react-bootstrap';
import { Field } from 'redux-form';
import SelectInput from 'components/default_redux/SelectInput';
import TextInput from 'components/default_redux/TextInput';
import GenUrl from 'utils/GenUrl';

export default class UsersSearchingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onSubmit (data) {
    const { formUrl } = this.props;
    const url = GenUrl(formUrl, { q: data });
    window.location.replace(url);
  }

  renderTextSearchButton () {
    const {
      submitting, submitSucceeded,
      locales: {
        buttons: { search: searchText }
      }
    } = this.props;
    if(!(submitting || submitSucceeded)) return(
      <div>
        <Glyphicon glyph="search" />&nbsp;
        {searchText}
      </div>
    );
    return(
      <div>
        <Glyphicon glyph="search" />&nbsp;
        {searchText}&nbsp;
        <Glyphicon glyph="refresh" className="glyphicon-refresh-animate" />
      </div>
    );
  }

  render () {
    const {
      handleSubmit, pristine, submitting, submitSucceeded,
      roles, formUrl, initFilter,
      locales: {
        form: {
          username: usernameLabel,
          company: companyLabel,
          role: roleLabel,
        },
        buttons: {
          clear: clearText
        }
      }
    } = this.props;
    return(
      <Form
        className="form-horizontal"
        onSubmit={handleSubmit(this.onSubmit.bind(this))}
      >
        <Field component={TextInput}
          label={usernameLabel}
          type="text"
          name={`username`}
          labelClass="col-xs-2"
          inputClass="col-xs-3"
        />
        <Field component={TextInput}
          label={companyLabel}
          type="text"
          name={`company`}
          labelClass="col-xs-2"
          inputClass="col-xs-3"
        />
        <Field component={SelectInput}
          label={roleLabel}
          name={`role`}
          options={roles}
          liveSearch={true}
          labelClass='col-xs-2'
          selectWrapperClass='col-xs-3'
        />
        <Row>
          <Col xsOffset={2} xs={3}>
            <Button
              block
              type="submit"
              disabled={pristine || submitting || submitSucceeded}
            >{ this.renderTextSearchButton.bind(this)() }</Button>
          </Col>
          { !isEmpty(initFilter) && <Col xs={1}>
            <a href={formUrl}>
              <Button bsStyle="link" block>{ clearText }</Button>
            </a>
          </Col>}
        </Row>
      </Form>
    );
  }
}

UsersSearchingForm.propTypes = {
  formUrl: PropTypes.string,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }).isRequired
  ),
  locales: PropTypes.shape({
    form: PropTypes.shape({
      username: PropTypes.string,
      company: PropTypes.string,
      role: PropTypes.string,
    }).isRequired,
    buttons: PropTypes.shape({
      search: PropTypes.string,
      clear: PropTypes.string,
    }).isRequired
  }).isRequired,

  // Redux form
  initFilter: PropTypes.object,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  submitSucceeded: PropTypes.bool,

};

UsersSearchingForm.defaultProps = {
  roles: [],
};
