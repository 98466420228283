import map from "lodash/map";
import replace from "lodash/replace";
import qs from "qs";
import uuid from "uuid/v1";
import FetchRetry from "utils/FetchRetry";

// resolve(s3Sign)
// reject(ex)
const getS3Sign = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    const params = qs.stringify(options, { arrayFormat: "brackets" });
    FetchRetry(`${url}&${params}`, {
      credentials: "same-origin",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      retryDelay: 5000,
      retries: 3
    })
      .then(response => {
        return response.json();
      })
      .then(s3Sign => {
        resolve(s3Sign);
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

// @params
// key => to map the order with this file
// file => file
// urlGetS3Sign => url to get s3 sign
// @@return
// resolve(s3Data, file)
// reject(ex)
const uploadFile = (file, urlGetS3Sign, params = {}) => {
  return new Promise((resolve, reject) => {
    getS3Sign(urlGetS3Sign, params)
      .then(response => {
        // console.log('getS3Sign : ', response);
        const formData = new FormData();
        const s3Url = response.url;
        const s3Data = response.data;
        const filename = `${uuid()}.${file.name.split(".").pop()}`;
        s3Data.key = replace(s3Data.key, "${filename}", filename);
        s3Data["content-type"] = file.type;
        s3Data.file = file;

        map(s3Data, (value, key) => {
          formData.append(key, value);
        });

        FetchRetry(response.url, {
          credentials: "same-origin",
          method: "POST",
          mode: "no-cors",
          headers: {
            Accept:
              "application/json, application/xml, text/play, text/html, *.*"
          },
          body: formData,
          retryDelay: 5000,
          retries: 3
        })
          .then(_response => {
            // console.log('uploadFile finished : ', response);
            const fileUrl = `${s3Url}${s3Data.key}`;
            resolve({ s3Data, file, fileUrl });
          })
          .catch(ex => {
            // console.log('uploadFile failed : ', ex);
            reject(ex);
          });
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export default uploadFile;
