import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SelectInput from 'components/default_redux/SelectInput';

export default class FrameType extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange (e) {
    const frameType = e.target.value;
    this.props.onChangeFrameType(frameType);
  }

  render () {
    if(!this.props.show) return null;
    const { options, label, name } = this.props;
    return (
      <Field component={SelectInput}
        name={name}
        label={label}
        options={options}
        selectFirst={true}
        labelClass='col-xs-3'
        selectWrapperClass='col-xs-6'
        required={true}
        handleChange={this.handleChange.bind(this)}
        {...this.props}
      />
    );
  }
}
FrameType.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  show: PropTypes.bool,
  options: PropTypes.array,
  onChangeFrameType: PropTypes.func,

  // Redux Form
  input: PropTypes.object,
};

FrameType.defaultProps = {
  label: "Frame Type",
  show: false,
  options: [],
  onChangeFrameType: () => {},
};
