import React from 'react';
import PropTypes from 'prop-types';
import InputForm from 'components/default_redux/InputForm';
import Dropzone from 'react-dropzone';

class FileInput extends React.Component {
  onChange(acceptedFiles, rejectedFiles) {
    // this.props.input.onChange(value);
    this.props.afterOnChange(acceptedFiles, rejectedFiles);
  }

  render () {
    const {
      label, input, formClass, inputClass,
      labelClass, disabled, meta,
      suffixAddon, showLabel,
      helpText, helpTextClass,
      required,
      dropZoneButton
    } = this.props;
    return (
      <InputForm
        label={label}
        labelFor={input.name}
        formClass={formClass}
        inputClass={inputClass}
        labelClass={labelClass}
        validationState={
          (!disabled && (meta.touched && meta.invalid)) ? 'error' : null
        }
        suffixAddon={suffixAddon}
        showLabel={showLabel}
        helpText={helpText}
        helpTextClass={helpTextClass}
        required={required}
      >
        <Dropzone
          onDrop={this.onChange.bind(this)}
          className="dropzone"
          multiple={false}
        >
          { dropZoneButton }
        </Dropzone>
      </InputForm>
    );
  }
}

FileInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.any,
  afterOnChange: PropTypes.func,
  suffixAddon: PropTypes.string,
  formClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  helpText: PropTypes.string,
  helpTextClass: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.object,

  dropZoneButton: PropTypes.any,

  // Redux Form
  validate: PropTypes.array,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  input: PropTypes.object,
};
FileInput.defaultProps = {
  afterOnChange: (_acceptedFiles, _rejectedFiles)=> {},
  input: {},
  meta: {},
};
export default FileInput;
