import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

export default class DemoColor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    const colors = this.props.color ? this.props.color.split('-') : [];
    return (
      <div>
        {
          map(colors, color => {
            let styles = {
              colorDemo: {
                width: '46px',
                height: '24px',
                display: 'inline-block',
                margin: '5px',
                backgroundColor: `#${color}`,
                border: '1px solid #ccc',
                borderRadius: '4px'
              }
            };
            return(
              <span
                key={color}
                style={styles.colorDemo}
              ></span>
            );
          })
        }
      </div>
    );
  }
}

DemoColor.propTypes = {
  color: PropTypes.string,
};

DemoColor.defaultProps = {
  color: null
};
