import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { ADD } from '../../../../constants/mapOrderColumns';
import TextInput from '../../../default_redux/TextInput';
import { required } from '../../../../utils/Validators';


export default class ADDInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    if(!this.props.show) return null;
    // console.log(`Render ${this.props.label}`);
    return (
      <Field component={TextInput}
        label={this.props.label}
        name={`order${this.props.attrNestedName}[${ADD}]`}
        labelClass="col-xs-4"
        inputClass="col-xs-8"
        required={this.props.required}
        validate={[
          required.bind(null, this.props.required),
        ]}
        readOnly={true}
      />
    );
  }
}

ADDInput.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  attrNestedName: PropTypes.string,
};

ADDInput.defaultProps = {
  label: "ADD",
  show: false,
  required: false,
  min: 0,
  max: 0,
  attrNestedName: '',
};
