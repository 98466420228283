export const mapDatabase = {
  order: {
    properties: {
      side: {
        left: 'left_lens_attributes',
        right: 'right_lens_attributes'
      },
      quantity: 'quantity',
      sph: 'sphere',
      cyl: 'cylinder',
      add: 'addition',
      axis: 'axis',
      fitting: 'fitting',
      seg: 'seg',
      cvd: 'cvd',
      ffa: 'ffa',
      pta: 'pta',
      farPd: 'far_pd',
      nearPd: 'near_pd',
      decenter: 'decenter',
      decenterX: 'decenter_x',
      decenterY: 'decenter_y',
      prismH: 'prism_h',
      prismHValue: 'prism_h_value',
      prismV: 'prism_v',
      prismVValue: 'prism_v_value',
      prism: 'prism',
    },
    opticalInfo: {
      self: 'optical_informations_attributes',
      corridor: 'corridor',
      tint: 'tint',
      tintValue: 'tint_value',
      uv: 'uv',
      flashMirror: 'flash_mirror',
      cutting: 'cutting',
      optThickness: 'opt_thickness',
      frameType: 'frame_shape',
      shapeType: 'shape_type',
      roundShape: 'round_shape',
      roundShapeDia: 'round_shape_dia_value',
      ovalShape: 'oval_shape',
      shapeEdge: 'shape_edge',
      insetType: 'inset_type',
      readingDis: 'reading_dis',
    }
  },
  frameType: {
    fullRim: 'Full Rim Frame',
    nylon: 'Nylon Frame',
    rimLess: 'Rim Less Frame'
  },
  shapeType: {
    round: 'Round Shape',
    oval: 'Oval Shape',
  },
  insetType: {
    readingDistance: 'Customized Reading Distance',
    customizeInset: 'Customized Inset',
  }
};

export const actionNames = {
  // all
  UPDATE_FETCH_ERRORS: '@@all/update-fetch-errors',

  // Stock lens
  ADD_STOCK_LENS: '@@stock-lens/add-order',
  REMOVE_STOCK_LENS: '@@stock-lens/remove-order',
  UPDATE_STOCK_LENS: 'UPDATE_STOCK_LENS',
  UPDATE_AGEN_STOCK_LENS: 'UPDATE_AGEN_STOCK_LENS',
  UPDATE_FILTER: 'UPDATE_FILTER',

  // Rx lens
  UPDATE_RX_LENS: '@@rx-lens/update-order',
  UPDATE_PRODUCT_RX_LENS: '@@rx-lens/update-product',
  UPDATE_RX_LOADING: '@@rx-lens/update-loading',
  ALLOW_POWER_EDITING: '@@rx-lens/allow-power-editing',
  ALLOW_PRODUCT_SELECTING: '@@rx-lens/allow-product-selecting',
  SEARCHING_PRODUCTS: '@@rx-lens/searching-products',
  STORE_RX_PRODUCTS: '@@rx-lens/store-rx-products',
  STORE_RX_PRODUCTS_RESPONSE: '@@rx-lens/store-rx-products-response',
  SET_INITIALIZED_ORDER: '@@rx-lens/set-initialized-order',

  // Rx lens filter
  UPDATE_RX_LENS_FILTER: 'UPDATE_RX_LENS_FILTER',
  STORE_AVAILABLE_DESIGNS_MATERIALS: '@@filter/store-available-designs-materials',
  UPDATE_FILTER_STEP: '@@filter/update-filter-step',
  SEARCHING_INDEXES_DESIGNS: '@@filter/searching-indexes-designs',

  // Product List
  MOVE_PRODUCT: '@@product-list/move-product-to-other-list',
  SAVE_PRODUCTS: '@@product-list/save-products',
  SAVE_AVAILABLED_PRODUCTS: '@@product-list/save-availabled-products',
  UPDATE_SKUS: '@@product-list/update-availabled-skus',
  ADD_STORE_SKU: '@@product-list/add-sku-to-store-skus',
  DELETE_STORE_SKU: '@@product-list/remove-sku-from-store-skus',
  UPDATE_PRODUCT_MANAGEMENT_LOADING: '@@product-list/update-loading',
  UPDATE_PRODUCT_PAGINATION: '@@product-list/update-pagination',

  // User
  UPDATE_PARENTS: '@@user-form/update-parents',
};

export const productsListType = {
  STORE: 'products',
  AVAILABLE: 'availabledProducts',
};
