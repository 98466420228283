import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import isEqual from 'lodash/isEqual';
import isPresent from 'utils/isPresent';
import { Field } from 'redux-form';
import SelectInput from 'components/default_redux/SelectInput';
import FetchError from 'components/shared/FetchError';

export default class GlazingType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  shouldComponentUpdate (nextProps, _nextState) {
    if(this.props.show == nextProps.show &&
      this.props.required == nextProps.required &&
      isEqual(this.props.fetchError, nextProps.fetchError) &&
      isEqual(this.props.options, nextProps.options)
    ) return false;
    return true;
  }

  handleChange (e) {
    const cutting = e.target.value;
    this.props.onChangeGlazingType(cutting);
  }

  render () {
    if(!this.props.show) return null;
    const { options, label, fetchError, locales, name } = this.props;
    if(options.length == 0) return null;
    // console.log(`Render ${label}`, this.props);
    return (
      <div>
        <Field component={SelectInput}
          name={name}
          label={label}
          options={options}
          selectFirst={true}
          labelClass='col-xs-3'
          selectWrapperClass='col-xs-6'
          required={true}
          handleChange={this.handleChange.bind(this)}
          disabled={isPresent(fetchError)}
        />
        {
          fetchError && (
            <Row>
              <Col xsOffset={3} xs={9}>
                <FetchError error={fetchError} {...locales.requestErrors} />
              </Col>
            </Row>
          )
        }
      </div>
    );
  }
}
GlazingType.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array,
  onChangeGlazingType: PropTypes.func,
  sku: PropTypes.string,
  name: PropTypes.string,

  fetchError: PropTypes.object,
  locales: PropTypes.object,
};

GlazingType.defaultProps = {
  label: "Glazing",
  show: false,
  required: false,
  onChangeGlazingType: () => {},
  options: [],
};
