import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import InputForm from 'components/default_redux/InputForm';
import SelectFrameLibrary from 'components/ordering/glazing/SelectFrameLibrary';
import UploadShape from 'components/ordering/glazing/UploadShape';
import ShapeFileBox from 'components/shared/ShapeFileBox';
import Loading from 'components/shared/Loading';

import {
  FRAME_LIBRARY_ID_FIELD,
} from 'constants/orderFields';
export default class FrameShape extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.renderShapeFileBox = this.renderShapeFileBox.bind(this);
    this.availableMethods = this.availableMethods.bind(this);
  }

  shouldComponentUpdate (nextProps, _nextState) {
    const currentProps = this.props;
    if(isEqual(currentProps.show, nextProps.show) &&
      isEqual(currentProps.required, nextProps.required) &&
      isEqual(currentProps.selectFrameLibrary, nextProps.selectFrameLibrary) &&
      isEqual(currentProps.uploadFrameShape, nextProps.uploadFrameShape) &&
      isEqual(currentProps.input, nextProps.input) &&
      isEqual(currentProps.meta, nextProps.meta) &&
      isEqual(currentProps.loading, nextProps.loading)
    ) return false;
    return true;
  }

  componentWillUnmount() {
    const { input: { onChange } } = this.props;
    onChange(null);
  }

  renderShapeFileBox () {
    const { uploadFrameShape: { shapeFile } } = this.props;
    if(isEmpty(shapeFile)) return null;
    const file = isArray(shapeFile) ? shapeFile[0] : shapeFile;
    return(
      <div className="text-center" style={{paddingTop: '10px'}}>
        { ShapeFileBox(file, { download: false }) }
      </div>
    );
  }

  availableMethods () {
    const {
      change, s3SignUrl,
      selectFrameLibrary, uploadFrameShape,
      convertFieldName
    } = this.props;

    const methods = [];
    if(selectFrameLibrary.enabled_frame_library.show) {
      methods.push(
        <Field component={SelectFrameLibrary}
          key="select_shape"
          name={convertFieldName(FRAME_LIBRARY_ID_FIELD)}
          label="Select Shape"
          options={
            selectFrameLibrary.frame_library_values.map(frameLibrary => {
              return({
                name: frameLibrary.number,
                value: frameLibrary.id
              });
            })
          }
          convertFieldName={convertFieldName.bind(this)}
          change={change}
          labelClass='col-xs-3'
          selectWrapperClass='col-xs-6'
        />
      );
    }
    if(uploadFrameShape.enabled_upload_frame_shape.show) {
      methods.push(
        <UploadShape
          key="upload_shape"
          label={uploadFrameShape.upload_frame_shape_values.label || "Upload Shape"}
          show={true}
          shapeFile={uploadFrameShape.shapeFile}
          s3SignUrl={s3SignUrl}
          allowFileExtensions={uploadFrameShape.upload_frame_shape_values.allow_file_extensions}
          convertFieldName={convertFieldName.bind(this)}
          change={change}
        />
      );
    }

    return methods;
  }

  render () {
    const { show, loading, label, meta, input, required } = this.props;
    if(!show) return null;
    if(loading && loading.frameShape) return(
      <Loading
        size='md'
        iconStyle='loading'
      />
    );
    return(
      <InputForm
        label={label}
        validationState={(meta.touched && meta.invalid) ? 'error' : null}
        formClass="frame_shape_form"
        labelClass="col-xs-3"
        inputClass="col-xs-6"
        showLabel={true}
        required={required}
      >
        <input type="hidden" {...input} />
        <Row>
          <Col xs={12}>
            { this.availableMethods()
              .reduce((prev, curr, index) => [prev, <span key={index}>&nbsp;or&nbsp;</span>, curr])
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
          { this.renderShapeFileBox() }
          </Col>
        </Row>
      </InputForm>
    );
  }
}

FrameShape.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  product: PropTypes.object,
  s3SignUrl: PropTypes.string,
  loading: PropTypes.object,
  selectFrameLibrary: PropTypes.shape({
    enabled_frame_library: PropTypes.object.isRequired,
    frame_library_values: PropTypes.array,
  }),
  uploadFrameShape: PropTypes.shape({
    enabled_upload_frame_shape: PropTypes.object.isRequired,
    upload_frame_shape_values: PropTypes.object,
    shapeFile: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
  }),
  convertFieldName: PropTypes.func.isRequired,

  // Redux Form
  change: PropTypes.func.isRequired,
  meta: PropTypes.object,
  input: PropTypes.object,
};

FrameShape.defaultProps = {
  label: 'Frame Shape',
  show: false,
};
