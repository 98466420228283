import "whatwg-fetch";
import { actionNames, productsListType } from "constants.js";
import GenUrl from "utils/GenUrl";

const updateProductPagination = (source, metadata, changedMeta = {}) => {
  return {
    type: actionNames.UPDATE_PRODUCT_PAGINATION,
    source,
    metadata,
    changedMeta
  };
};

export const moveProduct = (fromList, toList, product) => {
  return {
    type: actionNames.MOVE_PRODUCT,
    product,
    fromList,
    toList
  };
};

export const addProductToList = (url, data, fromList, toList, productData) => {
  return dispatch => {
    fetch(url, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const result = productData || json;
        return [
          dispatch(updateProductPagination(toList, {}, { total: +1 })),
          dispatch(moveProduct(fromList, toList, result)),
          dispatch(addStoreSku(productData.sku))
        ];
      })
      .catch(_ex => {});
  };
};

export const deleteProductFromList = (
  url,
  data,
  fromList,
  toList,
  productData
) => {
  return dispatch => {
    fetch(url, {
      credentials: "same-origin",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const result = productData || json;
        return [
          dispatch(updateProductPagination(fromList, {}, { total: -1 })),
          dispatch(moveProduct(fromList, toList, result)),
          dispatch(deleteStoreSku(productData.sku))
        ];
      })
      .catch(_ex => {});
  };
};

export const saveAvailabledProducts = products => {
  return {
    type: actionNames.SAVE_AVAILABLED_PRODUCTS,
    products
  };
};

export const saveProducts = products => {
  return {
    type: actionNames.SAVE_PRODUCTS,
    products
  };
};

export const updateAvailabledSKUs = skus => {
  return {
    type: actionNames.UPDATE_SKUS,
    skus
  };
};

export const addStoreSku = sku => {
  return {
    type: actionNames.ADD_STORE_SKU,
    sku
  };
};

export const deleteStoreSku = sku => {
  return {
    type: actionNames.DELETE_STORE_SKU,
    sku
  };
};

export const updateLoading = loading => {
  return {
    type: actionNames.UPDATE_PRODUCT_MANAGEMENT_LOADING,
    loading
  };
};

export const loadProducts = (url, type, filters = {}) => {
  return dispatch => {
    const loading = {};
    switch (type) {
      case productsListType.STORE: {
        loading.productList = true;
        break;
      }
      case productsListType.AVAILABLE: {
        loading.availableProductList = true;
        break;
      }
      default: {
        loading.productList = true;
        loading.availableProductList = true;
      }
    }
    dispatch(updateLoading(loading));

    url = GenUrl(url, filters);
    fetch(url, {
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const products = json.data;
        const metadata = json.meta;
        const loading = {};
        switch (type) {
          case productsListType.STORE: {
            dispatch(saveProducts(products));
            loading.productList = false;
            break;
          }
          case productsListType.AVAILABLE: {
            dispatch(saveAvailabledProducts(products));
            loading.availableProductList = false;
            break;
          }
          default: {
            dispatch(saveProducts(products));
            loading.productList = false;
            loading.availableProductList = false;
            break;
          }
        }
        return [
          dispatch(updateProductPagination(type, metadata)),
          dispatch(updateLoading(loading))
        ];
      })
      .catch(_ex => {});
  };
};
