import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import isPresent from 'utils/isPresent';
import InputForm from 'components/default_redux/InputForm';
import Select from 'react-select';

const filterProps = props => {
  const InputProps = [
    // HTML attributes
    "placeholder",
    "type",
  ];
  return pick(props, InputProps);
};

class SelectInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.isSelectFirst = this.isSelectFirst.bind(this);
    this.selectFirstValue = this.selectFirstValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount () {
    const { initValue, input: { value: inputValue } } = this.props;
    const targetValue = inputValue || initValue;
    const firstValue = this.selectFirstValue(targetValue);
    if(isPresent(firstValue) && firstValue != targetValue) {
      const e = { value: firstValue };
      this.handleChange(e);
    }
  }

  componentWillUnmount() {
    const { input: { onChange } } = this.props;
    onChange(null);
  }

  handleChange(e) {
    const value = (e || {}).value || null;
    const { handleChange, input } = this.props;
    if (input.onChange) {
      input.onChange(value);
      // input.onBlur(value);
    }
    handleChange({target: {value}});
  }

  handleFocus (e) {
    const { multiLoadUrl } = this.props;
    if(!multiLoadUrl) return e;
    const { name } = this.props.input;
    this.refs[name].loadOptions('');
  }

  render () {
    let { value } = this.props.input;
    if(!isPresent(value)) value = null;
    const {
      label, selectName, formWrapperClass, selectWrapperClass, labelClass,
      disabled, meta, showLabel, suffixAddon, required,
      selectClass, blankDisplay,
      loadOptions, options,
      initValue,
      multiLoadUrl,
      input,
      input: {
        name, onBlur, onFocus
      }
    } = this.props;
    return (
      <InputForm
        label={label}
        labelFor={selectName}
        formClass={formWrapperClass}
        inputClass={selectWrapperClass}
        labelClass={labelClass}
        validationState={
          (!disabled && (meta.touched && meta.invalid)) ? 'error' : null
        }
        showLabel={showLabel}
        suffixAddon={suffixAddon}
        required={required}
      >
        {
          this.props.loadOptions ?
          <Select.Async
            ref={name}
            name={name || selectName}
            className={selectClass}
            options={options}
            placeholder={blankDisplay}
            labelKey="name"
            searchable={this.isLiveSearch()}
            disabled={disabled}
            clearable={!required}
            menuContainerStyle={{zIndex: 5}}
            {...input}
            {...filterProps(this.props)}
            onBlur={() => onBlur(value)}
            onFocus={
              (e) => {
                this.handleFocus(e);
                return onFocus(e);
              }
            }
            onChange={this.handleChange}
            value={value || this.selectFirstValue() || ''}
            loadOptions={loadOptions}
            cache={false}
            autoload={isPresent(value) || isPresent(initValue) || !multiLoadUrl}
          /> :
          <Select
            name={name || selectName}
            className={selectClass}
            options={options}
            placeholder={blankDisplay}
            labelKey="name"
            searchable={this.isLiveSearch()}
            disabled={disabled}
            clearable={!required}
            menuContainerStyle={{zIndex: 5}}
            {...input}
            {...filterProps(this.props)}
            onBlur={() => onBlur(value)}
            onFocus={(e) => onFocus(e)}
            onChange={this.handleChange}
            value={value || this.selectFirstValue() || ''}
          />
        }
      </InputForm>
    );
  }

  convertOptions () {
    const transformOptions = [];
    this.props.options.map((option)=>{
      let newOption = {
        value: option.value,
        label: option.name
      };
      transformOptions.push(newOption);
    });
    return transformOptions;
  }

  isLiveSearch () {
    // if(this.props.options.length <= 5) return false;
    return this.props.liveSearch || true;
  }

  isSelectFirst () {
    // if(this.props.options.length <= 1) return true;
    return this.props.selectFirst;
  }

  selectFirstValue (value) {
    if(!this.isSelectFirst()) return null;
    const { options } = this.props;
    let firstValue = value;
    if(!isPresent(value)) {
      if(options.length > 0) firstValue = options[0].value;
    }
    return firstValue;
  }

}

SelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ]).isRequired
    })
  ),
  initValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  handleChange: PropTypes.func,
  selectFirst: PropTypes.bool,
  liveSearch: PropTypes.bool,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  name: PropTypes.string,
  selectName: PropTypes.string,
  selectWrapperClass: PropTypes.string,
  selectClass: PropTypes.string,
  formWrapperClass: PropTypes.string,
  blankDisplay: PropTypes.string,
  showLabel: PropTypes.bool,
  suffixAddon: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  loadOptions: PropTypes.func,
  multiLoadUrl: PropTypes.bool,

  // Redux Form
  input: PropTypes.object,
  onChange: PropTypes.func,
  meta: PropTypes.object,
};

SelectInput.defaultProps = {
  options: [],
  initValue: null,
  handleChange: (_e) => {},
  selectFirst: false,
  liveSearch: true,
  selectName: '',
  label: '',
  labelClass: '',
  selectWrapperClass: '',
  selectClass: '',
  formWrapperClass: '',
  blankDisplay: '',
  showLabel: true,
  required: false,
  disabled: false,
  multiLoadUrl: false,

  // Redux Form
  input: {},
};

export default SelectInput;
