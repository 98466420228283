import React from 'react';
import { Glyphicon, Image } from 'react-bootstrap';

const FrameShapeBox = (frameShape) => {
  return(
    <div>
      <p><Glyphicon glyph="picture" /> {`Shape ${parseInt(frameShape.number)}`}</p>
      <div style={{display: 'inline-block'}}>
        <Image src={frameShape.image_url} rounded className="frame__image left" />
        <Image src={frameShape.image_url} rounded className="frame__image right" />
      </div>
    </div>
  );
};

export default FrameShapeBox;
