import React, { FC, useState } from "react";
import { ComponentProps, Product, ItemProperties, Side } from "./types";
import {
  Row,
  Col,
  Panel,
  Button,
  ControlLabel,
  FormControl
} from "react-bootstrap";

export const ProductList: FC<ComponentProps & ProductAddFn> = props => {
  const { children, results, addProduct, locales } = props;
  const resultsData = results.map((product, idx) => ({
    props: { ...product, addProduct, locales },
    key: `p-${idx}`
  }));
  return (
    <div>
      {children}
      {resultsData.map(d => (
        <ProductResult {...d.props} key={d.key} />
      ))}
      {resultsData.length < 1 && <p>{locales.hints.notFoundLenses}</p>}
    </div>
  );
};

const ProductResult: FC<Product & ProductAddFn> = props => {
  const { name, sapCode, sku, powerType, addProduct, locales } = props;

  let [sph, setSph] = useState(0);
  let [cyl, setCyl] = useState(0);
  let [base, setBase] = useState(0);
  let [add, setAdd] = useState(0);
  let [curve, setCurve] = useState(0);
  let [side, setSide] = useState(Side.Left);

  if(!powerType.includes("SPH")) { sph = undefined; }
  if(!powerType.includes("CYL")) { cyl = undefined; }
  if(!powerType.includes("Base")) { base = undefined; }
  if(!powerType.includes("Add")) { add = undefined; }
  if(!powerType.includes("Curve")) { curve = undefined; }
  if(!powerType.includes("Side")) { side = undefined; }

  const callback = () => addProduct(sapCode, sku, { sph, cyl, base, add, curve, side }, 1);

  return (
    <Panel bsStyle="primary">
      <Panel.Body>
        <p>{`${name} (${sapCode})`}</p>
        <Row>
          {powerType.map(power => (
            <Col xs={3} key={power}>
              <ControlLabel children={power} />{" "}
            </Col>
          ))}
        </Row>
        <Row>
          {powerType.includes("SPH") &&
            <Col xs={3}>
              <FormControl
                value={sph}
                onChange={ev => setSph(ev.target.value)}
                type="number"
                step="0.25"
                placeholder="0"
              />
            </Col>
          }
          {powerType.includes("CYL") &&
            <Col xs={3}>
              <FormControl
                value={cyl}
                onChange={ev => setCyl(ev.target.value)}
                type="number"
                step="0.25"
                max="0"
                placeholder="0"
              />
            </Col>
          }
          {powerType.includes("Base") &&
            <Col xs={3}>
              <FormControl
                value={base}
                onChange={ev => setBase(ev.target.value)}
                type="number"
                max="20"
                min="0"
                step="0.25"
                placeholder="Base"
              />
            </Col>
          }
          {powerType.includes("Add") &&
            <Col xs={3}>
              <FormControl
                value={add}
                onChange={ev => setAdd(ev.target.value)}
                type="number"
                min="0"
                max="4"
                step="0.25"
                placeholder="0"
              />
            </Col>
          }
          {powerType.includes("Curve") &&
            <Col xs={3}>
              <FormControl
                value={curve}
                onChange={ev => setCurve(ev.target.value)}
                type="number"
                min="0"
                step="1"
                placeholder="0"
              />
            </Col>
          }
          {powerType.includes("Side") &&
            <Col xs={3}>
              <FormControl
                value={side}
                onChange={ev => setSide(ev.target.value)}
                componentClass="select"
                placeholder="select"
              >
                {[Side.Left, Side.Right].map((opt, idx) => (
                  <option key={idx} value={opt}>
                    {opt}
                  </option>
                ))}
              </FormControl>
            </Col>
          }
          <Col xs={3} className="pull-right">
            <Button
              onClick={callback}
              bsStyle="primary"
              block
              children={locales.buttons.add}
            />
          </Col>
        </Row>
      </Panel.Body>
    </Panel>
  );
};

interface ProductAddFn {
  addProduct: (
    sapCode: string,
    sku: string,
    properties: ItemProperties,
    quantity?: number
  ) => void;
  locales?: any
}
