import React from "react";
import PropTypes from "prop-types";
import SelectUser from "components/shared/SelectUser";
import { PARENT_ID } from "constants/mapUserColumns";

export default class SelectParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parentSourceKey: null,
      label: "Parent"
    };
  }

  componentDidMount() {
    this.getParentSourceKey(this.props.currentRole);
  }

  componentWillReceiveProps(nextProps) {
    this.getParentSourceKey(nextProps.currentRole);
    // if(nextProps.currentRole != this.props.currentRole) this.clearParent();
  }

  render() {
    if (this.state.parentSourceKey == null) return null;
    const { sourcesUrl } = this.props;
    const { label, parentSourceKey } = this.state;
    return (
      <SelectUser
        label={label}
        name={`user[${PARENT_ID}]`}
        loadUrl={sourcesUrl[parentSourceKey]}
        labelClass="col-xs-3"
        inputClass="col-xs-9"
        liveSearch={true}
        selectFirst={false}
        required={false}
        displayRolePrefix={false}
        multiLoadUrl={true}
      />
    );
  }

  getParentSourceKey(role) {
    let sourceUrlName = null;
    let label = null;
    switch (role) {
      case "AE User":
        label = "Key User";
        sourceUrlName = "KeyUsersUrl";
        break;
      case "L1":
        label = "AE User";
        sourceUrlName = "AEUsersUrl";
        break;
      case "L2":
        label = "L1";
        sourceUrlName = "L1UsersUrl";
        break;
      case "L3":
        label = "L2";
        sourceUrlName = "L2UsersUrl";
        break;
      case "B.L1":
        label = "Admin";
        sourceUrlName = "BulkAdminUrl";
        break;
    }
    this.setState({
      parentSourceKey: sourceUrlName,
      label: label
    });
    return sourceUrlName;
  }
}

SelectParent.propTypes = {
  parentId: PropTypes.number,
  currentRole: PropTypes.string,
  sourcesUrl: PropTypes.object,
  locales: PropTypes.object
};

SelectParent.defaultProps = {
  locales: {},
  sourcesUrl: {}
};
