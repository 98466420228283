import React from 'react';
import PropTypes from 'prop-types';
import isPresent from 'utils/isPresent';

class HiddenInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount () {
    let {
      defautlValue,
      input: {
        onChange,
        value: currentValue,
      }
    } = this.props;
    if(!isPresent(currentValue)) onChange(defautlValue);
  }

  componentWillUnmount() {
    const { input: { onChange } } = this.props;
    onChange(null);
  }

  render () {
    return (
      <input type="hidden" {...this.props.input} />
    );
  }

}

HiddenInput.propTypes = {
  defautlValue: PropTypes.any,

  // Redux Form
  input: PropTypes.object,
};

HiddenInput.defaultProps = {
};

export default HiddenInput;
