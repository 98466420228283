import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import { Field } from 'redux-form';
import {
  ROUND_OPTION, OVAL_OPTION
} from '../../../../constants/mapOrderColumns';
import {
  SHAPE_TYPE_FIELD,
} from '../../../../constants/orderFields';
import SelectInput from '../../../default_redux/SelectInput';

export default class ShapeType extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount () {
    this.checkOptionMatchServer.bind(this)();
  }

  checkOptionMatchServer () {
    const serverOptions = map(this.props.options, option => {
      return option.value;
    });
    const clientOptions = [ROUND_OPTION, OVAL_OPTION];
    forEach(serverOptions, serverOption => {
      if(includes(clientOptions, serverOption)) return true;
      console.error(
        `${serverOption} does not within ${clientOptions.join(', ')}. Client options of shape type must update!`
      );
    });
  }

  render () {
    if(!this.props.show) return null;
    const { options, label } = this.props;
    if(options.length == 0) return null;
    // console.log(`Render ${this.props.label}`);
    return (
      <div>
        <Field component={SelectInput}
          name={SHAPE_TYPE_FIELD}
          label={label}
          options={options}
          selectFirst={true}
          labelClass='col-xs-3'
          selectWrapperClass='col-xs-6'
          required={true}
        />
        {this.props.children}
      </div>
    );
  }
}

ShapeType.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  options: PropTypes.array,
  children: PropTypes.any,
};

ShapeType.defaultProps = {
  label: "Shape",
  show: false,
  options: [],
};
