import React from "react";
import PropTypes from "prop-types";
import forEach from "lodash/forEach";
import sortBy from "lodash/sortBy";
import slice from "lodash/slice";
import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";
import pick from "lodash/pick";
import isPresent from "utils/isPresent";
import getIn from "redux-form/lib/structure/plain/getIn";

import {
  Row,
  Col,
  Button,
  Form,
  Panel,
  Glyphicon,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import SelectInput from "components/default_redux/SelectInput";
import RxFilterPower from "components/filters/rx_lens/RxFilterPower";
import StepHeader from "components/shared/StepHeader";
import FetchError from "components/shared/FetchError";

// import RxFilterDia from './RxFilterDia';
import { FILTER_LENS_BY_POWER as FILTER_POWER_FORM_NAME } from "constants/reduxFormNames";
import {
  SKU_FIELD,
  LEFT_LENS_FIELDS,
  RIGHT_LENS_FIELDS,
  LEFT_LENS_ID_FIELD,
  RIGHT_LENS_ID_FIELD,
  LEFT_QUANTITY_FIELD,
  RIGHT_QUANTITY_FIELD,
  LEFT_SPH_FIELD,
  RIGHT_SPH_FIELD,
  LEFT_CYL_FIELD,
  RIGHT_CYL_FIELD,
  LEFT_ADD_FIELD,
  RIGHT_ADD_FIELD,
  LEFT_AXIS_FIELD,
  RIGHT_AXIS_FIELD
} from "constants/orderFields";
import {
  OPTICAL_INFO_ATTR,
  LEFT_SIDE,
  RIGHT_SIDE,
  LENS_ID,
  SPH,
  CYL,
  ADD,
  AXIS
} from "constants/mapOrderColumns";

class RxFilterLensForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderProductsNotFound = this.renderProductsNotFound.bind(this);
    this.clearStepValue = this.clearStepValue.bind(this);

    this.searchByFilter = this.searchByFilter.bind(this);
    this.editPower = this.editPower.bind(this);
    this.clearPrescriptionOrder = this.clearPrescriptionOrder.bind(this);
    this.searchDesignsMaterials = this.searchDesignsMaterials.bind(this);
  }

  componentDidMount() {
    const {
      order,
      actions: { updateFilterStep, loadProductRxLens, searchingProducts }
    } = this.props;
    if (isPresent(order) && isPresent(order.sku)) {
      let filter = {};
      const optical = order[OPTICAL_INFO_ATTR] || {};
      const leftLens = order[LEFT_SIDE] || {};
      const rightLens = order[RIGHT_SIDE] || {};
      filter.left = pick(leftLens, [SPH, CYL, ADD, AXIS]);
      filter.right = pick(rightLens, [SPH, CYL, ADD, AXIS]);
      filter.left.enabled = isPresent(leftLens);
      filter.right.enabled = isPresent(rightLens);

      this.searchDesignsMaterials({ filter }, _json => {
        updateFilterStep(4);
        filter.brand_id = optical.rx_brand_id;
        filter.material_id = optical.rx_material_id;
        filter.design_id = optical.rx_design_id;
        this.searchByFilter({ filter }, false, _json => {
          const sku = getIn(order, "sku");
          const loading = {
            product: true,
            glazing: true,
            glazingFields: true,
            frameShapeFields: true,
            prescription: true
          };
          loadProductRxLens(sku, order, loading, _product => {
            searchingProducts(false);
          });
        });
      });
    }
  }

  searchByFilter(formValues, isClear = true, callback = _json => {}) {
    const {
      actions: {
        allowProductSelecting,
        allowPowerEditing,
        searchingProducts,
        searchProductsByPower,
        storeProductRxLens,
        changeOrder,
        storeProducts,
        updateRxLens
      },
      afterSearchLenses,
      sources: { productsUrl }
    } = this.props;
    if (isClear) {
      changeOrder(SKU_FIELD, null);
      storeProducts(null);
      updateRxLens(null);
      this.clearPrescriptionOrder("left");
      this.clearPrescriptionOrder("right");
    }
    allowProductSelecting(false);
    allowPowerEditing(false);
    searchingProducts(true);
    const filter = {
      filters_by_power: {
        left: getIn(formValues, "filter.left"),
        right: getIn(formValues, "filter.right")
      },
      filters: {
        color_group: getIn(formValues, "filter.color_group_id"),
        brand: getIn(formValues, "filter.brand_id"),
        material: getIn(formValues, "filter.material_id"),
        design: getIn(formValues, "filter.design_id")
      }
    };

    const beforeSearch = dispatch => {
      dispatch(storeProductRxLens(null));
      dispatch(storeProducts(null));
    };
    const searchCallback = json => {
      if (isClear) {
        afterSearchLenses(
          filter.filters_by_power.left,
          filter.filters_by_power.right,
          filter.filters
        );
      }
      searchingProducts(false);
      allowProductSelecting(true);
      callback(json);
    };
    const beforeRetryCallback = () => {};
    searchProductsByPower(
      productsUrl,
      filter,
      beforeSearch,
      searchCallback,
      beforeRetryCallback
    );
  }

  editPower() {
    const {
      actions: {
        allowProductSelecting,
        allowPowerEditing,
        storeProducts,
        changeOrder,
        updateFilterStep,
        storeAvailabeDesignsMaterials
      }
    } = this.props;
    changeOrder(SKU_FIELD, null);
    storeProducts(null);
    storeAvailabeDesignsMaterials(null);
    allowProductSelecting(false);
    allowPowerEditing(true);
    this.clearStepValue(1);
    updateFilterStep(0);
  }

  clearPrescriptionOrder(side) {
    const filter = this.props.filter;
    if (!isPresent(filter)) return null;
    let changeFields;
    const {
      order: currentOrder,
      actions: { changeOrder }
    } = this.props;
    switch (side) {
      case "left":
        changeFields = {
          [LEFT_LENS_FIELDS]: null,
          [LEFT_QUANTITY_FIELD]: filter.left.enabled ? 1 : 0,
          [LEFT_LENS_ID_FIELD]: getIn(currentOrder, `${LEFT_SIDE}.${LENS_ID}`),
          [LEFT_SPH_FIELD]: filter.left[SPH] || null,
          [LEFT_CYL_FIELD]: filter.left[CYL] || null,
          [LEFT_ADD_FIELD]: filter.left[ADD] || null,
          [LEFT_AXIS_FIELD]: filter.left[AXIS] || null
        };
        break;
      case "right":
        changeFields = {
          [RIGHT_LENS_FIELDS]: null,
          [RIGHT_QUANTITY_FIELD]: filter.right.enabled ? 1 : 0,
          [RIGHT_LENS_ID_FIELD]: getIn(
            currentOrder,
            `${RIGHT_SIDE}.${LENS_ID}`
          ),
          [RIGHT_SPH_FIELD]: filter.right[SPH] || null,
          [RIGHT_CYL_FIELD]: filter.right[CYL] || null,
          [RIGHT_ADD_FIELD]: filter.right[ADD] || null,
          [RIGHT_AXIS_FIELD]: filter.right[AXIS] || null
        };
        break;
    }
    forEach(changeFields, (value, field) => {
      changeOrder(field, value);
    });
  }

  renderProductsNotFound() {
    const {
      products,
      canEditPower,
      searchingProducts,
      availableDesignsMaterials,
      searchingIndexesDesigns,
      locales: {
        hints: { factoryNotProduction: factoryNotProductionHint }
      }
    } = this.props;
    if (
      isPresent(products) ||
      isPresent(availableDesignsMaterials) ||
      canEditPower ||
      searchingProducts ||
      searchingIndexesDesigns
    )
      return null;

    return (
      <Row>
        <Col xsOffset={3} xs={9}>
          <p className="text-danger text-center">{factoryNotProductionHint}</p>
        </Col>
      </Row>
    );
  }

  searchDesignsMaterials(formValues, callback = _json => {}) {
    const {
      extraFields: { enabled_brand_filter },
      actions: {
        searchAvailableDesignsMaterials,
        allowProductSelecting,
        allowPowerEditing,
        searchingIndexesDesigns,
        updateFilterStep
      }
    } = this.props;
    const { availabeDesignsMaterialsUrl } = this.props.sources;
    allowProductSelecting(false);
    allowPowerEditing(false);
    searchingIndexesDesigns(true);
    const fetchCallback = json => {
      searchingIndexesDesigns(false);
      let step = 1;
      if (!enabled_brand_filter.show) step = 2;
      updateFilterStep(step);
      callback(json);
    };
    const fetchFailed = () => {
      this.editPower();
      allowProductSelecting(false);
      allowPowerEditing(false);
      searchingIndexesDesigns(true);
    };
    searchAvailableDesignsMaterials(
      availabeDesignsMaterialsUrl,
      { filters_by_power: formValues.filter },
      fetchCallback,
      fetchFailed
    );
  }

  render() {
    const filter = this.props.filter || { left: {}, right: {} };
    const {
      handleSubmit,
      change,
      canEditPower,
      searchingProducts,
      searchingIndexesDesigns,
      locales,
      availableDesignsMaterialsFetchError,
      powerRanges
    } = this.props;
    return (
      <Form
        horizontal
        ref="filterForm"
        onSubmit={handleSubmit(this.searchByFilter)}
      >
        <Panel>
          <Panel.Body>
            <Row>
              <Col xsOffset={2} xs={8}>
                <StepHeader
                  tag="h2"
                  number="1"
                  desc={locales.page.steps.findLens}
                />
              </Col>
            </Row>
            <Row>
              <Col xsOffset={2} xs={8}>
                <RxFilterPower
                  editPower={this.editPower}
                  changeFilter={change}
                  canEdit={canEditPower}
                  searchingIndexesDesigns={searchingIndexesDesigns}
                  searchingProducts={searchingProducts}
                  enabledLeft={filter.left.enabled || false}
                  enabledRight={filter.right.enabled || false}
                  handleSubmit={handleSubmit}
                  searchDesignsMaterials={this.searchDesignsMaterials}
                  fetchError={availableDesignsMaterialsFetchError}
                  locales={locales}
                  powerRanges={powerRanges}
                  filter={filter}
                />
                {!availableDesignsMaterialsFetchError &&
                  this.displaySteps.bind(this)()}
                {!availableDesignsMaterialsFetchError &&
                  this.renderProductsNotFound()}
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
      </Form>
    );
  }

  handleStepChange(step, _e) {
    const {
      updateFilterStep,
      storeProducts,
      changeOrder,
      allowProductSelecting
    } = this.props.actions;
    allowProductSelecting(false);
    this.clearStepValue(step);
    updateFilterStep(step);
    changeOrder(SKU_FIELD, null);
    storeProducts(null);
  }

  clearStepValue(step) {
    const { change } = this.props;
    const clearFields = [
      `filter.color_group_id`,
      `filter.brand_id`,
      `filter.material_id`,
      `filter.design_id`
    ];
    forEach(slice(clearFields, step - 1), field => {
      change(field, null);
    });
  }

  colorGroupComponent() {
    const {
      searchingProducts,
      availableDesignsMaterials,
      colorGroups,
      locales: {
        placeholders: { default: defaultPlaceholderText }
      }
    } = this.props;

    const colorGroupIds = map(availableDesignsMaterials, "color_group_id");
    const colorGroupOptions = filter(colorGroups, colorGroup => {
      return includes(colorGroupIds, colorGroup.id);
    });
    return (
      <Field
        component={SelectInput}
        key="color_group_id"
        options={sortBy(colorGroupOptions, "name").map(colorGroup => {
          return {
            name: colorGroup.name.toString(),
            value: colorGroup.id
          };
        })}
        handleChange={this.handleStepChange.bind(this, 2)}
        label="Filter"
        labelClass="col-xs-5"
        name={`filter.color_group_id`}
        selectWrapperClass="col-xs-7"
        required={true}
        blankDisplay={defaultPlaceholderText}
        disabled={searchingProducts}
      />
    );
  }

  brandComponent() {
    const {
      searchingProducts,
      availableDesignsMaterials,
      brands,
      extraFields: { enabled_brand_filter },
      filter: { color_group_id: colorGroupId },
      locales: {
        placeholders: { default: defaultPlaceholderText }
      }
    } = this.props;
    if (!enabled_brand_filter.show) return null;

    const previousFilterValues = {};
    if (isPresent(colorGroupId)) {
      previousFilterValues.color_group_id = colorGroupId;
    }
    const brandIds = map(
      filter(availableDesignsMaterials, previousFilterValues),
      "brand_id"
    );
    const brandOptions = filter(brands, brand => {
      return includes(brandIds, brand.id);
    });

    return (
      <Field
        component={SelectInput}
        key="brand_id"
        options={sortBy(brandOptions, "name").map(brand => {
          return {
            name: brand.name.toString(),
            value: brand.id
          };
        })}
        handleChange={this.handleStepChange.bind(this, 3)}
        label="Brand"
        labelClass="col-xs-5"
        name={`filter.brand_id`}
        selectWrapperClass="col-xs-7"
        required={true}
        blankDisplay={defaultPlaceholderText}
        disabled={searchingProducts}
      />
    );
  }

  indexComponent() {
    const {
      searchingProducts,
      availableDesignsMaterials,
      indexes,
      filter: { color_group_id: colorGroupId, brand_id: brandId },
      locales: {
        placeholders: { default: defaultPlaceholderText }
      }
    } = this.props;

    const previousFilterValues = {};
    if (isPresent(colorGroupId)) {
      previousFilterValues.color_group_id = colorGroupId;
    }
    if (isPresent(brandId)) previousFilterValues.brand_id = brandId;
    const indexIds = map(
      filter(availableDesignsMaterials, previousFilterValues),
      "material_id"
    );
    const indexOptions = filter(indexes, index => {
      return includes(indexIds, index.id);
    });
    return (
      <Field
        component={SelectInput}
        key="material_id"
        options={sortBy(indexOptions, "name").map(index => {
          return {
            name: index.name.toString(),
            value: index.id
          };
        })}
        handleChange={this.handleStepChange.bind(this, 4)}
        label="Index"
        labelClass="col-xs-5"
        name={`filter.material_id`}
        selectWrapperClass="col-xs-7"
        required={true}
        blankDisplay={defaultPlaceholderText}
        disabled={searchingProducts}
      />
    );
  }

  designComponent() {
    const {
      searchingProducts,
      availableDesignsMaterials,
      designs,
      filter: {
        color_group_id: colorGroupId,
        brand_id: brandId,
        material_id: indexId
      },
      locales: {
        placeholders: { default: defaultPlaceholderText }
      }
    } = this.props;

    const previousFilterValues = {};
    if (isPresent(colorGroupId)) {
      previousFilterValues.color_group_id = colorGroupId;
    }
    if (isPresent(brandId)) previousFilterValues.brand_id = brandId;
    if (isPresent(indexId)) previousFilterValues.material_id = indexId;
    const designIds = map(
      filter(availableDesignsMaterials, previousFilterValues),
      "design_id"
    );
    const designOptions = filter(designs, design => {
      return includes(designIds, design.id);
    });
    return (
      <Field
        component={SelectInput}
        key="design_id"
        options={sortBy(designOptions, "name").map(design => {
          return {
            name: design.name.toString(),
            value: design.id
          };
        })}
        handleChange={this.handleStepChange.bind(this, 5)}
        label="Design"
        labelClass="col-xs-5"
        name={`filter.design_id`}
        selectWrapperClass="col-xs-7"
        required={true}
        blankDisplay={defaultPlaceholderText}
        disabled={searchingProducts}
      />
    );
  }

  filterButton() {
    const { searchProductsByPowerFetchError } = this.props;
    if (isPresent(searchProductsByPowerFetchError)) return null;
    const {
      handleSubmit,
      searchingProducts,
      pristine,
      locales: {
        buttons: { filterLenses: filterLensesButtonTest }
      }
    } = this.props;
    return (
      <Row key="filterButton">
        <Col xsOffset={5} xs={7}>
          <Button
            type="submit"
            block
            disabled={pristine || searchingProducts}
            onClick={handleSubmit(this.searchByFilter)}
          >
            {searchingProducts ? (
              <Glyphicon
                glyph="refresh"
                className="glyphicon-refresh-animate"
              />
            ) : (
              <Glyphicon glyph="filter" />
            )}
            &nbsp;
            {filterLensesButtonTest}
          </Button>
        </Col>
      </Row>
    );
  }

  displaySteps() {
    const {
      availableDesignsMaterials,
      filterStep,
      searchProductsByPowerFetchError,
      locales,
      locales: {
        hints: {
          factoryNotProduction: factoryNotProductionHint,
          askIndexDesignNotFound: askIndexDesignNotFoundHint
        }
      }
    } = this.props;
    if (!isPresent(availableDesignsMaterials)) return null;
    let matchingElements = [];

    matchingElements.push(this.colorGroupComponent.bind(this)());
    matchingElements.push(this.brandComponent.bind(this)());
    matchingElements.push(this.indexComponent.bind(this)());
    matchingElements.push(this.designComponent.bind(this)());
    matchingElements.push(this.filterButton.bind(this)());
    // matchingElements.push(<RxFilterDia key="dia" {...this.props} />);
    return (
      <div>
        <Row>
          <Col xsOffset={3} xs={9}>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={7} style={{ paddingLeft: "22px" }}>
            {slice(matchingElements, 0, filterStep)}
          </Col>
          <Col xs={5}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="ask-index-design-not-found-tooltip">
                  {factoryNotProductionHint}
                </Tooltip>
              }
            >
              <p className="text-muted" style={{ cursor: "pointer" }}>
                {askIndexDesignNotFoundHint}
              </p>
            </OverlayTrigger>
          </Col>
        </Row>
        {searchProductsByPowerFetchError && (
          <Row>
            <Col xsOffset={3} xs={9}>
              <FetchError
                error={searchProductsByPowerFetchError}
                {...locales.requestErrors}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

RxFilterLensForm.propTypes = {
  // customers: PropTypes.array.isRequired
  order: PropTypes.object,
  colorGroups: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  indexes: PropTypes.array.isRequired,
  designs: PropTypes.array.isRequired,
  extraFields: PropTypes.shape({
    enabled_brand_filter: PropTypes.shape({
      show: PropTypes.bool.isRequired
    }).isRequired
  }),
  sources: PropTypes.shape({
    productsUrl: PropTypes.string.isRequired,
    availabeDesignsMaterialsUrl: PropTypes.string.isRequired
  }),
  locales: PropTypes.shape({
    hints: PropTypes.shape({
      factoryNotProduction: PropTypes.string,
      askIndexDesignNotFound: PropTypes.string
    }),
    buttons: PropTypes.shape({
      filterLenses: PropTypes.string
    }),
    placeholders: PropTypes.shape({
      default: PropTypes.string
    })
  }),
  powerRanges: PropTypes.shape({
    sph: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number
    }),
    cyl: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number
    }),
    axis: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number
    }),
    add: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number
    }),
    dia: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number
    })
  }),
  // Redux Store
  filter: PropTypes.object,
  filterStep: PropTypes.number,
  products: PropTypes.array,
  canEditPower: PropTypes.bool,
  searchingProducts: PropTypes.bool,
  searchingIndexesDesigns: PropTypes.bool,
  availableDesignsMaterials: PropTypes.array,
  afterSearchLenses: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    updateRxLensFilter: PropTypes.func.isRequired,
    changeOrder: PropTypes.func.isRequired,
    searchProductsByPower: PropTypes.func.isRequired,
    allowProductSelecting: PropTypes.func.isRequired,
    allowPowerEditing: PropTypes.func.isRequired,
    storeProducts: PropTypes.func.isRequired,
    searchAvailableDesignsMaterials: PropTypes.func.isRequired,
    updateFilterStep: PropTypes.func.isRequired,
    updateRxLens: PropTypes.func.isRequired,
    searchingProducts: PropTypes.func.isRequired,
    searchingIndexesDesigns: PropTypes.func.isRequired,
    storeAvailabeDesignsMaterials: PropTypes.func.isRequired
  }),
  availableDesignsMaterialsFetchError: PropTypes.object,
  searchProductsByPowerFetchError: PropTypes.object,

  // Redux form
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool
};

RxFilterLensForm.defaultProps = {
  indexes: [],
  designs: [],
  values: {}
};

// Decorate the form component
let form = reduxForm({
  form: FILTER_POWER_FORM_NAME
})(RxFilterLensForm);

const selector = formValueSelector(FILTER_POWER_FORM_NAME); // <-- same as form name
form = connect((state, props) => {
  const initial = {
    filter: {
      left: {
        enabled: true,
        cylinder: "0.00"
      },
      right: {
        enabled: true,
        cylinder: "0.00"
      }
    }
  };

  const order = props.order;
  if (isPresent(order) && isPresent(order.sku)) {
    const optical = order.optical_informations_attributes || {};
    const leftLens = order.left_lens_attributes || {};
    const rightLens = order.right_lens_attributes || {};
    initial.filter.left = leftLens;
    initial.filter.right = rightLens;
    initial.filter.left.enabled = isPresent(leftLens);
    initial.filter.right.enabled = isPresent(rightLens);
    initial.filter.brand_id = optical.rx_brand_id;
    initial.filter.material_id = optical.rx_material_id;
    initial.filter.design_id = optical.rx_design_id;
    initial.filter.color_group_id = optical.rx_color_group_id;
  }

  const filter = selector(state, `filter`);
  return {
    initialValues: initial,
    filter
  };
})(form);
export default form;
