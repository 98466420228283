import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { Button } from 'react-bootstrap';
import AskLanguage from 'components/shared/AskLanguage';

export default class SwitchLanguage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAskLanguageModal: false
    };
    this.showAskLanguage = this.showAskLanguage.bind(this);
    this.hideAskLanguage = this.hideAskLanguage.bind(this);
  }

  hideAskLanguage () {
    this.setState({showAskLanguageModal: false});
  }

  showAskLanguage () {
    this.setState({showAskLanguageModal: true});
  }

  render () {
    const { currentLocale, languages, label } = this.props;
    const { showAskLanguageModal } = this.state;
    const currentLanguage = find(languages, ['locale', currentLocale]);
    return (
      <div className="hidden-print">
        <span>{label} : </span>
        <Button
          onClick={this.showAskLanguage}
          bsSize="sm"
        >
          <span className={`flag-icon flag-icon-${currentLanguage.flag}`} aria-hidden="true"></span>
          &nbsp;{ currentLanguage.name }&nbsp;
          <span className="caret"></span>
        </Button>
        <AskLanguage
          {...this.props}
          show={showAskLanguageModal}
          handleHide={this.hideAskLanguage}
        />
      </div>
    );
  }
}

SwitchLanguage.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  label: PropTypes.string,
  languages: PropTypes.array,
};

SwitchLanguage.defaultProps = {
  label: "เปลี่ยนภาษา",
  languages: [],
};
