import map from "lodash/map";
import range from "lodash/range";
import rangeRight from "lodash/rangeRight";
import isNumber from "lodash/isNumber";

export const numberWithSign = (number, decimals = 0) => {
  if (!isNumber(number)) return number;
  return (number > 0 ? "+" : "") + number.toFixed(decimals);
};

export const genRange = (
  min = 0,
  max = 0,
  period = 0.25,
  decimals = 2,
  sign = false,
  order = "asc"
) => {
  max = max + period;
  const numbers =
    order == "desc" ? rangeRight(min, max, period) : range(min, max, period);
  return map(numbers, number => {
    const value = number.toFixed(decimals);
    const name = sign ? numberWithSign(number, decimals) : value;
    return { name, value };
  });
};
