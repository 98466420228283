import React, { FC } from "react";
import { ComponentProps } from "./types";

export const Output: FC<ComponentProps> = props => {
  const { cart } = props;
  return (
    <>
      <input type="hidden" name="cart" value={JSON.stringify(cart)} />
    </>
  );
};
