import React from 'react';
import PropTypes from 'prop-types';
import isPresent from 'utils/isPresent';
import { NOTE_ON_LABEL_FIELD } from 'constants/orderFields';
import { Field } from 'redux-form';
import TextInput from 'components/default_redux/TextInput';

export default class RxNoteOnLabel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }

  onChange (value) {
    const { helpTextClass: oldHelpTextClass } = this.state;
    if(isPresent(value) && !isPresent(oldHelpTextClass)) {
      this.setState({helpTextClass: 'text-danger'});
    }
    if(!isPresent(value)) {
      this.setState({helpTextClass: null});
    }
  }

  render () {
    // console.log('Render Patient');
    const { label, placeholder, helpText } = this.props;
    const { helpTextClass } = this.state;
    return (
      <Field component={TextInput}
        label={label}
        type="text"
        name={NOTE_ON_LABEL_FIELD}
        labelClass="col-xs-2"
        inputClass="col-xs-4"
        placeholder={placeholder}
        helpText={helpText}
        helpTextClass={helpTextClass}
        afterOnChange={this.onChange}
      />
    );
  }
}

RxNoteOnLabel.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
};

RxNoteOnLabel.defaultProps = {
  label: 'Note on Label',
};
