import React, { FC } from "react";
import { Modal, Row, Col, Panel, ListGroup, Button } from "react-bootstrap";
import { ComponentProps, UIState } from "./types";
import { BulkOrderDetails } from "components/orders/BulkOrderDetails";

export const BulkOrderConfirmModal: FC<ComponentProps & {
  setUITab: (s: UIState) => void;
  saveOrder: () => void;
  locales: any;
 }> = props => {
  const { uiState, setUITab, saveOrder, preview, locales } = props;

  const close = () => {
    setUITab(UIState.Cart);
  };

  const confirm = () => {
    saveOrder();
  };

  return (
    <Modal
      show={uiState == UIState.Review}
      bsSize="large"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{locales.modal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Panel>
          <Panel.Body>
            <ListGroup>
              <BulkOrderDetails
                order={preview}
                locales={locales}
              />
            </ListGroup>
          </Panel.Body>
        </Panel>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col xsOffset={7} xs={2}>
            <Button
              onClick={close}
              block
            >
              {locales.buttons.edit}
            </Button>
          </Col>
          <Col xs={3}>
            <Button
              bsStyle="primary"
              onClick={confirm}
              block
            >
              {locales.buttons.confirm}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};