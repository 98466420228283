import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, Glyphicon } from 'react-bootstrap';
import Modal from 'react-bootstrap-modal';
import TimeCountdown from 'components/shared/TimeCountdown';
import ReactTooltip from 'react-tooltip';

export default class OrderCancelButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: props.disabled || false,
      showConfirmation: false,
      isCancelling: false,
      expriedCancelling: false,
    };
    this.renderButtonText = this.renderButtonText.bind(this);
    this.afterEndCountdown = this.afterEndCountdown.bind(this);
    this.afterStartCountdown = this.afterStartCountdown.bind(this);
    this.openConfirmation = this.openConfirmation.bind(this);
    this.closeConfirmation = this.closeConfirmation.bind(this);
    this.renderCoundownMessage = this.renderCoundownMessage.bind(this);
    this.renderCoundown = this.renderCoundown.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }



  openConfirmation () {
    this.setState({showConfirmation: true});
  }

  closeConfirmation () {
    this.setState({showConfirmation: false});
  }

  setCancelling () {
    this.setState({isCancelling: true});
  }

  afterStartCountdown () {
    this.setState({disabled: false, expriedCancelling: false});
  }

  afterEndCountdown () {
    this.setState({disabled: true, expriedCancelling: true});
  }

  renderButtonText () {
    const { label, showIcon } = this.props;
    return (
      <span>
        {showIcon && <span><Glyphicon glyph="trash" />&nbsp;</span>}
        {label}
      </span>
    );
  }

  renderCoundownMessage () {
    const {
      expiredTime, replaceText,
      countMessage, endMessage, cancelable
    } = this.props;
    if (cancelable) return;
    return(
      <TimeCountdown
        expiredTime={expiredTime}
        replaceText={replaceText}
        countMessage={countMessage}
        endMessage={endMessage}
        afterStartCountdown={this.afterStartCountdown}
        afterEndCountdown={this.afterEndCountdown}
      />
    );
  }

  renderCoundown () {
    const {
      expiredTime,
      countMessage, endMessage, cancelable
    } = this.props;
    if (cancelable) return;
    return(
      <TimeCountdown
        expiredTime={expiredTime}
        replaceText={countMessage}
        countMessage={countMessage}
        endMessage={endMessage}
        afterStartCountdown={this.afterStartCountdown}
        afterEndCountdown={this.afterEndCountdown}
      />
    );
  }

  renderTooltip () {
    const { disabled } = this.state;
    const {
      url: tooltipID,
      replaceText,
      countMessage, endMessage,
      cancelableMessage, cancelable,
      tooltipPosition
    } = this.props;

    let tooltipMessages = null;
    if (cancelable) {
      tooltipMessages = (
        <div>
          {cancelableMessage}
        </div>
      );
    } else if(disabled) {
      tooltipMessages = <span dangerouslySetInnerHTML={{__html: endMessage}}></span>;
    } else {
      tooltipMessages = countMessage.split(replaceText);
      tooltipMessages = (
        <div>
          {tooltipMessages[0]}
          {this.renderCoundown()}
          {tooltipMessages[1]}
        </div>
      );
    }
    return(
      <span className="text-center">
        <ReactTooltip
          id={tooltipID}
          place={tooltipPosition}
          type="dark"
          effect="solid"
          resizeHide={false}
          getContent={[() => { return tooltipMessages; }, 1000]}
        />
      </span>
    );
  }

  renderConfirmationBox () {
    const { showConfirmation, isCancelling, expriedCancelling } = this.state;
    const { url, csrfToken, locales } = this.props;
    return (
      <Modal
        show={showConfirmation}
        backdrop='static'
      >
        <Modal.Header>
          <Modal.Title><Glyphicon glyph="exclamation-sign" />&nbsp;{locales.modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 dangerouslySetInnerHTML={{__html: locales.modal.question}} />
          <p>{ this.renderCoundownMessage() }</p>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            { expriedCancelling ?
                <Col xsOffset={3} xs={6}>
                  <Button block onClick={this.closeConfirmation}>
                    {locales.buttons.close}
                  </Button>
                </Col>
              :
              <div>
                <Col xsOffset={3} xs={3}>
                  <Button
                    block
                    disabled={isCancelling}
                    onClick={this.closeConfirmation}
                  >
                    {locales.buttons.no}
                  </Button>
                </Col>
                <Col xs={6}>
                  <Form action={url} method="POST" onSubmit={this.setCancelling.bind(this)}>
                    <input type="hidden" name="_method" value="put" />
                    <input type="hidden" name="authenticity_token" value={csrfToken} />
                    <Button
                      type="submit"
                      block
                      bsStyle='danger'
                      disabled={isCancelling}
                    >
                      { isCancelling && <span>
                        <Glyphicon glyph="refresh" className="glyphicon-refresh-animate" />
                        &nbsp;
                      </span>}
                      {locales.buttons.yes}
                    </Button>
                  </Form>
                </Col>
              </div>
            }

          </Row>
        </Modal.Footer>
      </Modal>
    );
  }

  renderButton () {
    const { disabled, showConfirmation } = this.state;
    const {
      messagePosition,
      bsStyle, bsSize, block,
    } = this.props;

    return(
      <div>
        <Button
          block={block}
          bsStyle={bsStyle}
          bsSize={bsSize}
          disabled={disabled || showConfirmation}
          onClick={this.openConfirmation}
        >
          { this.renderButtonText() }&nbsp;
          {(messagePosition == 'tooltip' && !disabled) && this.renderCoundown() }
        </Button>
        { this.state.showConfirmation && this.renderConfirmationBox.bind(this)() }
      </div>
    );
  }

  render () {
    const { className, messagePosition, url } = this.props;
    const tooltipProps = {};
    if(messagePosition == 'tooltip') {
      tooltipProps["data-tip"] = true;
      tooltipProps["data-for"] = url;
    }
    return(
      <span className="hidden-print">
        { (messagePosition == 'show-top') &&
          <div className="text-center">
            <small>{ this.renderCoundownMessage() }</small>
          </div> }
        <div {...tooltipProps} className={className}>
          { this.renderButton() }
        </div>
        { (messagePosition == 'tooltip') && this.renderTooltip() }
      </span>
    );
  }
}

OrderCancelButton.propTypes = {
  url: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,

  className: PropTypes.string,
  bsSize: PropTypes.oneOf(["lg", "large", "sm", "small", "xs", "xsmall"]),
  bsStyle: PropTypes.oneOf(["success", "warning", "danger", "info", "default", "primary", "link"]),
  label: PropTypes.string,
  showIcon: PropTypes.bool,
  messagePosition: PropTypes.oneOf(["show-top", "tooltip"]),
  tooltipPosition: PropTypes.oneOf(["bottom", "top", "left", "right"]),
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  cancelable: PropTypes.bool,

  countMessage: PropTypes.string,
  endMessage: PropTypes.string,
  expiredTime: PropTypes.string,
  replaceText: PropTypes.string,
  cancelableMessage: PropTypes.string,

  locales: PropTypes.shape({
    modal: PropTypes.shape({
      title: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
    }).isRequired,
    buttons: PropTypes.shape({
      close: PropTypes.string.isRequired,
      yes: PropTypes.string.isRequired,
      no: PropTypes.string.isRequired,
    }).isRequired
  }).isRequired,
};

OrderCancelButton.defaultProps = {
  label: 'Cancel',
  bsStyle: 'default',
  block: true,
  showIcon: true,
  disabled: true,
  cancelable: false,
  messagePosition: "popover",
  tooltipPosition: "bottom",
};
