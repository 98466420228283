import { actionNames } from "constants.js";
import update from "immutability-helper";

const rxLensFilter = (state = {}, action) => {
  // console.log('Reducer rxLensFilter : UPDATE_RX_LENS_FILTER : ', state, action);
  switch (action.type) {
    case actionNames.UPDATE_RX_LENS_FILTER: {
      return update(state, { $merge: { filter: action.filter } });
    }
    case actionNames.ALLOW_POWER_EDITING: {
      return update(state, { $merge: { canEditPower: action.allowed } });
    }
    case actionNames.ALLOW_PRODUCT_SELECTING: {
      return update(state, { $merge: { canSelectProduct: action.allowed } });
    }
    case actionNames.SEARCHING_PRODUCTS: {
      return update(state, { $merge: { searchingProducts: action.searching } });
    }
    case actionNames.SEARCHING_INDEXES_DESIGNS: {
      return update(state, {
        $merge: { searchingIndexesDesigns: action.searching }
      });
    }
    case actionNames.STORE_RX_PRODUCTS: {
      return update(state, { $merge: { products: action.products || [] } });
    }
    case actionNames.STORE_RX_PRODUCTS_RESPONSE: {
      return update(state, {
        $merge: { productsResponse: action.productsResponse || {} }
      });
    }
    case actionNames.STORE_AVAILABLE_DESIGNS_MATERIALS: {
      return update(state, {
        $merge: {
          availableDesignsMaterials: action.availabeDesignsMaterials || []
        }
      });
    }
    case actionNames.UPDATE_FILTER_STEP: {
      return update(state, {
        $merge: {
          filterStep: action.filterStep || 0
        }
      });
    }
    default: {
      return state;
    }
  }
};

export default rxLensFilter;
