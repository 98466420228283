import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { OPTICAL_INFO_ATTR, READING_DISTANCE } from '../../../../constants/mapOrderColumns';
import TextInput from '../../../default_redux/TextInput';
import {
  required as requiredValidate,
  min as minValidate,
  max as maxValidate
} from '../../../../utils/Validators';

export default class InsetDistance extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    if(!this.props.show) return null;
    const { label, showLabel, min, max, required } = this.props;
    // console.log(`Render ${this.props.label}`);
    return (
      <Field component={TextInput}
        label={label}
        type="number"
        step="any"
        name={`order[${OPTICAL_INFO_ATTR}][${READING_DISTANCE}]`}
        suffixAddon={`${min}-${max} cm.`}
        inputClass="col-xs-8"
        labelClass="col-xs-4"
        showLabel={showLabel}
        required={required}
        validate={[
          requiredValidate.bind(null, required),
          minValidate.bind(null, min),
          maxValidate.bind(null, max),
        ]}
      />
    );
  }
}

InsetDistance.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  period: PropTypes.number,
};

InsetDistance.defaultProps = {
  label: "Customized Reading Distance",
  show: false,
  required: false,
  showLabel: true,
  min: 0,
  max: 0,
  period: 1
};
