import _ from "lodash";
import { actionNames } from "constants.js";
import update from "immutability-helper";

const rxLens = (state = {}, action) => {
  switch (action.type) {
    case actionNames.UPDATE_RX_LENS: {
      return update(state, {
        $merge: {
          order: action.order
        }
      });
    }
    case actionNames.UPDATE_PRODUCT_RX_LENS: {
      return update(state, {
        $merge: {
          order: _.pick(state.order, ["sku", "user_id", "patient"]),
          product: action.product
        }
      });
    }
    case actionNames.SET_INITIALIZED_ORDER: {
      return update(state, {
        $merge: {
          initializedOrder: action.flag
        }
      });
    }
    case actionNames.UPDATE_RX_LOADING: {
      return update(state, {
        $merge: {
          loading: action.loading
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default rxLens;
