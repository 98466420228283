import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { NEAR_PD } from '../../../../constants/mapOrderColumns';
import TextInput from '../../../default_redux/TextInput';
import { required, min, max } from '../../../../utils/Validators';

export default class NearPD extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.farPD = this.farPD.bind(this);
    this.minPD = this.minPD.bind(this);
    this.maxPD = this.maxPD.bind(this);
    this.convertValue = this.convertValue.bind(this);
  }

  farPD () {
    return parseFloat(this.props.farPD);
  }

  minPD (value) {
    value = value || this.farPD();
    return value - 5;
  }

  maxPD (value) {
    value = value || this.farPD();
    return value;
  }

  convertValue (value) {
    value = parseFloat(value);
    return Number.isSafeInteger(value) ? value.toFixed(0) : value.toFixed(2);
  }

  render () {
    if(!this.props.show) return null;
    // console.log(`Render ${this.props.label}`);
    return (
      <Field component={TextInput}
        label={this.props.label}
        type="number"
        step="any"
        name={`order${this.props.attrNestedName}[${NEAR_PD}]`}
        default_reduxValue={this.convertValue(this.minPD())}
        suffixAddon={
          `${this.convertValue(this.minPD())}-${this.convertValue(this.maxPD())} mm.`
        }
        inputClass={"col-xs-8"}
        labelClass="col-xs-4"
        helpText={`Standard Near PD is ${this.convertValue(this.minPD())}.`}
        required={this.props.required}
        validate={[
          required.bind(null, this.props.required),
          min.bind(null, this.minPD()),
          max.bind(null, this.maxPD()),
        ]}
      />
    );
  }
}

NearPD.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  farPD: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  attrNestedName: PropTypes.string,
};

NearPD.defaultProps = {
  label: "Near PD",
  show: false,
  required: false,
  attrNestedName: '',
};
