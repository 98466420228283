import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SelectInput from '../../../default_redux/SelectInput';
import {
  READING_DISTANCE_OPTION, CUSTOMIZE_INSET_OPTION,
} from '../../../../constants/mapOrderColumns';
import {
  INSET_TYPE_FIELD,
} from '../../../../constants/orderFields';

export default class InsetType extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.insetTypeOptions = this.insetTypeOptions.bind(this);
  }

  insetTypeOptions () {
    let options = [];
    if(this.props.product.properties.enabled_normal_reading.show)
      options.push({name: READING_DISTANCE_OPTION, value: READING_DISTANCE_OPTION});
    if(this.props.product.properties.enabled_inset.show)
      options.push({name: CUSTOMIZE_INSET_OPTION, value: CUSTOMIZE_INSET_OPTION});
    return options;
  }

  render () {
    if(!this.props.show) return null;
    const options = this.insetTypeOptions();
    if(options.length < 1) return null;
    // console.log(`Render ${this.props.label}`);
    return (
      <div>
        <Field component={SelectInput}
          name={INSET_TYPE_FIELD}
          options={options}
          selectFirst={true}
          liveSearch={false}
          label={this.props.label}
          labelClass="col-xs-4"
          selectWrapperClass="col-xs-8"
          required={true}
        />
        {this.props.children}
      </div>
    );
  }
}

InsetType.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  product: PropTypes.shape({
    properties: PropTypes.shape({
      enabled_inset: PropTypes.object.isRequired,
      enabled_normal_reading: PropTypes.object.isRequired,
      normal_reading_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
        peroid: PropTypes.number,
      })
    })
  }),

  children: PropTypes.any,
};

InsetType.defaultProps = {
  label: "Inset Type",
  show: false,
};
