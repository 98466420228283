import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { OVAL_SHAPE_FIELD } from '../../../../constants/orderFields';
import SelectInput from '../../../default_redux/SelectInput';

export default class OvalShape extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render () {
    const { show, label, options } = this.props;
    if(!show) return null;
    // console.log(`Render ${this.props.label}`);
    return (
      <Field component={SelectInput}
        name={OVAL_SHAPE_FIELD}
        label={label}
        options={
          map(sortBy(options, ['name']), option => {
            return { name: option.name, value: option.name };
          })
        }
        selectFirst={true}
        labelClass="col-xs-3"
        selectWrapperClass="col-xs-6"
        showLabel={false}
        required={true}
      />
    );
  }
}
OvalShape.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  options: PropTypes.array,
};

OvalShape.defaultProps = {
  label: "Oval Shape Type",
  show: false,
  options: [],
};
