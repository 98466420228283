import ToggleStatus from "components/users/ToggleStatus";
import OrderDetails from "components/orders/OrderDetails";
import BulkOrderDetails from "components/orders/BulkOrderDetails";
import OrderModal from "components/orders/OrderModal";
import OrdersPrinter from "components/orders/OrdersPrinter";
import OrderEditButton from "components/orders/OrderEditButton";
import OrderCancelButton from "components/orders/OrderCancelButton";
import TimeCountdown from "components/shared/TimeCountdown";
import OrderSendMqButton from "components/orders/OrderSendMqButton";
import BulkOrderDownloadButton from "components/orders/BulkOrderDownloadButton";
import SwitchLanguage from "components/shared/SwitchLanguage";
import NewRxOrderFormWithFilterLens from "components/ordering/redux_rx_lens/NewRxOrderFormWithFilterLens";

global.ToggleStatus = ToggleStatus;
global.OrderDetails = OrderDetails;
global.BulkOrderDetails = BulkOrderDetails;
global.OrderModal = OrderModal;
global.OrdersPrinter = OrdersPrinter;
global.OrderEditButton = OrderEditButton;
global.OrderCancelButton = OrderCancelButton;
global.TimeCountdown = TimeCountdown;
global.OrderSendMqButton = OrderSendMqButton;
global.BulkOrderDownloadButton = BulkOrderDownloadButton;
global.SwitchLanguage = SwitchLanguage;
global.NewRxOrderFormWithFilterLens = NewRxOrderFormWithFilterLens;

import AdminActivityDetails from "components/admin/ActivityDetails";
global.AdminActivityDetails = AdminActivityDetails;

import InitSentry from "components/initializers/InitSentry";
global.InitSentry = InitSentry;
