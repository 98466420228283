import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';

class ActivityDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  close() {
    this.setState({ showModal: false });
  }

  open() {
    this.setState({ showModal: true });
  }

  render () {
    const { buttonText, activity } = this.props;
    return (
      <span>
        <a onClick={this.open}>{buttonText}</a>
        <Modal show={this.state.showModal} onHide={this.close} bsSize="lg">
          <Modal.Header closeButton>
            <Modal.Title>Activity for {activity.event} :: {activity.item_type}-{activity.item_id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>Time</b>: { moment(activity.created_at, moment.ISO_8601).format('DD-MM-YYYY HH:MM') }
              &nbsp;|&nbsp;
              <b>Who do it</b>: {activity.whodunnit || '-'}
            </p>
            <p>
              <b>Event</b>: {activity.event}
              &nbsp;|&nbsp;
              <b>Item</b>: {activity.item_type}-{activity.item_id}
            </p>
            <p><b>Object</b></p>
            <pre>{JSON.stringify(activity.object, null, 2) }</pre>
            <p><b>Object changes</b></p>
            <pre>{JSON.stringify(activity.object_changes, null, 2) }</pre>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

ActivityDetails.propTypes = {
  buttonText: PropTypes.string,
  activity: PropTypes.object,
};

ActivityDetails.defaultProps = {
  buttonText: 'Data',
  activity: {},
};
export default ActivityDetails;
