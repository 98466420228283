import React from 'react';
import PropTypes from 'prop-types';
import isPresent from 'utils/isPresent';
import {
  LEFT_SIDE, RIGHT_SIDE,
  LENS_ID, QUANTITY,
  SPH, CYL, AXIS, ADD,
} from 'constants/mapOrderColumns';
import { Row, Col, FormGroup } from 'react-bootstrap';
import { Field } from 'redux-form';
import SelectInput from 'components/default_redux/SelectInput';
import HiddenInput from 'components/default_redux/HiddenInput';
import InputForm from 'components/default_redux/InputForm';
import { required } from 'utils/Validators';
import { genRange } from 'utils/GenOptions';
import getIn from 'redux-form/lib/structure/plain/getIn';

export default class LenPropertiesForm extends React.Component {
  constructor(props) {
    super(props);
    const checkEnabledSide = side => {
      if(isPresent(props.currentOrder)){
        return isPresent(getIn(props, `currentOrder.${side}`));
      }
      return true;
    };
    this.state = {
      enabledLeft: checkEnabledSide(LEFT_SIDE),
      enabledRight: checkEnabledSide(RIGHT_SIDE),
    };
    this.POWER = { sph: SPH, cyl: CYL, axis: AXIS, add: ADD };
    this.onLensChange = this.onLensChange.bind(this);

    this.renderPower = this.renderPower.bind(this);
    this.clearPower = this.clearPower.bind(this);
    this.clearAxis = this.clearAxis.bind(this);
    this.renderDummyForm = this.renderDummyForm.bind(this);
    this.renderQuantity = this.renderQuantity.bind(this);
  }

  renderDummyForm(){
    return(
      <InputForm showLabel={false}>
        <div style={{height: '36px'}}></div>
      </InputForm>
    );
  }

  clearAxis () {
    const { orderName, change, currentFilters } = this.props;
    if(!isPresent(currentFilters)) return null;

    if(isPresent(currentFilters[LEFT_SIDE][CYL]) == 0) {
      change(`${orderName}[${LEFT_SIDE}][${AXIS}]`, null);
    }

    if(isPresent(currentFilters[RIGHT_SIDE][CYL]) == 0) {
      change(`${orderName}[${RIGHT_SIDE}][${AXIS}]`, null);
    }
  }

  changeFilter (name, side, e) {
    const value = e.target.value;
    const {
      currentFilters,
      actions: {
        updateFilter
      }
    } = this.props;
    currentFilters[side][name] = value;
    updateFilter(currentFilters);

    this.setState({ reloadAxis: true });
    this.clearAxis();
  }

  renderPower (name, values, label) {
    const { enabledRight, enabledLeft } = this.state;
    const { orderName, currentFilters } = this.props;
    const options = genRange(values.min, values.max, values.period, values.decimal, values.sign, values.order);

    const isDisabled = side => {
      if(name != this.POWER.axis) return false;
      if(!isPresent(currentFilters[side][CYL])) return true;

      return parseFloat(currentFilters[side][CYL]) == 0;
    };

    const isRequired = () => {
      return name != this.POWER.add;
    };

    return (
      <Col xs={3}>
        <Col xs={12}>
          <FormGroup>
            <div className="checkbox text-center">
              <span style={{fontWeight: 'bold'}}>{label}</span>
            </div>
          </FormGroup>
          {
            !enabledRight ? this.renderDummyForm() :
            isDisabled(RIGHT_SIDE) ?
              <div className="form-group">
                <div className="Select Select--single readonly">
                  <div className="Select-control">
                    <span className="Select-multi-value-wrapper">
                      <div className="Select-placeholder">R-AXIS</div>
                    </span>
                  </div>
                </div>
              </div> :
              <Field component={SelectInput}
                name={`${orderName}[${RIGHT_SIDE}][${name}]`}
                options={options}
                searchable={true}
                blankDisplay={`R-${label}`}
                labelClass=''
                selectFirst={name == this.POWER.cyl}
                showLabel={false}
                required={isRequired()}
                validate={[ required.bind(null, isRequired()) ]}
                handleChange={this.changeFilter.bind(this, name, RIGHT_SIDE)}
              />
          }
          {
            !enabledLeft ? this.renderDummyForm() :
            isDisabled(LEFT_SIDE) ?
              <div className="form-group">
                <div className="Select Select--single readonly">
                  <div className="Select-control">
                    <span className="Select-multi-value-wrapper">
                      <div className="Select-placeholder">L-AXIS</div>
                    </span>
                  </div>
                </div>
              </div> :
              <Field component={SelectInput}
                name={`${orderName}[${LEFT_SIDE}][${name}]`}
                options={options}
                searchable={true}
                blankDisplay={`L-${label}`}
                labelClass=''
                selectFirst={name == this.POWER.cyl}
                showLabel={false}
                required={isRequired()}
                validate={[ required.bind(null, isRequired()) ]}
                handleChange={this.changeFilter.bind(this, name, LEFT_SIDE)}
              />
          }
        </Col>
      </Col>
    );
  }

  renderQuantity (name) {
    const { enabledRight, enabledLeft} = this.state;
    const { orderName } = this.props;
    return (
      <div>
      {
        enabledRight && <Field component={HiddenInput}
          name={`${orderName}[${RIGHT_SIDE}][${name}]`}
          defautlValue={1}
        />
      }
      {
        enabledLeft &&
          <Field component={HiddenInput}
            name={`${orderName}[${LEFT_SIDE}][${name}]`}
            defautlValue={1}
          />
      }
      </div>
    );
  }

  onLensChange (side) {
    const enabledSide = {};
    let enabled = false;
    switch (side) {
      case LEFT_SIDE:
        enabledSide.enabledLeft = enabled = !this.state.enabledLeft;
        break;
      case RIGHT_SIDE:
        enabledSide.enabledRight = enabled = !this.state.enabledRight;
        break;
      default:
        return false;
    }
    this.clearPower(side, enabled);
    this.setState(enabledSide);
  }

  clearPower (side, enabled) {
    const { orderName, change, currentOrder } = this.props;
    change(`${orderName}[${side}]`, null);

    const lensId = getIn(currentOrder, `${side}.${LENS_ID}`);
    change(`${orderName}[${side}][${LENS_ID}]`, lensId);

    const quantity = enabled ? 1 : 0;
    change(`${orderName}[${side}][${QUANTITY}]`, quantity);
  }

  render () {
    const { properties } = this.props;
    const { enabledRight, enabledLeft } = this.state;
    if(!isPresent(properties)) return null;

    return (
      <Row>
        <Col xsOffset={1} xs={8}>
          <Row>
            <Col xs={4}>
              <Col xs={12}>
                <FormGroup>
                  <div className="checkbox text-right">
                    <span style={{color: 'transparent'}}>{this.props.locales.properties.side}</span>
                  </div>
                </FormGroup>
                <FormGroup className="text-right">
                  <div className="checkbox" style={{minHeight: "34px"}}>
                    <label style={{fontWeight: 'bold'}}>
                      <input
                        type="checkbox"
                        checked={enabledRight}
                        onChange={this.onLensChange.bind(this, RIGHT_SIDE)}
                      /> {this.props.locales.properties.right}
                    </label>
                  </div>
                </FormGroup>
                <FormGroup className="text-right">
                  <div className="checkbox" style={{minHeight: "34px"}}>
                    <label style={{fontWeight: 'bold'}}>
                      <input
                        type="checkbox"
                        checked={enabledLeft}
                        onChange={this.onLensChange.bind(this, LEFT_SIDE)}
                      /> {this.props.locales.properties.left}
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Col>
            <Col xs={8}>
              <Row>
                { this.renderQuantity(QUANTITY) }
                { this.renderPower(this.POWER.sph, properties.sph_values, this.props.locales.lens.sphere) }
                { this.renderPower(this.POWER.cyl, properties.cyl_values, this.props.locales.lens.cylinder) }
                { this.renderPower(this.POWER.axis, properties.axis_values, this.props.locales.lens.axis) }
                { this.renderPower(this.POWER.add, properties.add_values, this.props.locales.lens.addition) }
              </Row>

            </Col>
          </Row>

        </Col>
      </Row>
    );
  }
}

LenPropertiesForm.propTypes = {
  properties: PropTypes.object,
  locales: PropTypes.object.isRequired,
  orderName: PropTypes.string,
  currentOrder: PropTypes.object,

  // Redux Store
  currentFilters: PropTypes.object,
  actions: PropTypes.shape({
    updateFilter: PropTypes.func.isRequired,
  }),

  // Redux Form
  change: PropTypes.func,
  unregisterField: PropTypes.func,
};

// LenPropertiesForm.defaultProps = {
//   orderCode: '',
//   patient: '',
//   remark: '',
//   lens: null
// };
