import React from 'react';
import isFile from 'utils/isFile';
import {
  Glyphicon, Image,
  Popover, ButtonToolbar, OverlayTrigger
} from 'react-bootstrap';

const ShapeFileBox = (file, options={download: false, processingMsg: null}) => {
  file = file || {};
  if(!isFile(file)) return null;
  const preview = file.preview || false;
  let box, text;

  text = preview ? <a>{file.name}</a> : file.name;
  if(options.download && !file.processing && file.url)
    text = <a href={file.url} download>{file.name} (Download)</a>;
  if(options.download && file.processing)
    text = <span>{file.name || options.processingMsg}</span>;

  box = (
    <span style={{wordBreak: 'break-all'}}>
      <Glyphicon glyph="paperclip" />&nbsp;
      {text}
    </span>
  );

  if(preview) {
    const popoverFocus = (
      <Popover id="popover-trigger-focus">
        <Image src={preview} rounded width={200} />
      </Popover>
    );
    box = (
      <ButtonToolbar>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="right"
          overlay={popoverFocus}
        >
          {box}
        </OverlayTrigger>
      </ButtonToolbar>
    );
  }

  return box;
};

export default ShapeFileBox;
