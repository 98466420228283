import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Button, Glyphicon } from 'react-bootstrap';
import RxFilterPowerSide from 'components/filters/rx_lens/RxFilterPowerSide';
import { FormSection } from 'redux-form';
import { SPH, CYL, AXIS } from 'constants/mapOrderColumns';
import FetchError from 'components/shared/FetchError';
import isDiffSPH from 'utils/isDiffSPH';
import isPresent from 'utils/isPresent';
import getIn from 'redux-form/lib/structure/plain/getIn';
import merge from 'lodash/merge';

export default class RxFilterPower extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.searchDesignsMaterials = this.searchDesignsMaterials.bind(this);
    this.diffSPH = this.diffSPH.bind(this);
    this.requiredAxis = this.requiredAxis.bind(this);
    this.isDisabledAllSides = this.isDisabledAllSides.bind(this);
  }

  searchDesignsMaterials(formValues) {
    const { searchDesignsMaterials } = this.props;
    searchDesignsMaterials(formValues);
  }

  clearFilterPower (side, checked) {
    let field;
    switch (side) {
      case "left":
        field = "filter.left";
        break;
      case "right":
        field = "filter.right";
        break;
    }
    this.props.changeFilter(field, {
      [CYL]: '0.00',
      enabled: !checked
    });
  }

  diffSPH () {
    const { filter } = this.props;
    return isDiffSPH(
      getIn(filter, `left.${SPH}`),
      getIn(filter, `right.${SPH}`)
    );
  }

  requiredAxis (side) {
    const { filter } = this.props;
    let cyl = getIn(filter, `${side}.${CYL}`);
    if(!isPresent(cyl)) return false;
    cyl = parseFloat(cyl);
    return cyl != 0;
  }

  changeSideFilter(side, newFilter) {
    const { filter, changeFilter } = this.props;
    const oldFilter = filter[side] || {};
    return changeFilter(`filter.${side}`, merge(oldFilter, newFilter));
  }

  isDisabledAllSides () {
    const { filter } = this.props;
    return !(getIn(filter, 'left.enabled') || getIn(filter, 'right.enabled'));
  }

  renderPowerForm () {
    const {
      enabledRight, enabledLeft, canEdit, locales, powerRanges, filter,
    } = this.props;
    const diffSPH = this.diffSPH();
    return(
      <div>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={3}>
                <Col xs={12}>
                  <FormGroup>
                    <div className="checkbox text-right">
                      <span style={{color: 'transparent'}}>Side</span>
                    </div>
                  </FormGroup>
                </Col>
              </Col>
              <Col xs={9}>
                <Row>
                  <Col xs={3}>
                    <Col xs={12}>
                      <FormGroup>
                        <div className="checkbox">
                          <span style={{fontWeight: 'bold'}}>SPH</span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Col>
                  <Col xs={3}>
                    <Col xs={12}>
                      <FormGroup>
                        <div className="checkbox">
                          <span style={{fontWeight: 'bold'}}>CYL</span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Col>
                  <Col xs={3}>
                    <Col xs={12}>
                      <FormGroup>
                        <div className="checkbox">
                          <span style={{fontWeight: 'bold'}}>AXIS</span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Col>
                  <Col xs={3}>
                    <Col xs={12}>
                      <FormGroup>
                        <div className="checkbox">
                          <span style={{fontWeight: 'bold'}}>ADD</span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <FormSection name="filter">
          <FormSection name="right">
            <RxFilterPowerSide side="R"
              label={locales.lens.right}
              enabled={enabledRight}
              canEdit={canEdit}
              clearFilter={this.clearFilterPower.bind(this, 'right')}
              diffSPH={diffSPH}
              filter={filter.right}
              powerRanges={powerRanges}
              changeFilter={this.changeSideFilter.bind(this, 'right')}
            />
          </FormSection>

          <FormSection name="left">
            <RxFilterPowerSide side="L"
              label={locales.lens.left}
              enabled={enabledLeft}
              canEdit={canEdit}
              clearFilter={this.clearFilterPower.bind(this, 'left')}
              diffSPH={diffSPH}
              filter={filter.left}
              powerRanges={powerRanges}
              changeFilter={this.changeSideFilter.bind(this, 'left')}
            />
          </FormSection>
        </FormSection>
      </div>
    );
  }

  render () {
    const {
      canEdit, editPower,
      searchingIndexesDesigns, searchingProducts,
      handleSubmit, fetchError,
      locales,
      locales: {
        buttons: {
          searchLenses: searchLensesButtonText,
          editPower: editPowerButtonText
        },
        hints
      }
    } = this.props;
    const diffSPH = this.diffSPH();
    const isDisabledAllSides = this.isDisabledAllSides();
    return (
      <div>
        { this.renderPowerForm.bind(this)() }
        <Row>
          <Col xsOffset={3} xs={9}>
            {
              fetchError &&
              <div className="text-center">
                <FetchError error={fetchError} {...locales.requestErrors} />
              </div>
            }
            { diffSPH &&
              <p className="text-warning">{hints.diffSPH}</p>
            }
            {
              isDisabledAllSides &&
              <p className="text-warning">{hints.disabledAllSides}</p>
            }
            {
              !isDisabledAllSides && !fetchError && (canEdit || searchingIndexesDesigns) &&
                <Button
                  block
                  disabled={!canEdit}
                  onClick={handleSubmit(this.searchDesignsMaterials)}>
                  { searchingIndexesDesigns ?
                    <Glyphicon glyph="refresh" className="glyphicon-refresh-animate" /> :
                    <Glyphicon glyph="search" />
                  }
                  &nbsp;
                  {searchLensesButtonText}
                </Button>
            }
            {
              !isDisabledAllSides && !fetchError && (!canEdit && !searchingIndexesDesigns) &&
              <div className="text-center">
                <Button
                  bsStyle="link"
                  onClick={editPower}
                  disabled={searchingProducts}>
                  <Glyphicon glyph="pencil" />&nbsp;
                  {editPowerButtonText}
                </Button>
              </div>
            }
            <br/>
          </Col>
        </Row>
      </div>
    );
  }
}

RxFilterPower.propTypes = {
  changeFilter: PropTypes.func,
  editPower: PropTypes.func,
  enabledRight: PropTypes.bool,
  enabledLeft: PropTypes.bool,
  canEdit: PropTypes.bool,
  searchingProducts: PropTypes.bool,
  searchingIndexesDesigns: PropTypes.bool,
  handleSubmit: PropTypes.func,
  searchDesignsMaterials: PropTypes.func,
  locales: PropTypes.shape({
    buttons: PropTypes.shape({
      searchLenses: PropTypes.string.isRequired,
      editPower: PropTypes.string.isRequired,
    }),
    hints: PropTypes.shape({
      diffSPH: PropTypes.string.isRequired,
      disabledAllSides: PropTypes.string.isRequired,
    }),
    lens: PropTypes.shape({
      left: PropTypes.string.isRequired,
      right: PropTypes.string.isRequired,
    }),
  }),
  fetchError: PropTypes.object,
  diffSPH: PropTypes.bool,
  powerRanges: PropTypes.shape({
    sph: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    cyl: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    axis: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    add: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    dia: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }),

  filter: PropTypes.shape({
    left: PropTypes.shape({
      [SPH]: PropTypes.string,
      [CYL]: PropTypes.string,
      [AXIS]: PropTypes.string,
    }),
    right: PropTypes.shape({
      [SPH]: PropTypes.string,
      [CYL]: PropTypes.string,
      [AXIS]: PropTypes.string,
    }),
  }),

  //Redux Form
  reset: PropTypes.func,
};

RxFilterPower.defaultProps = {
};
