import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, Label } from 'react-bootstrap';

const CheckboxGroupInput = ({ label, required, name, options,  input}) => (
  <FormGroup controlId={name}>
    { label &&
      <ControlLabel>
        {label} { required && <Label bsStyle="info">required</Label> }
      </ControlLabel>
    }
      { options.map((option, index) => (
        <div className="checkbox" key={index}>
          <label>
            <input type="checkbox"
                   name={`${name}[${index}]`}
                   value={option.value}
                   checked={input.value.indexOf(option.value) !== -1}
                   onChange={event => {
                     const newValue = [...input.value];
                     if(event.target.checked) {
                       newValue.push(option.value);
                     } else {
                       newValue.splice(newValue.indexOf(option.value), 1);
                     }
                     return input.onChange(newValue);
                   }}/>
            {option.name}
          </label>
        </div>))
      }
  </FormGroup>
);

CheckboxGroupInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,

  // Redux form
  input: PropTypes.object,
};

CheckboxGroupInput.defaultProps = {
  handleChange: (checked) => { return checked; },
};

export default CheckboxGroupInput;
