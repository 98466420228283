import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SwitchInput from 'components/default_redux/SwitchInput';

export default class FrameShapeToCome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };

    const { show, required, change, name } = props;
    if(!show) {
      change(name, null);
    }
    else if(required) {
      change(name, true);
    }
  }

  handleChange (frameToCome) {
    this.props.handleChange(frameToCome);
  }

  render () {
    const { show, label, required, name } = this.props;
    if(!show) return null;
    return (
      <Field component={SwitchInput}
        name={name}
        label={label}
        onText='Yes'
        offText='No'
        labelClass='col-xs-3'
        inputWaraperClass='col-sm-6'
        handleChange={this.handleChange.bind(this)}
        required={required}
        readonly={required}
      />
    );
  }

}
FrameShapeToCome.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  show: PropTypes.bool,
  value: PropTypes.bool,
  required: PropTypes.bool,
  handleChange: PropTypes.func,

  // Reduc Form
  change: PropTypes.func,
};

FrameShapeToCome.defaultProps = {
  label: "Frame to come",
  show: false,
  value: false,
  required: false,
  handleChange: () => {},
};
