import React from 'react';
import PropTypes from 'prop-types';
import { FRAME_TO_COME_FIELD } from '../../../../constants/orderFields';
import { Field } from 'redux-form';
import SwitchInput from '../../../default_redux/SwitchInput';

export default class FrameToCome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange (frameToCome) {
    const { loadProductRxLens } = this.props;
    loadProductRxLens({
      fetchName: 'frameToComeChange',
      frameToCome: frameToCome,
      loadingframeIdField: true
    });
  }

  render () {
    const { show, label, required } = this.props;
    if(!show) return null;
    return (
      <Field component={SwitchInput}
        name={FRAME_TO_COME_FIELD}
        label={label}
        onText='Yes'
        offText='No'
        labelClass='col-xs-3'
        inputWaraperClass='col-sm-6'
        handleChange={this.handleChange.bind(this)}
        required={required}
        readonly={required}
      />
    );
  }

}
FrameToCome.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  loadProductRxLens: PropTypes.func,
};

FrameToCome.defaultProps = {
  label: "Frame to come",
  show: false,
  required: false,
};
