import React from 'react';
import PropTypes from 'prop-types';
import { Button, Glyphicon, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import OrdersFilterForm from 'components/orders/OrdersFilterForm';
import GenUrl from 'utils/GenUrl';

class OrdersExportCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilterModal: false,
      csvLoading: false,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.loadCSV = this.loadCSV.bind(this);
    this.renderExportText = this.renderExportText.bind(this);
    this.renderSubmitButton = this.renderSubmitButton.bind(this);
  }

  close() {
    this.setState({ showFilterModal: false });
  }

  open() {
    this.setState({ showFilterModal: true });
  }

  loadCSV (data) {
    this.setState({csvLoading: true});
    const { csvUrl } = this.props;
    const url = GenUrl(csvUrl, { q: data });
    window.location.replace(url);
    setTimeout(() => {
      this.close();
    }, 2000);
    setTimeout(() => {
      this.setState({csvLoading: false});
    }, 2500);
  }

  renderSubmitButton (_pristine, _submitting, _submitSucceeded) {
    const { csvLoading } = this.state;
    return (
      <Button
        bsStyle="warning"
        block
        type="submit"
        disabled={csvLoading}>
        {this.renderExportText()}
      </Button>
    );
  }

  renderExportText () {
    const { csvLoading } = this.state;
    const {
      locales: {
        buttons: { export: exportText }
      }
    } = this.props;
    return (
      <div>
        { csvLoading ? <Glyphicon glyph="refresh" className="glyphicon-refresh-animate" /> : <Glyphicon glyph="save" /> }
        &nbsp;{exportText}
      </div>

    );
  }

  render () {
    const { showFilterModal, csvLoading } = this.state;
    const {
      locales: {
        modal: {
          title: {
            export: exportTitle,
          }
        },
        buttons: {
          export: exportText
        }
      }
    } = this.props;
    return(
      <span>
        <Button
          onClick={this.open}
          disabled={showFilterModal || csvLoading} >
          <Glyphicon glyph="save" />
          &nbsp;{exportText}
        </Button>

        <Modal
          show={this.state.showFilterModal}
          onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>{exportTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OrdersFilterForm
              {...this.props}
              onSubmit={this.loadCSV}
              submitButton={this.renderSubmitButton} />
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

OrdersExportCSV.propTypes = {
  csvUrl: PropTypes.string,
  agents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      company: PropTypes.string,
      role: PropTypes.string
    })
  ),
  orderStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ]).isRequired
    })
  ),
  searchAgentsUrl: PropTypes.string.isRequired,
  locales: PropTypes.shape({
    modal: PropTypes.shape({
      title: PropTypes.shape({
        export: PropTypes.string,
      }).isRequired
    }).isRequired,
    buttons: PropTypes.shape({
      export: PropTypes.string,
    }).isRequired
  }).isRequired,

  // Redux form
  initFilter: PropTypes.object,
  createdPeriod: PropTypes.shape({
    form: PropTypes.string,
    to: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  submitSucceeded: PropTypes.bool,

};

OrdersExportCSV.defaultProps = {
};

let form = reduxForm({
  form: 'FilterCSV'
})(OrdersExportCSV);

const selector = formValueSelector('FilterCSV'); // <-- same as form name
form = connect(
  (state, props) => {
    const initialValues = props.initFilter || {};
    if(initialValues.user_id) initialValues.user_id = parseInt(initialValues.user_id);
    const createdPeriod = {
      start: selector(state, 'start_date'),
      end: selector(state, 'end_date')
    };
    // return {
    //   initialValues,
    //   currentRole
    // };
    return {
      initialValues,
      createdPeriod
    };
  }
)(form);

export default form;
