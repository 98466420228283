import React from 'react';
import PropTypes from 'prop-types';
import getIn from 'redux-form/lib/structure/plain/getIn';
import { Row, Col, Table } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import isFile from 'utils/isFile';
import isDiffSPH from 'utils/isDiffSPH';
import isPresent from 'utils/isPresent';
import { numberWithSign } from 'utils/GenOptions';
import moment from 'moment';
import ShapeFileBox from 'components/shared/ShapeFileBox';
import FrameShapeBox from 'components/shared/FrameShapeBox';


export default class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this._displayLensProperty = this._displayLensProperty.bind(this);
    this._displayValue = this._displayValue.bind(this);
    this._displayFrameShape = this._displayFrameShape.bind(this);
    this._displayShapeFile = this._displayShapeFile.bind(this);
    this._renderTintBox = this._renderTintBox.bind(this);
  }

  render () {
    return (
      <div>
        { this._detailsTemplate.bind(this)() }
      </div>
    );
  }

  _displayFrameShape (frameShape, label) {
    if(isEmpty(frameShape)) return null;
    return this._displayValue(FrameShapeBox(frameShape), label);
  }

  // file = {name, url, preview}
  _displayShapeFile (file, label) {
    file = file || {};
    if(!isFile(file)) return null;
    const shapeFileBoxOptions = {
      download: true,
      processingMsg: this.props.locales.msg.processingShapeFile
    };
    return this._displayValue(ShapeFileBox(file, shapeFileBoxOptions), label);
  }

  _renderTintBox () {
    const order = this.props.order;
    const optical = order.optical_informations || {};
    if(isEmpty(optical.tint_value)) return null;
    let tintText = `${optical.tint.display_name} :: ${optical.tint_value.percentage} %`;
    return this._displayValue(tintText, this.props.locales.optical.tint);
  }

  _detailsTemplate () {
    const { order, locales } = this.props;
    const user = order.user || {};
    const creator = order.creator || {};
    const optical = order.optical_informations || {};
    const diffSPH = isDiffSPH(
      getIn(order, 'left_lens.sphere'),
      getIn(order, 'right_lens.sphere')
    );
    return (
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <Table className="order_table--details" hover>
                <tbody>
                  { this._displayValue(`${creator.roles[0]} :: ${creator.company || creator.name || creator.username}`, locales.order.creatorId) }
                  { this._displayValue(`${user.roles[0]} :: ${user.company || user.user || user.username}`, locales.order.userId) }
                  { this._displayValue(order.code, locales.order.code) }
                  { this._displayValue(order.patient || '-', locales.order.patient) }
                  { this._displayValue(this._displayOrderDate.bind(this)(), locales.order.createdAt) }
                  { this._displayValue(this._displayEstFinfishDate.bind(this)(), locales.order.estFinishedDate) }
                  { this._displayValue(
                    isBoolean(order.express) ? (order.express ? 'Yes' : 'No') : null
                    , locales.order.express) }
                  { this._displayValue(order.note_on_label || '-', locales.order.noteOnLabel) }
                  { this._displayValue(order.private_note || '-', locales.order.privateNote) }
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Table className="order_table--details" hover>
                <tbody>
                  { this._displayValue(optical.product_name, locales.optical.productName) }
                  { this._displayValue(optical.design, locales.optical.design) }
                  { this._displayValue(optical.color, locales.optical.color) }
                  { this._displayValue(optical.coat, locales.optical.coat) }
                  { this._displayValue(optical.material, locales.optical.material) }
                  { this._displayValue(optical.index, locales.optical.index) }
                  { this._displayValue(optical.corridor, locales.optical.corridor) }
                  { this._displayValue(optical.degression, locales.optical.degression) }
                  { this._displayValue(
                    isBoolean(optical.uv) ? (optical.uv ? 'Yes' : 'No') : null
                    , locales.optical.uv) }
                  { this._displayValue(getIn(optical, 'flash_mirror.name'), locales.optical.flashMirror) }
                  { this._renderTintBox() }
                  { this._displayValue(optical.test_id, locales.optical.testId) }
                  { this._displayValue(optical.cutting, locales.optical.cutting) }
                  { this._displayValue(
                    isBoolean(optical.opt_thickness) ? (optical.opt_thickness ? 'Yes' : 'No') : null
                    , locales.optical.optThickness) }
                  { this._displayValue(optical.frame_type, locales.optical.frameType) }
                  { this._displayValue(isPresent(optical.frame_material) ? optical.frame_material.frame_material : null, locales.optical.frameMaterial) }
                  { this._displayValue(isPresent(optical.frame_library) ? optical.frame_library.number : null, locales.optical.frameLibrary) }
                  { this._displayValue(
                    isBoolean(optical.frame_to_come) ? (optical.frame_to_come ? 'Yes' : 'No') : null
                    , locales.optical.frameToCome) }
                  { this._displayValue(isPresent(optical.frame_id) ? optical.frame_id : null, locales.optical.frameId) }
                  { this._displayFrameShape(optical.frame_shape, locales.optical.frameShape) }
                  { this._displayShapeFile(optical.shape_file, locales.optical.shapeFile) }
                  { this._displayValue(optical.hbox, locales.optical.hbox, 'mm.') }
                  { this._displayValue(optical.vbox, locales.optical.vbox, 'mm.') }
                  { this._displayValue(optical.dbl, locales.optical.dbl, 'mm.') }
                  { this._displayValue(optical.round_shape, locales.optical.roundShape) }
                  { this._displayValue(optical.oval_shape, locales.optical.ovalShape) }
                  { this._displayValue(optical.dia, locales.optical.dia) }
                  { this._displayValue(optical.subDia, locales.optical.subDia) }
                  { this._displayValue(
                    isBoolean(optical.sharp_edge) ? (optical.sharp_edge ? 'Yes' : 'No') : null
                    , locales.optical.sharpEdge) }
                  { this._displayValue(optical.reading_dis, locales.optical.readingDis, 'cm.') }
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Table
                className={`table-label table-bordered ${this.classForTable.bind(this)()}`}
                hover
              >
                <tbody>
                  { this._displayLensSide.bind(this)() }
                  { this._displayLensProperty('quantity', locales.lens.quantity) }
                  { this._displayLensStatus.bind(this)() }
                  { this._displayLensProperty('sphere', locales.lens.sphere, diffSPH ? 'text-warning' : null, { sign: true }) }
                  { this._displayLensProperty('cylinder', locales.lens.cylinder, null, { sign: true }) }
                  { this._displayLensProperty('axis', locales.lens.axis) }
                  { this._displayLensProperty('addition', locales.lens.addition, null, { sign: true }) }
                  { this._displayLensProperty('et', locales.lens.et) }
                  { this._displayLensProperty('inset', locales.lens.inset) }
                  { this._displayLensProperty('far_pd', locales.lens.farPd) }
                  { this._displayLensProperty('near_pd', locales.lens.nearPd) }
                  { this._displayLensProperty('fitting', locales.lens.fitting) }
                  { this._displayLensProperty('seg', locales.lens.seg) }
                  { this._displayLensProperty('cvd', locales.lens.cvd) }
                  { this._displayLensProperty('ffa', locales.lens.ffa) }
                  { this._displayLensProperty('pta', locales.lens.pta) }
                  { this._displayLensProperty('decenter_x', locales.lens.decenterX) }
                  { this._displayLensProperty('decenter_y', locales.lens.decenterY) }
                  { this._displayLensProperty('prism_h', locales.lens.prismH) }
                  { this._displayLensProperty('prism_h_value', locales.lens.prismHValue) }
                  { this._displayLensProperty('prism_v', locales.lens.prismV) }
                  { this._displayLensProperty('prism_v_value', locales.lens.prismVValue) }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  _displayOrderDate () {
    if(this.props.order.created_at) {
      return moment(this.props.order.created_at, moment.ISO_8601).format('DD-MM-YYYY HH:MM');
    }
  }

  _displayEstFinfishDate (){
    if(this.props.order.est_finished_date) {
      return moment(this.props.order.est_finished_date).format('DD-MM-YYYY');
    }
  }

  _displayValue (value, label, suffix=null) {
    if(isEmpty(value)) return null;
    if(suffix) value = `${value} ${suffix}`;
    return(
      <tr>
        <th className="th-label text-right">{label}</th>
        <td>{value || '-'}</td>
      </tr>
    );
  }

  _displayLensProperty (prop, label, klassName=null, options={}) {
    let leftLens = this.props.order.left_lens;
    let rightLens = this.props.order.right_lens;
    let leftValue = null;
    let rightValue = null;
    if(!isEmpty(leftLens)) leftValue = leftLens[prop];
    if(!isEmpty(rightLens)) rightValue = rightLens[prop];
    if(isEmpty(leftValue) && isEmpty(rightValue)) return null;

    const sign = options.sign || false;
    const decimals = options.decimals || 2;
    return (
      <tr className={klassName}>
        <th className="th-label text-right">{label}</th>
        { rightLens ? <td>{sign ? numberWithSign(parseFloat(rightValue), decimals) : rightValue || '-'}</td> : null }
        { leftLens ? <td>{sign ? numberWithSign(parseFloat(leftValue), decimals) : leftValue || '-'}</td> : null }
      </tr>
    );
  }

  _displayLensStatus () {
    let leftLens = this.props.order.left_lens || {};
    let rightLens = this.props.order.right_lens || {};
    return (
      <tr>
        <th className="th-label text-right">{this.props.locales.lens.status}</th>
        { !isEmpty(rightLens) ? <td>{rightLens.id != null ? this.props.locales.lens.statuses[rightLens.status] : '-'}</td> : null }
        { !isEmpty(leftLens) ? <td>{leftLens.id != null ? this.props.locales.lens.statuses[leftLens.status] : '-'}</td> : null }
      </tr>
    );
  }

  _displayLensSide () {
    let leftLens = this.props.order.left_lens;
    let rightLens = this.props.order.right_lens;
    return (
      <tr>
        <th className="th-label text-right">{this.props.locales.lens.side}</th>
        { rightLens ? <td>{this.props.locales.lens.right}</td> : null }
        { leftLens ? <td>{this.props.locales.lens.left}</td> : null }
      </tr>
    );
  }

  classForTable () {
    let leftLens = this.props.order.left_lens;
    let rightLens = this.props.order.right_lens;
    if(!(leftLens && rightLens)){
      return 'half';
    }
  }
}

OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  locales: PropTypes.shape({
    order: PropTypes.shape({
      code: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      estFinishedDate: PropTypes.string.isRequired,
      patient: PropTypes.string.isRequired,
      noteOnLabel: PropTypes.string.isRequired,
      remark: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      testId: PropTypes.string.isRequired,
    }).isRequired,
    lens: PropTypes.shape({
      addition: PropTypes.string.isRequired,
      axis: PropTypes.string.isRequired,
      cvd: PropTypes.string.isRequired,
      cylinder: PropTypes.string.isRequired,
      decenterX: PropTypes.string.isRequired,
      decenterY: PropTypes.string.isRequired,
      farPd: PropTypes.string.isRequired,
      ffa: PropTypes.string.isRequired,
      fitting: PropTypes.string.isRequired,
      inset: PropTypes.string.isRequired,
      left: PropTypes.string.isRequired,
      nearPd: PropTypes.string.isRequired,
      prismH: PropTypes.string.isRequired,
      prismHValue: PropTypes.string.isRequired,
      prismV: PropTypes.string.isRequired,
      prismVValue: PropTypes.string.isRequired,
      pta: PropTypes.string.isRequired,
      quantity: PropTypes.string.isRequired,
      right: PropTypes.string.isRequired,
      seg: PropTypes.string.isRequired,
      side: PropTypes.string.isRequired,
      sphere: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      statuses: PropTypes.shape({
        canceled: PropTypes.string.isRequired,
        dispatched: PropTypes.string.isRequired,
        order_entered: PropTypes.string.isRequired,
        pending: PropTypes.string.isRequired,
        ready: PropTypes.string.isRequired,
        reworking: PropTypes.string.isRequired,
        uploading: PropTypes.string.isRequired,
        work_in_process: PropTypes.string.isRequired,
        new_lens: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    optical: PropTypes.shape({
      coat: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      corridor: PropTypes.string.isRequired,
      cutting: PropTypes.string.isRequired,
      dbl: PropTypes.string.isRequired,
      degression: PropTypes.string.isRequired,
      design: PropTypes.string.isRequired,
      dia: PropTypes.string.isRequired,
      flashMirror: PropTypes.string.isRequired,
      frameShape: PropTypes.string.isRequired,
      frameType: PropTypes.string.isRequired,
      frameLibrary: PropTypes.string.isRequired,
      hbox: PropTypes.string.isRequired,
      index: PropTypes.string.isRequired,
      lensType: PropTypes.string.isRequired,
      material: PropTypes.string.isRequired,
      optThickness: PropTypes.string.isRequired,
      ovalShape: PropTypes.string.isRequired,
      productName: PropTypes.string.isRequired,
      roundShape: PropTypes.string.isRequired,
      shapeFile: PropTypes.string.isRequired,
      sharpEdge: PropTypes.string.isRequired,
      subDia: PropTypes.string.isRequired,
      testId: PropTypes.string.isRequired,
      tint: PropTypes.string.isRequired,
      tintValue: PropTypes.string.isRequired,
      uv: PropTypes.string.isRequired,
      vbox: PropTypes.string.isRequired,
      readingDis: PropTypes.string.isRequired,
      frameMaterial: PropTypes.string.isRequired,
    }).isRequired,
    msg: PropTypes.shape({
      processingShapeFile: PropTypes.string.isRequired,
    }).isRequired,
    page: PropTypes.shape({
      button: PropTypes.string,
    }),
  }).isRequired
};

OrderDetails.defaultProps = {
};
