import React from 'react';
import PropTypes from 'prop-types';
import Csrf from 'utils/Csrf';
import { Button, Glyphicon } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

export default class OrderSendMqButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.renderButtonText = this.renderButtonText.bind(this);
    this.sendToMq = this.sendToMq.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  sendToMq (e) {
    this.setState({loading: true});
    e.preventDefault();
    const { url } = this.props;
    fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        authenticity_token: Csrf.token(),
      })
    }).then(response => {
      return response.json();
    }).then(_json => {
      setTimeout(()=>{
        this.setState({loading: false});
      }, 3000);
    }).catch(_ex => {
      setTimeout(()=>{
        this.setState({loading: false});
      }, 3000);
    });
  }

  renderButtonText () {
    const { loading } = this.state;
    if(loading) return (
      <span>
        <Glyphicon glyph="refresh" className="glyphicon-refresh-animate" />
      </span>

    );
    return (
      <span><Glyphicon glyph="send" /></span>
    );
  }

  renderTooltip () {
    const { url: tooltipID } = this.props;
    return(
      <span className="text-center">
        <ReactTooltip
          id={tooltipID}
          place="bottom"
          type="dark"
          effect="solid"
          resizeHide={false}
        >
          Send to the factory now!
        </ReactTooltip>
      </span>
    );
  }

  renderButton () {
    const { disabled, loading } = this.state;
    const { url, bsStyle, bsSize, block } = this.props;
    return(
      <Button
        href={url}
        block={block}
        bsStyle={bsStyle}
        bsSize={bsSize}
        disabled={disabled || loading}
        onClick={this.sendToMq}
      >
        { this.renderButtonText() }
      </Button>
    );
  }

  render () {
    const { className, url } = this.props;
    return(
      <span className="hidden-print">
        <div data-tip={true} data-for={url} className={className}>
          { this.renderButton() }
        </div>
        { this.renderTooltip() }
      </span>
    );
  }
}

OrderSendMqButton.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  bsSize: PropTypes.oneOf(["lg", "large", "sm", "small", "xs", "xsmall"]),
  bsStyle: PropTypes.oneOf(["success", "warning", "danger", "info", "default", "primary", "link"]),
  label: PropTypes.string,
  block: PropTypes.bool,
  disabled: PropTypes.bool,

};

OrderSendMqButton.defaultProps = {
  bsStyle: 'default',
  block: false,
  disabled: false,
};
