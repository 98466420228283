import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import CheckboxGroupInput from "components/default_redux/CheckboxGroupInput";
import { Row, Col, Panel } from "react-bootstrap";
import { Field } from "redux-form";
// import {
// } from '../../constants/mapUserColumns';

export default class UserExtraFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { extraFields, locales } = this.props;
    if (isEmpty(extraFields)) return null;
    return (
      <Col xs={4}>
        <Panel>
          <Panel.Heading>
            <h3>{locales.words.extraPermissions}</h3>
          </Panel.Heading>
          <Panel.Body>
            <Row>
              <Col xs={12}>
                <Col xs={12}>
                  <Field
                    component={CheckboxGroupInput}
                    name="user[extra_fields]"
                    options={map(extraFields, extraField => {
                      return {
                        name: locales.extraFields[extraField],
                        value: extraField
                      };
                    })}
                  />
                </Col>
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
      </Col>
    );
  }
}

UserExtraFields.propTypes = {
  user: PropTypes.object,
  action: PropTypes.string,
  extraFields: PropTypes.array,
  locales: PropTypes.shape({
    extraFields: PropTypes.object.isRequired,
    words: PropTypes.shape({
      extraPermissions: PropTypes.string.isRequired
    })
  })
};

UserExtraFields.defaultProps = {
  extraFields: [{ name: "PiYa" }, { name: "Kee" }, { name: "Off" }]
};
