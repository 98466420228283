import React, { Component } from "react";
import autoBind from "react-autobind";
import moment from "moment";
import { Row, Col, Table } from "react-bootstrap";
import { Order } from "../ordering/bulk/types";
import { isEmpty } from "lodash";

export class BulkOrderDetails extends Component<Props> {
  constructor(props: Props) {
    super(props);
    autoBind(this);
  }

  displayValue(value: string, label: string) {
    if (isEmpty(value)) return null;
    return (
      <tr>
        <th className="th-label text-right">{label}</th>
        <td>{value || "-"}</td>
      </tr>
    );
  }

  displayDateValue(value: Date, label: string) {
    if (isEmpty(value)) return null;
    return this.displayValue(
      moment(value, moment.ISO_8601).format("DD-MM-YYYY"),
      label
    );
  }

  render() {
    const {
      props: { order, locales },
      displayValue,
      displayDateValue
    } = this;
    return (
      <Row>
        <Col xsOffset={3} xs={6}>
          <Table className="order_table--details" hover>
            <tbody>
              {displayValue(order.creator.name, locales.order.creatorId)}
              {displayValue(order.user.name, locales.order.userId)}
              {displayDateValue(order.created_at, locales.order.createdAt)}
              {displayDateValue(
                order.est_finished_date,
                locales.order.estFinishedDate
              )}
              {displayValue(order.po_number, locales.order.poNumber)}
              {displayValue(order.so_number, locales.order.soNumber)}
              {displayValue(
                order.ship_to_address?.address,
                locales.order.shipToAddress
              )}
              {displayValue(
                order.ship_via_forwarder?.name,
                locales.order.shipViaForwarder
              )}
              {displayValue(order.note_on_label, locales.order.noteOnLabel)}
              {displayValue(order.private_note, locales.order.privateNote)}
            </tbody>
          </Table>
        </Col>
        <Col xs={12}>
          <Table className="table table-striped table-hover table-sm order_table--details">
            <thead>
              <tr>
                <th scope="col" className="th-label">
                  Name
                </th>
                <th scope="col" className="th-label">
                  Power 1
                </th>
                <th scope="col" className="th-label">
                  Power 2
                </th>
                <th scope="col" className="th-label">
                  SAP Code
                </th>
                <th scope="col" className="th-label">
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody>
              {order.optical_informationses.map((op, i) => {
                const lensElement = op.lens.map((len, index) => {
                  return (
                    <tr key={index}>
                      <td></td>
                      <td className="text-center">{len.power1}</td>
                      <td className="text-center">{len.power2}</td>
                      <th scope="row">{len.sap_code}</th>
                      <td className="text-center">{len.quantity}</td>
                    </tr>
                  );
                });
                return [
                  <tr key={i}>
                    <td>{op.product_name}</td>
                    <td colSpan={3}></td>
                    <td className="text-center">
                      <b>{op.total}</b>
                    </td>
                  </tr>,
                  lensElement
                ];
              })}
              <tr>
                <th colSpan={3}></th>
                <th>Total</th>
                <td className="text-center">
                  <b>{order.total}</b>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}

interface Props {
  order: Order;
  locales: any;
}

export default BulkOrderDetails;
