import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
// import { reduxForm, formValueSelector } from 'redux-form';
import { reduxForm } from 'redux-form';
import UsersSearchingForm from 'components/users/UsersSearchingForm';

class UsersSearching extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render () {
    return(
      <Row>
        <Col xs={12}>
          <UsersSearchingForm {...this.props} />
        </Col>
      </Row>
    );
  }
}

UsersSearching.propTypes = {
  formUrl: PropTypes.string,
};
UsersSearching.defaultProps = {
};

let form = reduxForm({
  form: 'usersSearching'
})(UsersSearching);

// const selector = formValueSelector('usersSearching'); // <-- same as form name
form = connect(
  (state, props) => {
    const initialValues = props.initFilter || {};
    return {
      initialValues,
    //   createdPeriod
    };
  }
)(form);

export default form;
