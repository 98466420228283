import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { PRISM, PRISM_H, PRISM_H_VALUE, PRISM_V, PRISM_V_VALUE } from '../../../../constants/mapOrderColumns';
import * as Bootstrap from 'react-bootstrap';
import SwitchInput from '../../../default_redux/SwitchInput';
import SelectInput from '../../../default_redux/SelectInput';
import { genRange } from '../../../../utils/GenOptions';

export default class Prism extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.enabledOptionFields = this.enabledOptionFields.bind(this);
  }

  enabledOptionFields () {
    if(!this.props.prism) return null;
    return(
      <div>
        <Bootstrap.Row>
          <Bootstrap.Col xs={8}>
            <Field component={SelectInput}
              name={`order${this.props.attrNestedName}[${PRISM_H}]`}
              options={[
                {name: 'In', value: 'in'},
                {name: 'Out', value: 'out'}
              ]}
              liveSearch={true}
              selectFirst={true}
              label="Horizontal"
              labelClass="col-xs-6"
              selectWrapperClass="col-xs-6"
              required={true}
            />
          </Bootstrap.Col>
          <Bootstrap.Col xs={4}>
            <Field component={SelectInput}
              name={`order${this.props.attrNestedName}[${PRISM_H_VALUE}]`}
              options={genRange(0, 5, 0.25, 2, 0, false, 'asc')}
              liveSearch={true}
              selectFirst={true}
              label="Horizontal Value"
              labelClass=""
              selectWrapperClass="col-xs-12"
              showLabel={false}
              required={true}
            />
          </Bootstrap.Col>
        </Bootstrap.Row>

        <Bootstrap.Row>
          <Bootstrap.Col xs={8}>
            <Field component={SelectInput}
              name={`order${this.props.attrNestedName}[${PRISM_V}]`}
              options={[
                {name: 'Up', value: 'up'},
                {name: 'Down', value: 'down'}
              ]}
              liveSearch={true}
              selectFirst={true}
              label="Vertical"
              labelClass="col-xs-6"
              selectWrapperClass="col-xs-6"
              required={true}
            />
          </Bootstrap.Col>
          <Bootstrap.Col xs={4}>
            <Field component={SelectInput}
              name={`order${this.props.attrNestedName}[${PRISM_V_VALUE}]`}
              options={genRange(0, 5, 0.25, 2, 0, false, 'asc')}
              liveSearch={true}
              selectFirst={true}
              label="Vertical Value"
              labelClass=""
              selectWrapperClass="col-xs-12"
              showLabel={false}
              required={true}
            />
          </Bootstrap.Col>
        </Bootstrap.Row>
      </div>
    );
  }

  render () {
    if(!this.props.show) return null;
    // console.log(`Render ${this.props.label}`);
    return (
      <div>
        <Field component={SwitchInput}
          name={`order${this.props.attrNestedName}[${PRISM}]`}
          label={this.props.label}
          labelClass="col-xs-4"
          inputWaraperClass="col-xs-8"
          onText="Yes"
          offText="No"
        />
        { this.enabledOptionFields() }
      </div>
    );
  }
}

Prism.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  attrNestedName: PropTypes.string,
  prism: PropTypes.bool,
};

Prism.defaultProps = {
  label: "Prism",
  show: false,
  min: 0,
  max: 0,
};
