import getIn from "redux-form/lib/structure/plain/getIn";

export const required = (isRequired = true, value) => {
  const error = "Required";
  let valid;
  if (!isRequired) return valid;
  if (!value) valid = error;
  return valid;
};

export const minLength = (min, value) => {
  const error = `must be more than ${min} chars`;
  let valid;
  if (parseInt(min) > value.length) valid = error;
  return valid;
};

export const maxLength = (max, value) => {
  const error = `must be less than ${max} chars`;
  let valid;
  if (parseInt(max) < value.length) valid = error;
  return valid;
};

// field is user.name if field's name is user[name]
export const same = (field, value, values) => {
  const error = `is different from ${field}`;
  let valid;
  const fieldValue = getIn(values, field);
  if (fieldValue != value) valid = error;
  return valid;
};

export const email = value => {
  const error = `email invalid pattern`;
  let valid;
  if (!value) return valid;
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(value)) valid = error;
  return valid;
};

export const min = (min, value) => {
  const error = `must be >= ${min}`;
  let valid;
  if (parseFloat(value) < parseFloat(min)) valid = error;
  return valid;
};

export const max = (max, value) => {
  const error = `must be <= ${max}`;
  let valid;
  if (parseFloat(value) > parseFloat(max)) valid = error;
  return valid;
};

export const period = (number, value) => {
  const error = `must be ${number} per step`;
  let valid;
  let countDecimals = 0;
  if (Math.floor(number) !== number) {
    countDecimals = number.toString().split(".")[1].length || 0;
  }
  let multiplier = 10 ** countDecimals;
  value *= multiplier;
  number *= multiplier;
  if (value % number != 0) valid = error;
  return valid;
};

const Validators = {
  required,
  minLength,
  maxLength,
  same,
  email,
  min,
  max,
  period
};

export default Validators;
