import React from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookie';
import moment from 'moment';
import isPresent from 'utils/isPresent';
import map from 'lodash/map';
import { Modal, Row, Col, Button } from 'react-bootstrap';

export default class AskLanguage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showed: props.show
    };
    this.cookieName = 'ask_language';
    this.setAskLanguageCookie = this.setAskLanguageCookie.bind(this);
    this.hasAskLanguageCookie = this.hasAskLanguageCookie.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.show != this.state.show) {
      this.setState({showed: nextProps.show});
    }
  }

  setAskLanguageCookie (language) {
    cookie.save(this.cookieName, Date.now(), {
      path: '/',
      expires: moment().add(1, 'months').toDate()
    });
    window.location = language.locale_url;
  }

  hasAskLanguageCookie () {
    const cookieValue = cookie.load(this.cookieName);
    return isPresent(cookieValue);
  }

  openModal () {
    this.setState({showed: true});
  }

  closeModal () {
    this.setState({showed: false});
    this.props.handleHide();
  }

  render () {
    const wantToShow = this.state.showed;
    let show = wantToShow || !this.hasAskLanguageCookie();
    const {languages, title, close: closeText } = this.props;
    return (
      <Modal
        show={show}
        onHide={this.closeModal.bind(this)}
      >
        <Modal.Header>
          <Modal.Title className="text-center" style={{fontSize: '24px'}}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
              <Col xsOffset={1} xs={10}>
              {
                map(languages, language => {
                  return(
                    <div key={language.locale}>
                      <Button
                        block
                        bsSize="large"
                        onClick={this.setAskLanguageCookie.bind(this, language)}
                      >
                        <span
                          className={`flag-icon flag-icon-${language.flag}`}
                          aria-hidden="true"
                        ></span>&nbsp;
                        {language.desc}
                      </Button>
                      <br/>
                    </div>
                  );
                })
              }
            </Col>
          </Row>
          { wantToShow &&
            <Modal.Footer onClick={this.closeModal}>
              <Row>
                <Col xsOffset={4} xs={4} className="text-center">
                  <Button block>{closeText}</Button>
                </Col>
              </Row>
            </Modal.Footer>
          }

        </Modal.Body>
      </Modal>
    );
  }
}

AskLanguage.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  languages: PropTypes.array,
  handleHide: PropTypes.func,
  close: PropTypes.string,
};

AskLanguage.defaultProps = {
  show: false,
  title: "Language?",
  languages: [],
  handleHide: ()=>{},
  close: "Close",
};
