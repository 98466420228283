import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Loading from 'components/shared/Loading';
import "moment-duration-format";
// import { Button, Glyphicon } from 'react-bootstrap';

export default class TimeCountdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      startCoundown: false,
      endCoundown: false
    };
    this.runningCountdown = this.runningCountdown.bind(this);
  }

  componentDidMount () {
    this.runningCountdown();
  }

  componentWillUnmount () {
    clearInterval(this.state.timer);
  }

  runningCountdown () {
    const {
      coundownFormat, expiredTime, afterStartCountdown, afterEndCountdown
    } = this.props;
    const eventTime= moment(expiredTime, moment.ISO_8601);
    const interval = 1000;
    const currentTime = moment();
    const diffTime = eventTime - currentTime;
    let duration = moment.duration(diffTime, 'milliseconds');
    // this.setState({startCoundown: true});
    if(diffTime > 0) {
      const timer = setInterval(() => {
        duration = moment.duration(duration - interval, 'milliseconds');
        if(duration.milliseconds() > 0) {
          const countdown = duration.format(coundownFormat, { trim: false });
          this.setState({countdown, startCoundown: true});
        } else {
          clearInterval(this.state.timer);
          this.setState({endCoundown: true});
          afterEndCountdown();
        }
      }, interval);
      this.setState({timer});
      afterStartCountdown();
    } else {
      this.setState({startCoundown: true, endCoundown: true});
      afterEndCountdown();
    }
  }

  render () {
    let msg;
    const { countMessage, replaceText, endMessage } = this.props;
    const { countdown, startCoundown, endCoundown } = this.state;
    if(!startCoundown) return(
      <Loading
        position="center"
        size="xs"
        inline={true}
      />
    );
    if(endCoundown) {
      msg = endMessage;
    } else {
      msg = countMessage.replace(replaceText, countdown);
    }
    return(
      <span dangerouslySetInnerHTML={{__html: msg}} ></span>
    );
  }
}

TimeCountdown.propTypes = {
  coundownFormat: PropTypes.string,
  countMessage: PropTypes.string,
  endMessage: PropTypes.string,
  expiredTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  replaceText: PropTypes.string,
  afterStartCountdown: PropTypes.func,
  afterEndCountdown: PropTypes.func,
};

TimeCountdown.defaultProps = {
  coundownFormat: "mm:ss",
  countMessage: "%countdown",
  endMessage: 'Done!',
  replaceText: "%countdown",
  afterStartCountdown: ()=>{},
  afterEndCountdown: ()=>{},
};
