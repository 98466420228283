import { actionNames } from "constants.js";
import update from "immutability-helper";

const userForm = (state = {}, action) => {
  // console.log('Reducer UserForm : ', state, action);
  switch (action.type) {
    case actionNames.UPDATE_PARENTS: {
      const parentsData = {};
      parentsData[action.sourceKey] = action.parents;
      let parentUsers = state.parentUsers || {};
      parentUsers = update(parentUsers, { $merge: parentsData });
      return update(state, { $merge: { parentUsers } });
    }
    default: {
      return state;
    }
  }
};

export default userForm;
