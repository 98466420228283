import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FAR_PD, NEAR_PD } from 'constants/mapOrderColumns';
import TextInput from 'components/default_redux/TextInput';
import { required, min, max } from 'utils/Validators';

export default class FarPD extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.nearPD = this.nearPD.bind(this);
  }

  nearPD (value) {
    return parseFloat(value) - 5;
  }

  afterOnChange (value) {
    const { change, name } = this.props;
    change(`${name}[${NEAR_PD}]`, this.nearPD(value));
  }

  render () {
    const {
      label, show, name,
    } = this.props;
    if(!show) return null;

    const requiredInput = this.props.required;
    return (
      <Field component={TextInput}
        ref="farPd"
        label={label}
        type="number"
        step="any"
        name={`${name}[${FAR_PD}]`}
        suffixAddon={`${this.props.min}-${this.props.max} mm.`}
        inputClass="col-xs-8"
        labelClass="col-xs-4"
        required={requiredInput}
        afterOnChange={this.afterOnChange.bind(this)}
        validate={[
          required.bind(null, requiredInput),
          min.bind(null, this.props.min),
          max.bind(null, this.props.max),
        ]}
      />
    );
  }
}

FarPD.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,

  change: PropTypes.func,
  children: PropTypes.any,
};

FarPD.defaultProps = {
  label: "Far PD",
  show: false,
  required: false,
  min: 0,
  max: 0,
};
