import { ReactNode } from "react";
import { number, string } from "prop-types";

export interface ComponentProps extends State {
  children?: ReactNode;
}

export enum UIState {
  Search,
  Cart,
  Review,
  Confirm
}

export enum APIFilterState {
  Querying,
  Idle,
  Err
}

export const DEFAULT_STATE: State = {
  uiState: UIState.Search,
  apiFilterState: APIFilterState.Idle,
  agents: [],
  shipToAddresses: [],
  shipViaForwarders: [],
  filters: [],
  selections: [],
  results: [],
  cart: {
    items: []
  }
};

export interface State {
  uiState: UIState;
  apiFilterState: APIFilterState;
  agents: Agent[];
  shipToAddresses: Address[];
  shipViaForwarders: Forwarder[];
  filters: FilterSelector[];
  selections: FilterSelection[];
  results: Product[];
  preview?: Order;
  order?: Order;
  cart: {
    items: CartItem[];
  };
}

export interface FilterSelector {
  name: string;
  key: string;
  options: FilterSelectorOption[];
}

export interface FilterSelectorOption {
  name: string;
  id?: string;
}

export interface FilterSelection {
  key: string;
  value: string;
}

export interface Product {
  name: string;
  sapCode: string;
  sku: string;
  powerType: string[];
}

export interface CartItem {
  id?: number;
  quantity: number;
  sapCode: string;
  sku: string;
  properties: ItemProperties;
  optical_informations_id?: number;
}

export interface ItemProperties {
  sph?: number;
  cyl?: number;
  base?: number;
  add?: number;
  curve?: number;
  side?: Side;
}

export enum Side {
  Left = "Left",
  Right = "Right"
}

export interface AvailableMaterials {
  material_id: number;
  design_id: number;
  lens_type_id: number;
  color_id: number;
  coat_id: number;
  diameter_id: number;
}

export interface Order {
  id?: number;
  user_id?: string;
  created_at: Date;
  est_finished_date?: Date;
  user: User;
  creator: User;
  private_note?: string;
  note_on_label?: string;
  po_number?: string;
  so_number: string;
  ship_to_address_id?: number;
  ship_via_forwarder_id?: number;
  ship_to_address?: Address;
  ship_via_forwarder?: Forwarder;
  optical_informationses?: OpticalInformations[];
  items?: CartItem[];
  total: number;
}

export interface User {
  username: string;
  name: string;
  so_number_updatable: boolean;
}

export interface OpticalInformations {
  product_name: string;
  total: number;
  lens?: Len[];
}

export interface Len {
  sap_code: string;
  quantity: number;
  power1?: string;
  power2?: string;
}

export interface Agent {
  name: string;
  id: number;
}

export interface Address {
  id: number;
  address?: string;
  phone?: string;
}

export interface Forwarder {
  id?: number;
  name: string;
  description?: string;
}
