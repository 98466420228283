import "whatwg-fetch";
import * as Sentry from "@sentry/browser";

const FetchRetry = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    let fetchName;
    let retries = 3;
    let retryDelay = 20000;
    let beforeRetryCallback = () => {};
    let updateErrorType = (_fetchName, _errorType, _options) => {};
    if (options && options.fetchName) {
      fetchName = options.fetchName;
    }
    if (options && options.retries) {
      retries = options.retries;
    }
    if (options && options.retryDelay) {
      retryDelay = options.retryDelay;
    }
    if (options && options.beforeRetryCallback) {
      beforeRetryCallback = options.beforeRetryCallback;
    }
    if (options && options.updateErrorType) {
      updateErrorType = options.updateErrorType;
    }
    const wrappedFetch = n => {
      fetch(url, options)
        .then(response => {
          if (response.status == 408 || response.status == 504) {
            throw new Error(`timeout`);
          }
          if (response.status >= 500) {
            throw new Error(`serverErrors`);
          }
          updateErrorType(fetchName, null);
          resolve(response);
        })
        .catch(error => {
          let errorType = error.message || "serverErrors";
          if (!navigator.onLine) errorType = "internet";
          updateErrorType(fetchName, errorType, {
            errorData: error,
            retryDelay
          });
          if (n > 0) {
            setTimeout(() => {
              beforeRetryCallback();
              updateErrorType(fetchName, "refreshing", { errorData: error });
              wrappedFetch(--n);
            }, retryDelay);
          } else {
            Sentry.captureException(error);
            updateErrorType(fetchName, "error", { errorData: error });
            reject(error);
          }
        });
    };
    wrappedFetch(retries);
  });
};

export default FetchRetry;
