import React, { Component } from "react";
import {
  createStore,
  bindActionCreators,
  applyMiddleware,
  compose
} from "redux";
import thunk from "redux-thunk";
import { connect, Provider } from "react-redux";
import { documentForm as documentFormReducer } from "reducers";
// import * as documentFormAction from '../actions/documentFormAction';
// import update from 'immutability-helper';
import DocumentForm from "components/admin/DocumentForm";

const mapStateToProps = (_state, _props) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  // return bindActionCreators(documentFormAction, dispatch);
  return bindActionCreators({}, dispatch);
};

const DocumentFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentForm);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  documentFormReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default class DocumentFormApp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Provider store={store}>
        <DocumentFormContainer {...this.props} />
      </Provider>
    );
  }
}
