import forOwn from "lodash/forOwn";
import isObject from "lodash/isObject";

const CleanObject = object => {
  const cleanData = {};
  forOwn(object, (value, key) => {
    cleanData[key] = isObject(value) ? CleanObject(value) : null;
  });
  return cleanData;
};
export default CleanObject;
