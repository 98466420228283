import React from 'react';
import PropTypes from 'prop-types';
import { Button, Glyphicon, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import OrdersFilterForm from './OrdersFilterForm';
import GenUrl from 'utils/GenUrl';

class OrdersSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilterModal: false,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.loadOrders = this.loadOrders.bind(this);
    this.renderSearchText = this.renderSearchText.bind(this);
    this.renderSubmitButton = this.renderSubmitButton.bind(this);
  }

  close() {
    this.setState({ showFilterModal: false });
  }

  open() {
    this.setState({ showFilterModal: true });
  }

  loadOrders (data) {
    this.setState({csvLoading: true});
    const { searchUrl } = this.props;
    const url = GenUrl(searchUrl, { q: data });
    window.location.replace(url);
  }

  renderSubmitButton (pristine, submitting, submitSucceeded) {
    return (
      <Button
        bsStyle="primary"
        block
        type="submit"
        disabled={pristine || submitting || submitSucceeded}>
        {this.renderSearchText()}
      </Button>
    );
  }

  renderSearchText () {
    const {
      submitting, submitSucceeded,
      locales: {
        buttons: { search: searchText }
      }
    } = this.props;
    if(!(submitting || submitSucceeded)) return (
      <div>
        <Glyphicon glyph="search" />
        &nbsp;{searchText}
      </div>
    );
    return (
      <div>
        <Glyphicon glyph="refresh" className="glyphicon-refresh-animate" />
        &nbsp;{searchText}
      </div>
    );
  }

  render () {
    const { showFilterModal } = this.state;
    const {
      customButton,
      locales: {
        modal: {
          title: {
            search: searchTitle,
          }
        },
      }
    } = this.props;
    return(
      <span>
        { customButton(this.open, showFilterModal) }
        <Modal
          show={showFilterModal}
          onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>{searchTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OrdersFilterForm
              {...this.props}
              onSubmit={this.loadOrders}
              submitButton={this.renderSubmitButton} />
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

OrdersSearch.propTypes = {
  searchUrl: PropTypes.string,
  customButton: PropTypes.func,
  searchAgentsUrl: PropTypes.string.isRequired,
  locales: PropTypes.shape({
    modal: PropTypes.shape({
      title: PropTypes.shape({
        search: PropTypes.string,
      }).isRequired
    }).isRequired,
    buttons: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired
  }).isRequired,

  // Redux form
  initFilter: PropTypes.object,
  createdPeriod: PropTypes.shape({
    form: PropTypes.string,
    to: PropTypes.string,
  }),
  submitting: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
};

OrdersSearch.defaultProps = {
  customButton: (_open, _showModal) => {
    return 'Search';
  }
};

let form = reduxForm({
  form: 'FilterSearch'
})(OrdersSearch);

const selector = formValueSelector('FilterSearch'); // <-- same as form name
form = connect(
  (state, props) => {
    const initialValues = props.initFilter || {};
    if(initialValues.user_id) initialValues.user_id = parseInt(initialValues.user_id);
    const createdPeriod = {
      start: selector(state, 'start_date'),
      end: selector(state, 'end_date')
    };
    // return {
    //   initialValues,
    //   currentRole
    // };
    return {
      initialValues,
      createdPeriod
    };
  }
)(form);

export default form;
