import React from 'react';
import PropTypes from 'prop-types';
import { UV_FIELD } from '../../../../constants/orderFields';
import { Field } from 'redux-form';
import SwitchInput from '../../../default_redux/SwitchInput';

export default class RxOptionUv extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render () {
    if(!this.props.show) return null;
    // console.log(`Render ${this.props.label}`);
    return (
      <Field component={SwitchInput}
        name={UV_FIELD}
        label={this.props.label}
        onText='Yes'
        offText='No'
        labelClass='col-xs-3'
        inputWaraperClass='col-sm-6'
        required={this.props.required}
      />
    );
  }

}
RxOptionUv.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
};

RxOptionUv.defaultProps = {
  label: "UV Protection",
  show: false,
  required: false,
};
