import React, { FC, useEffect } from "react";
import { ComponentProps, FilterSelector, FilterSelection } from "./types";
import {
  Form,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

export const FilterList: FC<ComponentProps & {
  filters: FilterSelector[];
  updateSelection: (key: string, val: string) => void;
}> = props => {
  const { filters, selections, children, updateSelection } = props;

  useEffect(() => {
    filters.forEach(f => {
      const k = f.key;
      const v = f.options[0]?.id;
      if (!(k && v)) return;

      updateSelection(k, v);
    });
  }, [filters.join(",")]);

  const filterData = filters.map((f, idx) => ({
    key: `f-${idx}`,
    name: f.name,
    value: getCurrentValue(f, selections),
    onChange: ev => {
      updateSelection(f.key, ev.target.value);
    },
    options: f.options.map((opt, idx) => ({
      key: `f-o-${idx}`,
      name: opt.name,
      value: opt.id
    }))
  }));

  return (
    <div>
      {children}

      <Form horizontal>
        {filterData.map(sel => (
          <FormGroup key={sel.key}>
            <Col componentClass={ControlLabel} sm={3}>
              {sel.name}
            </Col>
            <Col sm={9}>
              <FormControl
                value={sel.value}
                onChange={sel.onChange}
                componentClass="select"
                placeholder="select"
              >
                {sel.options.map(opt => (
                  <option key={opt.key} value={opt.value}>
                    {opt.name}
                  </option>
                ))}
              </FormControl>
            </Col>
          </FormGroup>
        ))}
      </Form>
    </div>
  );
};

function getCurrentValue(
  filter: FilterSelector,
  selections: FilterSelection[]
): string {
  const match = selections.find(sel => sel.key == filter.key);
  return match?.value || filter.options[0]?.id || "";
}
