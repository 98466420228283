import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextInput from 'components/default_redux/TextInput';
import { required } from 'utils/Validators';

export default class RxCustomerForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    // console.log('Render Patient');
    return (
      <Field component={TextInput}
        label={this.props.label}
        type="text"
        name={this.props.name}
        required={true}
        validate={[required.bind(null, true)]}
        labelClass="col-xs-3"
        inputClass="col-xs-6"
      />
    );
  }


}

RxCustomerForm.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

RxCustomerForm.defaultProps = {
  label: 'Patient',
  name: null,
};
