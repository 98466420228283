import React from 'react';
import PropTypes from 'prop-types';
import * as Bootstrap from 'react-bootstrap';

class InputForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.displayAddon = this.displayAddon.bind(this);
    this.isEnalbedAddon = this.isEnalbedAddon.bind(this);
    this.displayHelpText = this.displayHelpText.bind(this);
  }

  render () {
    return (
      <Bootstrap.FormGroup
        className={this.props.formClass}
        validationState={this.props.validationState}
      >
        <Bootstrap.ControlLabel
          htmlFor={this.props.labelFor}
          srOnly={!this.props.showLabel}
          className={this.props.labelClass}
        >
          { this.displayLabel.bind(this)() }
        </Bootstrap.ControlLabel>
        <div className={this.props.inputClass}>
          <div className={this.isEnalbedAddon() ? "input-group" : null}>
            {this.displayAddon(this.props.prefixAddon)}
            {this.props.children}
            {this.displayAddon(this.props.suffixAddon)}
          </div>
          {this.displayHelpText()}
        </div>
      </Bootstrap.FormGroup>
    );
  }

  displayLabel () {
    if(!this.props.required) return this.props.label;
    return(
      <span>
        <abbr title="required">*</abbr>{this.props.label}
      </span>
    );
  }

  isEnalbedAddon () {
    return this.props.suffixAddon != null || this.props.prefixAddon != null;
  }

  displayAddon (addon) {
    if(addon == null) return null;
    return(
      <Bootstrap.InputGroup.Addon>{addon}</Bootstrap.InputGroup.Addon>
    );
  }

  displayHelpText () {
    if(this.props.helpText == null) return null;
    return(
      <Bootstrap.HelpBlock
        className={this.props.helpTextClass}
        style={{wordBreak: 'break-all'}}>
        {this.props.helpText}
      </Bootstrap.HelpBlock>
    );
  }
}

InputForm.propTypes = {
  label: PropTypes.string,
  labelFor: PropTypes.string,
  prefixAddon: PropTypes.any,
  suffixAddon: PropTypes.any,
  formClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  validationState: PropTypes.string,
  showLabel: PropTypes.bool,
  helpText: PropTypes.string,
  helpTextClass: PropTypes.string,
  required: PropTypes.bool
};

InputForm.defaultProps = {
  formClass: '',
  inputClass: '',
  labelClass: '',
  validationState: null,
  showLabel: true,
  required: false,
};

export default InputForm;
