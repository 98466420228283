import React, { Component, FormEvent } from "react";
import moment from "moment";
import autoBind from "react-autobind";
import { Agent, Order, Address, Forwarder } from "./types";
import {
  Col,
  Panel,
  ControlLabel,
  Form,
  FormGroup,
  FormControl
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";

export class CustomerForm extends Component<Props> {
  constructor(props: Props) {
    super(props);
    autoBind(this);
  }

  render() {
    const {
      order,
      agents,
      shipToAddresses,
      shipViaForwarders,
      updateCustomer,
      locales
    } = this.props;
    return (
      <div>
        <Panel className="panel__bg--gray">
          <Panel.Body>
            <Form horizontal>
              <FormGroup>
                <Col xsOffset={2} componentClass={ControlLabel} sm={3}>
                  {locales.order.userId}
                </Col>
                <Col xs={4}>
                  <FormControl
                    value={order.user_id}
                    onChange={(e: any) =>
                      updateCustomer("user_id", e.target.value)
                    }
                    componentClass="select"
                    placeholder="select"
                  >
                    {agents.map(agent => (
                      <option key={agent.id} value={agent.id}>
                        {agent.name}
                      </option>
                    ))}
                  </FormControl>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col xsOffset={2} componentClass={ControlLabel} sm={3}>
                  {locales.order.estFinishedDate}
                </Col>
                <Col xs={4}>
                  <ReactDatePicker
                    value={moment(
                      order.est_finished_date,
                      moment.ISO_8601
                    ).format("DD-MM-YYYY")}
                    onChange={(date: Date) =>
                      updateCustomer("est_finished_date", date)
                    }
                    className="form-control"
                  />
                </Col>
              </FormGroup>
              {order.creator.so_number_updatable && (
                <FormGroup>
                  <Col xsOffset={2} componentClass={ControlLabel} sm={3}>
                    {locales.order.soNumber}
                  </Col>
                  <Col xs={4}>
                    <FormControl
                      type="text"
                      value={order.so_number || ""}
                      onChange={(e: any) =>
                        updateCustomer("so_number", e.target.value)
                      }
                    />
                  </Col>
                </FormGroup>
              )}
              <FormGroup>
                <Col xsOffset={2} componentClass={ControlLabel} sm={3}>
                  {locales.order.poNumber}
                </Col>
                <Col xs={4}>
                  <FormControl
                    type="text"
                    value={order.po_number || ""}
                    onChange={(e: any) =>
                      updateCustomer("po_number", e.target.value)
                    }
                  />
                </Col>
              </FormGroup>
              {order.ship_to_address_id && shipToAddresses && (
                <FormGroup>
                  <Col xsOffset={2} componentClass={ControlLabel} sm={3}>
                    {locales.order.shipToAddress}
                  </Col>
                  <Col xs={4}>
                    <FormControl
                      value={order.ship_to_address_id}
                      onChange={(e: any) =>
                        updateCustomer(
                          "ship_to_address_id",
                          parseInt(e.target.value)
                        )
                      }
                      componentClass="select"
                      placeholder="select"
                    >
                      {shipToAddresses.map(address => (
                        <option key={address.id} value={address.id}>
                          {address.address}
                        </option>
                      ))}
                    </FormControl>
                  </Col>
                </FormGroup>
              )}
              {shipViaForwarders && (
                <FormGroup>
                  <Col xsOffset={2} componentClass={ControlLabel} sm={3}>
                    {locales.order.shipViaForwarder}
                  </Col>
                  <Col xs={4}>
                    {order.ship_via_forwarder_id && (
                      <FormControl
                        value={order.ship_via_forwarder_id}
                        onChange={(e: any) =>
                          updateCustomer(
                            "ship_via_forwarder_id",
                            parseInt(e.target.value)
                          )
                        }
                        componentClass="select"
                        placeholder="select"
                      >
                        {shipViaForwarders.map(forwarder => (
                          <option key={forwarder.id} value={forwarder.id}>
                            {forwarder.name}
                          </option>
                        ))}
                      </FormControl>
                    )}
                  </Col>
                </FormGroup>
              )}
              <FormGroup>
                <Col xsOffset={2} componentClass={ControlLabel} sm={3}>
                  {locales.order.noteOnLabel}
                </Col>
                <Col xs={4}>
                  <FormControl
                    type="text"
                    value={order.note_on_label || ""}
                    onChange={(e: any) =>
                      updateCustomer("note_on_label", e.target.value)
                    }
                    placeholder={locales.placeholders.noteOnLabel}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Col xsOffset={2} componentClass={ControlLabel} sm={3}>
                  {locales.order.privateNote}
                </Col>
                <Col xs={4}>
                  <FormControl
                    type="text"
                    value={order.private_note || ""}
                    onChange={(e: any) =>
                      updateCustomer("private_note", e.target.value)
                    }
                    placeholder={locales.placeholders.privateNote}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

interface Props {
  order: Order;
  agents: Agent[];
  shipToAddresses: Address[];
  shipViaForwarders: Forwarder[];
  updateCustomer: Function;
  locales: any;
}

export default CustomerForm;
