import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Glyphicon } from 'react-bootstrap';

export default class Loading extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.renderContaniner = this.renderContaniner.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.renderSpinner = this.renderSpinner.bind(this);
  }

  renderIcon () {
    const { size } = this.props;
    let iconKlass = "loading__icon glyphicon-refresh-animate";
    if(size) iconKlass = iconKlass + ` loading--${size}`;
    return(<Glyphicon glyph="refresh" className={iconKlass} />);
  }

  renderSpinner () {
    const { size } = this.props;
    let iconKlass;
    if(size) iconKlass = iconKlass + ` spinner--${size}`;
    return (
      <span className={`spinner ${iconKlass}`}>
        <span className="bounce1"></span>
        <span className="bounce2"></span>
        <span className="bounce3"></span>
      </span>
    );
  }

  renderContaniner () {
    const { iconStyle } = this.props;
    switch (iconStyle) {
      case 'loading':
        return this.renderIcon();
      case 'spinner':
        return this.renderSpinner();
      default:
        return null;
    }
  }

  render () {
    const { inline } = this.props;
    let containerKlass = 'loading';
    if(inline) return(
      <span className={containerKlass}>
        {this.renderContaniner()}
      </span>
    );
    const { position } = this.props;
    if(position) containerKlass = containerKlass + ` loading--${position}`;
    return (
      <Row>
        <Col xs={12} className={containerKlass}>
          { this.renderContaniner() }
        </Col>
      </Row>
    );
  }
}

Loading.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  position: PropTypes.oneOf(['left', 'center', 'right']),
  inline: PropTypes.bool,
  iconStyle: PropTypes.oneOf(['loading', 'spinner']),
};

Loading.defaultProps = {
  position: 'center',
  iconStyle: 'loading',
  inline: false,
};
