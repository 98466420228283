import "whatwg-fetch";
import { actionNames } from "constants.js";
import Csrf from "utils/Csrf";
import isBoolean from "lodash/isBoolean";
import { initialize, change } from "redux-form";
import { RX_ORDER as RX_ORDER_FORM_NAME } from "constants/reduxFormNames";
import {
  OPT_THICKNESS_FIELD,
  FRAME_TO_COME_FIELD
} from "constants/orderFields";
import getIn from "redux-form/lib/structure/plain/getIn";
import { updateFetchErrors } from "actions/fetchErrorsAction";
import FetchRetry from "utils/FetchRetry";

const changeOrder = change.bind(null, RX_ORDER_FORM_NAME);
export const initializeOrder = data => {
  return initialize(RX_ORDER_FORM_NAME, { order: data });
};

const autoSelectPreOptimized = (product, dispatch) => {
  const showPreOptimized = getIn(
    product,
    "properties.enabled_pre_optimized.show"
  );
  const requiredPreOptimized = getIn(
    product,
    "properties.enabled_pre_optimized.required"
  );
  if (
    isBoolean(showPreOptimized) &&
    showPreOptimized &&
    isBoolean(requiredPreOptimized) && requiredPreOptimized
  ) {
    dispatch(changeOrder(OPT_THICKNESS_FIELD, true));
  }
};

const autoSelectFrameToCome = (product, dispatch) => {
  const showFrameToCome = getIn(
    product,
    "properties.enabled_frame_to_come.show"
  );
  const requiredFrameToCome = getIn(
    product,
    "properties.enabled_frame_to_come.required"
  );
  if (
    isBoolean(showFrameToCome) &&
    showFrameToCome &&
    isBoolean(requiredFrameToCome) && requiredFrameToCome
  ) {
    dispatch(changeOrder(FRAME_TO_COME_FIELD, true));
  }
};

export const updateRxLens = order => {
  return {
    type: actionNames.UPDATE_RX_LENS,
    order
  };
};

export const storeProductRxLens = product => {
  return {
    type: actionNames.UPDATE_PRODUCT_RX_LENS,
    product
  };
};

export const updateRxLoading = loading => {
  return {
    type: actionNames.UPDATE_RX_LOADING,
    loading
  };
};

export const loadProductRxLens = (
  sku,
  order = {},
  loading,
  fetchName,
  callback = _product => {},
  beforeRetryCallback = () => {}
) => {
  return dispatch => {
    const clearBeforeFetch = () => {
      dispatch(updateRxLoading(loading));
    };
    clearBeforeFetch();
    const url = `/products/${sku}/enabled_fields`;
    FetchRetry(url, {
      fetchName,
      updateErrorType: (fetchName, errorType = null, options = {}) => {
        dispatch(updateFetchErrors(fetchName, errorType, options));
      },
      beforeRetryCallback: () => {
        beforeRetryCallback();
        clearBeforeFetch();
      },
      credentials: "same-origin",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        order,
        authenticity_token: Csrf.token()
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const product = json;
        dispatch(storeProductRxLens(product));
        autoSelectPreOptimized(product, dispatch);
        autoSelectFrameToCome(product, dispatch);
        setTimeout(() => {
          dispatch(updateRxLoading({}));
        }, 1000);
        return callback(product);
      })
      .catch(_ex => {});
  };
};
