import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import _ from "lodash";
import Csrf from "utils/Csrf";
import "whatwg-fetch";
import OrderDetails from "components/orders/OrderDetails";
import { Row, Col, Modal, Button, Panel, ListGroup } from "react-bootstrap";
import {
  OPTICAL_INFO_ATTR,
  REMOTE_SHAPE_FILE,
  ORIGINAL_SHAPE_FILE
} from "constants/mapOrderColumns";
import S3Uploaded from "utils/S3Uploaded";
import isFile from "utils/isFile";
import isArray from "lodash/isArray";
import merge from "lodash/merge";
import getIn from "redux-form/lib/structure/plain/getIn";
import setIn from "redux-form/lib/structure/plain/setIn";

export default class StockOrderConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  componentDidMount() {
    this.requestToConfirmData.bind(this)();
  }

  requestToConfirmData() {
    const { orders } = this.props;
    fetch(this.props.confirmUrl, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Csrf.token()
      },
      body: JSON.stringify({
        authenticity_token: Csrf.token(),
        orders: orders
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const order = json;
        let shapeFiles =
          getIn(orders[0], `${OPTICAL_INFO_ATTR}.${ORIGINAL_SHAPE_FILE}`) || [];
        if (isArray(shapeFiles)) shapeFiles = shapeFiles[0];
        shapeFiles = shapeFiles || {};
        if (isFile(shapeFiles))
          order[0].optical_informations.shape_file = shapeFiles;
        this.setState({ orders: order, showModal: true });
      })
      .catch(_ex => {
        this.props.afterShow();
        this.props.afterHidden();
      });
  }

  uploadFrameShape(files = []) {
    return new Promise((resolve, reject) => {
      const { s3SignUrl } = this.props;
      const shapeFiles = files;
      if (!isArray(shapeFiles)) return resolve(null);
      const shapeFile = shapeFiles[0];
      if (!isFile(shapeFile)) return resolve(null);
      S3Uploaded(shapeFile, s3SignUrl)
        .then(({ _s3Data, _file, fileUrl }) => {
          resolve(fileUrl);
        })
        .catch(ex => {
          Sentry.captureException(ex);
          reject(ex);
        });
    });
  }

  async sendOrders() {
    this.setState({ isSending: true });
    const { formUrl, formAction, orders, locales } = this.props;
    const body = { authenticity_token: Csrf.token() };
    let orderData = orders[0];

    try {
      const shapeFiles =
        getIn(orderData, `${OPTICAL_INFO_ATTR}.${ORIGINAL_SHAPE_FILE}`) || [];
      const fileUrl = await this.uploadFrameShape(shapeFiles);
      orderData = setIn(
        orderData,
        `${OPTICAL_INFO_ATTR}.${ORIGINAL_SHAPE_FILE}`,
        fileUrl
      );
      orderData = setIn(
        orderData,
        `${OPTICAL_INFO_ATTR}.${REMOTE_SHAPE_FILE}`,
        fileUrl
      );
    } catch (error) {
      Sentry.captureException(error);
      let sendFetchError = { errorProp: "uploadShapeFileFailureText" };
      sendFetchError = merge(locales.requestErrors, sendFetchError);
      return this.setState({ sendFetchError, isSending: false });
    }

    if (formAction == "PUT" || formAction == "put") {
      body.order = orderData;
    } else {
      body.orders = [orderData];
    }
    fetch(formUrl, {
      credentials: "same-origin",
      method: formAction,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Csrf.token()
      },
      body: JSON.stringify(body)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        window.location.href = json.location;
      })
      .catch(_ex => {
        this.setState({ isSending: false });
      });
  }

  _submitText() {
    if (!this.state.isSending) return this.props.locales.buttons.send;
    return (
      <div>
        {this.props.locales.buttons.send}
        <span
          className="glyphicon glyphicon-refresh glyphicon-refresh-animate"
          aria-hidden="true"
        ></span>
      </div>
    );
  }

  open() {
    this.setState({ showModal: true });
  }

  close() {
    this.setState({ showModal: false });
  }

  render() {
    const { orders, isSending, showModal } = this.state;
    const { afterShow, afterHidden, locales } = this.props;
    return (
      <Modal
        show={showModal}
        bsSize="large"
        backdrop="static"
        keyboard={false}
        onEntered={afterShow}
        onExited={afterHidden}
      >
        <Modal.Header>
          <Modal.Title>{locales.page.modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Panel>
            <Panel.Body>
              <ListGroup>
                {_.map(orders, (order, index) => {
                  return (
                    <OrderDetails key={index} order={order} locales={locales} />
                  );
                })}
              </ListGroup>
            </Panel.Body>
          </Panel>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xsOffset={7} xs={2}>
              <Button
                onClick={this.close.bind(this)}
                disabled={isSending}
                block
              >
                {locales.buttons.edit}
              </Button>
            </Col>
            <Col xs={3}>
              <Button
                bsStyle="primary"
                onClick={this.sendOrders.bind(this)}
                disabled={isSending}
                block
              >
                {this._submitText.bind(this)()}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

StockOrderConfirmModal.propTypes = {
  formId: PropTypes.string.isRequired,
  confirmUrl: PropTypes.string.isRequired,
  afterShow: PropTypes.func,
  afterHidden: PropTypes.func,
  locales: PropTypes.object,
  formUrl: PropTypes.string,
  formAction: PropTypes.string,
  s3SignUrl: PropTypes.string.isRequired,
  // redux store
  orders: PropTypes.array.isRequired
};

StockOrderConfirmModal.defaultProps = {
  afterHidden: _e => {},
  afterShow: _e => {}
};
