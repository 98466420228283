import React, { FC } from "react";
import { ComponentProps, Product, CartItem } from "./types";
import { Row, Col, Panel, Button, FormControl } from "react-bootstrap";

export const CartList: FC<ComponentProps & {
  updateItemQuantity: (idx: number, q: number) => void;
}> = props => {
  const { children, cart, updateItemQuantity } = props;

  const cartItemsData = cart.items.map((item, idx) => ({
    key: `cart-${idx}`,
    sapCode: item.sapCode,
    properties: item.properties,
    quantity: item.quantity,
    removeFn: () => {
      updateItemQuantity(idx, 0);
    },
    updateQuantityFn: ev => {
      updateItemQuantity(idx, ev.target.value);
    }
  }));

  return (
    <div>
      {children}
      {cartItemsData.filter(d => d.quantity > 0).map(d => (
        <Panel key={d.key} bsStyle="info">
          <Panel.Body>
            <Row>
              <Col xs={7}>
                {d.sapCode}
                <br />
                {
                  ["sph", "cyl", "base", "add", "curve", "side"].filter(power => {
                    return d.properties[power] != null
                  }).map(power => {
                    return `${power}: ${d.properties[power]}`
                  }).join(" / ")
                }
              </Col>
              <Col xs={3}>
                <FormControl
                  type="number"
                  min="1"
                  value={d.quantity}
                  onChange={d.updateQuantityFn}
                />
              </Col>
              <Col xs={2} className="pull-right">
                <Button onClick={d.removeFn} bsStyle="danger">
                  <span className="glyphicon glyphicon-trash" />
                </Button>
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
      ))}
    </div>
  );
};
