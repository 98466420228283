export const ORDER_ID = "id";
export const AGENT_ID = "user_id";
export const SKU = "sku";
export const PATIENT = "patient";
export const REMARK = "remark";
export const PRIVATE_NOTE = "private_note";
export const NOTE_ON_LABEL = "note_on_label";

export const LEFT_SIDE = "left_lens_attributes";
export const RIGHT_SIDE = "right_lens_attributes";
export const LENS_ID = "id";
export const QUANTITY = "quantity";
export const SPH = "sphere";
export const CYL = "cylinder";
export const ADD = "addition";
export const AXIS = "axis";
export const FITTING_HEIGHT = "fitting";
export const SEG_HEIGHT = "seg";
export const CVD = "cvd";
export const FFA = "ffa";
export const PTA = "pta";
export const FAR_PD = "far_pd";
export const NEAR_PD = "near_pd";
export const DECENTER = "decenter";
export const DECENTER_X = "decenter_x";
export const DECENTER_Y = "decenter_y";
export const PRISM = "prism";
export const PRISM_H = "prism_h";
export const PRISM_H_VALUE = "prism_h_value";
export const PRISM_V = "prism_v";
export const PRISM_V_VALUE = "prism_v_value";
export const INDEX = "index";
export const DESIGN = "design";
export const TEST_ID = "test_id";
export const GLAZING = "glazing";
export const A = "hbox";
export const B = "vbox";
export const DBL = "dbl";
export const ET = "et";

export const OPTICAL_INFO_ATTR = "optical_informations_attributes";
export const OPTICAL_ID = "id";
export const CORRIDOR = "corridor";
export const TINT = "tint_value_id";
export const TINT_VALUE = "tint_percentage";
export const UV = "uv";
export const FLASH_MIRROR = "flash_mirror_id";
export const CUTTING = "cutting";
export const OPT_THICKNESS = "opt_thickness";
export const FRAME_TYPE = "frame_type";
export const FRAME_MATERIAL = "frame_material";
export const FRAME_MATERIAL_ID = "frame_material_id";
export const FRAME_SHAPE = "frame_shape_id";
export const FRAME_ID = "frame_id";
export const FRAME_LIBRARY = "frame_library";
export const FRAME_LIBRARY_ID = "frame_library_id";
export const FRAME_TO_COME = "frame_to_come";
export const REMOTE_SHAPE_FILE = "remote_shape_file_url";
export const ORIGINAL_SHAPE_FILE = "original_shape_file_url";
export const ORIGINAL_SHAPE_FILE_NAME = "original_shape_file_name";
export const FRAME_SHAPE_VALID = "frame_shape_valid";
export const SHAPE_FILE = "shape_file_url";
export const SHAPE_TYPE = "shape_type";
export const ROUND_SHAPE = "round_shape";
export const ROUND_DIA = "dia";
export const DIA = "dia";
export const OVAL_SHAPE = "oval_shape";
export const SHARP_EDGE = "sharp_edge";
export const INSET_TYPE = "inset_type";
export const READING_DISTANCE = "reading_dis";
export const INSET = "inset";
export const DEGRESSION = "degression";

export const EXPRESS = "express";

// frameType:
export const FULL_RIM_OPTION = "Full Rim Frame";
export const NYLON_OPTION = "Nylon Frame";
export const RIM_LESS_OPTION = "Rim Less Frame";
// shapeType:
export const ROUND_OPTION = "Round Shape";
export const OVAL_OPTION = "Oval Shape";
// insetType:
export const READING_DISTANCE_OPTION = "Customized Reading Distance";
export const CUSTOMIZE_INSET_OPTION = "Customized Inset";
// glazing:
export const CUT = "Cut";
export const UNCUT = "UnCut";
export const CUT_MOUNT = "Cut & Mount";
// round shape type:
export const STANDARD_OPTION = "Standard";
export const REDUCED_OPTION = "Reduced";

//
export const PATIENT_FIELD = `order[${PATIENT}]`;
export const AGENT_ID_FIELD = `order[${AGENT_ID}]`;
export const SKU_FIELD = `order[${SKU}]`;
