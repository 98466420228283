import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { CORRIDOR_FIELD } from '../../../../constants/orderFields';
import SelectInput from '../../../default_redux/SelectInput';
import { required } from '../../../../utils/Validators';

export default class Corridor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    if(!this.props.show) return null;
    // console.log('Render Corridor');
    return (
      <Field component={SelectInput}
        name={CORRIDOR_FIELD}
        label={this.props.label}
        options={
          this.props.values.map((value) => {
            return({
              name: value.toString(),
              value: value
            });
          })
        }
        selectFirst={true}
        labelClass='col-xs-3'
        selectWrapperClass='col-xs-6'
        required={this.props.required}
        validate={[required.bind(null, this.props.required)]}
      />
    );
  }
}
Corridor.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  values: PropTypes.array
};

Corridor.defaultProps = {
  label: 'Corridor',
  show: false,
  required: false,
  values: []
};
