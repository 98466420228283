import "whatwg-fetch";
import { actionNames } from "constants.js";
import { storeProductRxLens } from "actions/rxLensAction";
import { updateFetchErrors } from "actions/fetchErrorsAction";
import qs from "qs";
import FetchRetry from "utils/FetchRetry";
import GenUrl from "utils/GenUrl";

export const updateRxLensFilter = filter => {
  return {
    type: actionNames.UPDATE_RX_LENS_FILTER,
    filter
  };
};

export const allowPowerEditing = allowed => {
  return {
    type: actionNames.ALLOW_POWER_EDITING,
    allowed
  };
};

export const allowProductSelecting = allowed => {
  return {
    type: actionNames.ALLOW_PRODUCT_SELECTING,
    allowed
  };
};

export const searchingProducts = searching => {
  return {
    type: actionNames.SEARCHING_PRODUCTS,
    searching
  };
};

export const searchingIndexesDesigns = searching => {
  return {
    type: actionNames.SEARCHING_INDEXES_DESIGNS,
    searching
  };
};

export const storeProducts = (url, response) => {
  return storeProductsResponse(url, response);
};

export const storeProductsResponse = (url, response) => {
  return {
    type: actionNames.STORE_RX_PRODUCTS_RESPONSE,
    productsResponse: {
      url,
      response: response || {}
    }
  };
};

export const searchProductsByPower = (
  productsUrl,
  filter = {},
  beforeSearch = _dispatch => {},
  callback = _json => {},
  beforeRetryCallback = _json => {}
) => {
  return dispatch => {
    const fetchName = "searchProductsByPower";
    beforeSearch(dispatch);
    const url = GenUrl(productsUrl, filter);
    FetchRetry(url, {
      fetchName,
      updateErrorType: (fetchName, errorType = null, options = {}) => {
        dispatch(updateFetchErrors(fetchName, errorType, options));
      },
      beforeRetryCallback: () => {
        beforeSearch();
        beforeRetryCallback();
      },
      credentials: "same-origin",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const products = json.data;
        dispatch(storeProducts(url, json));
        return callback(products);
      })
      .catch(_ex => {});
  };
};

export const storeAvailabeDesignsMaterials = availabeDesignsMaterials => {
  return {
    type: actionNames.STORE_AVAILABLE_DESIGNS_MATERIALS,
    availabeDesignsMaterials
  };
};

export const searchAvailableDesignsMaterials = (
  availableDesignsMaterialsUrl,
  filter = {},
  fetchCallback = _json => {},
  fetchFailed = () => {}
) => {
  return dispatch => {
    const fetchName = "availableDesignsMaterials";
    const clearBeforeFetch = () => {
      dispatch(storeProductRxLens(null));
      dispatch(storeProducts(null));
    };
    clearBeforeFetch();
    const params = qs.stringify(filter, { arrayFormat: "brackets" });
    const url = `${availableDesignsMaterialsUrl}&${params}`;
    FetchRetry(url, {
      fetchName,
      updateErrorType: (fetchName, errorType = null, options = {}) => {
        dispatch(updateFetchErrors(fetchName, errorType, options));
      },
      beforeRetryCallback: () => {
        fetchFailed();
        clearBeforeFetch();
      },
      credentials: "same-origin",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const availableDesignsMaterials = json.data;
        dispatch(storeAvailabeDesignsMaterials(availableDesignsMaterials));
        fetchCallback(availableDesignsMaterials);
      })
      .catch(_ex => {});
  };
};

export const updateFilterStep = filterStep => {
  return {
    type: actionNames.UPDATE_FILTER_STEP,
    filterStep
  };
};
