import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import isPresent from '../../../../utils/isPresent';
import { Field } from 'redux-form';
import {
  OPT_THICKNESS_FIELD,
} from '../../../../constants/orderFields';
import SwitchInput from '../../../default_redux/SwitchInput';
import FetchError from '../../../shared/FetchError';

export default class PreOptThickness extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange (state) {
    const { loadProductRxLens } = this.props;
    loadProductRxLens({
      fetchName: 'optThicknessChange',
      optThickness: state,
      loadingframeShapeField: true
    });
  }

  render () {
    const { show, required, label, fetchError, locales } = this.props;
    if(!show) return null;
    return (
      <div>
        <Field component={SwitchInput}
          name={OPT_THICKNESS_FIELD}
          label={label}
          labelClass="col-xs-3"
          inputWaraperClass="col-xs-6"
          onText="Yes"
          offText="No"
          required={required}
          readonly={required}
          handleChange={this.handleChange.bind(this)}
          enabled={!isPresent(fetchError)}
        />
        {
          fetchError && (
            <Row>
              <Col xsOffset={3} xs={9}>
                <FetchError error={fetchError} {...locales.requestErrors} />
              </Col>
            </Row>
          )
        }
      </div>
    );
  }
}

PreOptThickness.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  changeOrder: PropTypes.func,
  loadProductRxLens: PropTypes.func,
  fetchError: PropTypes.object,
  locales: PropTypes.object,
};

PreOptThickness.defaultProps = {
  label: "Pre-optimized Thickness",
  show: false,
  loadProductRxLens: () => {},
};
