import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SwitchInput from '../../../default_redux/SwitchInput';
import { EXPRESS_FILED } from '../../../../constants/orderFields';

export default class RxOptionExpress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render () {
    if(!this.props.show) return null;
    const { label } = this.props;
    return (
      <Field component={SwitchInput}
        name={EXPRESS_FILED}
        label={label}
        labelClass="col-xs-3"
        inputWaraperClass="col-xs-6"
        onText="Yes"
        offText="No"
      />
    );
  }
}

RxOptionExpress.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool
};

RxOptionExpress.defaultProps = {
  label: 'Express',
  show: false
};
