import React from 'react';
import PropTypes from 'prop-types';
import isPresent from '../../../../utils/isPresent';
import Select from 'react-select';
import {
  FRAME_SHAPE_FIELD,
  FRAME_LIBRARY_FIELD,
  FRAME_SHAPE_VALID_FIELD,
  ORIGINAL_SHAPE_FILE_FIELD,
  ORIGINAL_SHAPE_FILE_NAME_FIELD
} from '../../../../constants/orderFields';

class SelectFrameLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.isSelectFirst = this.isSelectFirst.bind(this);
    this.selectFirstValue = this.selectFirstValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount () {
    const { initValue, input: { value: inputValue } } = this.props;
    const targetValue = inputValue || initValue;
    const firstValue = this.selectFirstValue(targetValue);
    if(isPresent(firstValue) && firstValue != targetValue) {
      const e = { value: firstValue };
      this.handleChange(e);
    }
  }

  componentWillUnmount() {
    const { input: { onChange } } = this.props;
    onChange(null);
  }

  handleChange(e) {
    const value = (e || {}).value || null;
    const name = (e || {}).name || null;
    const {
      loadProductRxLens,
      change: changeOrder,
      input: { onChange }
    } = this.props;

    onChange(value);
    changeOrder(FRAME_LIBRARY_FIELD, name);

    changeOrder(ORIGINAL_SHAPE_FILE_FIELD, null);
    changeOrder(ORIGINAL_SHAPE_FILE_NAME_FIELD, null);
    changeOrder(FRAME_SHAPE_FIELD, null);
    changeOrder(FRAME_SHAPE_VALID_FIELD, isPresent(value));

    loadProductRxLens({ frameLibraryId: value });
  }

  handleFocus (e) {
    const { multiLoadUrl } = this.props;
    if(!multiLoadUrl) return e;
    const { name } = this.props.input;
    this.refs[name].loadOptions('');
  }

  render () {
    let { value } = this.props.input;
    if(!isPresent(value)) value = null;
    const {
      disabled, required,
      selectClass, blankDisplay,
      options, input,
      input: {
        onBlur, onFocus
      }
    } = this.props;

    return (
      <Select
        className={selectClass}
        options={options}
        placeholder={blankDisplay}
        labelKey="name"
        disabled={disabled}
        clearable={!required}
        menuContainerStyle={{zIndex: 5}}
        {...input}
        onBlur={() => onBlur(value)}
        onFocus={(e) => onFocus(e)}
        onChange={this.handleChange}
        value={value || this.selectFirstValue() || ''}
        style={{ marginBottom: '10px' }}
      />
    );
  }

  convertOptions () {
    const transformOptions = [];
    this.props.options.map((option)=>{
      let newOption = {
        value: option.value,
        label: option.name
      };
      transformOptions.push(newOption);
    });
    return transformOptions;
  }

  isSelectFirst () {
    // if(this.props.options.length <= 1) return true
    return this.props.selectFirst;
  }

  selectFirstValue (value) {
    if(!this.isSelectFirst()) return null;
    const { options } = this.props;
    let firstValue = value;
    if(!isPresent(value)) {
      if(options.length > 0) firstValue = options[0].value;
    }
    return firstValue;
  }

}

SelectFrameLibrary.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ]).isRequired
    })
  ),
  initValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  handleChange: PropTypes.func,
  change: PropTypes.func,
  selectFirst: PropTypes.bool,
  liveSearch: PropTypes.bool,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  name: PropTypes.string,
  selectName: PropTypes.string,
  selectWrapperClass: PropTypes.string,
  selectClass: PropTypes.string,
  formWrapperClass: PropTypes.string,
  blankDisplay: PropTypes.string,
  showLabel: PropTypes.bool,
  suffixAddon: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  loadOptions: PropTypes.func,
  multiLoadUrl: PropTypes.bool,

  // Redux Form
  input: PropTypes.object,
  onChange: PropTypes.func,
  meta: PropTypes.object,
};

SelectFrameLibrary.defaultProps = {
  options: [],
  initValue: null,
  handleChange: (_e) => {},
  selectFirst: false,
  liveSearch: true,
  selectName: '',
  label: '',
  labelClass: '',
  selectWrapperClass: '',
  selectClass: '',
  formWrapperClass: '',
  blankDisplay: 'Frame Library',
  showLabel: true,
  required: false,
  disabled: false,
  multiLoadUrl: false,

  // Redux Form
  input: {},
};

export default SelectFrameLibrary;
