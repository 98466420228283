import React from 'react';
import PropTypes from 'prop-types';
import InputForm from 'components/default_redux/InputForm';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null
    };
  }

  componentWillUnmount() {
    const { input: { onChange } } = this.props;
    onChange(null);
  }

  onChange(date) {
    this.setState({ date });
    const { dateFormat } = this.props;
    const value = date ?  moment(date).format(dateFormat) : null;
    this.props.input.onChange(value);
  }

  render () {
    const {
      label, input, formClass, inputClass,
      labelClass, disabled, meta,
      suffixAddon, showLabel, helpText,
      required
    } = this.props;
    const { date } = this.state;
    return (
      <InputForm
        label={label}
        labelFor={input.name}
        formClass={formClass}
        inputClass={inputClass}
        labelClass={labelClass}
        validationState={
          (!disabled && (meta.touched && meta.invalid)) ? 'error' : null
        }
        suffixAddon={suffixAddon}
        showLabel={showLabel}
        helpText={helpText}
        required={required}
      >
        <DatePicker
          name={input.name}
          selected={date}
          onChange={this.onChange.bind(this)}
          onBlur={() => input.onBlur(input.value)}
          onFocus={(e) => input.onFocus(e)}
          dateFormat="dd/MM/yyyy"
          autoComplete='off'
          className="form-control"
        />
      </InputForm>
    );
  }
}

DateInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.any,
  afterOnChange: PropTypes.func,
  suffixAddon: PropTypes.string,
  formClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  readOnly: PropTypes.bool,
  dateFormat: PropTypes.string,

  // Redux Form
  validate: PropTypes.array,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  input: PropTypes.object,
};
export default DateInput;
