import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";

const isFile = (file = {}) => {
  const { processing, name } = file;
  if (isBoolean(processing)) return true;
  if (!isEmpty(name)) return true;
  return false;
};

export default isFile;
