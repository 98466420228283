import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-bootstrap-switch';

export default class ToggleStatus extends React.Component {

  constructor(props) {
    super();

    this.state = {
      disabled: props.disabled,
      value: props.defaultValue,
    };
    this.setSwitchState = this.setSwitchState.bind(this);
  }

  onSwitchClicked(e, state) {
    const nextStatus = state ? "enabled" : "disabled";
    this.setState({ disabled: true });
    this.updateUserStatus(nextStatus);
  }

  updateUserStatus(status) {
    this.setState({ disabled: true });

    const headers = {
      credentials: 'same-origin',
      method: this.props.ajaxMethod,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({status})
    };

    fetch(this.props.ajaxUrl, headers)
      .then(response => response.json())
      .then(json => this.setSwitchState(json.status == "enabled"))
      .catch(_e => this.setSwitchState(this.state.value));
  }

  setSwitchState(state) {
    setTimeout(()=>{
      this.setState({ value: state, disabled: false });
    }, 1000);
  }

  render() {
    return (
      <Switch
        {...this.props}
        animate={true}
        bsSize={this.props.size}
        disabled={this.state.disabled}
        // value={this.state.value}
        onChange={this.onSwitchClicked.bind(this)}
      />
    );
  }
}

ToggleStatus.propTypes = {
  ajaxMethod: PropTypes.oneOf(["POST", "PUT", "PATCH"]),
  ajaxUrl: PropTypes.string,
  disabled: PropTypes.bool,
  offText: PropTypes.string,
  onText: PropTypes.string,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  size: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool.isRequired,
};

ToggleStatus.defaultProps = {
  disabled: false,
  onText: 'Y',
  offText: 'N',
  size: 'mini',
  onColor: 'success',
  offColor: 'default',
};
