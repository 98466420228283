import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  STANDARD_OPTION, REDUCED_OPTION,
} from '../../../../constants/mapOrderColumns';
import {
  ROUND_SHAPE_FIELD, ROUND_DIA_FIELD
} from '../../../../constants/orderFields';
import { Row, Col } from 'react-bootstrap';
import SelectInput from '../../../default_redux/SelectInput';
import TextInput from '../../../default_redux/TextInput';
import {
  required as requiredValidate,
  max as maxValidate,
  min as minValidate,
  period as periodValidate
} from '../../../../utils/Validators';

export default class RoundShape extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.reducedDia = this.reducedDia.bind(this);
    this.standardDia = this.standardDia.bind(this);
    this.roundTypeChange = this.roundTypeChange.bind(this);
    this.renderDia = this.renderDia.bind(this);
  }

  standardDia () {
    return this.props.max;
  }

  reducedDia () {
    const { min, max } = this.props;
    return { min: min, max: max - 1 };
  }

  roundTypeChange (e) {
    const { changeOrder, max: standardDia } = this.props;
    const currentRoundType = e.target.value;
    if(currentRoundType == STANDARD_OPTION) {
      changeOrder(ROUND_DIA_FIELD, standardDia);
    } else {
      changeOrder(ROUND_DIA_FIELD, null);
    }
  }

  renderDia () {
    const {
      roundType,
      min: minValue,
      max: maxValue,
      period
    } = this.props;

    const fieldOptions = {};
    if(roundType == STANDARD_OPTION) {
      fieldOptions.defaultValue = this.standardDia();
      fieldOptions.disabled = true;
    }
    if(roundType == REDUCED_OPTION) {
      const reducredDia = this.reducedDia();
      fieldOptions.suffixAddon = `${reducredDia.min}-${reducredDia.max}`;
    }
    const starndardDia = this.standardDia();
    return(
      <Field component={TextInput}
        name={ROUND_DIA_FIELD}
        defaultValue={starndardDia}
        labelClass=""
        inputClass="col-xs-12"
        showLabel={false}
        required={true}
        {...fieldOptions}
        validate={[
          requiredValidate.bind(null, true),
          minValidate.bind(null, minValue),
          maxValidate.bind(null, maxValue),
          periodValidate.bind(null, period)
        ]}
      />
    );
  }

  render () {
    const { show, label } = this.props;
    if(!show) return null;
    // console.log(`Render ${label}`);
    return (
      <Row>
        <Col xsOffset={3} xs={3}>
          <Field component={SelectInput}
            name={ROUND_SHAPE_FIELD}
            label={label}
            options={
              [
                { name: STANDARD_OPTION, value: STANDARD_OPTION },
                { name: REDUCED_OPTION, value: REDUCED_OPTION },
              ]
            }
            handleChange={this.roundTypeChange}
            selectFirst={true}
            labelClass=""
            selectWrapperClass="col-xs-12"
            showLabel={false}
            required={true}
          />
        </Col>
        <Col xs={3}>
          { this.renderDia() }
        </Col>
      </Row>
    );
  }
}

RoundShape.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  period: PropTypes.number,
  changeOrder: PropTypes.func.isRequired,
  roundType: PropTypes.oneOf([STANDARD_OPTION, REDUCED_OPTION]),
};

RoundShape.defaultProps = {
  label: "Round Shape Type",
  show: false,
};
