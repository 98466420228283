import React, { Component } from "react";
import _ from "lodash";
import {
  createStore,
  bindActionCreators,
  applyMiddleware,
  compose
} from "redux";
import thunk from "redux-thunk";
import { connect, Provider } from "react-redux";
import { stockLensForm as stockLens } from "reducers";
import * as stockLensActions from "actions/stockLensAction";
import { LEFT_SIDE, RIGHT_SIDE } from "constants/mapOrderColumns";
// import update from 'immutability-helper';
import LensOrderForm from "components/ordering/stock_lens/LensOrderForm";

const mapStateToProps = (state, props) => {
  const orders = _.map(
    (state.form.stockOrder || { values: {} }).values.orders,
    order => {
      return _.pick(order, "sku");
    }
  );
  const { order } = props;
  const currentFilters = state.stockLens.currentFilters || {
    [RIGHT_SIDE]: {},
    [LEFT_SIDE]: {}
  };
  return {
    orders,
    order,
    currentFilters
  };
};

const mapDispatchToProps = dispatch => {
  return { actions: bindActionCreators(stockLensActions, dispatch) };
};

const NewStockLens = connect(
  mapStateToProps,
  mapDispatchToProps
)(LensOrderForm);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(stockLens, composeEnhancers(applyMiddleware(thunk)));

export default class NewStockLensForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Provider store={store}>
        <NewStockLens {...this.props} />
      </Provider>
    );
  }
}
