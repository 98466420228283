import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isPresent from 'utils/isPresent';
import { Row, Col, Label, Button, Glyphicon } from 'react-bootstrap';
import ExportCSVButton from 'components/orders/OrdersExportCSV';
import SearchButton from 'components/orders/OrdersSearch';

class OrderTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.renderFilterInfo = this.renderFilterInfo.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
    this.agentName = this.agentName.bind(this);
    this.orderStatusName = this.orderStatusName.bind(this);
    this.orderTypeName = this.orderTypeName.bind(this);
  }

  renderLabel (value) {
    return (
      <span><Label bsStyle="default">{value}</Label>&nbsp;</span>
    );
  }

  agentName (_userId) {
    const { agent } = this.props;
    return `${agent.roles[0]} :: ${agent.company || agent.username}`;
  }

  orderStatusName (value) {
    const { orderStatuses } = this.props;
    const status = find(orderStatuses, ['value', value]);
    return status.name;
  }

  orderTypeName (value) {
    const { orderTypes } = this.props;
    const type = find(orderTypes, ['value', value]);
    return type.name;
  }

  renderFilterInfo () {
    const {
      initFilter: filters,
      formUrl,
      sources: { searchAgentsUrl },
      locales: {
        page: {
          filter: filterText,
          all: allText,
        },
        buttons: {
          edit: editText,
          clear: clearText
        }
      }
    } = this.props;
    if(!isPresent(filters)) return (
      <p style={{marginTop: '14px'}}>
        {filterText}:&nbsp;{allText}
      </p>
    );
    return (
      <p style={{marginTop: '14px'}}>
        {filterText}:&nbsp;
        {isPresent(filters.patient) ? this.renderLabel(filters.patient) : null}
        {isPresent(filters.code) ? this.renderLabel(filters.code) : null}
        {isPresent(filters.user_id) ? this.renderLabel(this.agentName(filters.user_id)) : null}
        {isPresent(filters.type) ? this.renderLabel(this.orderTypeName(filters.type)) : null}
        {isPresent(filters.status) ? this.renderLabel(this.orderStatusName(filters.status)) : null}
        {isPresent(filters.start_date) ? this.renderLabel(filters.start_date) : null}
        {isPresent(filters.end_date) ? this.renderLabel(filters.end_date) : null}
        <SearchButton
          {...this.props}
          searchUrl={formUrl}
          searchAgentsUrl={searchAgentsUrl}
          customButton={(open, _showModal) => {
            return(
              <a onClick={open}>{editText}</a>
            );
          }}/>
        &nbsp;|&nbsp;
        <a href={formUrl}>{clearText}</a>
      </p>
    );
  }

  render () {
    const {
      formUrl, csvUrl,
      sources: { searchAgentsUrl },
      locales: {
        buttons: {
          search: searchText
        }
      }
    } = this.props;
    return(
      <Row>
        <Col xs={8}>
          { this.renderFilterInfo() }
        </Col>
        <Col xs={4}>
          <p className="text-right">
            <SearchButton
              {...this.props}
              searchUrl={formUrl}
              searchAgentsUrl={searchAgentsUrl}
              customButton={(open, showModal) => {
                return(
                  <Button
                    onClick={open}
                    disabled={showModal} >
                    <Glyphicon glyph="search" />
                    &nbsp;{searchText}
                  </Button>
                );
              }}/>
            &nbsp;&nbsp;
            <ExportCSVButton
              {...this.props}
              csvUrl={csvUrl}
              searchAgentsUrl={searchAgentsUrl} />
          </p>
        </Col>
      </Row>
    );
  }
}

OrderTools.propTypes = {
  formUrl: PropTypes.string,
  csvUrl: PropTypes.string,
  agent: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    company: PropTypes.string,
    role: PropTypes.string
  }),
  orderTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ]).isRequired
    })
  ),
  orderStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ]).isRequired
    })
  ),
  sources: PropTypes.shape({
    searchAgentsUrl: PropTypes.string.isRequired,
  }),
  locales: PropTypes.shape({
    page: PropTypes.shape({
      filter: PropTypes.string,
      all: PropTypes.string,
    }).isRequired,
    modal: PropTypes.shape({
      title: PropTypes.shape({
        search: PropTypes.string,
        export: PropTypes.string,
      }).isRequired
    }).isRequired,
    buttons: PropTypes.shape({
      search: PropTypes.string,
      export: PropTypes.string,
      edit: PropTypes.string,
      clear: PropTypes.string,
    }).isRequired
  }).isRequired,

  // Redux form
  initFilter: PropTypes.object,
  createdPeriod: PropTypes.shape({
    form: PropTypes.string,
    to: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  submitSucceeded: PropTypes.bool,

};

OrderTools.defaultProps = {
};

export default OrderTools;
