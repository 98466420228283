import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal, Button, Panel } from "react-bootstrap";
import OrderDetails from "components/orders/OrderDetails";
import { BulkOrderDetails } from "components/orders/BulkOrderDetails";
import OrdersPrinter from "components/orders/OrdersPrinter";
import Loading from "components/shared/Loading";
import FetchError from "components/shared/FetchError";
import FetchRetry from "utils/FetchRetry";
import isPresent from "utils/isPresent";
import OrderEditButton from "components/orders/OrderEditButton";
import OrderCancelButton from "components/orders/OrderCancelButton";

export default class OrderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showed: false,
      order: null,
      loading: true
    };
  }

  loadOrder() {
    FetchRetry(this.props.resources.orderUrl, {
      fetchName: "getOrder",
      beforeRetryCallback: () => {
        this.setState({ order: null, getOrderFetchError: null });
      },
      updateErrorType: (_fetchName, errorType, options = {}) => {
        if (isPresent(errorType)) {
          const getOrderFetchError = options;
          getOrderFetchError.type = errorType;
          this.setState({ getOrderFetchError });
        }
      },
      credentials: "same-origin"
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        const order = json;
        this.setState({ order, getOrderFetchError: null });
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1500);
      })
      .catch(_ex => {});
  }

  showModal(e) {
    e.preventDefault();
    this.setState({ showed: true });
    this.loadOrder.bind(this)();
    return false;
  }

  closeModal() {
    this.setState({ showed: false });
  }

  renderOrderDetails() {
    const { locales } = this.props;
    const { order, getOrderFetchError } = this.state;
    if (isPresent(getOrderFetchError))
      return (
        <FetchError error={getOrderFetchError} {...locales.requestErrors} />
      );
    if (!isPresent(order)) return <Loading size="lg" position="center" />;
    if (order.bulk) {
      return <BulkOrderDetails order={order} locales={locales} />;
    } else {
      return <OrderDetails order={order} locales={locales} />;
    }
  }

  render() {
    const {
      resources: { orderHTMLUrl, orderPrintUrl, editOrderUrl, cancelOrderUrl },
      locales,
      editButton: editButtonProps,
      cancelButton: cancelButtonProps
    } = this.props;
    const { order, showed } = this.state;
    return (
      <div>
        <a href={orderHTMLUrl} onClick={this.showModal.bind(this)}>
          {locales.page.button}
        </a>
        <Modal show={showed} onHide={this.closeModal.bind(this)} bsSize="lg">
          <Modal.Header closeButton>
            <Modal.Title>{locales.page.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Panel>
              <Panel.Body>{this.renderOrderDetails.bind(this)()}</Panel.Body>
            </Panel>
          </Modal.Body>
          {isPresent(order) && (
            <Modal.Footer>
              <Row>
                <Col xsOffset={3} xs={9}>
                  <Row>
                    <Col xs={3}>
                      <OrderCancelButton
                        url={cancelOrderUrl}
                        {...cancelButtonProps}
                        block={true}
                      />
                    </Col>
                    <Col xs={3}>
                      <OrderEditButton
                        url={editOrderUrl}
                        {...editButtonProps}
                        block={true}
                      />
                    </Col>
                    <Col xs={3}>
                      <OrdersPrinter
                        url={orderPrintUrl}
                        label={locales.buttons.print}
                        bsStyle="default"
                        block={true}
                      />
                    </Col>
                    <Col xs={3}>
                      <Button
                        block
                        ref="cancelBtn"
                        onClick={this.closeModal.bind(this)}
                      >
                        {locales.buttons.close}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}

OrderModal.propTypes = {
  button: PropTypes.string,
  title: PropTypes.string,
  resources: PropTypes.shape({
    orderUrl: PropTypes.string.isRequired,
    orderHTMLUrl: PropTypes.string.isRequired,
    orderPrintUrl: PropTypes.string.isRequired
  }),
  locales: PropTypes.shape({
    buttons: PropTypes.shape({
      close: PropTypes.string.isRequired,
      print: PropTypes.string.isRequired
    }).isRequired,
    page: PropTypes.shape({
      button: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  editButton: PropTypes.object,
  cancelButton: PropTypes.object
};

OrderModal.defaultProps = {
  button: "View",
  title: "Order Details",
  locales: {
    buttons: {
      back: "Back"
    }
  }
};
