import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SelectInput from 'components/default_redux/SelectInput';
import { required } from 'utils/Validators';

export default class FrameMaterial extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    if(!this.props.show) return null;
    const { options, label, name } = this.props;
    return (
      <Field component={SelectInput}
        name={name}
        label={label}
        options={options}
        labelClass='col-xs-3'
        selectWrapperClass='col-xs-6'
        required={true}
        validate={[required.bind(null, true)]}
        placeholder="Frame Material"
        {...this.props}
      />
    );
  }
}
FrameMaterial.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  show: PropTypes.bool,
  options: PropTypes.array,

  // Redux Form
  input: PropTypes.object,
};

FrameMaterial.defaultProps = {
  label: "Frame Material",
  show: false,
  options: []
};
