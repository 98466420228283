import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

class InitSentry extends React.PureComponent {

  componentDidMount() {
    const { dsn, environment } = this.props;
    Sentry.init({ dsn, environment });
  }

  render() {
    return null;
  }
}

InitSentry.propTypes = {
  dsn: PropTypes.string,
  environment: PropTypes.string
};

export default InitSentry;
