import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FAR_PD } from '../../../../constants/mapOrderColumns';
import TextInput from '../../../default_redux/TextInput';
import { required, min, max } from '../../../../utils/Validators';

export default class FarPD extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render () {
    if(!this.props.show) return null;
    // console.log(`Render ${this.props.label}`);
    return (
      <div>
        <Field component={TextInput}
          ref="farPd"
          label={this.props.label}
          type="number"
          step="any"
          name={`order${this.props.attrNestedName}[${FAR_PD}]`}
          suffixAddon={`${this.props.min}-${this.props.max} mm.`}
          inputClass="col-xs-8"
          labelClass="col-xs-4"
          required={this.props.required}
          validate={[
            required.bind(null, this.props.required),
            min.bind(null, this.props.min),
            max.bind(null, this.props.max),
          ]}
        />
        { this.props.children }
      </div>
    );
  }
}

FarPD.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  attrNestedName: PropTypes.string,

  children: PropTypes.any,
};

FarPD.defaultProps = {
  label: "Far PD",
  show: false,
  required: false,
  min: 0,
  max: 0,
  attrNestedName: '',
};
