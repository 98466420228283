import React from 'react';
import PropTypes from 'prop-types';
// import * as Bootstrap from 'react-bootstrap';
import LenOrderForm from 'components/ordering/stock_lens/LenOrderForm';

export default class LensOrdersManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.addNewOrder = this.addNewOrder.bind(this);
    this.removeNewOrder = this.removeNewOrder.bind(this);
  }

  addNewOrder () {
    this.props.fields.push({});
  }

  removeNewOrder (index) {
    this.props.fields.remove(index);
  }

  getOrder (index) {
    return this.props.fields.get(index);
  }

  render () {
    const {
      fields, orders, productsUrl, actions, currentFilters,
      locales, change, unregisterField, currentOrder, currentUserId,
      s3SignUrl,
    } = this.props;
    return (
      <div>
        {fields.map((orderName, index) =>
          <LenOrderForm
            key={index}
            sku={(orders[index] || {}).sku}
            orderName={orderName}
            removeNewOrder={this.removeNewOrder.bind(this, index)}
            getOrder={this.getOrder.bind(this, index)}
            userId={currentUserId}
            locales={locales}
            change={change}
            unregisterField={unregisterField}
            currentOrder={currentOrder}
            productsUrl={productsUrl}
            actions={actions}
            currentFilters={currentFilters}
            s3SignUrl={s3SignUrl}
          />
        )}
        {/* <Bootstrap.Button
          className="pull-right"
          onClick={this.addNewOrder}
        >{this.props.locales.buttons.add}
        </Bootstrap.Button> */}
      </div>
    );
  }
}

LensOrdersManagement.propTypes = {
  locales: PropTypes.object.isRequired,
  currentOrder: PropTypes.object,
  currentUserId: PropTypes.number,
  productsUrl: PropTypes.string,
  s3SignUrl: PropTypes.string,

  // Redux Store
  actions: PropTypes.object.isRequired,
  currentFilters: PropTypes.object.isRequired,

  // Redux form
  orders: PropTypes.array,
  fields: PropTypes.object,
  name: PropTypes.string,
  change: PropTypes.func,
  unregisterField: PropTypes.func,
};

LensOrdersManagement.defaultProps = {
};
