import URL from "url-parse";
import qs from "qs";
import isPresent from "utils/isPresent";
import forEach from "lodash/forEach";
import isObject from "lodash/isObject";
import merge from "lodash/merge";

const cleanQuery = params => {
  const newParams = {};
  forEach(params, (v, k) => {
    let newValue = v;
    if (isObject(v)) newValue = cleanQuery(v);
    if (isPresent(v)) newParams[k] = newValue;
  });
  return newParams;
};

const GenUrl = (url, params) => {
  if (isPresent(params)) {
    let urlObject = URL(url, false);
    const oldParams = qs.parse(urlObject.query.replace(/^(\?)(.*)/, "$2"));
    const newParams = cleanQuery(merge(oldParams, params));
    const queryParams = qs.stringify(newParams, { arrayFormat: "brackets" });
    urlObject.query = queryParams;
    url = urlObject.toString();
  }
  return url;
};

export default GenUrl;
