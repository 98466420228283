import React, { Component } from "react";
import autoBind from "react-autobind";
import _sortBy from "lodash/sortBy";
import map from "lodash/map";
import filter from "lodash/filter";
import each from "lodash/each";
import includes from "lodash/includes";
import capitalize from "lodash/capitalize";
import GenUrl from "utils/GenUrl";
import reformatCustomers from "utils/ReformatCustomers";
import Csrf from "utils/Csrf";
import isPresent from "utils/isPresent";

import { NewBulkOrderForm } from "components/ordering/bulk/NewBulkOrderForm";
import { CustomerForm } from "components/ordering/bulk/CustomerForm";
import { FilterList } from "components/ordering/bulk/FilterList";
import { FilterButton } from "components/ordering/bulk/FilterButton";
import { ReviewButton } from "components/ordering/bulk/ReviewButton";
import { CartList } from "components/ordering/bulk/CartList";
import { ProductList } from "components/ordering/bulk/ProductList";
import { Output } from "components/ordering/bulk/Output";
import { BulkOrderConfirmModal } from "components/ordering/bulk/BulkOrderConfirmModal";

import {
  State,
  UIState,
  APIFilterState,
  FilterSelector,
  FilterSelection,
  FilterSelectorOption,
  AvailableMaterials,
  Product,
  ItemProperties,
  Order,
  DEFAULT_STATE,
  Forwarder
} from "components/ordering/bulk/types";

export class NewBulkOrderApp extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    autoBind(this);

    const initialState = { ...DEFAULT_STATE, order: props.order };
    this.state = initialState;
  }

  async convertOrderLensToCartItems() {
    const { cart, order } = this.state;
    if (cart.items.length == order.items.length) return;
    cart.items = order.items;

    this.setState({ cart });
  }

  addProduct(
    sapCode: string,
    sku: string,
    properties: ItemProperties,
    quantity = 1
  ) {
    const { cart } = this.state;
    const existing = cart.items.find(
      i =>
        i.sapCode == sapCode &&
        ["sph", "cyl", "base", "add", "side", "curve"].every(power => {
          return i.properties[power] == properties[power];
        })
    );
    if (existing) {
      if (existing.quantity != 0) return; // item already in cart, not deleted one
      existing.quantity = 1; // item was deleted, and re-add again
    } else {
      cart.items.push({ sapCode, sku, quantity, properties });
      cart.items = _sortBy(cart.items, ["sapCode", "properties"]);
    }
    this.setState({ cart });
  }

  updateItemQuantity(idx: number, q: number) {
    const { cart } = this.state;
    const existing = cart.items[idx];
    if (!existing) return;
    existing.quantity = q;
    this.setState({ cart });
  }

  updateSelection(key: string, val: string) {
    const { selections } = this.state;
    const match = selections.find(curSel => curSel.key == key);
    if (!match) selections.push({ key: key, value: val });
    else if (isNaN(parseInt(val))) match.value = null;
    else match.value = val;
    this.setState({ selections });
  }

  updateCustomer(key: string, val: string | number) {
    if (!isPresent(val)) return;
    let { order } = this.state;
    order[key] = val;
    this.setState({ order });

    if (key == "user_id") {
      this.retrieveAddresses(true);
      this.retrieveForwarders(true);
    }
  }

  loadMaterialFilter(
    availableMaterials: AvailableMaterials[],
    key: string,
    collection: FilterSelectorOption[]
  ): FilterSelector {
    const ids = map(availableMaterials, `${key}_id`);
    const options = [{ name: "All available" }].concat(
      filter(collection, data => {
        return includes(ids, data.id);
      })
    );

    return {
      name: capitalize(key).replace("_", " "),
      key: key,
      options: options
    };
  }

  async searchAgents() {
    const { searchAgents } = this.props.urls;
    const { agents, order } = this.state;

    if (isPresent(agents)) return;

    const request = new Request(searchAgents, { method: "GET" });
    this.setState({ apiFilterState: APIFilterState.Querying });

    try {
      const response = await fetch(request);
      const responseDoc = await response.json();
      const agents = reformatCustomers(responseDoc.data);

      this.setState({ agents });
      if (!isPresent(order?.user_id))
        this.updateCustomer("user_id", String(agents[0].id));
    } catch (_err) {
      // TODO: some real error handling
      this.setState({ apiFilterState: APIFilterState.Err });
    } finally {
      this.setState({ apiFilterState: APIFilterState.Idle });
    }
  }

  async retrieveAddresses(setDefalt: boolean = false) {
    const { retrieveAddresses } = this.props.urls;
    const { order } = this.state;
    const url = GenUrl(retrieveAddresses, { user_id: order?.user_id });

    const request = new Request(url, { method: "GET" });
    this.setState({ apiFilterState: APIFilterState.Querying });

    try {
      const response = await fetch(request);
      const responseDoc = await response.json();
      const shipToAddresses = (response.ok && responseDoc) || undefined;
      const defaultShipToAddressId =
        (response.ok && responseDoc[0]?.id) || undefined;

      this.setState({ shipToAddresses });
      if (setDefalt)
        this.updateCustomer("ship_to_address_id", defaultShipToAddressId);
    } catch (_err) {
      // TODO: some real error handling
      this.setState({ apiFilterState: APIFilterState.Err });
    } finally {
      this.setState({ apiFilterState: APIFilterState.Idle });
    }
  }

  async retrieveForwarders(setDefalt: boolean = false) {
    const { retrieveForwarders } = this.props.urls;
    const { order } = this.state;
    const url = GenUrl(retrieveForwarders, { user_id: order?.user_id });

    const request = new Request(url, { method: "GET" });
    this.setState({ apiFilterState: APIFilterState.Querying });

    try {
      const response = await fetch(request);
      const responseDoc = await response.json();
      const shipViaForwarders = (response.ok && responseDoc) || undefined;
      const defaultShipViaForwarderId =
        (response.ok && responseDoc[0]?.id) || undefined;

      this.setState({ shipViaForwarders });
      if (setDefalt)
        this.updateCustomer("ship_via_forwarder_id", defaultShipViaForwarderId);
    } catch (_err) {
      // TODO: some real error handling
      this.setState({ apiFilterState: APIFilterState.Err });
    } finally {
      this.setState({ apiFilterState: APIFilterState.Idle });
    }
  }

  async filterProducts(selections: FilterSelection[]) {
    const { productFilter } = this.props.urls;
    let filters = {};

    each(selections, selection => {
      filters[selection.key] = selection.value;
    });

    const url = GenUrl(productFilter, { filters: filters });
    const request = new Request(url, { method: "GET" });

    this.setState({ apiFilterState: APIFilterState.Querying });

    try {
      const response = await fetch(request);
      const responseDoc = await response.json();

      const products = map(responseDoc.data, data => {
        return {
          name: data.name,
          sapCode: data.properties.sap_code,
          sku: data.sku,
          powerType: data.properties.power_type
        };
      });

      const results: Product[] = products;

      this.setState({ results });
    } catch (_err) {
      // TODO: some real error handling
      this.setState({ apiFilterState: APIFilterState.Err });
    } finally {
      this.setState({ apiFilterState: APIFilterState.Idle });
    }
  }

  UNSAFE_componentWillMount() {
    this.searchAgents();
    this.retrieveFilters();
    this.convertOrderLensToCartItems();
    this.retrieveAddresses();
    this.retrieveForwarders();
  }

  async retrieveFilters() {
    const {
      urls: { materialFilters },
      filters: { materials, lensTypes, designs, colors, coats, diameters }
    } = this.props;
    const { loadMaterialFilter } = this;
    if (!materialFilters) return;

    let filters = [];
    const request = new Request(materialFilters, { method: "GET" });

    // it would be good to have some kind of Filter API state here
    // so we can provide feedback to user that API is fetching
    try {
      const response = await fetch(request);
      const responseDoc = await response.json();

      const materialFilters = loadMaterialFilter(
        responseDoc.data,
        "material",
        materials
      );
      const lensTypeFilters = loadMaterialFilter(
        responseDoc.data,
        "lens_type",
        lensTypes
      );
      const designFilters = loadMaterialFilter(
        responseDoc.data,
        "design",
        designs
      );
      const colorFilters = loadMaterialFilter(
        responseDoc.data,
        "color",
        colors
      );
      const coatFilters = loadMaterialFilter(responseDoc.data, "coat", coats);
      const diameterFilters = loadMaterialFilter(
        responseDoc.data,
        "diameter",
        diameters
      );

      filters.push(materialFilters);
      filters.push(lensTypeFilters);
      filters.push(designFilters);
      filters.push(colorFilters);
      filters.push(coatFilters);
      filters.push(diameterFilters);

      this.setState({ filters });
    } catch (_err) {
      // NO-OP; just use the props filters (or default)
    } finally {
      // if we have Filter API state, here we would set it back to "idle"
    }
  }

  async newOrder() {
    const {
      cart: { items },
      order
    } = this.state;
    const { confirmOrder } = this.props.urls;
    const request = new Request(confirmOrder, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Csrf.token()
      },
      body: JSON.stringify({ order, items })
    });

    try {
      const response = await fetch(request);
      const responseDoc = await response.json();

      this.setPreviewOrder(responseDoc);
      this.setUITab(UIState.Review);
    } catch (_err) {
      // NO-OP; just use the props filters (or default)
    } finally {
      // if we have Filter API state, here we would set it back to "idle"
    }
  }

  async saveOrder() {
    const {
      cart: { items },
      order
    } = this.state;
    const { saveOrder } = this.props.urls;
    const method = order.id ? "PUT" : "POST";
    const request = new Request(saveOrder, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Csrf.token()
      },
      body: JSON.stringify({ order, items })
    });

    try {
      const response = await fetch(request);
      const responseDoc = await response.json();
      const redirectUrl = responseDoc.location;

      if (isPresent(redirectUrl)) window.location.href = redirectUrl;
    } catch (_err) {
      // NO-OP; just use the props filters (or default)
    } finally {
      // if we have Filter API state, here we would set it back to "idle"
    }
  }

  setPreviewOrder(order: Order) {
    this.setState({ preview: order });
  }

  setUITab(s: UIState) {
    this.setState({ uiState: s });
  }

  render() {
    const {
      state,
      setUITab,
      newOrder,
      saveOrder,
      updateSelection,
      updateCustomer,
      addProduct,
      filterProducts,
      updateItemQuantity,
      props: { locales }
    } = this;

    return (
      <NewBulkOrderForm
        {...state}
        customerTab={
          <div>
            {isPresent(state.order.user_id) && (
              <CustomerForm
                order={state.order}
                agents={state.agents}
                shipToAddresses={state.shipToAddresses}
                shipViaForwarders={state.shipViaForwarders}
                updateCustomer={updateCustomer}
                locales={locales}
              />
            )}
          </div>
        }
        searchTab={
          <div>
            <h3>{locales.steps.findLenses}</h3>
            <FilterList {...state} updateSelection={updateSelection} />
            <FilterButton
              {...state}
              filterProducts={filterProducts}
              buttonText={locales.buttons.filter}
            />
            <hr className="my-3" />
            <h3>{locales.steps.searchResults}</h3>
            <ProductList {...state} addProduct={addProduct} locales={locales} />
          </div>
        }
        cartTab={
          <div>
            <h3>{locales.steps.currentOrder}</h3>
            <CartList {...state} updateItemQuantity={updateItemQuantity} />
            {state.cart.items.length > 0 && (
              <>
                <Output {...state} />
                <ReviewButton
                  {...state}
                  newOrder={newOrder}
                  buttonText={locales.buttons.reviewAndConfirm}
                />
              </>
            )}
          </div>
        }
        confirmModal={
          <div>
            {state.preview && (
              <BulkOrderConfirmModal
                {...state}
                setUITab={setUITab}
                saveOrder={saveOrder}
                locales={locales}
              />
            )}
          </div>
        }
      />
    );
  }
}

interface Props {
  order?: Order;
  urls: {
    materialFilters: string;
    productFilter: string;
    confirmOrder: string;
    saveOrder: string;
    searchAgents: string;
    retrieveAddresses: string;
    retrieveForwarders: string;
  };
  filters: {
    materials: FilterSelectorOption[];
    lensTypes: FilterSelectorOption[];
    designs: FilterSelectorOption[];
    colors: FilterSelectorOption[];
    coats: FilterSelectorOption[];
    diameters: FilterSelectorOption[];
  };
  locales: any;
  initialState?: State;
  materials: FilterSelectorOption[];
  lensTypes: FilterSelectorOption[];
  designs: FilterSelectorOption[];
  colors: FilterSelectorOption[];
  coats: FilterSelectorOption[];
  diameters: FilterSelectorOption[];
}

export default NewBulkOrderApp;
