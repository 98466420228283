import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import RxFilterLensForm from 'components/filters/rx_lens/RxFilterLensForm';
import RxLensOrderForm from './RxLensOrderForm';
import pick from 'lodash/pick';
import merge from 'lodash/merge';
import CleanObject from 'utils/CleanObject';

import {
  ORDER_ID, AGENT_ID,
  PATIENT, PRIVATE_NOTE, NOTE_ON_LABEL,
  LEFT_SIDE, RIGHT_SIDE,
  QUANTITY,
  OPTICAL_INFO_ATTR, OPTICAL_ID
} from 'constants/mapOrderColumns';

export default class NewRxOrderFormWithFilterLens extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.afterEditPower = this.afterEditPower.bind(this);
    this.afterSearchLenses = this.afterSearchLenses.bind(this);
    this.resetOrder = this.resetOrder.bind(this);
  }

  afterSearchLenses (leftLens={}, rightLens={}, _filter={}) {

    const mergeLensData = lens => {
      return merge(lens, { [QUANTITY]: lens.enabled ? 1 : 0 });
    };

    const leftLensData = mergeLensData(leftLens);
    const rightLensData = mergeLensData(rightLens);
    this.resetOrder(leftLensData, rightLensData);
  }

  /*eslint-disable */
  afterEditPower (leftLens={}, rightLens={}, _filter={}) {
  }
  /*eslint-enable */

  resetOrder (leftLens={}, rightLens={}) {
    const { actions: { initializeOrder }, order: currentOrder } = this.props;
    const cleanOrderData = CleanObject(currentOrder);
    const opticalInfoData = pick(currentOrder[OPTICAL_INFO_ATTR] || {}, [OPTICAL_ID]);

    cleanOrderData[LEFT_SIDE] = merge(cleanOrderData[LEFT_SIDE], leftLens);
    cleanOrderData[RIGHT_SIDE] = merge(cleanOrderData[RIGHT_SIDE], rightLens);
    cleanOrderData[OPTICAL_INFO_ATTR] = merge(cleanOrderData[OPTICAL_INFO_ATTR], opticalInfoData);

    const orderData = merge(cleanOrderData, pick(currentOrder, [ORDER_ID, AGENT_ID, PATIENT, PRIVATE_NOTE, NOTE_ON_LABEL]));
    initializeOrder(orderData);
  }

  render() {
    const { canSelectProduct } = this.props;
    return (
      <Row>
        <Col xs={12}>
          <RxFilterLensForm {...this.props}
            afterEditPower={this.afterEditPower}
            afterSearchLenses={this.afterSearchLenses}
          />
        </Col>
        <Col xs={12}>
          { canSelectProduct &&
            <RxLensOrderForm {...this.props}
              resetOrder={this.resetOrder}
            />
          }
        </Col>
      </Row>
    );
  }
}

NewRxOrderFormWithFilterLens.propTypes = {
  canSelectProduct: PropTypes.bool,
  order: PropTypes.object,
  actions: PropTypes.shape({
    initializeOrder: PropTypes.func.isRequired,
  }),
};
