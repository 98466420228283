import React from 'react';
import PropTypes from 'prop-types';
import { TINT_VALUE_FIELD } from '../../../../constants/orderFields';
import findIndex from 'lodash/findIndex';
import * as Bootstrap from 'react-bootstrap';
import { Field } from 'redux-form';
import SelectInput from '../../../default_redux/SelectInput';
import DemoColor from './DemoColor';
import { required } from '../../../../utils/Validators';

export default class RxOptionTintPercentage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.demoColor = this.demoColor.bind(this);
  }

  demoColor () {
    let valueIndex = findIndex(this.props.tintValues, (value) => {
      return value == this.props.tintValue;
    });
    const demoColor = this.props.tintColors ? this.props.tintColors[valueIndex] : "";
    return demoColor;
  }

  render () {
    if(!this.props.show) return null;
    const demoColor = this.demoColor() || "";
    // console.log(`Render ${this.props.label}`);
    return (
      <Bootstrap.Row>
        <Bootstrap.Col xs={6}>
          <Field component={SelectInput}
            name={TINT_VALUE_FIELD}
            label={this.props.label}
            options={
              this.props.tintValues.map((value) => {
                return({
                  name: value,
                  value: value
                });
              })
            }
            labelClass='col-xs-6'
            selectWrapperClass='col-xs-6'
            required={true}
            validate={[required.bind(null, true)]}
          />
        </Bootstrap.Col>
        <Bootstrap.Col xs={3}>
          <DemoColor color={demoColor} />
        </Bootstrap.Col>
      </Bootstrap.Row>
    );
  }
}
RxOptionTintPercentage.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  tintValues: PropTypes.array,
  tintColors: PropTypes.array,
  tintValue: PropTypes.string,
};

RxOptionTintPercentage.defaultProps = {
  label: 'Tint %',
  show: false,
  tintValues: [],
  tintColors: []
};
