import * as c from "constants/mapOrderColumns";

export const ORDER_ID_FIELD = `order[${c.ORDER_ID}]`;
export const PATIENT_FIELD = `order[${c.PATIENT}]`;
export const AGENT_ID_FIELD = `order[${c.AGENT_ID}]`;
export const SKU_FIELD = `order[${c.SKU}]`;
export const NOTE_ON_LABEL_FIELD = `order[${c.NOTE_ON_LABEL}]`;
export const PRIVATE_NOTE_FIELD = `order[${c.PRIVATE_NOTE}]`;

export const EXPRESS_FILED = `order[${c.EXPRESS}]`;

export const OPTICAL_ID_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.OPTICAL_ID}]`;
export const CUTTING_FILED = `order[${c.OPTICAL_INFO_ATTR}][${c.CUTTING}]`;
export const TINT_ID_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.TINT}]`;
export const TINT_VALUE_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.TINT_VALUE}]`;
export const OPT_THICKNESS_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.OPT_THICKNESS}]`;
export const FRAME_SHAPE_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.FRAME_SHAPE}]`;
export const FRAME_SHAPE_VALID_FIELD = `order[${c.OPTICAL_INFO_ATTR}][frame_shape_valid]`;
export const FRAME_ID_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.FRAME_ID}]`;
export const FRAME_LIBRARY_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.FRAME_LIBRARY}]`;
export const FRAME_LIBRARY_ID_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.FRAME_LIBRARY_ID}]`;
export const FRAME_TYPE_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.FRAME_TYPE}]`;
export const FRAME_MATERIAL_ID_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.FRAME_MATERIAL_ID}]`;
export const FRAME_TO_COME_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.FRAME_TO_COME}]`;
export const ORIGINAL_SHAPE_FILE_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.ORIGINAL_SHAPE_FILE}]`;
export const ORIGINAL_SHAPE_FILE_NAME_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.ORIGINAL_SHAPE_FILE_NAME}]`;
export const INSET_TYPE_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.INSET_TYPE}]`;
export const A_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.A}]`;
export const B_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.B}]`;
export const DBL_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.DBL}]`;
export const DEGRESSION_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.DEGRESSION}]`;
export const CORRIDOR_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.CORRIDOR}]`;
export const FLASH_MIRROR_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.FLASH_MIRROR}]`;
export const TINT_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.TINT}]`;
export const UV_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.UV}]`;

export const SHAPE_TYPE_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.SHAPE_TYPE}]`;
export const ROUND_SHAPE_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.ROUND_SHAPE}]`;
export const ROUND_DIA_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.ROUND_DIA}]`;
export const OVAL_SHAPE_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.OVAL_SHAPE}]`;
export const SHARP_EDGE_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.SHARP_EDGE}]`;
export const READING_DISTANCE_FIELD = `order[${c.OPTICAL_INFO_ATTR}][${c.READING_DISTANCE}]`;

export const LEFT_LENS_ID_FIELD = `order[${c.LEFT_SIDE}][${c.LENS_ID}]`;
export const LEFT_QUANTITY_FIELD = `order[${c.LEFT_SIDE}][${c.QUANTITY}]`;
export const LEFT_SPH_FIELD = `order[${c.LEFT_SIDE}][${c.SPH}]`;
export const LEFT_CYL_FIELD = `order[${c.LEFT_SIDE}][${c.CYL}]`;
export const LEFT_ADD_FIELD = `order[${c.LEFT_SIDE}][${c.ADD}]`;
export const LEFT_AXIS_FIELD = `order[${c.LEFT_SIDE}][${c.AXIS}]`;
export const LEFT_INSET_FIELD = `order[${c.LEFT_SIDE}][${c.INSET}]`;
export const LEFT_DECENTER_FIELD = `order[${c.LEFT_SIDE}][${c.DECENTER}]`;
export const LEFT_DECENTER_X_FIELD = `order[${c.LEFT_SIDE}][${c.DECENTER_X}]`;
export const LEFT_DECENTER_Y_FIELD = `order[${c.LEFT_SIDE}][${c.DECENTER_Y}]`;
export const LEFT_ET_FIELD = `order[${c.LEFT_SIDE}][${c.ET}]`;

export const RIGHT_LENS_ID_FIELD = `order[${c.RIGHT_SIDE}][${c.LENS_ID}]`;
export const RIGHT_QUANTITY_FIELD = `order[${c.RIGHT_SIDE}][${c.QUANTITY}]`;
export const RIGHT_SPH_FIELD = `order[${c.RIGHT_SIDE}][${c.SPH}]`;
export const RIGHT_CYL_FIELD = `order[${c.RIGHT_SIDE}][${c.CYL}]`;
export const RIGHT_ADD_FIELD = `order[${c.RIGHT_SIDE}][${c.ADD}]`;
export const RIGHT_AXIS_FIELD = `order[${c.RIGHT_SIDE}][${c.AXIS}]`;
export const RIGHT_INSET_FIELD = `order[${c.RIGHT_SIDE}][${c.INSET}]`;
export const RIGHT_DECENTER_FIELD = `order[${c.RIGHT_SIDE}][${c.DECENTER}]`;
export const RIGHT_DECENTER_X_FIELD = `order[${c.RIGHT_SIDE}][${c.DECENTER_X}]`;
export const RIGHT_DECENTER_Y_FIELD = `order[${c.RIGHT_SIDE}][${c.DECENTER_Y}]`;
export const RIGHT_ET_FIELD = `order[${c.RIGHT_SIDE}][${c.ET}]`;

export const LEFT_LENS_FIELDS = `order[${c.LEFT_SIDE}]`;
export const RIGHT_LENS_FIELDS = `order[${c.RIGHT_SIDE}]`;
