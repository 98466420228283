import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/shared/Loading';

export default class ProductLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false
    };
  }

  handleClick () {
    const { processing } = this.state;
    const { handleClick, product } = this.props;
    if(processing) return null;
    this.setState({processing: true});
    handleClick(product);
  }

  render () {
    const { processing } = this.state;
    const { product } = this.props;
    return (
      <tr
        onClick={this.handleClick.bind(this)}
        className={processing ? "processing" : null}
      >
        <td>
          { processing ? <Loading inline={true} /> : null }
          <span className="product__name">
            {product.name}
          </span>
        </td>
      </tr>
    );
  }
}

ProductLabel.propTypes = {
  product: PropTypes.object,
  handleClick: PropTypes.func,
};

ProductLabel.defaultProps = {
  product: {},
  handleClick: (_product)=>{},
};
