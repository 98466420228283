import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, Glyphicon } from 'react-bootstrap';
import Csrf from 'utils/Csrf';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection, SubmissionError, formValueSelector } from 'redux-form';
import SelectInput from 'components/default_redux/SelectInput';
import TextInput from 'components/default_redux/TextInput';
import FileInput from 'components/default_redux/FileInput';
import * as Validators from 'utils/Validators';
import map from 'lodash/map';
import S3Uploaded from 'utils/S3Uploaded';
import getIn from 'redux-form/lib/structure/plain/getIn';
import {
  DOCUMENT_ATTR,
  TITLE, LANG, STATUS, TAG,
  FILES_FIELD, FILE_FIELD, ORIGINAL_FILE_NAME_FIELD,
  EDITABLE_FILES_FIELD, EDITABLE_FILE_FIELD, EDITABLE_ORIGINAL_FILE_NAME_FIELD
} from 'constants/mapDocumentColumns';

class DocumentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.uploadFileToS3 = this.uploadFileToS3.bind(this);
  }

  uploadFileToS3 (s3SignUrl, files) {
    return new Promise((resolve, reject) => {
      if(!files) resolve(null);
      const file = files[0];
      S3Uploaded(file, s3SignUrl)
        .then(({_s3Data, _file, fileUrl}) => {
          resolve(fileUrl);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  }

  async onSubmit (values) {
    this.setState({ isSending: true });
    const {
      formUrl, action,
      s3SignUrl: {
        file: fileS3SignUrl,
        editableFile: editableFileS3SignUrl
      }
    } = this.props;
    const document = values.document;
    const fileUrl = await this.uploadFileToS3(fileS3SignUrl, document.files);
    const editableFileUrl = await this.uploadFileToS3(editableFileS3SignUrl, document.editable_files);
    document.remote_editable_file_url = editableFileUrl;
    document.remote_file_url = fileUrl;

    return fetch(formUrl, {
      credentials: 'same-origin',
      method: action,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        authenticity_token: Csrf.token(),
        document
      })
    }).then(response => {
      return response.json();
    }).then(json => {
      if(json.errors) {
        throw {
          name: 'Form invalid',
          status: 422,
          errors: { user: json.errors }
        };
      }
      window.location.href = json.location;
    }).catch(e => {
      this.setState({isSending: false});
      switch (e.status) {
        case 422:
          e.errors._error = map(e.errors.user, (error, key) => {
            return(
              <p key={key}>{error}</p>
            );
          });
          throw new SubmissionError(e.errors);
        default:
          throw new SubmissionError({ _error: e.message });
      }
    });
  }

  renderSubmitText () {
    const { action, locales } = this.props;
    let submitText = locales.buttons.create;
    if(action == 'PUT' || action == 'put') {
      submitText = locales.buttons.update;
    }
    if(!this.state.isSending) return submitText;
    return(
      <div>
        {submitText}
        <span className="glyphicon glyphicon-refresh glyphicon-refresh-animate"
        aria-hidden="true"></span>
      </div>
    );
  }

  onDropEditableFile (acceptedFiles, _rejectedFiles) {
    const { change } = this.props;
    const fileName = acceptedFiles[0].name;
    change(EDITABLE_FILES_FIELD, acceptedFiles);
    change(EDITABLE_ORIGINAL_FILE_NAME_FIELD, fileName);
  }

  onDropFile (acceptedFiles, _rejectedFiles) {
    const { change } = this.props;
    const fileName = acceptedFiles[0].name;
    change(FILES_FIELD, acceptedFiles);
    change(ORIGINAL_FILE_NAME_FIELD, fileName);
  }

  renderDropZoneButton (text) {
    return (
      <div>
        <Button><Glyphicon glyph="upload" /> {text}</Button>
      </div>
    );
  }

  render () {
    const {
      uploadedFile, uploadedEditableFile,
      handleSubmit, languages,
      tags, statuses, error, locales } = this.props;
    const { isSending } = this.state;
    return (
      <Form
        className="form-horizontal"
        onSubmit={handleSubmit(this.onSubmit.bind(this))}
      >
        <FormSection name={DOCUMENT_ATTR}>
          <FileInput
            label={locales.document.editableFile}
            afterOnChange={this.onDropEditableFile.bind(this)}
            required={true}
            dropZoneButton={this.renderDropZoneButton.bind(this)(locales.buttons.upload_editable)}
            labelClass="col-xs-3"
            inputClass="col-xs-9"
            helpText={uploadedEditableFile && uploadedEditableFile.name}
          />
          <FileInput
            label={locales.document.file}
            afterOnChange={this.onDropFile.bind(this)}
            required={true}
            dropZoneButton={this.renderDropZoneButton.bind(this)(locales.buttons.upload)}
            labelClass="col-xs-3"
            inputClass="col-xs-9"
            helpText={uploadedFile && uploadedFile.name}
          />
          <Field component={TextInput}
            label={locales.document.title}
            type="text"
            name={TITLE}
            labelClass="col-xs-3"
            inputClass="col-xs-9"
            validate={[
              Validators.required.bind(null, true)
            ]}
            required={true}
          />
          <Field component={SelectInput}
            name={LANG}
            label={locales.document.lang}
            options={
              map(languages, (lang)=> {
                return({ name: lang, value: lang });
              })
            }
            searchable={true}
            labelClass="col-xs-3"
            selectWrapperClass="col-xs-9"
            required={true}
            validate={[
              Validators.required.bind(null, true)
            ]}
          />
          <Field component={SelectInput}
            name={TAG}
            label={locales.document.tag}
            options={
              map(tags, (tag)=> {
                return({ name: tag, value: tag });
              })
            }
            searchable={true}
            labelClass="col-xs-3"
            selectWrapperClass="col-xs-9"
            required={true}
            validate={[
              Validators.required.bind(null, true)
            ]}
          />
          <Field component={SelectInput}
            name={STATUS}
            label={locales.document.status}
            options={
              map(statuses, (status)=> {
                return({ name: status, value: status });
              })
            }
            searchable={true}
            labelClass="col-xs-3"
            selectWrapperClass="col-xs-9"
            required={true}
            validate={[
              Validators.required.bind(null, true)
            ]}
          />
        </FormSection>
        { error &&
          <Row>
            <Col xsOffset={3} xs={9}>
              <br/>
              <div className="text-center text-danger">{error}</div>
            </Col>
          </Row>
        }
        <Row>
          <Col xsOffset={3} xs={9}>
            <Button
              type="submit"
              block
              bsStyle="primary"
              disabled={isSending}
            >
              { this.renderSubmitText.bind(this)() }
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

DocumentForm.propTypes = {
  s3SignUrl: PropTypes.shape({
    file: PropTypes.string,
    editableFile: PropTypes.string
  }),
  formUrl: PropTypes.string,
  action: PropTypes.string,
  languages: PropTypes.array,
  tags: PropTypes.array,
  statuses: PropTypes.array,
  locales: PropTypes.object,

  // redux form
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  error: PropTypes.string,
  uploadedFile: PropTypes.any,
  uploadedEditableFile: PropTypes.any,
};

DocumentForm.defaultProps = {
};

let form = reduxForm({
  form: 'documentForm',
})(DocumentForm);

const selector = formValueSelector('documentForm'); // <-- same as form name
form = connect(
  (state, props) => {
    const initialValues = { [DOCUMENT_ATTR]: props.document };
    const uploadedFile = (selector(state, FILES_FIELD) || [])[0] || getIn(props, FILE_FIELD);
    const uploadedEditableFile = (selector(state, EDITABLE_FILES_FIELD) || [])[0] || getIn(props, EDITABLE_FILE_FIELD);
    return {
      initialValues,
      uploadedFile,
      uploadedEditableFile
    };
  }
)(form);

export default form;
