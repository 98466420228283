import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import stockLens from "reducers/stockLens";
import rxLens from "reducers/rxLens";
import rxLensFilter from "reducers/rxLensFilter";
import productsListReducer from "reducers/productsList";
import userFormReducer from "reducers/userForm";
import fetchErrors from "reducers/fetchErrors";

export const stockLensForm = combineReducers({
  stockLens,
  form: formReducer
});

export const rxLensForm = combineReducers({
  fetchErrors,
  rxLensFilter,
  rxLens,
  form: formReducer
});

export const productsList = combineReducers({
  fetchErrors,
  productsListReducer,
  form: formReducer
});

export const userForm = combineReducers({
  fetchErrors,
  userFormReducer,
  form: formReducer
});

export const ordersSearchingForm = combineReducers({
  form: formReducer
});

export const usersSearchingForm = combineReducers({
  form: formReducer
});

export const documentForm = combineReducers({
  form: formReducer
});
