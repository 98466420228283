import "whatwg-fetch";
import { actionNames } from "constants.js";

export const updateParents = (sourceKey, parents) => {
  return {
    type: actionNames.UPDATE_PARENTS,
    sourceKey,
    parents
  };
};

export const loadParents = (sourceKey, url) => {
  return dispatch => {
    fetch(url, {
      credentials: "same-origin"
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        return dispatch(updateParents(sourceKey, json));
      })
      .catch(_ex => {});
  };
};
