import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button, Glyphicon } from 'react-bootstrap';
import {
  ORIGINAL_SHAPE_FILE_FIELD,
  ORIGINAL_SHAPE_FILE_NAME_FIELD,
  FRAME_ID_FIELD,
  FRAME_LIBRARY_FIELD,
  FRAME_LIBRARY_ID_FIELD,
  FRAME_SHAPE_VALID_FIELD
} from 'constants/orderFields';

export default class UploadShape extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.buttonText = this.buttonText.bind(this);
  }

  buttonText () {
    let text = this.props.label;
    if(this.props.shapeFile) {
      return (
        <div>
          <Glyphicon glyph="record" /> {text}
        </div>
      );
    }
    return text;
  }

  onDrop (acceptedFiles, _rejectedFiles) {
    const { change, convertFieldName } = this.props;
    const file = acceptedFiles[0];
    change(convertFieldName(FRAME_ID_FIELD), null);
    change(convertFieldName(FRAME_LIBRARY_FIELD), null);
    change(convertFieldName(FRAME_LIBRARY_ID_FIELD), null);

    change(convertFieldName(ORIGINAL_SHAPE_FILE_FIELD), acceptedFiles);
    change(convertFieldName(ORIGINAL_SHAPE_FILE_NAME_FIELD), file.name);
    change(convertFieldName(FRAME_SHAPE_VALID_FIELD), true);
  }

  render () {
    if(!this.props.show) return null;
    const { allowFileExtensions } = this.props;
    const options = {
      onDrop: this.onDrop.bind(this),
      className: "dropzone",
      multiple: false
    };
    if(allowFileExtensions) {
      options.accept = allowFileExtensions.join(',');
    }
    return (
      <span>
        <Dropzone
          {...options}>
          <Button
            onClick={this.showChoices}>
            { this.buttonText() }
          </Button>
        </Dropzone>
      </span>
    );
  }

}

UploadShape.propTypes = {
  show: PropTypes.bool,
  name: PropTypes.string,
  shapeFile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  label: PropTypes.string,
  s3SignUrl: PropTypes.string,
  allowFileExtensions: PropTypes.array,
  convertFieldName: PropTypes.func,

  // Redux form
  change: PropTypes.func,
};

UploadShape.defaultProps = {
  show: false,
  allowFileExtensions: [],
  convertFieldName: () => {},
};
