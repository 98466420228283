import React, { FC, ReactNode } from "react";
import { ComponentProps, UIState } from "./types";
import { Row, Col, Panel } from "react-bootstrap";

export const NewBulkOrderForm: FC<ComponentProps & {
  customerTab?: ReactNode;
  searchTab?: ReactNode;
  cartTab?: ReactNode;
  confirmModal?: ReactNode;
}> = props => {
  const { customerTab, searchTab, cartTab, confirmModal } = props;

  return (
    <Panel>
      <Panel.Body>
        <Row>
          <Col sm={12}>{customerTab}</Col>
        </Row>
        <Row>
          <Col sm={6}>{searchTab}</Col>
          <Col sm={6}>{cartTab}</Col>
        </Row>
        {confirmModal}
      </Panel.Body>
    </Panel>
  );
};
