import React from 'react';
import PropTypes from 'prop-types';

export default class StepHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { tag, number, desc } = this.props;
    const CustomTag = tag;
    return(
      <CustomTag className="circled-step">
        <div className="number">
          {number}
        </div>
        <div className="small">
          {desc}
        </div>
      </CustomTag>
    );
  }
}

StepHeader.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3']),
  number: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  desc: PropTypes.string,
};

StepHeader.defaultProps = {
  tag: 'h1',
};
