import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { PTA } from '../../../../constants/mapOrderColumns';
import TextInput from '../../../default_redux/TextInput';
import { required, min, max } from '../../../../utils/Validators';

export default class PTAInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render () {
    if(!this.props.show) return null;
    return (
      <Field component={TextInput}
        label={this.props.label}
        type="number"
        step="any"
        name={`order${this.props.attrNestedName}[${PTA}]`}
        suffixAddon={`${this.props.min}-${this.props.max} degree`}
        inputClass="col-xs-8"
        labelClass="col-xs-4"
        required={this.props.required}
        validate={[
          required.bind(null, this.props.required),
          min.bind(null, this.props.min),
          max.bind(null, this.props.max),
        ]}
      />
    );
  }
}

PTAInput.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  attrNestedName: PropTypes.string,
};

PTAInput.defaultProps = {
  label: "PTA",
  show: false,
  required: false,
  min: 0,
  max: 0,
  attrNestedName: '',
};
