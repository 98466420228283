import { actionNames } from "constants.js";

export const addStockLens = agentId => {
  return {
    type: actionNames.ADD_STOCK_LENS,
    agentId
  };
};

export const removeStockLen = index => {
  return {
    type: actionNames.REMOVE_STOCK_LENS,
    index
  };
};

export const updateStockLens = orders => {
  return {
    type: actionNames.UPDATE_STOCK_LENS,
    orders
  };
};

export const updateAgenStockLens = agenId => {
  return {
    type: actionNames.UPDATE_AGEN_STOCK_LENS,
    agenId
  };
};

export const updateFilter = filters => {
  return {
    type: actionNames.UPDATE_FILTER,
    filters
  };
};
