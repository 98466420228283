import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'react-bootstrap';
import isPresent from 'utils/isPresent';

class CheckboxInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount () {
    const {
      initialValue,
      input: { onChange, value: currentValue }
    } = this.props;
    if(!isPresent(currentValue) && isPresent(initialValue)) {
      onChange(initialValue);
    }
  }

  componentWillUnmount() {
    const { input: { onChange } } = this.props;
    onChange(null);
  }

  handleChange (e) {
    e.target.value = e.target.value == 'true';
    if (this.props.input.onChange) {
      this.props.input.onChange(e);
      // this.props.input.onBlur(value);
    }
    this.props.handleChange(e.target.value);
  }

  render () {
    const { label, input, disabled } = this.props;
    return (
      <Checkbox
        checked={input.value}
        {...input}
        onChange={this.handleChange.bind(this)}
        disabled={disabled}
        style={{fontWeight: "bold", minHeight: "34px"}}
      > {label}
      </Checkbox>
    );
  }


}

CheckboxInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  initialValue: PropTypes.any,

  // Redux form
  input: PropTypes.object,
};

CheckboxInput.defaultProps = {
  handleChange: (checked) => { return checked; },
};

export default CheckboxInput;
