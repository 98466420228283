import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { NEAR_PD } from 'constants/mapOrderColumns';
import TextInput from 'components/default_redux/TextInput';
import { required, min, max } from 'utils/Validators';

export default class NearPD extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.farPD = this.farPD.bind(this);
    this.minPD = this.minPD.bind(this);
    this.maxPD = this.maxPD.bind(this);
    this.convertValue = this.convertValue.bind(this);
    this.suffixAddon = this.suffixAddon.bind(this);
    this.validate = this.validate.bind(this);
  }

  farPD () {
    return parseFloat(this.props.farPD);
  }

  minPD (value) {
    value = value || this.farPD();
    return value - 5;
  }

  maxPD (value) {
    value = value || this.farPD();
    return value;
  }

  convertValue (value) {
    value = parseFloat(value);
    return Number.isSafeInteger(value) ? value.toFixed(0) : value.toFixed(2);
  }

  suffixAddon () {
    if(this.props.fixValue) return null;
    return `${this.convertValue(this.minPD())}-${this.convertValue(this.maxPD())} mm.`;
  }

  validate () {
    const { fixValue } = this.props;
    const requiredInput = this.props.required;

    if(fixValue) return [];
    return ([
      required.bind(null, requiredInput),
      min.bind(null, this.minPD()),
      max.bind(null, this.maxPD()),
    ]);
  }

  render () {
    const {
      label, show, name,
      fixValue,
    } = this.props;
    if(!show) return null;

    const requiredInput = this.props.required;
    return (
      <Field component={TextInput}
        label={label}
        type="number"
        step="any"
        name={`${name}[${NEAR_PD}]`}
        default_reduxValue={this.convertValue(this.minPD())}
        suffixAddon={this.suffixAddon()}
        readOnly={fixValue}
        inputClass="col-xs-8"
        labelClass="col-xs-4"
        helpText={`Standard Near PD is ${this.convertValue(this.minPD())}.`}
        required={requiredInput}
        validate={this.validate()}
      />
    );
  }
}

NearPD.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  fixValue: PropTypes.bool,
  farPD: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

NearPD.defaultProps = {
  label: "Near PD",
  show: false,
  required: false,
};
