import NewStockLensApp from "containers/NewStockLensApp";
import NewRxLensApp from "containers/NewRxLensApp";
import ProductManagementApp from "containers/ProductManagementApp";
import UserFormApp from "containers/UserFormApp";
import OrdersToolsApp from "containers/OrdersToolsApp";
import UsersSearchingApp from "containers/UsersSearchingApp";
import DocumentFormApp from "containers/DocumentFormApp";
import { NewBulkOrderApp } from "containers/NewBulkOrderApp";

global.NewStockLensApp = NewStockLensApp;
global.NewRxLensApp = NewRxLensApp;
global.ProductManagementApp = ProductManagementApp;
global.UserFormApp = UserFormApp;
global.OrdersToolsApp = OrdersToolsApp;
global.UsersSearchingApp = UsersSearchingApp;
global.DocumentFormApp = DocumentFormApp;
global.NewBulkOrderApp = NewBulkOrderApp;
