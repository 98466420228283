import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import SelectInput from 'components/default_redux/SelectInput';
import ProductList from 'components/users/products/ProductList';
import * as Bootstrap from 'react-bootstrap';
import { productsListType } from 'constants.js';

class ProductManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this._inputFilterTemplate = this._inputFilterTemplate.bind(this);
    this.selectedItemSale = this.selectedItemSale.bind(this);
    this.loadAllLists = this.loadAllLists.bind(this);
    this.loadProducts = this.loadProducts.bind(this);
    this.fillProducts = this.fillProducts.bind(this);
    this.createAvailableProduct = this.createAvailableProduct.bind(this);
    this.removeAvailableProduct = this.removeAvailableProduct.bind(this);
    this.loadProductsList = this.loadProductsList.bind(this);
    this.loadAvailabledProductsList = this.loadAvailabledProductsList.bind(this);
  }

  componentDidMount() {
    this.loadAllLists();
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.category != nextProps.category ||
      this.props.material != nextProps.material ||
      this.props.design != nextProps.design) {
        const filters = {
          category: nextProps.category,
          material: nextProps.material,
          design: nextProps.design,
        };
        this.loadAllLists({filters});
      }
  }

  loadAllLists (queryParams={}) {
    this.loadProductsList(queryParams);
    this.loadAvailabledProductsList(queryParams);
  }

  loadProducts (type, url, queryParams={})  {
    const { category, material, design, loadProducts } = this.props;
    const defaultFilters = { filters: { category, material, design } };
    queryParams = merge(defaultFilters, queryParams);
    loadProducts(url, type, queryParams);
  }

  fillProducts (loadMethod=(_queryParams)=>{}, currentProducts=[], pagination={}, queryParams={}) {
    const { current_page, total_pages } = pagination;
    if(currentProducts.length > 1) return null;
    const nextPage = current_page;
    if(nextPage > total_pages) return null;
    const offset = 1;
    const defaultParams = { page: nextPage, offset };
    queryParams = merge(defaultParams, queryParams);
    loadMethod(queryParams);
  }

  loadProductsList (queryParams={}) {
    const { productsText } = this.props;
    const defaultParams = {
      filters: {
        name: productsText
      }
    };
    queryParams = merge(defaultParams, queryParams);
    this.loadProducts(productsListType.STORE,
      this.props.sources.productsUrl, queryParams
    );
  }

  loadAvailabledProductsList (queryParams={}) {
    const { availabledProductsText } = this.props;
    const defaultParams = {
      filters: {
        name: availabledProductsText
      }
    };
    queryParams = merge(defaultParams, queryParams);
    this.loadProducts(productsListType.AVAILABLE,
      this.props.sources.availableProductsUrl, queryParams
    );
  }

  createAvailableProduct (fromList, toList, product) {
    const url = this.props.sources.updateOrDeleteProductUrl;
    const data = {
      customers_product: {
        product_sku: product.sku,
        material_id: product.properties.material_id,
        design_id: product.properties.design_id
      }
    };
    this.props.addProductToList(
      url, data, fromList, toList, product
    );

    this.fillProducts(
      this.loadProductsList,
      this.props.products,
      this.props.paginationParams.products
    );
  }

  removeAvailableProduct (fromList, toList, product) {
    const url = this.props.sources.updateOrDeleteProductUrl;
    const data = {
      customers_product: {
        product_sku: product.sku,
      }
    };
    this.props.deleteProductFromList(
      url, data, fromList, toList, product
    );

    this.fillProducts(
      this.loadAvailabledProductsList,
      this.props.availabledProducts,
      this.props.paginationParams.available
    );
  }

  selectedItemSale () {
    return this.props.category == "item-sales";
  }

  clearFilter (e) {
    const { change } = this.props;
    change('category', e.target.value);
    change('material', null);
    change('design', null);
  }

  _inputFilterTemplate (name, label, options, show=false, afterChange=(_e)=>{ } ) {
    if(!show) return null;
    return (
      <Field component={SelectInput}
        name={name}
        label={label}
        blankDisplay='All'
        options={options}
        handleChange={afterChange}
        labelClass='col-xs-4'
        selectWrapperClass='col-xs-8'
      />
    );
  }

  searchProductName (name) {
    const filters = {
      category: this.props.category,
      material: this.props.material,
      design: this.props.design,
      name: name
    };
    this.loadProductsList({filters});
  }

  searchAvailabledProductName (name) {
    const filters = {
      category: this.props.category,
      material: this.props.material,
      design: this.props.design,
      name: name
    };
    this.loadAvailabledProductsList({filters});
  }

  render () {
    const { products, availabledProducts,
      filters, loading, paginationParams } = this.props;
    return (
      <div>
        <Bootstrap.Row>
          <Bootstrap.Col xsOffset={3} xs={5}>
            { this._inputFilterTemplate('category', 'Product Type',
              filters.categories, true, this.clearFilter.bind(this)) }
            { this._inputFilterTemplate('material', 'Material',
              filters.materials, this.selectedItemSale()) }
            { this._inputFilterTemplate('design', 'Design',
              filters.designs, this.selectedItemSale()) }
          </Bootstrap.Col>
        </Bootstrap.Row>
        <Bootstrap.Row>
          <Bootstrap.Col xs={12}>
            <br/>
            <hr/>
          </Bootstrap.Col>
        </Bootstrap.Row>
        <Bootstrap.Row>
          <Bootstrap.Col xs={6}>
            <h4>Products List</h4>
            <ProductList
              type="products"
              loadProducts={this.loadProductsList}
              products={products}
              handleClickProduct={this.createAvailableProduct.bind(null, productsListType.STORE, productsListType.AVAILABLE)}
              handleSearchName={this.searchProductName.bind(this)}
              loading={loading.productList}
              pagination={paginationParams[productsListType.STORE]}
            />
          </Bootstrap.Col>
          <Bootstrap.Col xs={6}>
          <h4>Available Products List</h4>
            <ProductList
              type="availabledProducts"
              loadProducts={this.loadAvailabledProductsList}
              products={availabledProducts}
              handleClickProduct={this.removeAvailableProduct.bind(null, productsListType.AVAILABLE, productsListType.STORE)}
              handleSearchName={this.searchAvailabledProductName.bind(this)}
              loading={loading.availableProductList}
              pagination={paginationParams[productsListType.AVAILABLE]}
            />
          </Bootstrap.Col>
        </Bootstrap.Row>
      </div>
    );
  }
}

ProductManagement.propTypes = {
  filters: PropTypes.object,
  sources: PropTypes.object,

  //Redux Reducer
  loadProducts: PropTypes.func,
  moveProduct: PropTypes.func,
  updateAvailabledSKUs: PropTypes.func,
  addProductToList: PropTypes.func,
  deleteProductFromList: PropTypes.func,
  storeSKUs: PropTypes.array,
  paginationParams: PropTypes.object,

  //Redux form
  change: PropTypes.func,
  reset: PropTypes.func,
  category: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  material: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  design: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  loading:  PropTypes.object,
  products: PropTypes.array,
  availabledProducts: PropTypes.array,
  productsText: PropTypes.string,
  availabledProductsText: PropTypes.string,
};

ProductManagement.defaultProps = {
};

let form = reduxForm({
  form: 'userProducts',
})(ProductManagement);

const selector = formValueSelector('userProducts'); // <-- same as form name
form = connect(
  state => {
    const filter = selector(state,
      'category', 'material', 'design',
      'productsText', 'availabledProductsText');
    return filter;
  }
)(form);

export default form;
