import { actionNames } from "constants.js";

export const updateFetchErrors = (fetchName, errorType, options = {}) => {
  return {
    type: actionNames.UPDATE_FETCH_ERRORS,
    fetchName,
    errorType,
    options
  };
};
