import React, { FC } from "react";
import { ComponentProps, APIFilterState, FilterSelection } from "./types";
import { Button } from "react-bootstrap";

export const FilterButton: FC<ComponentProps & {
  filterProducts: (s: FilterSelection[]) => void;
  buttonText: string;
}> = props => {
  const { apiFilterState, selections, filterProducts, buttonText } = props;
  const boundCallback = () => {
    filterProducts(selections);
  };
  const disabled = apiFilterState == APIFilterState.Querying;
  return (
    <Button
      onClick={boundCallback}
      disabled={disabled}
      bsStyle="primary"
      block
      children={buttonText}
    />
  );
};
