import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal, Button, Panel } from "react-bootstrap";
import ProductsList from "components/users/products/ProductList";
import InputForm from "components/default_redux/InputForm";
import Loading from "components/shared/Loading";
import isEqual from "lodash/isEqual";

export default class SelectProductsInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      waitingSelectedProduct: false,
      productsLoading: false
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.loadProducts = this.loadProducts.bind(this);
    this.handleSearchName = this.handleSearchName.bind(this);
    this.handleClickProduct = this.handleClickProduct.bind(this);
    this.selectedProductName = this.selectedProductName.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.selectedProduct, this.props.selectedProduct)) {
      setTimeout(() => {
        this.setState({ waitingSelectedProduct: false });
      }, 1500);
    }
  }

  open() {
    this.setState({
      showModal: true,
      waitingSelectedProduct: true
    });
  }

  close() {
    this.setState({
      showModal: false
    });
  }

  loadProducts(queryParams) {
    let {
      searchProducts,
      productsResponse: { url }
    } = this.props;
    const beforeSearch = _dispatch => {
      this.setState({ productsLoading: true });
    };
    const searchCallback = _json => {
      this.setState({ productsLoading: false });
    };
    const beforeRetryCallback = _json => {};
    searchProducts(
      url,
      queryParams,
      beforeSearch,
      searchCallback,
      beforeRetryCallback
    );
  }

  handleSearchName(name) {
    this.loadProducts({ page: 1, filters: { name } });
  }

  handleClickProduct(product) {
    let {
      handleProductChange,
      input: { onChange }
    } = this.props;
    const value = product.sku;
    onChange(value);
    handleProductChange({ target: { value } });
    setTimeout(this.close, 800);
  }

  selectedProductName() {
    let { waitingSelectedProduct } = this.state;
    if (waitingSelectedProduct)
      return (
        <Loading size="sm" position="left" inline={true} iconStyle="loading" />
      );
    let selectedProduct = this.props.selectedProduct || {};
    return selectedProduct.name;
  }

  render() {
    let {
      productsResponse: {
        response: { data: products, meta: pagination }
      },
      afterShow,
      afterHidden,
      input,
      meta,
      label,
      formClass,
      inputClass,
      showLabel,
      labelClass,
      disabled,
      helpText,
      helpTextClass,
      required,
      locales
    } = this.props;
    let { productsLoading } = this.state;
    return (
      <InputForm
        label={label}
        formClass={formClass}
        inputClass={inputClass}
        labelClass={labelClass}
        validationState={
          !disabled && meta.touched && meta.invalid ? "error" : null
        }
        showLabel={showLabel}
        helpText={helpText}
        helpTextClass={helpTextClass}
        required={required}
      >
        <div
          onClick={this.open}
          className="Select Select--single is-searchable has-value"
        >
          <input type="hidden" {...input} />
          <div className="Select-control">
            <span className="Select-multi-value-wrapper">
              <div className="Select-value">
                <span
                  className="Select-value-label"
                  role="option"
                  aria-selected="true"
                >
                  {this.selectedProductName()}
                </span>
              </div>
            </span>
            <span className="Select-arrow-zone">
              <span className="Select-arrow"></span>
            </span>
          </div>
        </div>
        <Modal
          show={this.state.showModal}
          bsSize="lg"
          backdrop="static"
          keyboard={false}
          onEntered={afterShow}
          onExited={afterHidden}
        >
          <Modal.Header>
            <Modal.Title className="text-center" componentClass="h3">
              {locales.modals.titles.productList}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Panel>
              <Panel.Body>
                <Row>
                  <Col xsOffset={2} xs={8}>
                    <ProductsList
                      loadProducts={this.loadProducts}
                      products={products}
                      pagination={pagination}
                      loading={productsLoading}
                      handleSearchName={this.handleSearchName}
                      handleClickProduct={this.handleClickProduct}
                      locales={locales}
                    />
                  </Col>
                </Row>
              </Panel.Body>
            </Panel>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col xsOffset={5} xs={2}>
                <Button
                  ref="sendBtn"
                  bsStyle="default"
                  onClick={() => {
                    this.setState({ waitingSelectedProduct: false });
                    this.close();
                  }}
                  block
                >
                  {locales.buttons.close}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </InputForm>
    );
  }
}

SelectProductsInput.propTypes = {
  afterHidden: PropTypes.func,
  afterShow: PropTypes.func,
  handleProductChange: PropTypes.func,
  searchProducts: PropTypes.func,
  productsResponse: PropTypes.shape({
    url: PropTypes.string,
    response: PropTypes.shape({
      data: PropTypes.array,
      meta: PropTypes.object
    })
  }),
  selectedProduct: PropTypes.object,
  locales: PropTypes.shape({
    modals: PropTypes.shape({
      titles: PropTypes.shape({
        productList: PropTypes.string
      })
    }),
    buttons: PropTypes.shape({
      close: PropTypes.string
    }),
    placeholders: PropTypes.shape({
      searchProducts: PropTypes.string
    }),
    hints: PropTypes.shape({
      notFoundProducts: PropTypes.string
    })
  }),

  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.any,
  afterOnChange: PropTypes.func,
  prefixAddon: PropTypes.any,
  suffixAddon: PropTypes.any,
  formClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  helpText: PropTypes.string,
  helpTextClass: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,

  // Redux Form
  // validate: PropTypes.array,
  // pristine: PropTypes.bool,
  // invalid: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object
};

SelectProductsInput.defaultProps = {
  searchProducts: (
    _url,
    _queryParams,
    _beforeSearch,
    _searchCallback,
    _beforeRetryCallback
  ) => {},
  afterHidden: _e => {},
  afterShow: _e => {},
  selectedProduct: {},
  locales: {
    modals: {
      titles: {
        productList: "Select lens"
      }
    },
    buttons: {
      close: "Close"
    },
    placeholders: {
      searchProducts: "Search name"
    },
    hints: {
      notFoundProducts: "You don't have any products in this list."
    }
  }
};
