import React, { Component } from "react";
import isBoolean from "lodash/isBoolean";
import isPresent from "utils/isPresent";
import {
  createStore,
  bindActionCreators,
  applyMiddleware,
  compose
} from "redux";
import ReduxThunk from "redux-thunk";
import { connect, Provider } from "react-redux";
import { change } from "redux-form";
import { rxLensForm as rxLens } from "reducers";
import * as rxLensActions from "actions/rxLensAction";
import * as rxLensFilterActions from "actions/rxLensFilterAction";
import update from "immutability-helper";
import NewRxOrderFormWithFilterLens from "components/ordering/redux_rx_lens/NewRxOrderFormWithFilterLens";
import { RX_ORDER as RX_ORDER_FORM_NAME } from "constants/reduxFormNames";

const mapStateToProps = (state, props) => {
  const {
    rxLensFilter: {
      canSelectProduct,
      canEditPower,
      searchingProducts,
      productsResponse,
      availableDesignsMaterials,
      filterStep,
      searchingIndexesDesigns
    },
    rxLens: { initializedOrder, order, loading, product },
    fetchErrors: {
      availableDesignsMaterialsFetchError,
      searchProductsByPowerFetchError,
      productChangeFetchError,
      glazingChangeFetchError,
      optThicknessChangeFetchError
    }
  } = state;
  return {
    availableDesignsMaterialsFetchError,
    searchProductsByPowerFetchError,
    productChangeFetchError,
    glazingChangeFetchError,
    optThicknessChangeFetchError,
    canEditPower: isBoolean(canEditPower) ? canEditPower : true,
    canSelectProduct: isBoolean(canSelectProduct) ? canSelectProduct : false,
    searchingProducts: isBoolean(searchingProducts) ? searchingProducts : false,
    searchingIndexesDesigns: isBoolean(searchingIndexesDesigns)
      ? searchingIndexesDesigns
      : false,
    availableDesignsMaterials,
    productsResponse: productsResponse || { url: null, response: {} },
    loading: loading || {},
    product: product,
    filterStep: filterStep || 0,
    initializedOrder: initializedOrder,
    order: isPresent(order) ? order : props.order
  };
};

const mapDispatchToProps = dispatch => {
  let creators = update(bindActionCreators(rxLensFilterActions, dispatch), {
    $merge: bindActionCreators(rxLensActions, dispatch)
  });
  // ref for change https://github.com/erikras/redux-form/blob/master/src/actions.js#L61
  const changeOrder = change.bind(null, RX_ORDER_FORM_NAME);
  creators = update(creators, {
    $merge: bindActionCreators({ changeOrder }, dispatch)
  });
  return {
    actions: creators
  };
};

const NewRxLensForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewRxOrderFormWithFilterLens);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rxLens,
  composeEnhancers(applyMiddleware(ReduxThunk))
);

export default class NewRxLensApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      order: {}
    };
  }

  render() {
    return (
      <Provider store={store}>
        <NewRxLensForm {...this.props} />
      </Provider>
    );
  }
}
