import React, { FC } from "react";
import { ComponentProps, UIState } from "./types";
import { Button } from "react-bootstrap";

export const ReviewButton: FC<ComponentProps & {
  newOrder: () => void;
  buttonText?: string;
}> = props => {
  const { newOrder, buttonText } = props;
  const boundCallback = () => {
    newOrder();
  };
  return (
    <Button
      onClick={boundCallback}
      bsStyle="primary"
      block
      children={buttonText}
    />
  );
};
