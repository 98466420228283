import React from 'react';
import PropTypes from 'prop-types';
import { Button, Glyphicon } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

export default class BulkOrderDownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.renderButtonText = this.renderButtonText.bind(this);
    this.download = this.download.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  download (e) {
    this.setState({loading: true});
    e.preventDefault();
    const { url } = this.props;
    window.location.href = url;
    setTimeout(()=>{
      this.setState({loading: false});
    }, 3000);
  }

  renderButtonText () {
    const { label } = this.props;
    const { loading } = this.state;
    if(loading) return (
      <span>
        <Glyphicon glyph="refresh" className="glyphicon-refresh-animate" />
        &nbsp;{label}
      </span>

    );
    return (
      <span><Glyphicon glyph="save" />&nbsp;{label}</span>
    );
  }

  renderTooltip () {
    const { url: tooltipID } = this.props;
    return(
      <span className="text-center">
        <ReactTooltip
          id={tooltipID}
          place="bottom"
          type="dark"
          effect="solid"
          resizeHide={false}
        >
          Download txt file for SO
        </ReactTooltip>
      </span>
    );
  }

  renderButton () {
    const { disabled, loading } = this.state;
    const { url, bsStyle, bsSize, block } = this.props;
    let { className } = this.props;
    if(block) className = `btn-block ${className}`;
    return(
      <Button
        className={className}
        href={url}
        block={block}
        bsStyle={bsStyle}
        bsSize={bsSize}
        disabled={disabled || loading}
        onClick={this.download}
      >
        { this.renderButtonText() }
      </Button>
    );
  }

  render () {
    const { className, url, showTooltip } = this.props;
    return(
      <span className="hidden-print">
        <div data-tip={true} data-for={url} className={className}>
          { this.renderButton() }
        </div>
        { showTooltip && this.renderTooltip() }
      </span>
    );
  }
}

BulkOrderDownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  bsSize: PropTypes.oneOf(["lg", "large", "sm", "small", "xs", "xsmall"]),
  bsStyle: PropTypes.oneOf(["success", "warning", "danger", "info", "default", "primary", "link"]),
  label: PropTypes.string,
  block: PropTypes.bool,
  disabled: PropTypes.bool,

};

BulkOrderDownloadButton.defaultProps = {
  bsStyle: 'default',
  block: false,
  disabled: false,
  showTooltip: false,
};
