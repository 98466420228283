import filter from "lodash/filter";
import dropWhile from "lodash/dropWhile";
import isEmpty from "lodash/isEmpty";
import { actionNames } from "constants.js";
import update from "immutability-helper";

const productsList = (state = {}, action) => {
  switch (action.type) {
    case actionNames.UPDATE_PRODUCT_MANAGEMENT_LOADING: {
      const loading = update(state.loading || {}, {
        $merge: action.loading
      });
      return update(state, { $merge: { loading } });
    }
    case actionNames.MOVE_PRODUCT: {
      const formList = state[action.fromList] || [];
      const toList = state[action.toList] || [];
      const newState = {};
      newState[action.fromList] = filter(formList, product => {
        return product.id != action.product.id;
      });
      newState[action.toList] = [action.product, ...toList];
      return update(state, { $merge: newState });
    }
    case actionNames.ADD_STORE_SKU: {
      return update(state, {
        $merge: {
          storeSKUs: [action.sku, ...state.storeSKUs]
        }
      });
    }
    case actionNames.DELETE_STORE_SKU: {
      return update(state, {
        $merge: {
          storeSKUs: dropWhile(state.storeSKUs, sku => {
            return sku == action.sku;
          })
        }
      });
    }
    case actionNames.SAVE_PRODUCTS: {
      return update(state, {
        $merge: {
          products: action.products
        }
      });
    }
    case actionNames.SAVE_AVAILABLED_PRODUCTS: {
      return update(state, {
        $merge: {
          availabledProducts: action.products
        }
      });
    }
    case actionNames.UPDATE_PRODUCT_PAGINATION: {
      let paginationParams = state.paginationParams || {};
      const metadata = action.metadata;
      if (!isEmpty(metadata)) {
        paginationParams = update(paginationParams, {
          $merge: {
            [action.source]: metadata
          }
        });
      }
      const changedMeta = action.changedMeta;
      if (!isEmpty(changedMeta)) {
        const meta = paginationParams[action.source];
        meta.total = meta.total + changedMeta.total;
        meta.total_pages = Math.ceil(meta.total / meta.per_page);

        paginationParams = update(state.paginationParams || {}, {
          $merge: {
            [action.source]: meta
          }
        });
      }

      return update(state, { $merge: { paginationParams } });
    }
    case actionNames.UPDATE_SKUS: {
      return update(state, {
        $merge: {
          storeSKUs: action.skus
        }
      });
    }
    default: {
      return state;
    }
  }
};

export default productsList;
