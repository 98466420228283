import React from 'react';
import PropTypes from 'prop-types';
// import $ from 'jquery';
import { Field } from 'redux-form';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { Row, Glyphicon, Col } from 'react-bootstrap';
import TextInput from 'components/default_redux/TextInput';
import ProductLabel from 'components/users/products/ProductLabel';
import Loading from 'components/shared/Loading';
import ReactPaginate from 'react-paginate';

export default class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  searchName (name) {
    const ms = 1000; // milliseconds
    clearTimeout(this.state.liveSearch);
    let liveSearch = setTimeout(()=> {
      this.props.handleSearchName(name);
    }, ms);
    this.setState({liveSearch, name});
  }

  renderList () {
    const { loading, products, handleClickProduct, locales} = this.props;
    const { pagination: { per_page } } = this.props;
    if(loading) return (
      <div>
        <br/>
        <Loading position="left" size="md" />
      </div>
    );
    if(products.length == 0) return (
      <p className="text-warning">{locales.hints.notFoundProducts}</p>
    );

    const displayProducts = (per_page && products.length > per_page ? products.slice(0, per_page - 1) : products);
    return(
      <table className="product-list table table-hover">
        <tbody>
          {
            map(displayProducts, (product, index)=>{
              return (
                <ProductLabel
                  key={`${index}-${product.id}`}
                  product={product}
                  handleClick={handleClickProduct}
                />
              );
            })
          }
        </tbody>
      </table>
    );
  }

  handlePageClick (data) {
    const nextPage = data.selected + 1;
    const { loadProducts, pagination: { per_page } } = this.props;
    const { name } = this.state;
    const queryParams = {
      page: nextPage,
      per_page,
      filters: {
        name
      }
    };
    loadProducts(queryParams);
  }

  renderPagination () {
    const { pagination } = this.props;
    if(isEmpty(pagination)) return null;
    const pageCount = pagination.total_pages;
    if(pageCount <= 1) return null;
    return(
      <ReactPaginate
        pageCount={pageCount}
        forcePage={pagination.current_page - 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        previousLabel="«"
        nextLabel="»"
        breakLabel={<a>...</a>}
        breakClassName="break-me"
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
      />
    );
  }

  render () {
    const {
      type,
      locales
    } = this.props;
    return (
      <Row>
        <Field component={TextInput}
          label="Search"
          type="text"
          name={`${type}Text`}
          afterOnChange={this.searchName.bind(this)}
          labelClass=""
          inputClass="col-xs-8"
          showLabel={false}
          placeholder={locales.placeholders.searchProducts}
          prefixAddon={<Glyphicon glyph="search" />}
        />
        <Col xs={12}>
          <br/>
          { this.renderList.bind(this)() }
        </Col>
        <Col xs={12}>
          { this.renderPagination.bind(this)() }
        </Col>
      </Row>
    );
  }
}

ProductList.propTypes = {
  products: PropTypes.array,
  loading: PropTypes.bool,
  handleClickProduct: PropTypes.func,
  handleSearchName: PropTypes.func,
  loadProducts: PropTypes.func,
  type: PropTypes.oneOf(['products', 'availabledProducts']),
  pagination: PropTypes.object,
  locales: PropTypes.shape({
    placeholders: PropTypes.shape({
      searchProducts: PropTypes.string,
    }),
    hints: PropTypes.shape({
      notFoundProducts: PropTypes.string,
    }),
  }),
};

ProductList.defaultProps = {
  products: [],
  loadProducts: (_queryParams)=>{},
  handleClickProduct: (_product)=>{},
  handleSearchName: (_name)=>{},
  loading: true,
  pagination: {},
  locales: {
    placeholders: {
      searchProducts: 'Search name'
    },
    hints: {
      notFoundProducts: "You don't have any products in this list.",
    },
  }
};
