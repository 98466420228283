import React, { Component } from "react";
import {
  createStore,
  bindActionCreators,
  applyMiddleware,
  compose
} from "redux";
import thunk from "redux-thunk";
import { connect, Provider } from "react-redux";
import { userForm as userFormReducer } from "reducers";
import * as userFormAction from "actions/userFormAction";
// import update from 'immutability-helper';
import UserForm from "components/users/UserForm";

const mapStateToProps = (_state, _props) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(userFormAction, dispatch);
};

const UserFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  userFormReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default class UserFormApp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Provider store={store}>
        <UserFormContainer {...this.props} />
      </Provider>
    );
  }
}
