import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SelectInput from 'components/default_redux/SelectInput';
import { required } from 'utils/Validators';
import Loading from 'components/shared/Loading';

export default class FrameShapeId extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    const { label, show, name, options, loading } = this.props;
    if(!show) return null;
    if(loading && loading.frameId) return(
      <Loading
        size='md'
        iconStyle='loading'
      />
    );

    const requiredInput = this.props.required;
    return (
      <Field component={SelectInput}
        name={name}
        label={label}
        options={options.map(frameId => {
                  return({
                    name: frameId.number,
                    value: frameId.id
                  });
                })
        }
        labelClass='col-xs-3'
        selectWrapperClass='col-xs-6'
        required={requiredInput}
        validate={[required.bind(null, requiredInput)]}
      />
    );
  }
}
FrameShapeId.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array,
  loading: PropTypes.object,
};

FrameShapeId.defaultProps = {
  label: "Frame Id",
  show: false,
  required: false,
  options: [],
};
