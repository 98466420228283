import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import { Row, Col, Form } from 'react-bootstrap';
import { Field } from 'redux-form';
import DateInput from 'components/default_redux/DateInput';
import SelectInput from 'components/default_redux/SelectInput';
import TextInput from 'components/default_redux/TextInput';
import SelectAgent from 'components/shared/SelectUser';

export default class OrdersFilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.sortCustomers = this.sortCustomers.bind(this);
  }

  sortCustomers () {
    const { agents } = this.props;
    return sortBy(agents, (agent)=>{
      return [agent.role, agent.company];
    });
  }

  onSubmit (data) {
    const { onSubmit } = this.props;
    onSubmit(data);
  }

  // NOTE:: If you add more filter input, don't fogot to add display filter at index.csv.erb
  render () {
    const {
      createdPeriod, orderStatuses, orderTypes, submitButton,
      handleSubmit, pristine, submitting, submitSucceeded,
      searchAgentsUrl,
      locales: {
        form: {
          patient: patientLabel,
          code: codeLabel,
          userId: agentLabel,
          status: statusLabel,
          startDate: startDateLabel,
          endDate: endDateLabel,
          orderType: typeLabel,
        }
      }
    } = this.props;
    const dateFormat = "DD/MM/YYYY";
    const startDate = createdPeriod.start ? moment(createdPeriod.start).format(dateFormat) : null;
    const endDate = createdPeriod.end ? moment(createdPeriod.end).format(dateFormat) : null;
    return(
      <Form
        className="form-horizontal"
        onSubmit={handleSubmit(this.onSubmit.bind(this))}
      >
        <Field component={TextInput}
          label={patientLabel}
          type="text"
          name={`patient`}
          labelClass="col-xs-3"
          inputClass="col-xs-7"
        />
        <Field component={TextInput}
          label={codeLabel}
          type="text"
          name={`code`}
          labelClass="col-xs-3"
          inputClass="col-xs-7"
        />
        <SelectAgent
          label={agentLabel}
          name="user_id"
          loadUrl={searchAgentsUrl}
          labelClass="col-xs-3"
          inputClass="col-xs-7"
          liveSearch={true}
          selectFirst={false}
          required={false}
          blankDisplay="All"
        />
        <Field component={SelectInput}
          name={`type`}
          label={typeLabel}
          options={orderTypes}
          liveSearch={true}
          blankDisplay="All"
          labelClass='col-xs-3'
          selectWrapperClass='col-xs-7'
        />
        <Field component={SelectInput}
          name={`status`}
          label={statusLabel}
          options={orderStatuses}
          liveSearch={true}
          blankDisplay="All"
          labelClass='col-xs-3'
          selectWrapperClass='col-xs-7'
        />
        <Row>
          <Col xs={6}>
            <Field component={DateInput}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat={dateFormat}
              isClearable
              label={startDateLabel}
              name={`start_date`}
              labelClass="col-xs-6"
              inputClass="col-xs-6"
            />
          </Col>
          <Col xs={6}>
            <Field component={DateInput}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              dateFormat={dateFormat}
              isClearable
              label={endDateLabel}
              name={'end_date'}
              labelClass="col-xs-2"
              inputClass="col-xs-6"
            />
          </Col>
        </Row>

        <Row>
          <Col xsOffset={3} xs={7}>
            { submitButton(pristine, submitting, submitSucceeded) }
          </Col>
        </Row>
      </Form>
    );
  }
}

OrdersFilterForm.propTypes = {
  formUrl: PropTypes.string,
  onSubmit: PropTypes.func,
  submitButton: PropTypes.func,
  agents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      company: PropTypes.string,
      role: PropTypes.string
    })
  ),
  orderTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ]).isRequired
    })
  ),
  orderStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ]).isRequired
    })
  ),
  searchAgentsUrl: PropTypes.string.isRequired,
  locales: PropTypes.shape({
    form: PropTypes.shape({
      patient: PropTypes.string,
      code: PropTypes.string,
      userId: PropTypes.string,
      status: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      orderType: PropTypes.string,
    }).isRequired,
  }).isRequired,

  // Redux form
  createdPeriod: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  submitSucceeded: PropTypes.bool,

};

OrdersFilterForm.defaultProps = {
  submitButton: (_pristine, _submitting, _submitSucceeded) => {
    return 'Submit';
  },
  dateFormat: "DD/MM/YYYY",
};
