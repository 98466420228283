import React, { Component } from "react";
import {
  createStore,
  bindActionCreators,
  applyMiddleware,
  compose
} from "redux";
import thunk from "redux-thunk";
import { connect, Provider } from "react-redux";
import { usersSearchingForm as usersSearchingReducer } from "reducers";
// import * as stockLensActions from 'actions/stockLensAction';
// import update from 'immutability-helper';
import UsersSearching from "components/users/UsersSearching";

const mapStateToProps = (_state, _props) => {
  // const orders = _.map((state.form.stockOrder || { values: {}}).values.orders, (order)=>{
  //   return _.pick(order, 'sku');
  // });
  // return {
  //   orders
  // };
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

const UsersSearchingApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersSearching);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  usersSearchingReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default class UsersSearchingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Provider store={store}>
        <UsersSearchingApp {...this.props} />
      </Provider>
    );
  }
}
