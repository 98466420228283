import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import InsetType from './InsetType';
import InsetDistance from './InsetDistance';
import RxPrescriptionSide from './RxPrescriptionSide';
import { READING_DISTANCE_OPTION } from '../../../../constants/mapOrderColumns';

export default class RxPrescriptionForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    // console.log(`Render Prescription Form`);
    const {
      product, changeOrder, insetType,
      rightQuantity, leftQuantity,
      rightCyl, leftCyl,
      rightDecenter, leftDecenter,
      rightPrism, leftPrism,
      rightFarPD, leftFarPD,
      product: {
        properties: {
          enabled_inset,
          enabled_normal_reading, normal_reading_values
        }
      },
      locales
    } = this.props;
    return (
      <div>
        <Row>
          <Col xs={6}>
            { (enabled_inset.show ||
            enabled_normal_reading.show) && <InsetType
              label="Inset Type"
              show={
                enabled_inset.show ||
                enabled_normal_reading.show
              }
              product={product}
            /> }
            { (enabled_normal_reading.show &&
            this.props.insetType == READING_DISTANCE_OPTION) && <InsetDistance
              label="Customized Reading Distance"
              show={
                enabled_normal_reading.show &&
                this.props.insetType == READING_DISTANCE_OPTION
              }
              {...normal_reading_values}
              required={true}
              showLabel={false}
            /> }
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <RxPrescriptionSide
              side="right"
              changeOrder={changeOrder}
              product={product}
              insetType={insetType}
              quantity={rightQuantity}
              cyl={rightCyl}
              decenter={rightDecenter}
              prism={rightPrism}
              farPD={rightFarPD}
              locales={locales}
            />
          </Col>
          <Col xs={6}>
            <RxPrescriptionSide
              side="left"
              changeOrder={changeOrder}
              product={product}
              insetType={insetType}
              quantity={leftQuantity}
              cyl={leftCyl}
              decenter={leftDecenter}
              prism={leftPrism}
              farPD={leftFarPD}
              locales={locales}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

RxPrescriptionForm.propTypes = {
  product: PropTypes.shape({
    properties: PropTypes.shape({
      enabled_inset: PropTypes.object.isRequired,
      enabled_normal_reading: PropTypes.object.isRequired,
      normal_reading_values: PropTypes.object,
    })
  }),
  locales: PropTypes.object,
  changeOrder: PropTypes.func.isRequired,

  insetType: PropTypes.any,
  rightQuantity: PropTypes.any,
  leftQuantity: PropTypes.any,
  leftCyl: PropTypes.any,
  rightCyl: PropTypes.any,
  leftDecenter: PropTypes.any,
  rightDecenter: PropTypes.any,
  leftPrism: PropTypes.any,
  rightPrism: PropTypes.any,
  leftFarPD: PropTypes.any,
  rightFarPD: PropTypes.any,
};

RxPrescriptionForm.defaultProps = {
  product: {}
};
