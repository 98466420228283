import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import isPresent from 'utils/isPresent';
import InputForm from 'components/default_redux/InputForm';

const filterProps = props => {
  const InputProps = [
    // HTML attributes
    "placeholder",
    "type",
    "disabled",
    "step"
  ];
  return pick(props, InputProps);
};

class TextInput extends React.Component {
  componentDidMount () {
    const { defaultValue, input: { onChange, value: currentValue } } = this.props;
    if(!isPresent(currentValue) && !isNil(defaultValue)) onChange(defaultValue);
  }

  componentWillUnmount() {
    const { input: { onChange } } = this.props;
    onChange(null);
  }

  onChange(e) {
    const value = e.target.value;
    this.props.input.onChange(value);
    this.props.afterOnChange(value);
  }

  render () {
    const {
      label, input, formClass, inputClass,
      labelClass, disabled, meta,
      suffixAddon, prefixAddon, showLabel,
      helpText, helpTextClass,
      required, readOnly
    } = this.props;
    return (
      <InputForm
        label={label}
        labelFor={input.name}
        formClass={formClass}
        inputClass={inputClass}
        labelClass={labelClass}
        validationState={
          (!disabled && (meta.touched && meta.invalid)) ? 'error' : null
        }
        prefixAddon={prefixAddon}
        suffixAddon={suffixAddon}
        showLabel={showLabel}
        helpText={helpText}
        helpTextClass={helpTextClass}
        required={required}
      >
        <input type="text"
          {...input}
          {...filterProps(this.props)}
          readOnly={readOnly}
          onChange={this.onChange.bind(this)}
          className="form-control"
          onKeyPress={e => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        />
      </InputForm>
    );
  }
}

TextInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.any,
  afterOnChange: PropTypes.func,
  prefixAddon: PropTypes.any,
  suffixAddon: PropTypes.any,
  formClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  helpText: PropTypes.string,
  helpTextClass: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  readOnly: PropTypes.bool,

  // Redux Form
  validate: PropTypes.array,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  input: PropTypes.object,
};
TextInput.defaultProps = {
  afterOnChange: (value)=> { return value; }
};
export default TextInput;
