import React from "react";
import PropTypes from "prop-types";
import Csrf from "utils/Csrf";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import update from "immutability-helper";
import { connect } from "react-redux";
import {
  reduxForm,
  Field,
  formValueSelector,
  SubmissionError
} from "redux-form";
import SelectInput from "components/default_redux/SelectInput";
import TextInput from "components/default_redux/TextInput";
import SwitchInput from "components/default_redux/SwitchInput";
import HiddenInput from "components/default_redux/HiddenInput";
import SelectParent from "components/users/SelectParent";
import TogglePassword from "components/users/TogglePassword";
import UserExtraFields from "components/users/UserExtraFields";
import { Row, Col, Button, Form, Panel } from "react-bootstrap";
import * as Validators from "utils/Validators";
import {
  ROLE,
  STATUS,
  USERNAME,
  COMPANY,
  NAME,
  EMAIL,
  POSITION,
  PHONE,
  PARENT_ID
} from "constants/mapUserColumns";
import { USER_ORDER_FORM } from "constants/reduxFormNames";

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isAdminMode = this.isAdminMode.bind(this);
  }

  renderCompanyTemplate() {
    if (!this.isCustomer()) return null;
    const {
      locales: { labels }
    } = this.props;
    return (
      <Field
        component={TextInput}
        label={labels.company}
        type="text"
        name={`user[${COMPANY}]`}
        inputClass="col-xs-9"
        labelClass="col-xs-3"
        disabled={true}
      />
    );
  }

  isCustomer() {
    return this.props.scope == "Customer User";
  }

  isAdminMode() {
    return this.props.mode == "admin";
  }

  onSubmit(values) {
    const userData = values.user || {};
    const user = update(userData, {
      $merge: {
        status: userData.status ? "enabled" : "disabled"
      }
    });
    const { scope, redirectAfterSubmitSuccessUrl } = this.props;

    return fetch(this.props.formUrl, {
      credentials: "same-origin",
      method: this.props.action,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        authenticity_token: Csrf.token(),
        scope,
        user,
        return_to: redirectAfterSubmitSuccessUrl
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.errors) {
          throw {
            name: "User form invalid",
            status: 422,
            errors: { user: json.errors }
          };
        }
        window.location.href = json.location;
      })
      .catch(e => {
        switch (e.status) {
          case 422:
            e.errors._error = map(e.errors.user, (error, key) => {
              return <p key={key}>{`${key} ${error}`}</p>;
            });
            throw new SubmissionError(e.errors);
          default:
            throw new SubmissionError({ _error: e.message });
        }
      });
  }

  submitText() {
    const {
      action,
      locales: { buttons }
    } = this.props;
    let text = "";
    switch (action) {
      case "post":
        text = buttons.post;
        break;
      case "put":
        text = buttons.put;
        break;
      default:
        text = buttons.put;
    }
    return text;
  }

  handleRoleChange(_e) {
    const { change } = this.props;
    change(`user[${PARENT_ID}]`, null);
  }

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      error,
      submitSucceeded,
      valid,
      extraFields,
      user,
      scope,
      locales,
      backUrl,
      locales: { labels }
    } = this.props;
    return (
      <Form
        className="form-horizontal"
        onSubmit={handleSubmit(this.onSubmit.bind(this))}
      >
        <Row>
          <Col xs={8}>
            <Panel>
              <Panel.Heading>
                <h3>{this.props.locales.words.account}</h3>
              </Panel.Heading>
              <Panel.Body>
                <Field component={HiddenInput} name="scope" value={scope} />
                {this.isAdminMode() && (
                  <Field
                    component={SelectInput}
                    name={`user[${ROLE}]`}
                    label={labels.role}
                    options={map(this.props.roles, role => {
                      return { name: role, value: role };
                    })}
                    searchable={true}
                    labelClass="col-xs-3"
                    selectWrapperClass="col-xs-9"
                    required={true}
                    validate={[Validators.required.bind(null, true)]}
                    handleChange={this.handleRoleChange.bind(this)}
                  />
                )}
                {this.isAdminMode() && (
                  <SelectParent
                    currentRole={this.props.currentRole}
                    // parentUsers={this.props.parentUsers}
                    sourcesUrl={this.props.otherSources}
                    // loadParents={this.props.loadParents}
                    locales={this.props.locales}
                  />
                )}

                {this.renderCompanyTemplate.bind(this)()}

                {this.isAdminMode() && (
                  <Field
                    component={SwitchInput}
                    name={`user[${STATUS}]`}
                    label={labels.status}
                    labelClass="col-xs-3"
                    inputWaraperClass="col-xs-9"
                    offText="Disable"
                    onText="Enable"
                  />
                )}

                <Field
                  component={TextInput}
                  label={labels.username}
                  type="text"
                  name={`user[${USERNAME}]`}
                  labelClass="col-xs-3"
                  inputClass="col-xs-9"
                  disabled={
                    this.props.action == "put" && user.username ? true : false
                  }
                  validate={[Validators.required.bind(null, true)]}
                  required={true}
                />

                <TogglePassword
                  validate={this.props.formValidate.password}
                  action={this.props.action}
                  canToggle={
                    this.props.action == "put" && !isEmpty(user.username)
                  }
                  enabled={
                    this.props.action == "post" ||
                    (this.props.action == "put" && isEmpty(user.username))
                  }
                  locales={this.props.locales}
                />
              </Panel.Body>
            </Panel>
            <Panel>
              <Panel.Heading>
                <h3>{this.props.locales.words.information}</h3>
              </Panel.Heading>
              <Panel.Body>
                <Field
                  component={TextInput}
                  label={labels.name}
                  type="text"
                  name={`user[${NAME}]`}
                  labelClass="col-xs-3"
                  inputClass="col-xs-9"
                />

                <Field
                  component={TextInput}
                  label={labels.email}
                  type="email"
                  name={`user[${EMAIL}]`}
                  validate={[Validators.email]}
                  labelClass="col-xs-3"
                  inputClass="col-xs-9"
                />

                <Field
                  component={TextInput}
                  label={labels.position}
                  type="text"
                  name={`user[${POSITION}]`}
                  labelClass="col-xs-3"
                  inputClass="col-xs-9"
                />

                <Field
                  component={TextInput}
                  label={labels.phone}
                  type="tel"
                  name={`user[${PHONE}]`}
                  labelClass="col-xs-3"
                  inputClass="col-xs-9"
                />
              </Panel.Body>
            </Panel>
            <Row>
              <Col xs={3}>
                <a href={backUrl}>
                  <Button bsStyle="link">{locales.buttons.back}</Button>
                </a>
              </Col>
              <Col xs={9}>
                <Col xs={12}>
                  <Button
                    type="submit"
                    ref="confirmBtn"
                    bsStyle="primary"
                    disabled={
                      pristine || submitting || (submitSucceeded && valid)
                    }
                    block
                  >
                    {this.submitText.bind(this)()}
                  </Button>
                </Col>
              </Col>
            </Row>

            {error && (
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xsOffset={3} xs={9}>
                      <br />
                      <div className="text-center text-danger">{error}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
          {this.isAdminMode() && scope == "Customer User" && (
            <UserExtraFields
              user={user}
              extraFields={extraFields}
              locales={locales}
            />
          )}
        </Row>
      </Form>
    );
  }
}

UserForm.propTypes = {
  mode: PropTypes.oneOf(["admin", "user"]),
  user: PropTypes.object,
  action: PropTypes.string,
  formUrl: PropTypes.string,
  roles: PropTypes.array,
  locales: PropTypes.object,
  otherSources: PropTypes.object,
  scope: PropTypes.oneOf(["Customer User", "TOG User"]),
  formValidate: PropTypes.object,
  extraFields: PropTypes.array,
  redirectAfterSubmitSuccessUrl: PropTypes.string,
  backUrl: PropTypes.string,

  // Redux
  loadParents: PropTypes.func,
  // Redux Form
  currentRole: PropTypes.any,
  parentUsers: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  error: PropTypes.any,
  change: PropTypes.func
};

UserForm.defaultProps = {
  mode: "admin",
  user: {},
  action: "post",
  roles: [],
  locales: {}
};

let form = reduxForm({
  form: USER_ORDER_FORM
})(UserForm);

const selector = formValueSelector("userForm"); // <-- same as form name
form = connect((state, props) => {
  const userData = props.user || {};
  const user = {
    username: userData.username,
    status: userData.status == "enabled",
    name: userData.name,
    company: userData.company,
    email: userData.email,
    position: userData.position,
    phone: userData.phone,
    role: userData.roles[0],
    parent_id: (userData.parent || {}).id,
    extra_fields: userData.extra_fields || []
  };
  const initialValues = { user };
  const currentRole = selector(state, "user.role");
  return {
    initialValues,
    currentRole
  };
})(form);

export default form;
