import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextInput from 'components/default_redux/TextInput';
import * as Validators from 'utils/Validators';
import { PASSWORD, PASSWORD_CONFIRMATION } from 'constants/mapUserColumns';

export default class TogglePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enablePassword: props.enabled,
    };
  }

  toggleEnablePassword () {
    let flag = !this.state.enablePassword;
    this.setState({enablePassword: flag});
  }

  _changePasswordButton () {
    if(!this.props.canToggle) return null;
    return (
      <div className="row">
        <div className="col-xs-offset-3 col-xs-9">
          <a onClick={this.toggleEnablePassword.bind(this)} style={{cursor: 'pointer'}}>
            {this.props.locales.words.changePassword}
          </a>
        </div>
      </div>
    );
  }

  _passwordTemplate () {
    if(!this.state.enablePassword) return null;
    return (
      <div>
        <Field component={TextInput}
          label={this.props.locales.labels.password}
          type="password"
          name={`user[${PASSWORD}]`}
          labelClass="col-xs-3"
          inputClass="col-xs-9"
          required={true}
          validate={[
            Validators.required.bind(null, true),
            Validators.minLength.bind(null, this.props.validate.min),
            Validators.maxLength.bind(null, this.props.validate.max)
          ]}
        />

        <Field component={TextInput}
          label={this.props.locales.labels.passwordConfirmation}
          type="password"
          name={`user[${PASSWORD_CONFIRMATION}]`}
          labelClass="col-xs-3"
          inputClass="col-xs-9"
          required={true}
          validate={[
            Validators.required.bind(null, true),
            Validators.minLength.bind(null, this.props.validate.min),
            Validators.maxLength.bind(null, this.props.validate.max),
            Validators.same.bind(null, `user.${PASSWORD}`),
          ]}
        />
      </div>
    );
  }

  render () {
    return (
      <div>
        { this._changePasswordButton.bind(this)() }
        { this._passwordTemplate.bind(this)() }
      </div>
    );
  }
}

TogglePassword.propTypes = {
  enabled: PropTypes.bool,
  canToggle: PropTypes.bool,
  action: PropTypes.string,
  user: PropTypes.object,
  locales: PropTypes.object,
  validate: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
};

TogglePassword.defaultProps = {
  enabled: false,
  action: 'post',
  locales: {}
};
