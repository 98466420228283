import React from 'react';
import PropTypes from 'prop-types';
import { TINT_FIELD } from '../../../../constants/orderFields';
import find from 'lodash/find';
import { Field } from 'redux-form';
import SelectInput from '../../../default_redux/SelectInput';
import RxOptionTintPercentage from './TintPercentage';
import { required } from '../../../../utils/Validators';
import Loading from '../../../../components/shared/Loading';

export default class RxOptionTint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    if(!this.props.show) return null;
    if(this.props.loading) return <Loading size="md" />;
    const tint = find(this.props.tintValues, { id: this.props.tintId });

    return (
      <div>
        <Field component={SelectInput}
          name={TINT_FIELD}
          label={this.props.label}
          options={
            this.props.tintValues.map((value) => {
              return({
                name: (value.tint.display_name || value.tint.name),
                value: value.id
              });
            })
          }
          liveSearch={true}
          labelClass='col-xs-3'
          selectWrapperClass='col-sm-6'
          required={this.props.required}
          validate={[required.bind(null, this.props.required)]}
          placeholder="(No Tint)"
        />
        {
          tint ? <RxOptionTintPercentage
            label={this.props.labelPecentage}
            show={true}
            tintValue={this.props.tintValue}
            tintColors={tint.colors}
            tintValues={tint.values}
          /> : null
        }
      </div>
    );
  }
}
RxOptionTint.propTypes = {
  label: PropTypes.string,
  labelPecentage: PropTypes.string,
  tintId: PropTypes.number,
  tintValue: PropTypes.string,
  tintValues: PropTypes.array,
  required: PropTypes.bool,
  show: PropTypes.bool,
  loading: PropTypes.bool,
};

RxOptionTint.defaultProps = {
  label: 'Tint',
  labelPecentage: 'Tint %',
  tintValues: [],
  required: false,
  show: false,
  loading: false
};
