import React from 'react';
import PropTypes from 'prop-types';
// import { CUSTOMIZE_INSET_OPTION } from 'constants/mapOrderColumns';
import { mapDatabase } from  'constants.js';
import isPresent from 'utils/isPresent';
// import Quantity from './Quantity';
import SPH from 'components/ordering/prescription/SPH';
import CYL from 'components/ordering/prescription/CYL';
import AXIS from 'components/ordering/prescription/AXIS';
import ADD from 'components/ordering/prescription/ADD';
import FarPD from 'components/ordering/prescription/FarPD';
import NearPD from 'components/ordering/prescription/NearPD';
import FH from 'components/ordering/prescription/FH';
import SH from 'components/ordering/prescription/SH';

export default class PrescriptionBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.lensSideAttrName = this.lensSideAttrName.bind(this);
    this.attrNestedName = this.attrNestedName.bind(this);
    this.renderNoSide = this.renderNoSide.bind(this);
    this.hasValue = this.hasValue.bind(this);
    this.hasCylValue = this.hasCylValue.bind(this);
  }

  lensSideAttrName () {
    return mapDatabase.order.properties.side[this.props.side];
  }

  attrNestedName () {
    return `[${this.lensSideAttrName()}]`;
  }

  // TODO, check that can be use or not?
  hasValue (attr) {
    const value = this.props[attr];
    return value && parseInt(value) != 0;
  }

  hasCylValue () {
    const { cyl } = this.props.lenPower;
    return cyl && parseInt(cyl) != 0;
  }

  renderNoSide () {
    const {
      locales: {
        hints: {
          disabledSideLens: {
            [this.props.side]: disabledSideLensText
          }
        }
      }
    } = this.props;
    return(
      <p className="text-center">
        {disabledSideLensText}
      </p>
    );
  }

  displayOptionFields () {
    const { lenPower } = this.props;
    if (!isPresent(lenPower) || parseInt(lenPower.quantity) <= 0) return this.renderNoSide();

    const {
      name, change,
      properties: {
        enabled_sph, sph_values,
        enabled_cyl, cyl_values,
        enabled_axis, axis_values,
        enabled_add, add_values,
        enabled_far_pd, far_pd_values,
        enabled_near_pd,
        enabled_fitting_height, fitting_height_values,
        enabled_seg_height, seg_height_values
      },
      lenPower: { farPD }
    } = this.props;
    return(
      <div>
        { enabled_sph.show && <SPH
          name={name}
          label="SPH"
          {...enabled_sph}
          {...sph_values}
        /> }
        { enabled_cyl.show && <CYL
          name={name}
          label="CYL"
          {...enabled_cyl}
          {...cyl_values}
        /> }
        { enabled_axis.show && <AXIS
          name={name}
          label="AXIS"
          show={enabled_axis.show}
          required={this.hasCylValue()}
          {...axis_values}
        /> }
        { enabled_add.show && <ADD
          name={name}
          label="ADD"
          {...enabled_add}
          {...add_values}
        /> }
        { enabled_far_pd.show && <FarPD
          name={name}
          label="Far PD"
          change={change}
          {...enabled_far_pd}
          {...far_pd_values}
        /> }
        { (farPD && enabled_near_pd.show) && <NearPD
          name={name}
          label="Near PD"
          farPD={farPD}
          fixValue={true}
          {...enabled_near_pd}
        /> }
        { enabled_fitting_height.show && <FH
          name={name}
          label="Fitting Height"
          {...enabled_fitting_height}
          {...fitting_height_values}
        /> }
        { enabled_seg_height.show && <SH
          name={name}
          label="Seg Height"
          {...enabled_seg_height}
          {...seg_height_values}
        /> }
      </div>
    );
  }


  render () {
    return (
      <div className="panel panel__bg--gray">
        <div className="panel-heading">
          <h3 className="panel-title text-center"
            style={{textTransform: 'uppercase'}}
          >
            {this.props.side}
          </h3>
        </div>
        <div className="panel-body">
          { this.displayOptionFields.bind(this)() }
        </div>
      </div>
    );
  }
}

PrescriptionBox.propTypes = {
  side: PropTypes.oneOf(['left', 'right']),
  name: PropTypes.string,
  order: PropTypes.object,
  properties: PropTypes.object,
  locales: PropTypes.shape({
    hints: PropTypes.shape({
      disabledSideLens: PropTypes.shape({
        left: PropTypes.string,
        right: PropTypes.string,
      }),
    }),
  }),
  lenPower: PropTypes.object,
  change: PropTypes.func,
};

PrescriptionBox.defaultProps = {
};
