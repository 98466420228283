import "core-js/stable";
import "regenerator-runtime/runtime";


// Patch to use browserify package.
// var React = window.React = global.React = require('react');
// var ReactDOM = window.ReactDOM = global.ReactDOM = require('react-dom');
// import React from 'react';
// import ReactDOM from 'react-dom';
// import PropTypes from 'prop-types';

import 'components';
import 'containers';
