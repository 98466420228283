import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import {
  ORIGINAL_SHAPE_FILE_FIELD,
  FRAME_SHAPE_VALID_FIELD,
  ORIGINAL_SHAPE_FILE_NAME_FIELD,
  FRAME_LIBRARY_FIELD,
  FRAME_LIBRARY_ID_FIELD
} from '../../../../constants/orderFields';
import { Modal, Button, Row, Col, Image, Glyphicon } from 'react-bootstrap';

export default class SelectShape extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.showChoices = this.showChoices.bind(this);
    this.closeChoices = this.closeChoices.bind(this);
    this.buttonText = this.buttonText.bind(this);
  }

  componentWillUnmount() {
    const { input: { onChange } } = this.props;
    onChange(null);
  }

  showChoices () {
    this.setState({showChoices: true});
  }

  closeChoices () {
    this.setState({showChoices: false});
  }

  onSelect (frameId) {
    const {
      loadProductRxLens,
      change: changeOrder,
      input: { onChange }
    } = this.props;
    onChange(frameId);
    this.closeChoices();
    changeOrder(ORIGINAL_SHAPE_FILE_FIELD, null);
    changeOrder(ORIGINAL_SHAPE_FILE_NAME_FIELD, null);
    changeOrder(FRAME_SHAPE_VALID_FIELD, true);
    changeOrder(FRAME_LIBRARY_FIELD, null);
    changeOrder(FRAME_LIBRARY_ID_FIELD, null);

    loadProductRxLens({ frameLibraryId: null });
  }

  buttonText () {
    let text = this.props.label;
    if(this.props.value) {
      return (
        <div>
          <Glyphicon glyph="record" /> {text}
        </div>
      );
    }
    return text;
  }

  render () {
    if(!this.props.show) return null;
    const { input, frameShapes } = this.props;
    return (
      <span>
        <Button
          onClick={this.showChoices}>
          { this.buttonText() }
        </Button>
        <input type="hidden" {...input} />
        <Modal
          show={this.state.showChoices}
          bsSize="large"
          onHide={this.closeChoices}
        >
          <Modal.Header closeButton>
            <Modal.Title>Frame Shapes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {
                map(frameShapes, frame => {
                  return(
                    <Col xs={2} className="frame-shape__card text-center" key={frame.number}>
                      <div className="frame-shape__body" onClick={this.onSelect.bind(this, frame.id)}>
                        <p>Shape {parseInt(frame.number)}</p>
                        <Image src={frame.image_url} rounded />
                      </div>
                    </Col>
                  );
                })
              }
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              ref="cancelBtn"
              onClick={this.closeChoices}
            >Close</Button>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }

}

SelectShape.propTypes = {
  show: PropTypes.bool,
  frameShapes: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.string,
  change: PropTypes.func.isRequired,
  input: PropTypes.object
};

SelectShape.defaultProps = {
  show: false,
  frameShapes: [],
  label: "Select Shape",
};
