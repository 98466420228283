import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import isBoolean from "lodash/isBoolean";
import isNumber from "lodash/isNumber";

const isPresent = value => {
  if (isNil(value)) return false;
  if (isBoolean(value)) return true;
  if (isNumber(value)) return true;
  return !isEmpty(value);
};

export default isPresent;
