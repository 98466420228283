import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FITTING_HEIGHT } from 'constants/mapOrderColumns';
import TextInput from 'components/default_redux/TextInput';
import { required, min, max } from 'utils/Validators';

export default class FHInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    const {
      label, show, name,
    } = this.props;
    if(!show) return null;

    const requiredInput = this.props.required;
    return (
      <Field component={TextInput}
        label={label}
        type="number"
        step="any"
        name={`${name}[${FITTING_HEIGHT}]`}
        suffixAddon={`${this.props.min}-${this.props.max} mm.`}
        inputClass="col-xs-8"
        labelClass="col-xs-4"
        required={requiredInput}
        validate={[
          required.bind(null, requiredInput),
          min.bind(null, this.props.min),
          max.bind(null, this.props.max),
        ]}
      />
    );
  }
}

FHInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

FHInput.defaultProps = {
  label: "Fitting Height",
  show: false,
  required: false,
  min: 0,
  max: 0,
};
