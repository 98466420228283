import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { DEGRESSION_FIELD } from '../../../../constants/orderFields';
import SelectInput from '../../../default_redux/SelectInput';
import {
  required as requiredValidate,
  max as maxValidate,
  min as minValidate,
  period as periodValidate
} from '../../../../utils/Validators';
import { genRange } from '../../../../utils/GenOptions';

export default class Degression extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    if(!this.props.show) return null;
    const {
      label,
      required: requiredValue,
      min: minValue, max: maxValue, period: periodValue
    } = this.props;
    // console.log(`Render ${this.props.label}`);
    return (
      <Field component={SelectInput}
        label={label}
        name={DEGRESSION_FIELD}
        options={genRange(minValue, maxValue, periodValue, 2, false, 'asc')}
        liveSearch={true}
        required={requiredValue}
        validate={[
          requiredValidate.bind(null, requiredValue),
          maxValidate.bind(null, maxValue),
          minValidate.bind(null, minValue),
          periodValidate.bind(null, periodValue)
        ]}
        labelClass={'col-xs-3'}
        selectWrapperClass={'col-xs-6'}
      />
    );
  }
}

Degression.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  period: PropTypes.number,
};

Degression.defaultProps = {
  label: "Degression",
  show: false,
  required: false,
  min: 0,
  max: 0,
  period: 0,
};
