import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { Row, Col, Form, Glyphicon, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import LensOrdersManagement from './LensOrdersManagement';
import StockOrderConfirmModal from './StockOrderConfirmModal';
import {
  AGENT_ID,
  LEFT_SIDE, RIGHT_SIDE,
  QUANTITY
} from 'constants/mapOrderColumns';
import SelectUser from 'components/shared/SelectUser';
import isPresent from 'utils/isPresent';

class NewLensOrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
    };
    this.sortCustomers = this.sortCustomers.bind(this);
  }

  onSubmit (values) {
    const userId = values.user_id;
    const orders = map(values.orders, (order)=>{
      order.user_id = userId;
      return order;
    });
    this.setState({confirm: true, orders: orders});
  }

  render () {
    const {
      handleSubmit,
      sources: { searchAgentsUrl, productsUrl },
      orders, order,
      change, unregisterField,
      formAction, formConfirmUrl, formUrl,
      locales, currentUserId,
      actions, currentFilters,
      s3SignUrl,
    } = this.props;
    return (
      <Form
        className="form-horizontal"
        onSubmit={handleSubmit(this.onSubmit.bind(this))}
      >
        <Row>
          <Col xs={12}>
            <Col xsOffset={2} xs={7}>
              <SelectUser
                label={locales.order.userId}
                name={AGENT_ID}
                loadUrl={searchAgentsUrl}
                labelClass="col-xs-4"
                inputClass="col-xs-5"
                liveSearch={true}
                selectFirst={true}
                required={true}
              />
            </Col>
          </Col>
          <Col xs={12}>
            <FieldArray component={LensOrdersManagement}
              name="orders"
              orders={orders}
              locales={locales}
              change={change}
              unregisterField={unregisterField}
              currentOrder={order}
              currentUserId={currentUserId}
              productsUrl={productsUrl}
              actions={actions}
              currentFilters={currentFilters}
              s3SignUrl={s3SignUrl}
            />
          </Col>
          <Col xsOffset={3} xs={6}>
            <Button
              type="submit"
              ref="confirmBtn"
              bsStyle="primary"
              block
              disabled={this.state.confirm}
            >{this.confirmTextBtn.bind(this)()}</Button>
          </Col>

          {
            this.state.confirm ?
              <StockOrderConfirmModal
                orders={this.state.orders}
                formId={'stock-lenses-ordering'}
                formAction={formAction}
                confirmUrl={formConfirmUrl}
                formUrl={formUrl}
                afterHidden={this.handleAfterModalHidden.bind(this)}
                locales={locales}
                s3SignUrl={s3SignUrl}
             /> : null
         }
        </Row>
      </Form>
    );
  }


  sortCustomers () {
    return sortBy(this.props.customers, (customer)=>{
      return [customer.role, customer.company];
    });
  }

  confirmTextBtn () {
    if(!this.state.confirm) return this.props.locales.buttons.confirm;
    return(
      <div>
        {this.props.locales.buttons.confirm}
        <Glyphicon glyph="refresh" className="glyphicon-refresh-animate" />
      </div>
    );
  }

  handleAfterModalHidden (_e) {
    this.setState({confirm: false});
  }
}

NewLensOrderForm.propTypes = {
  locales: PropTypes.object,
  currentUserId: PropTypes.number,
  formAction: PropTypes.string,
  formConfirmUrl: PropTypes.string,
  formUrl: PropTypes.string,
  customers: PropTypes.array,
  sources: PropTypes.shape({
    searchAgentsUrl: PropTypes.string,
  }),
  order: PropTypes.object,
  s3SignUrl: PropTypes.string,

  // Redux Store
  actions: PropTypes.object,
  currentFilters: PropTypes.object,

  // Redux Form
  orders: PropTypes.array,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  unregisterField: PropTypes.func,
};

let form = reduxForm({
  form: 'stockOrder',
})(NewLensOrderForm);

// const selector = formValueSelector('stockOrder'); // <-- same as form name
form = connect(
  (state, props) => {
    const initOrder = {
      [LEFT_SIDE]: {
        [QUANTITY]: 1,
      },
      [RIGHT_SIDE]: {
        [QUANTITY]: 1,
      },
    };
    const initialValues = {
      orders: [initOrder],
      [AGENT_ID]: props.currentUserId
    };
    const order = props.order || {};
    if(isPresent(order)) {
      order.sku = order.optical_informations_attributes.glazziq_sku;
      initialValues.orders = [order];
      initialValues.user_id = order.user_id;
    }
    return {
      initialValues,
    //   currentRole
    };
  }
)(form);

export default form;
