import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { CYL } from '../../../../constants/mapOrderColumns';
import TextInput from '../../../default_redux/TextInput';
import { required } from '../../../../utils/Validators';

export default class CYLInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    const { show } = this.props;
    if(!show) return null;
    // console.log(`Render ${this.props.label}`);
    const { label, attrNestedName } = this.props;
    const requiredInput = this.props.required;
    return (
      <Field component={TextInput}
        label={label}
        name={`order${attrNestedName}[${CYL}]`}
        labelClass="col-xs-4"
        inputClass="col-xs-8"
        required={requiredInput}
        validate={[
          required.bind(null, requiredInput),
        ]}
        readOnly={true}
      />
    );
  }
}

CYLInput.propTypes = {
  label: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  attrNestedName: PropTypes.string,
};

CYLInput.defaultProps = {
  label: "CYL",
  show: false,
  required: false,
  min: 0,
  max: 0,
  attrNestedName: '',
};
