import React from 'react';
import PropTypes from 'prop-types';
import isPresent from 'utils/isPresent';
import TimeCountdown from 'components/shared/TimeCountdown';
import moment from 'moment';

export default class FetchError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderCoundown = this.renderCoundown.bind(this);
  }

  renderCoundown (message) {
    const {
      refreshingText,
      error: {
        retryDelay: retryDelayMs
      }
    } = this.props;
    const expiredTime = moment().add(retryDelayMs/1000 || 0, 'seconds');
    const coundownFormat = 's';
    return <TimeCountdown
      coundownFormat={coundownFormat}
      expiredTime={expiredTime}
      replaceText="%{time}"
      countMessage={message}
      endMessage={refreshingText}
    />;
  }

  render() {
    const { error } = this.props;
    if(!isPresent(error)) return null;
    const {
      errorText, timeoutText, badInternetText, refreshingText,
      errorProp
    } = this.props;
    let { enabledCountdown } = this.props;
    let message;
    const type = error.type;
    switch (type) {
      case 'error':
        message = errorText;
        break;
      case 'timeout':
        enabledCountdown = true;
        message = timeoutText;
        break;
      case 'internet':
        enabledCountdown = true;
        message = badInternetText;
        break;
      case 'refreshing':
        message = refreshingText;
        break;
      default:
        message = this.props[errorProp] || errorText;
    }
    if(enabledCountdown) {
      message = this.renderCoundown(message);
    }
    return(
      <span className="text-danger">{message}</span>
    );
  }
}

FetchError.propTypes = {
  error: PropTypes.object,
  timeoutText: PropTypes.string,
  errorText: PropTypes.string,
  badInternetText: PropTypes.string,
  refreshingText: PropTypes.string,
  enabledCountdown: PropTypes.bool,
  errorProp: PropTypes.string
};

FetchError.defaultProps = {
  errorText: 'The server is having some problems. We are fixing, please use it later.',
  timeoutText: 'The loading is timeout. It will refresh automatically in %{time} seconds.',
  badInternetText: 'Your internet disconnected. It will refresh automatically in %{time} seconds.',
  refreshingText: 'It is refreshing',
  enabledCountdown: false,
};
