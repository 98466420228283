export const DOCUMENT_ATTR = "document";

export const TITLE = "title";
export const LANG = "lang";
export const STATUS = "status";
export const TAG = "display_tag";
export const FILES = "files";
export const FILE = "file";
export const PROCESSING_FILE = "processing_file";
export const ORIGINAL_FILE_NAME = "original_file_name";
export const EDITABLE_FILES = "editable_files";
export const EDITABLE_FILE = "editable_file";
export const PROCESSING_EDITABLE_FILE = "processing_editable_file";
export const EDITABLE_ORIGINAL_FILE_NAME = "editable_original_file_name";

export const TITLE_FIELD = `${DOCUMENT_ATTR}.${TITLE}`;
export const LANG_FIELD = `${DOCUMENT_ATTR}.${LANG}`;
export const STATUS_FIELD = `${DOCUMENT_ATTR}.${STATUS}`;
export const TAG_FIELD = `${DOCUMENT_ATTR}.${TAG}`;
export const FILES_FIELD = `${DOCUMENT_ATTR}.${FILES}`;
export const FILE_FIELD = `${DOCUMENT_ATTR}.${FILE}`;
export const PROCESSING_FILE_FIELD = `${DOCUMENT_ATTR}.${PROCESSING_FILE}`;
export const ORIGINAL_FILE_NAME_FIELD = `${DOCUMENT_ATTR}.${ORIGINAL_FILE_NAME}`;
export const EDITABLE_FILES_FIELD = `${DOCUMENT_ATTR}.${EDITABLE_FILES}`;
export const EDITABLE_FILE_FIELD = `${DOCUMENT_ATTR}.${EDITABLE_FILE}`;
export const PROCESSING_EDITABLE_FILE_FIELD = `${DOCUMENT_ATTR}.${PROCESSING_EDITABLE_FILE}`;
export const EDITABLE_ORIGINAL_FILE_NAME_FIELD = `${DOCUMENT_ATTR}.${EDITABLE_ORIGINAL_FILE_NAME}`;
