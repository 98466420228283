import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { Field } from 'redux-form';
import SelectInput from 'components/default_redux/SelectInput';
import { AGENT_ID } from 'constants/mapOrderColumns';
import { required as requiredValidate } from 'utils/Validators';
import GenUrl from 'utils/GenUrl';
import isPresent from 'utils/isPresent';

export default class SelectUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newValue: null
    };
    this.loadUsers = this.loadUsers.bind(this);
    this.loadUsersDelay = this.loadUsersDelay.bind(this);
    this.sortCustomers = this.sortCustomers.bind(this);
    this.userName = this.userName.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  sortCustomers (users) {
    return sortBy(users, (user)=>{
      return [user.roles[0], user.company];
    });
  }

  loadUsersDelay (input, callback) {
    const ms = 1000; // milliseconds
    clearTimeout(this.state.loadUsersProcessing);
    let loadUsersProcessing = setTimeout(()=> {
      this.loadUsers(input).then(users => {
        callback(null, {
          options: users,
          complete: true
        });
      });
    }, ms);
    this.setState({loadUsersProcessing});
  }

  userName (user) {
    const { displayRolePrefix } = this.props;
    let name = `${user.company || user.username}`;
    if(displayRolePrefix) name = `${user.roles[0]} :: ${name}`;
    return name;
  }

  onSelectChange (e) {
    this.props.handleChange(e);
    this.setState({ newValue: e.target.value });
  }

  loadUsers (input) {
    const { loadUrl } = this.props;
    const query = {
      text: input
    };
    if(isPresent(this.state.newValue)) {
      query.default_user_id = this.state.newValue;
    }
    const url = GenUrl(loadUrl, { q: query });
    return(
      fetch(url, {
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        return response.json();
      }).then(json => {
        const users = json.data;
        return map(this.sortCustomers(users), user => {
          return({
            name: this.userName(user),
            value: user.id
          });
        });
      })
    );
  }

  render() {
    const {
      label, name, labelClass, inputClass, initValue,
      liveSearch, selectFirst, required, multiLoadUrl,
      blankDisplay
    } = this.props;
    return(
      <Field component={SelectInput}
        ref="parentSelect"
        name={name}
        label={label}
        initValue={initValue}
        loadOptions={this.loadUsersDelay}
        validate={[ requiredValidate.bind(null, required) ]}
        liveSearch={liveSearch}
        selectFirst={selectFirst}
        labelClass={labelClass}
        selectWrapperClass={inputClass}
        required={required}
        multiLoadUrl={multiLoadUrl}
        handleChange={this.onSelectChange}
        blankDisplay={blankDisplay}
      />
    );
  }
}

SelectUser.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  loadUrl: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  initValue: PropTypes.any,
  liveSearch: PropTypes.bool,
  selectFirst: PropTypes.bool,
  required: PropTypes.bool,
  displayRolePrefix: PropTypes.bool,
  multiLoadUrl: PropTypes.bool,
  handleChange: PropTypes.func,
  blankDisplay: PropTypes.string,
};

SelectUser.defaultProps = {
  label: 'Select User',
  name: AGENT_ID,
  initValue: null,
  displayRolePrefix: true,
  selectFirst: false,
  handleChange: (_e) => {},
};
