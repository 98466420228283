import React from 'react';
import PropTypes from 'prop-types';
import { CUSTOMIZE_INSET_OPTION } from '../../../../constants/mapOrderColumns';
import { mapDatabase } from  '../../../../constants';
// import Quantity from './Quantity';
import Inset from './Inset';
import SPH from './SPH';
import CYL from './CYL';
import AXIS from './AXIS';
import ADD from './ADD';
import FarPD from './FarPD';
import NearPD from './NearPD';
import FH from './FH';
import SH from './SH';
import CVD from './CVD';
import FFA from './FFA';
import PTA from './PTA';
import ET from './ET';
import Decenter from './Decenter';
import Prism from './Prism';

export default class RxPrescriptionSide extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.lensSideAttrName = this.lensSideAttrName.bind(this);
    this.attrNestedName = this.attrNestedName.bind(this);
    this.renderNoSide = this.renderNoSide.bind(this);
  }

  lensSideAttrName () {
    return mapDatabase.order.properties.side[this.props.side];
  }

  attrNestedName () {
    return `[${this.lensSideAttrName()}]`;
  }

  renderNoSide () {
    const {
      locales: {
        hints: {
          disabledSideLens: {
            [this.props.side]: disabledSideLensText
          }
        }
      }
    } = this.props;
    return(
      <p className="text-center">
        {disabledSideLensText}
      </p>
    );
  }

  displayOptionFields () {
    const quantity = parseInt(this.props.quantity) || 0;
    if(quantity < 1) return this.renderNoSide();

    const {
      cyl, farPD, decenter, prism
    } = this.props;
    const {
      enabled_inset, inset_values,
      enabled_sph, sph_values,
      enabled_cyl, cyl_values,
      enabled_axis, axis_values,
      enabled_add, add_values,
      enabled_et, et_values,
      enabled_far_pd, far_pd_values,
      enabled_near_pd,
      enabled_fitting_height, fitting_height_values,
      enabled_seg_height, seg_height_values,
      enabled_cvd, cvd_values,
      enabled_ffa, ffa_values,
      enabled_pta, pta_values,
      enabled_decenter, decenter_values,
      enabled_prism
    } = this.props.product.properties;
    return(
      <div>
        { enabled_inset.show && <Inset
          label="Inset"
          attrNestedName={this.attrNestedName()}
          {...enabled_inset}
          show={this.props.insetType == CUSTOMIZE_INSET_OPTION}
          {...inset_values}
        /> }
        { enabled_sph.show && <SPH
          label="SPH"
          attrNestedName={this.attrNestedName()}
          {...enabled_sph}
          {...sph_values}
        /> }
        { enabled_cyl.show && <CYL
          label="CYL"
          attrNestedName={this.attrNestedName()}
          {...enabled_cyl}
          {...cyl_values}
        /> }
        { (enabled_axis.show && this.props.cyl && this.props.cyl != 0) && <AXIS
          label="AXIS"
          attrNestedName={this.attrNestedName()}
          show={enabled_axis.show && this.props.cyl && this.props.cyl != 0}
          required={cyl && cyl != 0}
          {...axis_values}
        /> }
        { enabled_add.show && <ADD
          label="ADD"
          attrNestedName={this.attrNestedName()}
          {...enabled_add}
          {...add_values}
        /> }
        { enabled_et.show && <ET
          label="ET"
          attrNestedName={this.attrNestedName()}
          {...enabled_et}
          {...et_values}
        /> }
        { enabled_far_pd.show && <FarPD
          label="Far PD"
          attrNestedName={this.attrNestedName()}
          {...enabled_far_pd}
          {...far_pd_values}
        /> }
        { (farPD && enabled_near_pd.show) && <NearPD
          label="Near PD"
          attrNestedName={this.attrNestedName()}
          {...enabled_near_pd}
          show={farPD && enabled_near_pd.show}
          farPD={farPD}
        /> }
        { enabled_fitting_height.show && <FH
          label="Fitting Height"
          attrNestedName={this.attrNestedName()}
          {...enabled_fitting_height}
          {...fitting_height_values}
        /> }
        { enabled_seg_height.show && <SH
          label="Seg Height"
          attrNestedName={this.attrNestedName()}
          {...enabled_seg_height}
          {...seg_height_values}
        /> }
        { enabled_cvd.show && <CVD
          label="CVD"
          attrNestedName={this.attrNestedName()}
          {...enabled_cvd}
          {...cvd_values}
        /> }
        { enabled_ffa.show && <FFA
          label="FFA"
          attrNestedName={this.attrNestedName()}
          {...enabled_ffa}
          {...ffa_values}
        /> }
        { enabled_pta.show && <PTA
          label="PTA"
          attrNestedName={this.attrNestedName()}
          {...enabled_pta}
          {...pta_values}
        /> }
        { enabled_decenter.show && <Decenter
          label="Decenter"
          lensSideAttrName={this.lensSideAttrName()}
          attrNestedName={this.attrNestedName()}
          {...enabled_decenter}
          {...decenter_values}
          decenter={decenter}
        /> }
        { enabled_prism.show && <Prism
          label="Prism"
          lensSideAttrName={this.lensSideAttrName()}
          attrNestedName={this.attrNestedName()}
          {...enabled_prism}
          prism={prism}
        /> }
      </div>
    );
  }


  render () {
    // console.log(`Render Prescription Side ${this.props.side}`);
    return (
      <div className="panel panel__bg--gray">
        <div className="panel-heading">
          <h3 className="panel-title text-center"
            style={{textTransform: 'uppercase'}}
          >
            {this.props.side}
          </h3>
        </div>
        <div className="panel-body">
          {/* <Quantity
            label="Quantity"
            attrNestedName={this.attrNestedName()}
            changeOrder={this.props.changeOrder}
          /> */}
          { this.displayOptionFields.bind(this)() }
        </div>
      </div>
    );
  }
}

RxPrescriptionSide.propTypes = {
  side: PropTypes.oneOf(['left', 'right']),
  enabledInset: PropTypes.bool,
  order: PropTypes.object,
  changeOrder: PropTypes.func.isRequired,
  product: PropTypes.shape({
    properties: PropTypes.shape({
      enabled_inset: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_sph: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_cyl: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_add: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_et: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_far_pd: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_near_pd: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_fitting_height: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_seg_height: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_cvd: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_ffa: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_pta: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_decenter: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_prism: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      enabled_axis: PropTypes.shape({
        show: PropTypes.bool,
        required: PropTypes.bool,
      }).isRequired,
      add_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      cyl_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      et_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
        period: PropTypes.number,
      }),
      fitting_height_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      seg_height_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      cvd_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      ffa_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      pta_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      axis_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      sph_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      far_pd_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      inset_values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      decenter_values: PropTypes.shape({
        x: PropTypes.shape({
          min: PropTypes.number,
          max: PropTypes.number,
          period: PropTypes.number,
        }),
        y: PropTypes.shape({
          min: PropTypes.number,
          max: PropTypes.number,
          period: PropTypes.number,
        }),
      }),
    })
  }),
  locales: PropTypes.shape({
    hints: PropTypes.shape({
      disabledSideLens: PropTypes.shape({
        left: PropTypes.string,
        right: PropTypes.string,
      }),
    }),
  }),
  quantity: PropTypes.any,
  cyl: PropTypes.any,
  insetType: PropTypes.any,
  farPD: PropTypes.any,
  decenter: PropTypes.any,
  prism: PropTypes.any,
};

RxPrescriptionSide.defaultProps = {
};
