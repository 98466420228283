import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button, Glyphicon } from 'react-bootstrap';
import {
  ORIGINAL_SHAPE_FILE_FIELD,
  ORIGINAL_SHAPE_FILE_NAME_FIELD,
  FRAME_ID_FIELD,
  FRAME_LIBRARY_FIELD,
  FRAME_LIBRARY_ID_FIELD,
  FRAME_SHAPE_VALID_FIELD,
  FRAME_SHAPE_FIELD
} from '../../../../constants/orderFields';

export default class UploadShape extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.buttonText = this.buttonText.bind(this);
  }

  buttonText () {
    let text = this.props.label;
    if(this.props.shapeFile) {
      return (
        <div>
          <Glyphicon glyph="record" /> {text}
        </div>
      );
    }
    return text;
  }

  onDrop (acceptedFiles, _rejectedFiles) {
    const { loadProductRxLens, change } = this.props;
    const file = acceptedFiles[0];
    change(FRAME_ID_FIELD, null);
    change(FRAME_LIBRARY_FIELD, null);
    change(FRAME_LIBRARY_ID_FIELD, null);

    change(ORIGINAL_SHAPE_FILE_FIELD, acceptedFiles);
    change(ORIGINAL_SHAPE_FILE_NAME_FIELD, file.name);
    change(FRAME_SHAPE_FIELD, null);
    change(FRAME_SHAPE_VALID_FIELD, true);

    loadProductRxLens({ frameLibraryId: null });
  }

  render () {
    if(!this.props.show) return null;
    const { allowFileExtensions } = this.props;
    const options = {
      onDrop: this.onDrop.bind(this),
      className: "dropzone",
      multiple: false
    };
    if(allowFileExtensions) {
      options.accept = allowFileExtensions.join(',');
    }
    return (
      <span>
        <Dropzone
          {...options}>
          <Button
            onClick={this.showChoices}>
            { this.buttonText() }
          </Button>
        </Dropzone>
      </span>
    );
  }

}

UploadShape.propTypes = {
  show: PropTypes.bool,
  shapeFile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  label: PropTypes.string,
  change: PropTypes.func.isRequired,
  s3SignUrl: PropTypes.string,
  allowFileExtensions: PropTypes.array,
};

UploadShape.defaultProps = {
  show: false,
  allowFileExtensions: []
};
