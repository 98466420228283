import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SPH } from 'constants/mapOrderColumns';
import TextInput from 'components/default_redux/TextInput';
import { required } from 'utils/Validators';

export default class SPHInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    const { label, name, show } = this.props;
    if(!show) return null;

    const requiredInput = this.props.required;
    return (
      <Field component={TextInput}
        label={label}
        name={`${name}[${SPH}]`}
        labelClass="col-xs-4"
        inputClass="col-xs-8"
        required={requiredInput}
        validate={[
          required.bind(null, requiredInput),
        ]}
        readOnly={true}
      />
    );
  }
}

SPHInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

SPHInput.defaultProps = {
  label: "SPH",
  show: false,
  required: false,
  min: 0,
  max: 0,
};
