import React, { Component } from "react";
import {
  createStore,
  bindActionCreators,
  applyMiddleware,
  compose
} from "redux";
import thunk from "redux-thunk";
import { connect, Provider } from "react-redux";
import { productsList } from "reducers";
import * as productsListAction from "actions/productsListAction";
// import update from 'immutability-helper';
import ProductManagement from "components/users/products/ProductManagement";

const mapStateToProps = state => {
  // console.log('mapStateToProps ProductManagementApp : ', state);
  return {
    products: state.productsListReducer.products || [],
    availabledProducts: state.productsListReducer.availabledProducts || [],
    storeSKUs: state.productsListReducer.storeSKUs || [],
    loading: state.productsListReducer.loading || {},
    paginationParams: state.productsListReducer.paginationParams || {}
  };
};

const mapDispatchToProps = dispatch => {
  // console.log('mapDispatchToProps : ', bindActionCreators(productsListAction, dispatch));
  return bindActionCreators(productsListAction, dispatch);
};

const ProductManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductManagement);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  productsList,
  composeEnhancers(applyMiddleware(thunk))
);

export default class ProductManagementApp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Provider store={store}>
        <ProductManagementContainer {...this.props} />
      </Provider>
    );
  }
}
